import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {Button, Col, Collapse, Input, Label, Row} from 'reactstrap'
import ListMasterModal from '../ListMasterModal'
import {ActionButton} from '../ActionButton'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'
import InputPopupModal from '../../../Helpers/InputPopupModal'
import {
  officeFunctionDesignatorOptions,
  participantIdentifierOptions,
} from '../../../Helpers/options'
import Select from 'react-select'



export const OtherParticipantInformation = ({
  errors,
  control,
  clearErrors,
  setValue,
  airportList,
  isDetail = false,
  fields,
  append,
  remove,
}) => {
  const [isOpen, setIsOpen] = useState(true)


  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const [showPopup, setShowPopup] = useState({
    otherParticipantDestinationAirport: false,
    senderOfficeAirportCode: false,
  })

  const onConfirm = (field, value) => {
    clearErrors(field)
    setValue(field, value)
    setShowPopup({
      ...showPopup,
      [field]: false,
    })
  }

  const checkValidData = (field, data) => {
    let isExists = airportList.find((a) => a.code === data)
    if (!isExists) {
      setValue(field, '')
      setShowPopup({
        ...showPopup,
        [field]: true,
      })
    } else {
      setValue(field, data)
    }
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Other Participant Information</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        {fields?.map((item, index) => (
          <OtherParticipantInformationGroup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            checkValidData={checkValidData}
            index={index}
            errors={errors}
            control={control}
            remove={remove}
            setValue={setValue}
          />
        ))}
        {!isDetail && (
          <Row className='gy-2 ps-1'>
            <Col lg={4} md={6} sm={12}>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='m-0 w-50'></div>
                <div className='w-50'>
                  <Button
                    type='button'
                    className='btn btn-light'
                    style={{
                      width: 120,
                      color: '#69809A',
                      backgroundColor: '#E7EBEF',
                    }}
                    onClick={() =>
                      append({
                        countryCode: '',
                        informationIdentifier: '',
                        customInfoIdentifier: '',
                        supplementaryCustomInformation: '',
                      })
                    }
                  >
                    Add Row
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Collapse>

      {!isDetail &&
        Object.keys(showPopup).map((key) => {
          return (
            <ListMasterModal
              key={key}
              field={key}
              show={showPopup[key]}
              onCloseClick={() => {
                setShowPopup((v) => ({...v, [key]: !showPopup[key]}))
              }}
              onConfirm={onConfirm}
              dataList={airportList}
              title={`Airport City Code`}
              mapData={{
                name: 'CITY PORT NAME',
                code: 'CITY PORT CODE',
              }}
            />
          )
        })}
    </div>
  )
}


function OtherParticipantInformationGroup({isDetail, index, setValue, errors, control, checkValidData,showPopup, setShowPopup, remove}) {
  const [
    isOtherParticipantOfficeMessageAddress,
    setIsOtherParticipantOfficeMessageAddress,
  ] = useState(false)
  const [
    isOtherParticipantIdentification,
    setIsOtherParticipantIdentification,
  ] = useState(false)

  const togleOfficeMessageAddress = (value) => {
    if(!value){
      setValue(`otherParticipantOffice.${index}.otherParticipantOfficeAirportCode`,'')
      setValue(`otherParticipantOffice.${index}.otherParticipantOfficeFunctionDesignator`,'')
      setValue(`otherParticipantOffice.${index}.otherParticipantCompanyDesignator`,'')
    }
    setIsOtherParticipantOfficeMessageAddress(value)
  }

  const togleIdentification = (value) => {
    if(!value){
      setValue(`otherParticipantOffice.${index}.otherParticipantParticipantIdentifier`,'')
      setValue(`otherParticipantOffice.${index}.otherParticipantDestinationAirport`,'')
      setValue(`otherParticipantOffice.${index}.otherParticipantParticipantCode`,'')
    }
    setIsOtherParticipantIdentification(value)
  }

  return (
    <Row className='gy-2 ps-1 mb-4'>
      <Col lg={12} className='text-end mb-n2'>
        {!isDetail && index > 0 ? (
          <ActionButton
            onClick={() => remove(index)}
            action='remove'
          />
        ) : null}
      </Col>
      <Col lg={4} md={6} sm={12}>
        <Label className='form-label fw-semibold m-0 text-decoration-underline'>
          Other Participant Office File Reference
        </Label>
        <ErrorFieldWrapper
          error={errors.otherParticipantOffice?.[index]?.otherParticipantOfficeFileReference}
          message={errors.otherParticipantOffice?.[index]?.otherParticipantOfficeFileReference?.message}
        >
          <div className='d-flex align-items-center justify-content-between'>
            <Label
              htmlFor={`otherParticipantOffice.${index}.otherParticipantOfficeFileReference`}
              className='form-label m-0 w-50 w-50'
            >
              File Reference
            </Label>
            <Controller
              id={`otherParticipantOffice.${index}.otherParticipantOfficeFileReference`}
              name={`otherParticipantOffice.${index}.otherParticipantOfficeFileReference`}
              type='text'
              control={control}
              render={({field}) => (
                <InputTextFhlFwb
                  className='w-50'
                  {...field}
                  value={field.value}
                  disabled={isDetail}
                  maxLength={15}
                />
              )}
            />
          </div>
        </ErrorFieldWrapper>
      </Col>
      <Col lg={4} md={6} sm={12}>
        <Label className='form-label m-0 text-decoration-underline'></Label>
        <ErrorFieldWrapper
          error={errors.otherParticipantOffice?.[index]?.otherParticipantName}
          message={errors.otherParticipantOffice?.[index]?.otherParticipantName?.message}
        >
          <div className='d-flex align-items-center justify-content-between'>
            <Label
              htmlFor={`otherParticipantOffice.${index}.otherParticipantName`}
              className='form-label m-0 w-50 w-50'
            >
              Name
            </Label>
            <Controller
              id={`otherParticipantOffice.${index}.otherParticipantName`}
              name={`otherParticipantOffice.${index}.otherParticipantName`}
              type='text'
              control={control}
              render={({field}) => (
                <InputTextFhlFwb
                  className='w-50'
                  {...field}
                  value={field.value}
                  disabled={isDetail}
                  maxLength={35}
                />
              )}
            />
          </div>
        </ErrorFieldWrapper>
      </Col>
      <Col lg={4} md={6} sm={12}></Col>
      <Col lg={4} md={6} sm={12}>
      <div className='d-flex gap-2'>
          <input
            type='checkbox'
            id='otherParticipantOfficeMessageAddress'
            className=' border-3'
            checked={isOtherParticipantOfficeMessageAddress}
            disabled={isOtherParticipantIdentification}
            onChange={(e) =>
              togleOfficeMessageAddress(e.target.checked)
            }
          />
          <Label
            htmlFor='otherParticipantOfficeMessageAddress'
            className='form-label fw-semibold m-0 text-decoration-underline'
          >
            Other Participant Office Message Address
          </Label>
        </div>
   
        <ErrorFieldWrapper
          error={errors.otherParticipantOffice?.[index]?.otherParticipantOfficeAirportCode}
          message={errors.otherParticipantOffice?.[index]?.otherParticipantOfficeAirportCode?.message}
        >
          <div className='d-flex align-items-center justify-content-between'>
            <Label
              htmlFor={`otherParticipantOffice.${index}.otherParticipantOfficeAirportCode`}
              className='form-label m-0 w-50 w-50'
            >
              Airport/City Code
            </Label>
            <Controller
              id={`otherParticipantOffice.${index}.otherParticipantOfficeAirportCode`}
              name={`otherParticipantOffice.${index}.otherParticipantOfficeAirportCode`}
              type='text'
              control={control}
              render={({field}) => (
                <div className='d-flex w-50 align-items-stretch gap-1'>
                  <InputPopupModal
                    fieldName={`otherParticipantOffice.${index}.otherParticipantOfficeAirportCode`}
                    checkValidData={checkValidData}
                    {...field}
                    value={field.value}
                    maxLength={3}
                    disabled={
                      isDetail || !isOtherParticipantOfficeMessageAddress
                    }
                  />
                  {!isDetail && (
                    <ActionButton
                      disabled={!isOtherParticipantOfficeMessageAddress}
                      onClick={() =>
                        setShowPopup({
                          ...showPopup,
                          [`otherParticipantOffice.${index}.otherParticipantOfficeAirportCode`]: true,
                        })
                      }
                    />
                  )}
                </div>
              )}
            />
          </div>
        </ErrorFieldWrapper>
      </Col>
   

      <Col lg={4} md={6} sm={12}>
        <Label className='form-label m-0'></Label>
        <ErrorFieldWrapper
          error={errors.otherParticipantOffice?.[index]?.otherParticipantOfficeFunctionDesignator}
          message={
            errors.otherParticipantOffice?.[index]?.otherParticipantOfficeFunctionDesignator?.message
          }
        >
          <div className='d-flex align-items-center justify-content-between'>
            <Label
              htmlFor={`otherParticipantOffice.${index}.otherParticipantOfficeFunctionDesignator`}
              className='form-label m-0 w-50 w-50'
            >
              Office Function Designator
            </Label>
            <Controller
              id={`otherParticipantOffice.${index}.otherParticipantOfficeFunctionDesignator`}
              name={`otherParticipantOffice.${index}.otherParticipantOfficeFunctionDesignator`}
              type='text'
              control={control}
              render={({field}) => (
                <Select
                  {...field}
                  isSearchable={false}
                  className='w-50'
                  isDisabled={
                    isDetail || !isOtherParticipantOfficeMessageAddress
                  }
                  isClearable
                  defaultOptions
                  value={
                    officeFunctionDesignatorOptions.find(
                      (option) => option.value === field.value,
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                  options={officeFunctionDesignatorOptions}
                />
              )}
            />
          </div>
        </ErrorFieldWrapper>
      </Col>

      <Col lg={4} md={6} sm={12}>
        <Label className='form-label m-0 text-decoration-underline'></Label>
        <ErrorFieldWrapper
          error={errors.otherParticipantOffice?.[index]?.otherParticipantCompanyDesignator}
          message={errors.otherParticipantOffice?.[index]?.otherParticipantCompanyDesignator?.message}
        >
          <div className='d-flex align-items-center justify-content-between'>
            <Label
              htmlFor={`otherParticipantOffice.${index}.otherParticipantCompanyDesignator`}
              className='form-label m-0 w-50 w-50'
            >
              Company Designator
            </Label>
            <Controller
              id={`otherParticipantOffice.${index}.otherParticipantCompanyDesignator`}
              name={`otherParticipantOffice.${index}.otherParticipantCompanyDesignator`}
              type='text'
              control={control}
              render={({field}) => (
                <InputTextFhlFwb
                  className='w-50'
                  {...field}
                  value={field.value}
                  disabled={
                    isDetail || !isOtherParticipantOfficeMessageAddress
                  }
                  maxLength={2}
                />
              )}
            />
          </div>
        </ErrorFieldWrapper>
      </Col>

      <Col lg={4} md={6} sm={12}>
        <div className='d-flex gap-2'>
          <input
            type='checkbox'
            id='otherParticipantIdentification'
            className=' border-3'
            checked={isOtherParticipantIdentification}
            disabled={isOtherParticipantOfficeMessageAddress}
            onChange={(e) =>
              togleIdentification(e.target.checked)
            }
          />
          <Label
            htmlFor='otherParticipantIdentification'
            className='form-label fw-semibold m-0 text-decoration-underline'
          >
            Other Participant Identification
          </Label>
        </div>
        <ErrorFieldWrapper
          error={errors.otherParticipantOffice?.[index]?.otherParticipantParticipantIdentifier}
          message={errors.otherParticipantOffice?.[index]?.otherParticipantParticipantIdentifier?.message}
        >
          <div className='d-flex align-items-center justify-content-between'>
            <Label
              htmlFor={`otherParticipantOffice.${index}.otherParticipantParticipantIdentifier`}
              className='form-label m-0 w-50 w-50'
            >
              Participant Identifier
            </Label>
            <Controller
              id={`otherParticipantOffice.${index}.otherParticipantParticipantIdentifier`}
              name={`otherParticipantOffice.${index}.otherParticipantParticipantIdentifier`}
              type='text'
              control={control}
              render={({field}) => (
                <Select
                  {...field}
                  isSearchable={false}
                  className='w-50'
                  isDisabled={
                    isDetail || !isOtherParticipantIdentification
                  }
                  isClearable
                  defaultOptions
                  value={
                    participantIdentifierOptions.find(
                      (option) => option.value === field.value,
                    ) || null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                  options={participantIdentifierOptions}
                />
              )}
            />
          </div>
        </ErrorFieldWrapper>
      </Col>

      <Col lg={4} md={6} sm={12}>
        <Label className='form-label m-0'></Label>
        <ErrorFieldWrapper
          error={errors.otherParticipantOffice?.[index]?.otherParticipantParticipantCode}
          message={errors.otherParticipantOffice?.[index]?.otherParticipantParticipantCode?.message}
        >
          <div className='d-flex align-items-center justify-content-between'>
            <Label
              htmlFor={`otherParticipantOffice.${index}.otherParticipantParticipantCode`}
              className='form-label m-0 w-50 w-50'
            >
              Participant Code
            </Label>
            <Controller
              id={`otherParticipantOffice.${index}.otherParticipantParticipantCode`}
              name={`otherParticipantOffice.${index}.otherParticipantParticipantCode`}
              type='text'
              control={control}
              render={({field}) => (
                <InputTextFhlFwb
                  className='w-50'
                  {...field}
                  value={field.value}
                  disabled={isDetail || !isOtherParticipantIdentification}
                  maxLength={17}
                />
              )}
            />
          </div>
        </ErrorFieldWrapper>
      </Col>

      <Col lg={4} md={6} sm={12}>
      
      <Label className='form-label m-0'></Label>
        <ErrorFieldWrapper
          error={errors.otherParticipantOffice?.[index]?.otherParticipantDestinationAirport}
          message={errors.otherParticipantOffice?.[index]?.otherParticipantDestinationAirport?.message}
        >
          <div className='d-flex align-items-center justify-content-between'>
            <Label
              htmlFor={`otherParticipantOffice.${index}.otherParticipantDestinationAirport`}
              className='form-label m-0 w-50 w-50'
            >
              Airport/City Code
            </Label>
            <Controller
              id={`otherParticipantOffice.${index}.otherParticipantDestinationAirport`}
              name={`otherParticipantOffice.${index}.otherParticipantDestinationAirport`}
              type='text'
              control={control}
              render={({field}) => (
                <div className='d-flex w-50 align-items-stretch gap-1'>
                  <InputPopupModal
                    fieldName={`otherParticipantOffice.${index}.otherParticipantDestinationAirport`}
                    checkValidData={checkValidData}
                    {...field}
                    value={field.value}
                    maxLength={3}
                    disabled={
                      isDetail || !isOtherParticipantIdentification
                    }
                  />
                  {!isDetail && (
                    <ActionButton
                      disabled={!isOtherParticipantIdentification}
                      onClick={() =>
                        setShowPopup({
                          ...showPopup,
                          [`otherParticipantOffice.${index}.otherParticipantDestinationAirport`]: true,
                        })
                      }
                    />
                  )}
                </div>
              )}
            />
          </div>
        </ErrorFieldWrapper>
      </Col>

    </Row>
  )
}