import {useQuery} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import MyPagination from 'Components/Common/Table/Pagination'
import {apiBearer} from 'Helpers/api'
import {formatNumber} from 'Helpers/number'
import {
  ADMIN_REPORT_REQUEST_AGENT_ONLINE_FORM,
  ADMIN_REPORT_REQUEST_AGENT_ONLINE_FORM_EXPORT,
} from 'Helpers/url_helper'
import React, {useEffect, useRef, useState} from 'react'
import {
  ButtonGroup,
  Card,
  CardBody, CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from 'reactstrap'
import {
  documentTypes,
  getBadgeRequestOnlineClass, getDocumentLabel,
  getOnlineAgentOrderStatusLabel,
  getOnlineAgentPaymentStatusLabel,
} from '../../../Helpers/options'
import Select from 'react-select'
import flatpickr from 'flatpickr'
import moment from 'moment'

const RequestAgentOnlineFormReport = () => {
  const [isFetched, setIsFetched] = useState(false)
  const [stateIndicator, setStateIndicator] = useState(false)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [selectedTypeDocument, setSelectedTypeDocument] = useState()
  const [uploadDate, setUploadDate] = useState('')
  const [sendDate, setSendDate] = useState('')
  const [startSendDate, setStartSendDate] = useState('')
  const [endSendDate, setEndSendDate] = useState('')
  const [startUploadDate, setStartUploadDate] = useState('')
  const [endUploadDate, setEndUploadDate] = useState('')
  const [search, setSearch] = useState('')

  const formRef = useRef()
  const uploadDateRef = useRef()
  const sendDateRef = useRef()

  useEffect(() => {
    if (uploadDateRef.current) {
      flatpickr(uploadDateRef.current, {
        dateFormat: 'd/m/Y',
        mode: 'range',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setUploadDate(sDate)
        },
      })
    }
  }, [])

  useEffect(() => {
    if (sendDateRef.current) {
      flatpickr(sendDateRef.current, {
        dateFormat: 'd/m/Y',
        mode: 'range',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setSendDate(sDate)
        },
      })
    }
  }, [])

  const {data, isFetching, status, refetch} = useQuery(
    ['report-fhl-fwb', page, size],
    async () => {
      const params = {
        startSendDate,
        endSendDate,
        startUploadDate,
        endUploadDate,
        search,
        typeDoc: selectedTypeDocument?.value,
        page,
        size,
      }
      setIsFetched(true)
      const res = await apiBearer.get(ADMIN_REPORT_REQUEST_AGENT_ONLINE_FORM, {params})
      return res.data?.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      enabled: isFetched,
      retryOnMount: false,
      retry: false,
    },
  )
  const {report_data} = data || {}
  useEffect(() => {
    if (isFetched) refetch()
  }, [isFetched, refetch, stateIndicator])

  useEffect(() => {
    if (sendDate) {
      const splits = sendDate.split(' - ')
      setStartSendDate(moment(splits[0], "DD/MM/YYYY").format("YYYY-MM-DD"))
      setEndSendDate(moment(splits[1], "DD/MM/YYYY").format("YYYY-MM-DD"))
    } else {
      setStartSendDate('')
      setEndSendDate('')
    }
  }, [sendDate])

  useEffect(() => {
    if (uploadDate) {
      const splits = uploadDate.split(' - ')
      setStartUploadDate(moment(splits[0], "DD/MM/YYYY").format("YYYY-MM-DD"))
      setEndUploadDate(moment(splits[1], "DD/MM/YYYY").format("YYYY-MM-DD"))
    } else {
      setStartUploadDate('')
      setEndUploadDate('')
    }
  }, [uploadDate])

  const {
    isFetching: isFetchingExport,
    refetch: refetchExport,
  } = useQuery(
    ['report-fhl-fwb-export', page, size],
    async () => {
      const params = {
        startSendDate,
        endSendDate,
        startUploadDate,
        endUploadDate,
        search,
        typeDoc: selectedTypeDocument?.value,
      }
      const res = await apiBearer.get(ADMIN_REPORT_REQUEST_AGENT_ONLINE_FORM_EXPORT, {
        params,
        responseType: 'blob',
      })
      const href = URL.createObjectURL(res.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      const typeDoc = selectedTypeDocument?.label ?? 'FWB FHL'
      link.setAttribute('download', `Report ${typeDoc}.xlsx`) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      enabled: false,
    },
  )

  const onKeywordChange = (value) => {
    setPage(0)
    setSearch(value)

  }

  const submitSearch = () => {
    setStateIndicator((v) => !v)
  }

  const onSubmitSearch = (e) => {
    e.preventDefault()
    if (page === 0) refetch()
    else setPage(0)
  }

  const theadStyles = {
    textAlign: 'center',
    verticalAlign: 'middle',
  }

  const renderData = () => {
    if (status === 'error')
      return (
        <tr>
          <td colSpan={9} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    if (isFetching)
      return (
        <tr>
          <td colSpan={9} className='text-center'>
            <Spinner color='success' />
          </td>
        </tr>
      )
    if (!report_data?.data || report_data?.data.length < 1)
      return (
        <tr>
          <td colSpan={9} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    return report_data?.data?.map((props, i) => {
      return (
        <tr key={i}>
          <td>{i + report_data?.offset + 1}</td>
          <td>{getDocumentLabel(props.type_doc)}</td>
          <td>{props.agent_name}</td>
          <td>{props.upload_date ? moment(props.upload_date).format('DD MMMM YYYY') : ''}</td>
          <td>{props.shipper_name}</td>
          <td>{props.consignee_name}</td>
          <td>{props.send_date ? moment(props.send_date).format('DD MMMM YYYY') : ''}</td>
          <td>
            <span className={getBadgeRequestOnlineClass(props.payment_status)}>{getOnlineAgentPaymentStatusLabel(props.payment_status)}</span>
          </td>
          <td>
            <span className={getBadgeRequestOnlineClass(props.order_status)}>{getOnlineAgentOrderStatusLabel(props.order_status)}</span>
          </td>

        </tr>
      )
    })
  }
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Laporan Transaksi FWB / FHL' />
        <Row>
          <Col md={4} sm={12}>
            <Card>
              <CardHeader>
                <h5 className='fw-semibold'>Total Document FHL</h5>
              </CardHeader>
              <CardBody style={{height: 150}}>
                <Row className='mt-4'>
                  <Col md={6}>
                    <div className='flex-grow-1 ms-3'>
                      <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'> Total</p>
                      <h4 className=' mb-0'><span className='counter-value'>{data?.count_fhl ?? 0}</span></h4>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='flex-grow-1 ms-3'>
                      <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'> Nominal</p>
                      <h4 className=' mb-0'>Rp<span
                        className='counter-value'>{formatNumber(data?.sum_fhl) ?? '-'}</span></h4>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={4} sm={12}>
            <Card>
              <CardHeader>
                <h5 className='fw-semibold'>Total Document FWB</h5>
              </CardHeader>
              <CardBody style={{height: 150}}>
                <Row className='mt-4'>
                  <Col md={6}>
                    <div className='flex-grow-1 ms-3'>
                      <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'> Total</p>
                      <h4 className=' mb-0'><span className='counter-value'>{data?.count_fwb ?? 0}</span></h4>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className='flex-grow-1 ms-3'>
                      <p className='text-uppercase fw-semibold fs-12 text-muted mb-1'> Nominal</p>
                      <h4 className=' mb-0'>Rp<span
                        className='counter-value'>{formatNumber(data?.sum_fwb) ?? '-'}</span></h4>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <h4>Laporan Transaksi FWB / FHL</h4>
            <div style={{border: '1px solid #3F5F29'}} className='rounded mt-3'>
              <div className='bg-primary px-3 py-3'>
                <h6 className='text-white '>Search</h6>
              </div>
              <form onSubmit={(onSubmitSearch)} ref={formRef}>
                <Row className='px-3 mt-3 mb-2 align-items-end'>
                  <Col lg={4} md={6} sm={12}>
                    <label className='text-uppercase' htmlFor='method'>Type Document</label>
                    <Select
                      isClearable
                      value={selectedTypeDocument ?? ''}
                      options={documentTypes}
                      onChange={(v) => {
                        setSelectedTypeDocument(v)
                      }}
                      placeholder='Pilih Type'
                    />
                  </Col>

                  <Col lg={4} md={6} sm={12}>
                    <label className='text-uppercase' htmlFor='start'>Upload Date</label>
                    <input
                      type='text'
                      id='start'
                      ref={uploadDateRef}
                      className='form-control'
                      placeholder='dd/mm/YYYY - dd/mm/YYYY'
                    />
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <label className='text-uppercase' htmlFor='end'>Send Date</label>
                    <input
                      type='text'
                      id='end'
                      ref={sendDateRef}
                      className='form-control'
                      placeholder='dd/mm/YYYY - dd/mm/YYYY'
                    />
                  </Col>

                  <Col sm={12} className='mt-2 d-flex justify-content-end'>
                    <div className='flex align-items-end'>
                      <button
                        className='btn btn-primary'
                        type='button'
                        onClick={() => {
                          setSelectedTypeDocument(null)
                          setUploadDate('')
                          setSendDate('')
                          setStartSendDate('')
                          setEndSendDate('')
                          setStartUploadDate('')
                          setEndUploadDate('')
                          setSearch('')
                          setPage(0)
                          setStateIndicator((v) => !v)
                          formRef.current?.reset()
                        }}
                      >
                        <i
                          className='mdi mdi-restore'
                          style={{fontSize: 15}}
                        ></i>
                      </button>
                      <button
                        className='ms-3 btn btn-primary bg-white text-primary'
                        style={{border: '1px solid #3F5F29'}}
                        type='submit'
                      >
                        Search
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
            <Row className='mt-3'>
              <Col lg={12} md={12} xs={12} className='mb-2'>
                <div className='d-flex'>
                  <div className='flex-grow-1'>
                    Show&nbsp;
                    <ButtonGroup>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag='button'
                          className='d-flex align-items-center gap-1 btn btn-sm btn-primary'
                        >
                          {data?.report_data?.limit}
                          <i className='mdi mdi-chevron-down'></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => setSize(10)}>
                            10
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(25)}>
                            25
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(50)}>
                            50
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(100)}>
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </ButtonGroup>
                    &nbsp;entries
                  </div>
                  <div className='d-flex justify-content-end'>
                    <div className='search-box me-2'>
                      <input
                        placeholder='Search...'
                        type='text'
                        className='form-control form-control'
                        value={search}
                        onChange={(event) => onKeywordChange(event.target.value)}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            submitSearch()
                          }
                        }}
                      />
                      <i className='ri-search-line search-icon'></i>
                    </div>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary waves-effect waves-light fw-bold d-flex align-items-center'
                      onClick={refetchExport}
                    >
                      <i
                        className={
                          isFetchingExport
                            ? 'mdi mdi-loading mdi-spin mdi-18px me-2'
                            : 'mdi mdi-printer mdi-18px me-2'
                        }
                      ></i>{' '}
                      Export
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className='table-responsive'>
              <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
                <thead>
                <tr>
                  <th scope='col' style={theadStyles}>
                    No
                  </th>
                  <th scope='col' style={theadStyles}>
                    Type Document
                  </th>
                  <th scope='col' style={theadStyles}>
                    Agent Name
                  </th>
                  <th scope='col' style={theadStyles}>
                    Upload Date
                  </th>
                  <th scope='col' style={theadStyles}>
                    Shipper Name
                  </th>
                  <th scope='col' style={theadStyles}>
                    Consignee Name
                  </th>
                  <th scope='col' style={theadStyles}>
                    Send Date
                  </th>
                  <th scope='col' style={theadStyles}>
                    Payment Status
                  </th>
                  <th scope='col' style={theadStyles}>
                    Order Status
                  </th>
                </tr>
                </thead>
                <tbody>{renderData()}</tbody>
              </Table>
            </div>

        
            <MyPagination
              total={report_data?.total || 0}
              page={report_data?.offset || 0}
              perPage={report_data?.size || 10}
              onPageChange={(e) => {
                setPage(e.selected)
              }}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default RequestAgentOnlineFormReport
