import React from 'react'
import {Button} from 'reactstrap'

export const ActionButton = ({onClick, action, disabled = false}) => {
  const renderText = () => {
    if (action === 'add') {
      return <span className='ms-1'><i className='ri-add-line label-icon align-middle fs-16'></i></span>
    } else if (action === 'remove') {
      return <span className='ms-1'><i className='ri-delete-bin-line'></i></span>
    } else if (action === 'search') {
      return <span className='ms-1'><i className='ri-search-line'></i></span>
    }
    return <span className='ms-1 mn-1'>...</span>
  }
  const bgColor = () => {
    if (action === 'remove') {
      return 'soft-danger'
    }else if (action === 'search') {
      return 'primary'
    }
    return 'white'
  }
  return (
    <Button
      tabIndex={-1}
      onClick={onClick}
      type='button'
      color={bgColor()}
      disabled={disabled}
      // className='d-flex align-items-center px-2 fw-bold'
      className='px-2 fw-bold'
      style={{
        border: '1px solid silver',
        letterSpacing: '4px',
        textAlign: 'center',
      }}
    >
      {renderText()}
    </Button>
  )
}
