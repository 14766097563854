import {yupResolver} from '@hookform/resolvers/yup'
import React, {useEffect, useState} from 'react'
import {Controller, useFieldArray, useForm} from 'react-hook-form'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap'
import * as Yup from 'yup'
import {documentTypes, ONLINE_AGENT_TYPE_DOC} from 'Helpers/options'
import BreadCrumb from 'Components/Common/BreadCrumb'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import ImageTextPreview from '../../../Components/Common/ImageTextPreview'
import {toBase64} from '../../../Helpers/fileToBase64'
import {RequestOnlineAgentFormRQ} from '../../../Hooks/Queries/requestOnlineAgentFormRQ'
import InputNumeric from '../../../Helpers/InputNumeric'
import InputPopupModal from '../../../Helpers/InputPopupModal'
import {ActionButton} from '../../../Components/FwbFhl/ActionButton'
import {customValidation} from '../../../Helpers/validation'
import {apiBearer} from '../../../Helpers/api'
import {GET_MAWB_REQUEST_ONLINE_AGENT_URL} from '../../../Helpers/url_helper'

let idd = 0

export default function EditCreateUploadOnlineAgentForm() {
  const {id} = useParams()
  const navigate = useNavigate()

  const [documentType, setDocumentType] = useState(null)
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)

  const validation = Yup.object().shape({
    docs: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string(),
          base64: Yup.string(),
          url: Yup.string(),
          doc: Yup.mixed()
            .when('url', {
              is: (url) => !url,
              then: Yup.mixed()
                .test(
                  'required',
                  'File Upload tidak boleh kosong',
                  (value) => value && value.length > 0,
                )
                .test(
                  'fileSize',
                  'File maksimal 2MB',
                  (value) => value && value[0]?.size <= 2 * 1024 * 1024,
                )
                .test(
                  'fileType',
                  'Format harus JPG, PNG, atau PDF',
                  (value) =>
                    value &&
                    ['image/jpeg', 'image/png', 'application/pdf'].includes(
                      value[0]?.type,
                    ),
                ),
              otherwise: Yup.mixed().notRequired(),
            })
            .when('base64', {
              is: (base64) => !base64,
              then: Yup.mixed().notRequired(),
              otherwise: Yup.mixed()
                .test(
                  'fileSize',
                  'File maksimal 2MB',
                  (value) => value && value[0]?.size <= 2 * 1024 * 1024,
                )
                .test(
                  'fileType',
                  'Format harus JPG, PNG, atau PDF',
                  (value) =>
                    value &&
                    ['image/jpeg', 'image/png', 'application/pdf'].includes(
                      value[0]?.type,
                    ),
                ),
            }),
        }),
      )
      .min(1, 'Minimal satu file harus diunggah'),

    documentType: customValidation('Jenis Document'),
    fwbId: Yup.mixed().when('documentType', {
      is: (documentType) => [
        ONLINE_AGENT_TYPE_DOC.FHL_V4,
        ONLINE_AGENT_TYPE_DOC.FHL_V5,
      ].includes(documentType),
      then: Yup.string().required('No Air Waybill tidak valid'),
      otherwise: Yup.mixed().notRequired(),
    }),
    airlinePrefix: Yup.mixed().when('documentType', {
      is: (documentType) => [
        ONLINE_AGENT_TYPE_DOC.FHL_V4,
        ONLINE_AGENT_TYPE_DOC.FHL_V5,
      ].includes(documentType),
      then: customValidation('Prefix', {length: 3}),
      otherwise: Yup.mixed().notRequired(),
    }),
    awbSerialNumber: Yup.mixed().when('documentType', {
      is: (documentType) => [
        ONLINE_AGENT_TYPE_DOC.FHL_V4,
        ONLINE_AGENT_TYPE_DOC.FHL_V5,
      ].includes(documentType),
      then: customValidation('AWB serial', {length: 8}),
      otherwise: Yup.mixed().notRequired(),
    }),
  })

  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      documentType: '',
      description: '',
      airlinePrefix: '',
      awbSerialNumber: '',
      fwbId: '',
      docs: [{id: idd, doc: [], base64: ''}],
    },
  }
  const {
    handleSubmit,
    formState,
    control,
    reset,
    getValues,
    setError,
    clearErrors,
    setValue,
    register,
  } = useForm(formOptions)
  const {errors} = formState

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'docs',
  })

  const {mutate: updateOnlineAgentForm, isLoading: isLoadingUpdate} =
    RequestOnlineAgentFormRQ.useUpdateUploadData(id, navigate)()

  const {mutate: addOnlineAgentForm, isLoading: isLoadingCreate} =
    RequestOnlineAgentFormRQ.useAddUploadData(navigate)()

  const _updateOnlineAgentForm = (values, buttonType) => {
    updateOnlineAgentForm({data: getPayload(values, false), buttonType})
  }

  const _createOnlineAgentForm = async (values, buttonType) => {
    addOnlineAgentForm({data: getPayload(values, true), buttonType})
  }

  const getPayload = (values, isCreate = false) => {
    
    const formData = values
    formData.type = documentType.value
    let files
    if (isCreate) {
      files = formData.docs.map((v) => {
        return {
          id: "",
          doc: v.base64,
        }
      })
    } else {
   
      
      files = formData.docs.map((v) => {
        if (v?.id?.toString().length < 10) {
          return {
            id: "",
            doc: v.base64,
          }
        } else {
          return {
            id: "",//v?.id ?? "",
            doc: v.base64 ? v.base64 : null,
          }
        }
      })
    }

    return {
      type: documentType.value,
      mawb:
      [
        ONLINE_AGENT_TYPE_DOC.FHL_V4,
        ONLINE_AGENT_TYPE_DOC.FHL_V5,
      ].includes(documentType.value) ? getMawb() : null,
      files: files,
      description: formData.description,
    }
  }

  const onChangeDocs = async (index, files) => {
    try {
      const base64File = await toBase64(files[0])
      setValue(`docs.${index}.doc`, files)
      setValue(`docs.${index}.base64`, base64File)
    } catch (error) {
      const fileEl = document.getElementById(id)
      if (fileEl) {
        fileEl.value = []
      }
    }
  }

  const onAddDocInputs = () => {
    append({
      id: idd++,
      doc: [],
      base64: '',
      url: '',
    })
  }

  const onSubmit = (data, event) => {
    const buttonType = event.nativeEvent.submitter.value
    if (id) {
      return _updateOnlineAgentForm(data, buttonType)
    }
    return _createOnlineAgentForm(data, buttonType)
  }

  const {
    data: detailData,
    error,
    isFetching,
    status,
  } = RequestOnlineAgentFormRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      if (detailData) {
        let fwbId = ''
        let airlinePrefix = ''
        let awbSerialNumber = ''        
        if ([
          ONLINE_AGENT_TYPE_DOC.FHL_V4,
          ONLINE_AGENT_TYPE_DOC.FHL_V5,
        ].includes(detailData.data?.type_doc)) {
          fwbId = detailData.data?.id
          airlinePrefix = detailData.data?.data?.awb?.air_line
          awbSerialNumber = detailData.data?.data?.awb?.air_way_bill_no
        }

        const files = detailData?.data?.upload?.files ?? []
        reset({
          documentType: detailData.data?.type_doc,
          description: detailData.data?.upload?.description,
          fwbId: fwbId,
          airlinePrefix: airlinePrefix,
          awbSerialNumber: awbSerialNumber,
          docs: files[files.length - 1]?.file?.map((doc) => ({
            id: doc?.id,
            doc: [],
            base64: '',
            url: doc?.url,
          })) ?? [
            {
              id: idd++,
              doc: [],
              base64: '',
              url: '',
            },
          ],
        })
        setDocumentType({
          label: detailData.data?.type_doc,
          value: detailData.data?.type_doc,
        })
      }
    }
    if (!isFetching && status === 'error') {
      console.error(error)
    }
  }, [detailData, reset])

  const getMawb = () => {
    const prefix = getValues('airlinePrefix')
    const awb = getValues('awbSerialNumber')
    return prefix + '-' + awb
  }
  const searchAwb = () => {
    setValue('fwbId', '')
    let mawb = getMawb()
    if (getValues('airlinePrefix') && getValues('awbSerialNumber')) {
      setIsLoadingSearch(true)
      apiBearer
        .get(GET_MAWB_REQUEST_ONLINE_AGENT_URL(mawb), {})
        .then((result) => {
          setIsLoadingSearch(false)
          setValue('fwbId', result?.data?.data?.id, {shouldValidate: true})
          clearErrors('awbSerialNumber')
        })
        .catch(() => {
          setIsLoadingSearch(false)
          setError('awbSerialNumber', {
            type: 'manual',
            message:
              'No Air Waybill tidak ditemukan pastikan sudah melakukan input data FWB',
          })
        })
    }
  }

  const checkValidDataAwb = () => {
    searchAwb()
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Upload Document' pageTitle='Upload Document' />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={(event) =>
                handleSubmit((data) => onSubmit(data, event))(event)
              }
            >
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Update' : 'Create'} Upload Document
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors?.documentType}
                        message={errors?.documentType?.message}
                      >
                        <Label htmlFor='documentType' className='form-label'>
                          Type Document <span className='mandatory'>*</span>
                        </Label>
                        <Controller
                          id='documentType'
                          name='documentType'
                          type='text'
                          control={control}
                          render={({field: {onChange, ...field}}) => (
                            <Select
                              isDisabled={id}
                              {...field}
                              isSearchable={false}
                              cacheOptions
                              defaultOptions
                              value={documentType}
                              options={documentTypes}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value)
                                setDocumentType(e)
                              }}
                              placeholder='Pilih Jenis Document'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    {[
                      ONLINE_AGENT_TYPE_DOC.FHL_V4,
                      ONLINE_AGENT_TYPE_DOC.FHL_V5,
                    ].includes(getValues('documentType')) && (
                      <Col md={6} sm={12}>
                        <ErrorFieldWrapper
                          error={
                            errors?.airlinePrefix ??
                            errors?.awbSerialNumber ??
                            errors?.fwbId
                          }
                          message={
                            errors?.airlinePrefix?.message ??
                            errors?.awbSerialNumber?.message ??
                            errors?.fwbId?.message
                          }
                        >
                          <Label
                            htmlFor='airlinePrefix'
                            className='form-label w-50'
                          >
                            Air Waybil No <span className='mandatory'>*</span>
                          </Label>
                          <div className='d-flex w-100 align-items-center gap-1'>
                            <Controller
                              id='airlinePrefix'
                              name='airlinePrefix'
                              type='text'
                              control={control}
                              render={({field}) => (
                                <InputNumeric
                                  className='w-25'
                                  {...field}
                                  value={field.value}
                                  onBlur={() => searchAwb()}
                                  maxLength={3}
                                />
                              )}
                            />
                            <span>-</span>
                            <Controller
                              id='awbSerialNumber'
                              name='awbSerialNumber'
                              type='text'
                              control={control}
                              render={({field}) => (
                                <div className='d-flex w-75 align-items-stretch gap-1'>
                                  <InputPopupModal
                                    {...field}
                                    value={field.value}
                                    fieldName='awbSerialNumber'
                                    checkValidData={checkValidDataAwb}
                                    maxLength={8}
                                  />
                                  {isLoadingSearch && (
                                    <Spinner color='success' />
                                  )}
                                  {!isLoadingSearch && (
                                    <ActionButton
                                      action='search'
                                      onClick={searchAwb}
                                    />
                                  )}
                                </div>
                              )}
                            />
                          </div>
                          <Controller
                            id='fwbId'
                            name='fwbId'
                            control={control}
                            render={({field}) => (
                              <Input type='hidden' {...field} />
                            )}
                          />
                        </ErrorFieldWrapper>
                      </Col>
                    )}
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.description}
                        message={errors.description?.message}
                      >
                        <Label htmlFor='description' className='form-label'>
                          Keterangan
                        </Label>
                        <Controller
                          id='description'
                          name='description'
                          type='text'
                          control={control}
                          render={({field}) => (
                            <textarea
                              className='form-control'
                              {...field}
                              value={field.value}
                              placeholder='Keterangan...'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>

                    {fields.map((field, index) => (
                      <Col lg={6} sm={12} key={field.id}>
                        <ErrorFieldWrapper
                          error={errors.docs?.[index]?.doc}
                          message={errors.docs?.[index]?.doc?.message}
                        >
                          <div className='d-flex justify-content-between'>
                            <Label className='form-label'>
                              File Upload <span className='mandatory'>*</span>
                            </Label>
                            {getValues(`docs.${index}.url`) &&
                            typeof getValues(`docs.${index}.id`) !==
                              'number' ? (
                              <ImageTextPreview
                                link={getValues(`docs.${index}.url`)}
                              />
                            ) : null}
                          </div>
                          <Controller
                            name={`docs.${index}.doc`}
                            type='file'
                            control={control}
                            render={({_}) => (
                              <div className='mb-3 d-flex align-items-end'>
                                <Input
                                  className='form-control'
                                  {...register(`docs.${index}.doc`)}
                                  type='file'
                                  onChange={(e) =>
                                    onChangeDocs(index, e.target.files)
                                  }
                                  // onChange={(e) => setValue(`docs.${index}.doc`, e.target.files)}
                                  // value={field.value}
                                />
                                {index >= 1 ? (
                                  <button
                                    className='btn mx-1 btn-danger'
                                    onClick={() => remove(index)}
                                    type='button'
                                  >
                                    <i className='ri-subtract-fill '></i>
                                  </button>
                                ) : null}
                                <button
                                  className='btn mx-1 btn-success'
                                  onClick={() => onAddDocInputs()}
                                  type='button'
                                >
                                  <i className='ri-add-fill '></i>
                                </button>
                              </div>
                            )}
                          />
                        </ErrorFieldWrapper>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/fwb-fhl' className='btn btn-soft-dark'>
                    Cancel
                  </Link>
                  <Button
                    color='btn btn-primary'
                    type='submit'
                    value='submit'
                    disabled={isLoadingCreate || isLoadingUpdate} //|| //!isValueValid}
                  >
                    {isLoadingCreate || isLoadingUpdate
                      ? 'Loading...'
                      : id
                      ? 'Update'
                      : 'Submit'}
                  </Button>
                  {!id && (
                    <Button
                      color='btn btn-secondary'
                      type='submit'
                      value='pay'
                      disabled={isLoadingCreate || isLoadingUpdate} //|| //!isValueValid}
                    >
                      {isLoadingCreate || isLoadingUpdate
                        ? 'Loading...'
                        : 'Pay'}
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
