import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Input, Label, Row} from 'reactstrap'
import React, {useState} from 'react'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'

export const CarrierExecution = ({
                                errors,
                                control,
                                isDetail = false,
                              }) => {
  const [isOpen, setIsOpen] = useState(true)


  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Carrier's Execution</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.carrierExecutionDate}
              message={errors.carrierExecutionDate?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='carrierExecutionDate'
                  className='form-label m-0 w-50 w-50'
                >
                  Executed on (date) <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='carrierExecutionDate'
                  name='carrierExecutionDate'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={7} placeholder='DDMMMYY'/>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.carrierExecutionPlace}
              message={errors.carrierExecutionPlace?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='carrierExecutionPlace'
                  className='form-label m-0 w-50 w-50'
                >
                  Place <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='carrierExecutionPlace'
                  name='carrierExecutionPlace'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={17} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.carrierExecutionSignature}
              message={errors.carrierExecutionSignature?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='carrierExecutionSignature'
                  className='form-label m-0 w-50'
                >
                  Signature
                </Label>
                <Controller
                  id='carrierExecutionSignature'
                  name='carrierExecutionSignature'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={20} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>
    </div>
  )
}
