import React from 'react'
import {TabPane} from 'reactstrap'
import {OtherCharge} from './OtherCharge'
import {ChargeSummary} from './ChargeSummary'
import {ShipperCertification} from './ShipperCertification'
import {CarrierExecution} from './CarrierExecution'
import {OtherServiceInformation} from './OtherServiceInformation'
import {RateDescription} from './RateDescription'

export const Step3Fwb = ({
  errors,
  control,
  setValue,
  getValues,
  clearErrors,
  chargeCodeList,
  fieldsRates,
  appendRates,
  removeRates,
  fieldsOtherCharge,
  appendOtherCharge,
  removeOtherCharge,
  fieldsOtherService,
  appendOtherService,
  removeOtherService,
  onCalculateCharge,
  isDetail = false,
  trigger,
  watch,
  setError,
}) => {
  return (
    <TabPane tabId={3}>
      <RateDescription
        errors={errors}
        control={control}
        getValues={getValues}
        setValue={setValue}
        remove={removeRates}
        append={appendRates}
        fields={fieldsRates}
        isDetail={isDetail}
        onCalculateCharge={onCalculateCharge}
      />
      <OtherCharge
        errors={errors}
        control={control}
        setValue={setValue}
        clearErrors={clearErrors}
        chargeCodeList={chargeCodeList}
        append={appendOtherCharge}
        fields={fieldsOtherCharge}
        remove={removeOtherCharge}
        isDetail={isDetail}
        onCalculateCharge={onCalculateCharge}
        trigger={trigger}
        getValue={getValues}
        watch={watch}
        setError={setError}
      />
      <ChargeSummary
        errors={errors}
        control={control}
        isDetail={isDetail}
        onCalculateCharge={onCalculateCharge}
      />
      <ShipperCertification
        errors={errors}
        control={control}
        isDetail={isDetail}
      />
      <CarrierExecution errors={errors} control={control} isDetail={isDetail} />
      <OtherServiceInformation
        errors={errors}
        control={control}
        isDetail={isDetail}
        append={appendOtherService}
        fields={fieldsOtherService}
        remove={removeOtherService}
      />
    </TabPane>
  )
}
