import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment/moment'
import {
  getBadgeRequestOnlineClass,
  getDocumentLabel,
  getOnlineAgentOrderStatusLabel,
  getOnlineAgentPaymentStatusLabel,
  ONLINE_AGENT_ORDER_STATUS,
  ONLINE_AGENT_PAYMENT_STATUS,
} from '../../Helpers/options'

const RequestAgentOnlineFormItem = ({
  page,
  index,
  id,
  agent_name,
  consignee_name,
  shipper_name,
  type_doc,
  upload_date,
  order_status,
  payment_status,
  sita_text,
  additionalActions,
}) => {
  const linkedToSitaText = () => {
    return getDocumentLabel(type_doc) === 'FWB' ? (
      <Link
        to={`/app/request-agent-online-form/detail/${id}`}
        className='link-dark'
        title={'Detail'}
      >
        <i className='ri-eye-fill'></i>
      </Link>
    ) : (
      <Link
        to='#'
        className='link-dark'
        onClick={() => {
          additionalActions({sita_text, id, order_status})
        }}
        title={'Detail'}
      >
        <i className='ri-eye-fill'></i>
      </Link>
    )
  }

  return (
    <tr>
      <td className='fw-medium'>{index + page + 1}</td>
      <td>
        <div className='hstack gap-3 fs-15'>
          {order_status === ONLINE_AGENT_ORDER_STATUS.DRAFT ? (
            linkedToSitaText()
          ) : (
            <Link to={`/app/upload/detail/${id}`} className='link-dark'>
              <i className='ri-eye-fill'></i>
            </Link>
          )}
        </div>
      </td>
      <td>{getDocumentLabel(type_doc)}</td>
      <td>{agent_name}</td>
      <td>{upload_date ? moment(upload_date).format('DD MMMM YYYY') : ''}</td>
      <td>{shipper_name}</td>
      <td>{consignee_name}</td>
      <td>
        <span className={getBadgeRequestOnlineClass(payment_status)}>
          {getOnlineAgentPaymentStatusLabel(payment_status)}
        </span>
      </td>
      <td>
        <span className={getBadgeRequestOnlineClass(order_status)}>
          {getOnlineAgentOrderStatusLabel(order_status)}
        </span>
      </td>
    </tr>
  )
}

export default RequestAgentOnlineFormItem
