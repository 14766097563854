import PropTypes from 'prop-types'
import React from 'react'
import {Button, Label, Modal, ModalBody} from 'reactstrap'
import ErrorFieldWrapper from '../Common/ErrorFieldWrapper'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm} from 'react-hook-form'
import * as Yup from 'yup'

const RevisionModal = ({
                         show,
                         isLoadingRevision,
                         onCloseClick,
                         onSubmitClick,
                       }) => {
  const validation = Yup.object().shape({
    revision: Yup.string().required('Revisi tidak boleh kosong'),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      revision: '',
    },
  }
  const {handleSubmit, formState, register, reset, control} =
    useForm(formOptions)
  const {errors} = formState

  const _onSubmit = async (values) => {
    onSubmitClick(values)
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} backdrop="static">
      <ModalBody className='py-3 px-5'>
        <div className="mt-2 text-center">
          <div className="mt-2 pt-2 fs-15">
            <p className="fw-bold mb-0">
              Revision
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit(_onSubmit)}>
          <div className='mt-2'>
            <ErrorFieldWrapper
              error={errors.revision}
              message={errors.revision?.message}
            >
              <Label htmlFor='revision'>
                Keterangan <span className='mandatory'>*</span>
              </Label>
              <textarea className='form-control' {...register('revision')} />
            </ErrorFieldWrapper>
          </div>
          <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>
            <Button
              type='button'
              color='btn btn-light'
              className='btn w-sm'
              data-bs-dismiss='modal'
              onClick={onCloseClick}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              color='btn btn-primary'
              className='btn w-sm'
              disabled={isLoadingRevision}
            >
              {isLoadingRevision
                ? 'Loading...'
                : 'Submit'}
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

RevisionModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  show: PropTypes.any,
}

export default RevisionModal