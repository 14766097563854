import {useQuery} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import GeneralSettingItem from 'Components/Master/GeneralSettingItem'
import {apiBearer} from 'Helpers/api'
import {VIEW_GENERAL_SETTING_URL} from 'Helpers/url_helper'
import {Card, Container, Table} from 'reactstrap'

export default function ListCdoRate() {
  const {data} = useQuery(['CDO_PRICE'], async () => {
    const res = await apiBearer.get(VIEW_GENERAL_SETTING_URL('CDO_PRICE'))
    return res.data
  })
  const props = {
    ...data?.data,
    updateUrl: '/app/struktur-harga/cdo/rate/update',
    type: 'Cdo Rate',
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Rate' />

        <Card>
          <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
            <thead>
              <tr>
                {['Action', 'Deskripsi', 'Jumlah'].map((v, i) => (
                  <th
                    key={i}
                    scope='col'
                    style={{
                      width: v.toLowerCase() === 'action' ? 120 : '',
                    }}
                  >
                    {v}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <GeneralSettingItem {...props} />
            </tbody>
          </Table>
        </Card>
      </Container>
    </div>
  )
}
