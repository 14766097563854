import {useMutation, useQuery} from '@tanstack/react-query'
import {
  CREATE_FHL_REQUEST_ONLINE_AGENT_URL, CREATE_FHL_V4_REQUEST_ONLINE_AGENT_URL, CREATE_FWB_REQUEST_ONLINE_AGENT_URL,
  CREATE_UPLOAD_REQUEST_ONLINE_AGENT_URL,
  LIST_REQUEST_ONLINE_AGENT_URL,
  PAYMENT_UPLOAD_REQUEST_ONLINE_AGENT_URL,
  UPDATE_FHL_REQUEST_ONLINE_AGENT_URL, UPDATE_FHL_V4_REQUEST_ONLINE_AGENT_URL, UPDATE_FWB_REQUEST_ONLINE_AGENT_URL,
  UPDATE_UPLOAD_REQUEST_ONLINE_AGENT_URL, VIEW_REQUEST_ONLINE_AGENT_URL,
} from 'Helpers/url_helper'
import {apiBearer} from '../../Helpers/api'
import {showSuccess} from '../../Helpers/show_toast'

class RequestOnlineAgentFormRQClass {
  QUERY_KEY = ''
  LIST_URL = ''
  CREATE_UPLOAD_URL = ''
  CREATE_FHL_URL = ''
  CREATE_FHL_V4_URL = ''
  CREATE_FWB_URL = ''
  UPDATE_UPLOAD_URL = function(id) {
  }
  UPDATE_FHL_URL = function(id) {
  }
  UPDATE_FHL_V4_URL = function(id) {
  }
  UPDATE_FWB_URL = function(id) {
  }
  REDIRECT_URL = ''
  VIEW_URL = function(id) {
  }
  PAYMENT_URL = function(id) {
  }
  REDIRECT_URL_PAYMENT = function(id) {
  }

  constructor(
    QUERY_KEY,
    LIST_URL,
    CREATE_UPLOAD_URL,
    CREATE_FHL_URL,
    CREATE_FHL_V4_URL,
    CREATE_FWB_URL,
    UPDATE_UPLOAD_URL,
    UPDATE_FHL_URL,
    UPDATE_FHL_V4_URL,
    UPDATE_FWB_URL,
    REDIRECT_URL,
    VIEW_URL,
    PAYMENT_URL,
    REDIRECT_URL_PAYMENT,
  ) {
    this.QUERY_KEY = QUERY_KEY
    this.LIST_URL = LIST_URL
    this.CREATE_UPLOAD_URL = CREATE_UPLOAD_URL
    this.CREATE_FHL_URL = CREATE_FHL_URL
    this.CREATE_FHL_V4_URL = CREATE_FHL_V4_URL
    this.CREATE_FWB_URL = CREATE_FWB_URL
    this.UPDATE_UPLOAD_URL = UPDATE_UPLOAD_URL
    this.UPDATE_FHL_URL = UPDATE_FHL_URL
    this.UPDATE_FHL_V4_URL = UPDATE_FHL_V4_URL
    this.UPDATE_FWB_URL = UPDATE_FWB_URL
    this.REDIRECT_URL = REDIRECT_URL
    this.VIEW_URL = VIEW_URL
    this.PAYMENT_URL = PAYMENT_URL
    this.REDIRECT_URL_PAYMENT = REDIRECT_URL_PAYMENT
  }

  useGetDataList() {
    return (isSearch = false, keyword = '', offset = 0, limit = 10, order_status = '') =>
      useQuery(
        [this.QUERY_KEY, isSearch, keyword, offset, limit, order_status],
        async () => {
          const params = {
            page: offset,
            size: limit,
            search: keyword,
            order_status: order_status,
          }
          const res = await apiBearer.get(this.LIST_URL, {params})
          return res.data?.data
        },
      )
  }

  useAddUploadData(navigate) {
    return () =>
      useMutation({
        mutationFn: ({data, buttonType}) => {
          return apiBearer.post(this.CREATE_UPLOAD_URL, data)
        },
        onSuccess: async (data, variables) => {
          showSuccess('New data added successfully')
          if (variables.buttonType === 'submit') {
            navigate(this.REDIRECT_URL)
          } else {
            navigate(this.REDIRECT_URL_PAYMENT(data.data.data.id))
          }
        },
        onError: async (error) => {
        },
      })
  }

  useAddFhlData(navigate) {
    return () =>
      useMutation({
        mutationFn: ({data, buttonType}) => {
          return apiBearer.post(this.CREATE_FHL_URL, data)
        },
        onSuccess: async (data, variables) => {
          showSuccess('New data added successfully')
          if (variables.buttonType === 'submit') {
            navigate(this.REDIRECT_URL)
          } else {
            navigate(this.REDIRECT_URL_PAYMENT(data.data.data.id))
          }
        },
        onError: async (error) => {
        },
      })
  }

  useAddFhlV4Data(navigate) {
    return () =>
      useMutation({
        mutationFn: ({data, buttonType}) => {
          return apiBearer.post(this.CREATE_FHL_V4_URL, data)
        },
        onSuccess: async (data, variables) => {
          showSuccess('New data added successfully')
          if (variables.buttonType === 'submit') {
            navigate(this.REDIRECT_URL)
          } else {
            navigate(this.REDIRECT_URL_PAYMENT(data.data.data.id))
          }
        },
        onError: async (error) => {
        },
      })
  }

  useAddFwbData(navigate) {
    return () =>
      useMutation({
        mutationFn: ({data, buttonType}) => {
          return apiBearer.post(this.CREATE_FWB_URL, data)
        },
        onSuccess: async (data, variables) => {
          showSuccess('New data added successfully')
          if (variables.buttonType === 'submit') {
            navigate(this.REDIRECT_URL)
          } else {
            navigate(this.REDIRECT_URL_PAYMENT(data.data.data.id))
          }
        },
        onError: async (error) => {
        },
      })
  }

  useUpdateUploadData(id, navigate) {
    return () =>
      useMutation({
        mutationFn: ({data, buttonType}) => {
          return apiBearer.patch(this.UPDATE_UPLOAD_URL(id), data)
        },
        onSuccess: async (data, variables) => {
          showSuccess('Updated data successfully')
          if (variables.buttonType === 'submit') {
            navigate(this.REDIRECT_URL)
          } else {
            navigate(this.REDIRECT_URL_PAYMENT(id))
          }
        },
        onError: async (error) => {
        },
      })
  }

  useUpdateFhlData(id, navigate) {
    return () =>
      useMutation({
        mutationFn: ({data, buttonType}) => {
          return apiBearer.patch(this.UPDATE_FHL_URL(id), data)
        },
        onSuccess: async (data, variables) => {
          showSuccess('Updated data successfully')
          if (variables.buttonType === 'submit') {
            navigate(this.REDIRECT_URL)
          }else if (variables.buttonType === 'admin') {
            navigate(`/app/request-agent-online-form`)
          } else {
            navigate(this.REDIRECT_URL_PAYMENT(id))
          }
        },
        onError: async (error) => {
        },
      })
  }

  useUpdateFhlV4Data(id, navigate) {
    return () =>
      useMutation({
        mutationFn: ({data, buttonType}) => {
          return apiBearer.patch(this.UPDATE_FHL_V4_URL(id), data)
        },
        onSuccess: async (data, variables) => {
          showSuccess('Updated data successfully')
          if (variables.buttonType === 'submit') {
            navigate(this.REDIRECT_URL)
          }else if (variables.buttonType === 'admin') {
            navigate(`/app/request-agent-online-form`)
          } else {
            navigate(this.REDIRECT_URL_PAYMENT(id))
          }
        },
        onError: async (error) => {
        },
      })
  }

  useUpdateFwbData(id, navigate) {
    return () =>
      useMutation({
        mutationFn: ({data, buttonType}) => {
          return apiBearer.patch(this.UPDATE_FWB_URL(id), data)
        },
        onSuccess: async (data, variables) => {
          showSuccess('Updated data successfully')
          if (variables.buttonType === 'submit') {
            navigate(this.REDIRECT_URL)
          }else if (variables.buttonType === 'admin') {
            navigate(`/app/request-agent-online-form`)
          } else {
            navigate(this.REDIRECT_URL_PAYMENT(id))
          }
        },
        onError: async (error) => {
        },
      })
  }

  usePaymentData(id, navigate) {
    return () =>
      useMutation({
        mutationFn: (newData) => {
          return apiBearer.post(this.PAYMENT_URL(id), newData)
        },
        onSuccess: async () => {
          showSuccess('Terimakasih Pembayaran Berhasil!!!')
          navigate(this.REDIRECT_URL)
        },
        onError: async (error) => {
        },
      })
  }

  useGetData(id) {
    return () =>
      useQuery(
        [this.QUERY_KEY + '_DETAIL'],
        async () => {
          const res = await apiBearer.get(this.VIEW_URL(id))
          return res.data
        },
        {
          enabled: id !== undefined,
        },
      )
  }
}

const QUERY_KEY = 'fwb-fhl'
const REDIRECT_URL = '/app/fwb-fhl'
const REDIRECT_URL_PAYMENT = (id) => '/app/fwb-fhl/:id/payment'.replace(':id', id)

export const RequestOnlineAgentFormRQ = new RequestOnlineAgentFormRQClass(
  QUERY_KEY,
  LIST_REQUEST_ONLINE_AGENT_URL,
  CREATE_UPLOAD_REQUEST_ONLINE_AGENT_URL,
  CREATE_FHL_REQUEST_ONLINE_AGENT_URL,
  CREATE_FHL_V4_REQUEST_ONLINE_AGENT_URL,
  CREATE_FWB_REQUEST_ONLINE_AGENT_URL,
  UPDATE_UPLOAD_REQUEST_ONLINE_AGENT_URL,
  UPDATE_FHL_REQUEST_ONLINE_AGENT_URL,
  UPDATE_FHL_V4_REQUEST_ONLINE_AGENT_URL,
  UPDATE_FWB_REQUEST_ONLINE_AGENT_URL,
  REDIRECT_URL,
  VIEW_REQUEST_ONLINE_AGENT_URL,
  PAYMENT_UPLOAD_REQUEST_ONLINE_AGENT_URL,
  REDIRECT_URL_PAYMENT,
)
