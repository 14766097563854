import { TRUCKING } from "Helpers/options";

export const TRX_TYPES = {
  CDO: "CDO",
  BOOKING_TRUCK: "BOOKING_TRUCK",
  TOPUP: "TOPUP",
  REQUEST_ONLINE_AGENT_FORM: "REQUEST_ONLINE_AGENT_FORM",
};
export const getInvLink = (type, id, role) => {
  switch (type) {
    case TRX_TYPES.BOOKING_TRUCK:
      return `/app/${
        role === TRUCKING ? "trucking-order" : "truck-book/booking"
      }/detail/${id}`;
    case TRX_TYPES.CDO:
      return "/app/cdo/detail/" + id;
    case TRX_TYPES.REQUEST_ONLINE_AGENT_FORM:
      return "/app/fhl/detail/" + id;
    default:
      return "/app/topups/detail/" + id;
  }
};

export const BEA_DOC_KEYS = {
  741:'awb',
  740:'hawb'
}