import {formatNumber} from '../../Helpers/number'
import moment from 'moment'
import React from 'react'
import {topupStatus} from './helpers'
import {Link} from 'react-router-dom'
import {TOPUP_STATUS_CONSTANTS} from 'Helpers/options'

export function TopupItem({
                            page,
                            id,
                            index,
                            amount,
                            url,
                            date,
                            status,
                            detail,
                            table,
                          }) {

  return (
    <tr>
      <td className='fw-medium'>{index + page + 1}</td>
      <td>
        <div className='hstack gap-3 fs-15'>
          <Link to={`/app/${table}/detail/${id}`} className='link-dark'>
            <i className='ri-eye-fill'></i>
          </Link>
          {status === TOPUP_STATUS_CONSTANTS.PENDING ? (
              <Link to={`/app/${table}/payment/${id}`} className='link-dark'>
                <i className='bx bx-money fs-5'></i>
              </Link>
          ) : null}
        </div>
      </td>
      <td>Rp.{formatNumber(amount)}</td>
      <td>{moment(date).format('DD MMMM YYYY')}</td>
      <td>{topupStatus(status)}</td>
    </tr>
  )
}
