import { CREATE_AIRPORT_URL, DELETE_AIRPORT_URL, LIST_AIRPORT_URL, UPDATE_AIRPORT_URL, VIEW_AIRPORT_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'airport';
const REDIRECT_URL = '/app/online-agent-form/airport';

export const airportRQ = new RQ(
  QUERY_KEY,
  LIST_AIRPORT_URL,
  VIEW_AIRPORT_URL,
  CREATE_AIRPORT_URL,
  UPDATE_AIRPORT_URL,
  DELETE_AIRPORT_URL,
  REDIRECT_URL
);