import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap'
import {logoutAction} from '../../Redux/actions/auth'

const ProfileDropdown = () => {
  const dispatch = useDispatch()

  const {user} = useSelector((state) => ({
    user: state.auth.profile,
  }))

  const [userName, setUserName] = useState('Admin')
  const [userRole, setUserRole] = useState('Admin')

  useEffect(() => {
    if (user) {
      setUserName(user.email?.split('@')[0] || 'Admin')
      setUserRole(user.role)
    }
  }, [userName, user])
  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false)
  const toggleProfileDropdown = () => setIsProfileDropdown((v) => !v)
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className='ms-sm-3 header-item topbar-user'
      >
        <DropdownToggle tag='button' type='button' className='btn'>
          <span className='d-flex align-items-center'>
            <span className='rounded-circle header-profile-user bg-primary text-white d-flex justify-content-center align-items-center'>
              {user.avatar ? (
                <img
                  className='rounded-circle header-profile-user'
                  src={user.avatar}
                  alt='Header Avatar'
                  style={{objectFit:'cover', objectPosition:'center'}}
                />
              ) : (
                userName.charAt(0).toUpperCase()
              )}
            </span>
            <span className='text-start ms-xl-2'>
              <span className='d-none d-xl-inline-block ms-1 fw-medium user-name-text'>
                {userName}
              </span>
              <span className='d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text'>
                {userRole}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu-end'>
          <h6 className='dropdown-header'>Welcome {userName}!</h6>
          <DropdownItem>
            <Link to='/app/profile'>
              <i className='mdi mdi-account-circle text-muted fs-16 align-middle me-1'></i>
              <span className='align-middle'>Profile</span>
            </Link>
          </DropdownItem>
          {/* <DropdownItem href="/apps-chat"><i
                        className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Messages</span></DropdownItem>
                    <DropdownItem href="#"><i
                        className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Taskboard</span></DropdownItem>
                    <DropdownItem href="/pages-faqs"><i
                        className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Help</span></DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem href="/pages-profile"><i
                        className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle">Balance : <b>$5971.67</b></span></DropdownItem>
                    <DropdownItem href="/pages-profile-settings"><span
                        className="badge bg-soft-success text-success mt-1 float-end">New</span><i
                            className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i> <span
                                className="align-middle">Settings</span></DropdownItem> */}
          {/*<DropdownItem href='/auth-lockscreen-basic'>*/}
          {/*  <i className='mdi mdi-lock text-muted fs-16 align-middle me-1'></i>{' '}*/}
          {/*  <span className='align-middle'>Lock screen</span>*/}
          {/*</DropdownItem>*/}
          <DropdownItem onClick={() => dispatch(logoutAction())}>
            <i className='mdi mdi-logout text-muted fs-16 align-middle me-1'></i>{' '}
            <span className='align-middle' data-key='t-logout'>
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default ProfileDropdown
