import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Input, Label, Row} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'

export const SpecialServiceRequest = ({errors, control, fields, remove, append, isDetail = false}) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Special Service Request</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          {
            fields?.map((field, index) => (
                <Col lg={6} sm={12} key={index}>
                  <ErrorFieldWrapper
                    error={errors.specialServiceRequest?.[index]?.value}
                    message={errors.specialServiceRequest?.[index]?.value?.message}
                  >
                    <div className='d-flex align-items-center justify-content-between'>
                      <Label htmlFor={`specialServiceRequest.${index}.value`} className='form-label m-0 w-25'>
                        Special Service Request
                      </Label>
                      <Controller
                        name={`specialServiceRequest.${index}.value`}
                        type='text'
                        control={control}
                        render={({field}) => (
                          <div className='d-flex align-items-stretch gap-1' style={{width: '67.5%'}}>
                            <InputTextFhlFwb
                              {...field}
                              value={field.value}
                              disabled={isDetail}
                              maxLength={65}
                            />
                            {!isDetail && index >= 1 ? (
                              <ActionButton onClick={() => remove(index)} action='remove' />
                            ) : null}
                            {
                              !isDetail && <ActionButton onClick={() => append({value: ''})} action='add' disabled={fields.length === 3} />
                            }
                          </div>
                        )}
                      />
                    </div>
                  </ErrorFieldWrapper>
                </Col>
            ))
          }
        </Row>
      </Collapse>
    </div>
  )
}
