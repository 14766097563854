import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller, useFieldArray} from 'react-hook-form'
import Select from 'react-select'
import {Button, Col, Collapse, Label, Row} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import ListMasterModal from '../ListMasterModal'
import InputNumeric from '../../../Helpers/InputNumeric'
import InputPopupModal from '../../../Helpers/InputPopupModal'
import {
  chargeCodeOptions,
  entitlementCodeOptions,
} from '../../../Helpers/options'
import { debounce } from 'lodash'

export const OtherCharge = ({
  errors,
  control,
  chargeCodeList,
  clearErrors,
  setValue,
  getValue,
  append,
  fields,
  remove,
  onCalculateCharge,
  isDetail = false,
  watch,
  trigger = () => {},
  setError,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [showPopup, setShowPopup] = useState(false)
  const [fieldActive, setFieldActive] = useState('')
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const openModal = (field) => {
    setFieldActive(field)
    setShowPopup(true)
  }

  const checkValidData = (field, data) => {
    let isExists = chargeCodeList.find((a) => a.code === data)
    if (!isExists) {
      setValue(field, '')
      openModal(field)
    } else {
      setValue(field, data)
    }
  }

  const onConfirm = (type, value) => {
    clearErrors(fieldActive)
    setValue(fieldActive, value)
    setShowPopup(false)
  }
  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Other Charge</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        {fields.map((item, index) => (
          <div className='mb-3'>
            <Row key={item.id} className='gy-2 ps-1 mb-2'>
              <Col lg={4} md={6} sm={12}>
                <ErrorFieldWrapper
                  error={errors.otherCharge?.[index]?.otherChargeIndicator}
                  message={
                    errors.otherCharge?.[index]?.otherChargeIndicator?.message
                  }
                >
                  <div className='d-flex align-items-center justify-content-between'>
                    <Label
                      htmlFor={`otherCharge.${index}.otherChargeIndicator`}
                      className='form-label m-0 w-50'
                    >
                      P/C Indicator
                    </Label>
                    <Controller
                      name={`otherCharge.${index}.otherChargeIndicator`}
                      type='text'
                      control={control}
                      render={({field}) => (
                        <Select
                          className='w-50'
                          {...field}
                          isSearchable={false}
                          cacheOptions
                          isDisabled={isDetail}
                          isClearable
                          defaultOptions
                          value={
                            chargeCodeOptions.find(
                              (option) => option.value === field.value,
                            ) || null
                          }
                          onChange={(selectedOption) => {
                            fields.forEach((field, idx) => {
                              setValue(
                                `otherCharge.${idx}.otherChargeIndicator`,
                                selectedOption?.value ?? '',
                              )
                            })
                            onCalculateCharge()
                          }}
                          options={chargeCodeOptions}
                        />
                      )}
                    />
                  </div>
                </ErrorFieldWrapper>
              </Col>
              <Col>
                {!isDetail && index > 0 ? (
                  <ActionButton onClick={() => remove(index)} action='remove' />
                ) : null}
              </Col>
            </Row>
            <Row className='gy-2 ps-1 mb-3'>
              <SubOtherChargeItem
                errors={errors}
                control={control}
                isDetail={isDetail}
                openModal={openModal}
                onCalculateCharge={onCalculateCharge}
                index={index}
                setValue={setValue}
                watch={watch}
                setError={setError}
                clearErrors={clearErrors}
              />
            </Row>
          </div>
        ))}
        {!isDetail && (
          <Row className='gy-2 ps-1'>
            <Col lg={4} md={6} sm={12}>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='m-0 w-50'></div>
                <div className='w-50'>
                  <Button
                    type='button'
                    className='btn btn-light'
                    style={{
                      width: 120,
                      color: '#69809A',
                      backgroundColor: '#E7EBEF',
                    }}
                    onClick={() => {
                      const otherChargeIndicator = getValue(
                        `otherCharge.0.otherChargeIndicator`,
                      )
                      append({
                        otherChargeIndicator: otherChargeIndicator,
                        items: [
                          {
                            otherChargeChargeCode: '',
                            entitlementCode: '',
                            otherChargeAmount: '',
                          },
                        ],
                      })
                    }}
                  >
                    Add Row
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Collapse>

      {!isDetail && (
        <ListMasterModal
          show={showPopup}
          onCloseClick={() => {
            setShowPopup((v) => !showPopup)
          }}
          onConfirm={onConfirm}
          dataList={chargeCodeList}
          withFilterAlphabet={false}
          mapData={{
            description: 'DESCRIPTION',
            code: 'CODE',
          }}
          title={`Charge Code`}
        />
      )}
    </div>
  )
}

function SubOtherChargeItem({
  errors,
  control,
  isDetail = false,
  openModal,
  onCalculateCharge = () => {},
  index,
  setValue,
  watch,
  setError,
  clearErrors,
}) {
  const {append, fields, remove} = useFieldArray({
    control,
    name: `otherCharge.${index}.items`,
  })

  const checkDuplicateCombination = ({outerIndex, innerIndex}) => {
    clearErrors( `otherCharge.${outerIndex}.items.${innerIndex}.otherChargeChargeCode`)
    
    const watchOtherCharge = watch('otherCharge')

    const combinationList = []

    watchOtherCharge.forEach((item, index) => {
      item.items.forEach((subItem, idx) => {
        
        if(index === outerIndex && idx === innerIndex) return
        combinationList.push(
          `${subItem.otherChargeChargeCode}-${subItem.entitlementCode}`,
        )
      })
    })

    const currentCombination = `${watchOtherCharge[outerIndex].items[
      innerIndex
    ].otherChargeChargeCode?.toUpperCase()}-${
      watchOtherCharge[outerIndex].items[innerIndex].entitlementCode
    }`

    if (combinationList.includes(currentCombination)) {
      setError(
        `otherCharge.${outerIndex}.items.${innerIndex}.otherChargeChargeCode`,

        {message: 'Charge Code and Entitlement Code Combination already exists',}
      )
    }
  }

  const validateCombination = debounce(checkDuplicateCombination, 500)
  return fields.map((item, idx) => (
    <React.Fragment key={item.id ?? idx}>
      <Col lg={4} md={6} sm={12}>
        <ErrorFieldWrapper
          error={
            errors.otherCharge?.[index]?.items?.[idx]?.otherChargeChargeCode
          }
          message={
            errors.otherCharge?.[index]?.items?.[idx]?.otherChargeChargeCode
              ?.message
          }
        >
          <div className='d-flex align-items-center justify-content-between'>
            <Label
              htmlFor={`otherCharge.${index}.items.${idx}.otherChargeChargeCode`}
              className='form-label m-0 w-50'
            >
              Charge Code
            </Label>
            <Controller
              name={`otherCharge.${index}.items.${idx}.otherChargeChargeCode`}
              type='text'
              control={control}
              render={({field}) => (
                <div className='d-flex align-items-stretch w-50 gap-1'>
                  <InputPopupModal
                    fieldName={`otherCharge.${index}.items.${idx}.otherChargeChargeCode`}
                    // checkValidData={checkValidData}
                    {...field}
                    value={field.value}
                    maxLength={3}
                    disabled={isDetail}
                    onBlur={() => {
                      setValue(
                        `otherCharge.${index}.items.${idx}.otherChargeChargeCode`,
                        field.value.toUpperCase(),
                      )
                      validateCombination({
                        outerIndex: index,
                        innerIndex: idx,
                      })
                    }}
                  />
                  {!isDetail && (
                    <ActionButton
                      onClick={() =>
                        openModal(
                          `otherCharge.${index}.items.${idx}.otherChargeChargeCode`,
                        )
                      }
                    />
                  )}
                </div>
              )}
            />
          </div>
        </ErrorFieldWrapper>
      </Col>
      <Col lg={4} md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.otherCharge?.[index]?.items?.[idx]?.entitlementCode}
          message={
            errors.otherCharge?.[index]?.items?.[idx]?.entitlementCode?.message
          }
        >
          <div className='d-flex align-items-center justify-content-between'>
            <Label
              htmlFor={`otherCharge.${index}.items.${idx}.entitlementCode`}
              className='form-label m-0 w-50'
            >
              Entitlement Code
            </Label>
            <Controller
              name={`otherCharge.${index}.items.${idx}.entitlementCode`}
              type='text'
              control={control}
              render={({field}) => (
                <Select
                  className='w-50'
                  {...field}
                  isSearchable={false}
                  cacheOptions
                  isDisabled={isDetail}
                  isClearable
                  defaultOptions
                  value={
                    entitlementCodeOptions.find(
                      (option) => option.value === field.value,
                    ) || ""
                  }
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption?.value)
                    onCalculateCharge()
                    validateCombination({outerIndex: index, innerIndex: idx})
                  }}
                  options={entitlementCodeOptions}
                />
              )}
            />
          </div>
        </ErrorFieldWrapper>
      </Col>
      <Col lg={4} md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.otherCharge?.[index]?.items?.[idx]?.otherChargeAmount}
          message={
            errors.otherCharge?.[index]?.items?.[idx]?.otherChargeAmount
              ?.message
          }
        >
          <div className='d-flex align-items-center justify-content-between'>
            <Label htmlFor='otherChargeAmount' className='form-label m-0 w-50'>
              Charge Amount
            </Label>
            <Controller
              name={`otherCharge.${index}.items.${idx}.otherChargeAmount`}
              type='text'
              control={control}
              render={({field}) => (
                <div className='d-flex align-items-stretch w-50 gap-1'>
                  <InputNumeric
                    {...field}
                    value={field.value}
                    disabled={isDetail}
                    maxLength={12}
                    isDecimal={true}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      onCalculateCharge()
                    }}
                  />
                  {!isDetail && idx === 0 ? (
                    <ActionButton
                      disabled={fields.length === 3}
                      onClick={() =>
                        append({
                          otherChargeChargeCode: '',
                          entitlementCode: '',
                          otherChargeAmount: '',
                        })
                      }
                      action='add'
                    />
                  ) : null}
                  {!isDetail && idx > 0 ? (
                    <ActionButton onClick={() => remove(idx)} action='remove' />
                  ) : null}
                </div>
              )}
            />
          </div>
        </ErrorFieldWrapper>
      </Col>
    </React.Fragment>
  ))
}
