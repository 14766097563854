import BreadCrumb from 'Components/Common/BreadCrumb'
import { formatNumber } from 'Helpers/number'
import { AdditionalCostOnlineAgentFormRQ } from 'Hooks/Queries'
import { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table
} from 'reactstrap'

export default function DetailAdditionalCost(props) {
  const {id} = useParams()

  const {data, error, isFetching, status} = AdditionalCostOnlineAgentFormRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Biaya' pageTitle='Biaya' />
        <Row>
          <Col lg={12}>
            <form onSubmit={() => {}}>
              <Card>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                  <h4 className=''>Detail Biaya</h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <div className='table-responsive'>
                    <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
                      <thead></thead>
                      <tbody>
                        <tr>
                          <th
                            style={{background: 'rgb(243, 246, 249)'}}
                            colSpan={3}
                          >
                            General
                          </th>
                        </tr>
                        <tr>
                          <th style={{width: '40%'}}>Deskripsi</th>
                          <td colSpan={2}>{data?.data?.name ?? '-'}</td>
                        </tr>
                        <tr>
                          <th style={{width: '40%'}}>Amount</th>
                          <td colSpan={2}>
                            {data?.data?.value_type === 'PERCENT'
                              ? `${data?.data?.value}%`
                              : `Rp. ${formatNumber(data?.data?.value)}`}
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{background: 'rgb(243, 246, 249)'}}
                            colSpan={3}
                          >
                            System
                          </th>
                        </tr>
                        <tr>
                          <th style={{width: '40%'}}>Waktu dibuat</th>
                          <td colSpan={2}>{data?.data?.created_on ?? '-'}</td>
                        </tr>
                        <tr>
                          <th style={{width: '40%'}}>Waktu diubah</th>
                          <td colSpan={2}>{data?.data?.updated_on ?? '-'}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link
                    to='/app/struktur-harga/online-agent-form/biaya'
                    className='btn btn-outline-dark'
                  >
                    Back
                  </Link>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
