import {apiBearer} from 'Helpers/api'
import {
  LIST_PAYMENT_BANKS_URL,
  PAYMENT_UPLOAD_REQUEST_ONLINE_AGENT_URL,
} from 'Helpers/url_helper'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'

import {
  Row,
  Col,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from 'reactstrap'
import {RequestOnlineAgentFormRQ} from '../../../Hooks/Queries/requestOnlineAgentFormRQ'
import {Navigate, useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ONLINE_AGENT_PAYMENT_STATUS,
  PAYMENT_METHODS,
} from '../../../Helpers/options'
import {showError, showSuccess} from '../../../Helpers/show_toast'
import {formatNumber} from '../../../Helpers/number'
import {useSelector} from 'react-redux'

function PaymentInfo({data}) {
  return (
    <div className='payment-section-left'>
      <div className='payment-info-section'>
        <span className='text-white d-flex justify-content-between'>
          <span>Payment To</span>
          <span>{data?.payment?.detail?.displayName}</span>
        </span>
        <div className='payment-info-card'>
          <div className='d-flex flex-column gap-2 py-2 border-bottom'>
            <span>Amount</span>
            <span className='fw-bold'>
              Rp.{formatNumber(data?.total ?? data?.detail?.amount)}
            </span>
          </div>
          {data?.payment?.detail?.description && (
            <div className='d-flex justify-content-between gap-2 py-2'>
              <span>Notes</span>
              <span>{data?.payment?.detail?.description}</span>
            </div>
          )}
          <div className='d-flex justify-content-between gap-2 py-2'>
            <span>Status</span>
            <span>
              <span className='badge badge-soft-danger'>Unpaid</span>
            </span>
          </div>
          {data?.payment?.detail?.expiredAt && (
            <div className='d-flex justify-content-between gap-2 py-2 border-bottom'>
              <span>Pay Before</span>
              <span>
                {moment(data?.payment?.detail?.expiredAt).format(
                  'DD MMMM YYYY HH:mm',
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function PaymentSuccessImage() {
  return (
    <div className='payment-section-left'>
      <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
        <img
          alt='success'
          src='/assets/images/success.png'
          style={{maxWidth: '100%'}}
        />
      </div>
    </div>
  )
}

function PaymentBanks({
  banks,
  handleNext,
  register,
  watchBank,
  isLoadingPayment,
}) {
  return (
    <div className='payment-section-right'>
      <div className='payment-method-section'>
        <span className='text-muted d-flex justify-content-start gap-2 fs-4 m-0 p-0 fw-bold mb-3'>
          <span className='p-0 m-0'>Choose payment method</span>
        </span>
        <div className='payment-method-list'>
          {banks &&
            banks.map((bank, index) => {
              return (
                <div className='form-check payment-method-item' key={bank.id}>
                  <input
                    className='form-check-input ms-0 me-3'
                    type='radio'
                    value={bank.id}
                    id={bank.id}
                    {...register('bank')}
                  />
                  <label className='form-check-label' htmlFor={bank.id}>
                    {bank.name}
                  </label>
                </div>
              )
            })}
        </div>
        <button
          onClick={handleNext}
          className='btn button-pay-filled'
          disabled={banks.length === 0 || !watchBank || isLoadingPayment}
        >
          {isLoadingPayment ? 'Loading...' : 'NEXT'}
        </button>
      </div>
    </div>
  )
}

function PaymentSuccess({navigateToList, data}) {
  return (
    <div className='payment-section-right'>
      <div className='payment-method-section'>
        <div className='d-flex  flex-column justify-content-center h-100'>
          <span className='text-muted d-flex flex-column text-center justify-content-start gap-3 fs-4 m-0 p-0 mb-4'>
            <span className='p-0 m-0 fw-bold'>Successfully paid</span>
            <span className='p-0 m-0 fs-6'>
              We have successfully received your payment
            </span>
          </span>
          <div className='d-flex justify-content-between gap-2 py-3 '>
            <span className='text-muted'>Amount</span>
            <span>Rp.{formatNumber(data?.amount)}</span>
          </div>
          <div className='d-flex justify-content-between gap-2 py-3'>
            <span className='text-muted'>Payment Date</span>
            <span>{moment(data?.createdAt).format('DD MMMM YYYY HH:mm')}</span>
          </div>
          <div className='d-flex justify-content-between gap-2 py-3'>
            <span className='text-muted'>Paid to</span>
            <span>{data?.displayName}</span>
          </div>

          <div className='d-flex justify-content-between gap-2 py-3 border-bottom border-2'>
            <span className='text-muted'>Notes</span>
            <span>{data?.description}</span>
          </div>
          <div className='d-flex justify-content-between gap-2 py-3'>
            <span className='text-muted'>Reff ID: {data?.id}</span>
          </div>
        </div>
        <button
          onClick={navigateToList}
          style={{height: '54px'}}
          className='btn btn-outline-dark w-100 mt-3 fw-bold fs-5'
        >
          Kembali
        </button>
      </div>
    </div>
  )
}

function PaymentBankDetail({
  checkStatus,
  navigateToList,
  data,
  bank,
  isLoading,
  handleCopy,
}) {
  const [open, setOpen] = useState('')
  const toggle = (id) => {
    if (open === id) {
      setOpen('')
    } else {
      setOpen(id)
    }
  }

  const expiredAt = moment(data?.expiredAt)
  const calculateTimeLeft = () => expiredAt - Date.now()

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    if (timeLeft <= 0) return

    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)
    return () => clearInterval(interval)
  }, [expiredAt, timeLeft])

  const formatHours = (ms) => {
    if (ms <= 0) return '00'
    const hours = Math.floor(ms / (1000 * 60 * 60))
    return `${hours.toString().padStart(2, '0')}`
  }

  const formatMinutes = (ms) => {
    if (ms <= 0) return '00'
    const minutes = Math.floor((ms / (1000 * 60)) % 60)
    return `${minutes.toString().padStart(2, '0')}`
  }
  const formatSeconds = (ms) => {
    if (ms <= 0) return '00'
    const seconds = Math.floor((ms / 1000) % 60)
    return `${seconds.toString().padStart(2, '0')}`
  }

  let bankInstructions = bank?.instructions ?? '{}'
  bankInstructions = bankInstructions
    .replaceAll('#VA_CODE', data?.accountNo)
    .replaceAll('#VA_NAME', data?.displayName)
    .replaceAll('#AMOUNT', 'Rp.' + formatNumber(data?.amount))
  const parsedData = JSON.parse(bankInstructions)

  return (
    <Col md={12}>
      <div className='payment-section-right'>
        <div className='payment-method-section'>
          <div className='payment-count-down'>
            <i className='ri-timer-line icon'></i>
            <div className='d-flex flex-column'>
              <span className='fw-bold'>Pay before</span>
              <span>
                {moment(data?.expiredAt).format('DD MMMM YYYY HH:mm')}
              </span>
            </div>
            <div className='timer'>
              <span className='timer-item'>{formatHours(timeLeft)}</span>:
              <span className='timer-item'>{formatMinutes(timeLeft)}</span>:
              <span className='timer-item'>{formatSeconds(timeLeft)}</span>
            </div>
          </div>
          <span className='text-muted d-flex justify-content-start gap-2 fs-4 m-0 p-0 fw-bold mb-2'>
            <span className='p-0 m-0'>Transfer to</span>
          </span>
          <div className='payment-method-detail'>
            <div className='payment-method-detail-item fw-bold'>
              <div className='d-flex align-items-center'>
                <img
                  alt=''
                  height={'30px'}
                  style={{width: 'auto'}}
                  src={`/assets/icons/${data?.bank?.toLowerCase()}.png`}
                  className='me-2 img-fluid'
                />
                {bank?.name}
              </div>
            </div>
            <div className='payment-method-detail-item'>
              <span className='title'>Account Number</span>
              <div className='d-flex align-items-center'>
                <span className='content'>{data?.accountNo}</span>
                <button
                  className='btn btn-sm p-0 text-dark ms-1'
                  onClick={() => handleCopy(data?.accountNo)}
                  title='Copy'
                >
                  <i className='ri-file-copy-line fs-5'></i>
                </button>
              </div>
            </div>
            <div className='payment-method-detail-item'>
              <span className='title'>Account Name</span>
              <span className='content'>{data?.displayName}</span>
            </div>
            <div className='payment-method-detail-item'>
              <span className='title'>Total Payment</span>
              <div className='d-flex align-items-center'>
                <span className='content'>Rp.{formatNumber(data?.amount)}</span>
                <button
                  className='btn btn-sm p-0 text-dark ms-1'
                  onClick={() => handleCopy(data?.amount)}
                  title='Copy'
                >
                  <i className='ri-file-copy-line fs-5'></i>
                </button>
              </div>
            </div>
          </div>

          <span className='text-muted d-flex justify-content-start align-items-center gap-2 fs-4 m-0 p-0 fw-bold mb-2 mt-3'>
            <i className='ri-wallet-3-line icon'></i>
            <span className='p-0 m-0'>How to Transfer</span>
          </span>
          <div className=''>
            <Accordion open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader className='bordered' targetId='1'>
                  ATM {bank?.name}
                </AccordionHeader>
                <AccordionBody accordionId='1'>
                  {parsedData && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: parsedData?.atm,
                      }}
                    />
                  )}
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='mt-1'>
                <AccordionHeader className='bordered' targetId='2'>
                  m-Banking {bank?.name}
                </AccordionHeader>
                <AccordionBody accordionId='2'>
                  {parsedData && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: parsedData?.mBanking,
                      }}
                    />
                  )}
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className='mt-1'>
                <AccordionHeader className='bordered' targetId='3'>
                  iBanking {bank?.name}
                </AccordionHeader>
                <AccordionBody accordionId='3'>
                  {parsedData && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: parsedData?.iBanking,
                      }}
                    />
                  )}
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <button
            onClick={checkStatus}
            style={{height: '54px'}}
            onMouseDown={(e) => e.preventDefault()}
            disabled={isLoading}
            className='btn btn-outline-warning w-100 mt-4 fw-bold fs-5'
          >
            {isLoading ? 'Loading...' : 'Cek Status'}
          </button>
          <button
            onClick={navigateToList}
            style={{height: '54px'}}
            className='btn btn-outline-dark w-100 mt-3 fw-bold fs-5'
          >
            Kembali
          </button>
        </div>
      </div>
    </Col>
  )
}

export default function BankTransfer() {
  const {id} = useParams()
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)
  const {isLoggedin} = auth
  const [banks, setBanks] = useState([])
  const [activeTab, setActiveTab] = useState(1)
  const [isLoadingPayment, setIsLoadingPayment] = useState(false)
  const [detailData, setDetailData] = useState({})
  const {register, handleSubmit, watch} = useForm()

  const watchBank = watch('bank', {})

  const {data, error, isFetching, status, refetch} =
    RequestOnlineAgentFormRQ.useGetData(id)()

  const navigateToList = () => {
    navigate('/app/fwb-fhl')
  }

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text || '')
      .then(() => {
        showSuccess('Copied!')
      })
      .catch((err) => console.error('Failed to copy:', err))
  }

  const checkStatus = () => {
    refetch().then((res) => {
      if (res.data.data.payment_status === ONLINE_AGENT_PAYMENT_STATUS.PAID) {
        setActiveTab(3)
      }
    })
  }

  const fetchBanks = async () => {
    if (!isLoggedin) {
      navigate('/login')
      return
    } else {
      const result = await apiBearer.get(LIST_PAYMENT_BANKS_URL)

      if (result?.status === 200) {
        const {data} = result
        if (data.success) {
          setBanks(data?.data)
        }
      }
    }
  }

  const onPayment = () => {
    setIsLoadingPayment(true)
    apiBearer
      .post(PAYMENT_UPLOAD_REQUEST_ONLINE_AGENT_URL(id), {
        payment_method: PAYMENT_METHODS.TRANSFER,
        bank_id: watchBank,
      })
      .then((result) => {
        setIsLoadingPayment(false)
        if (result?.data?.data) {
          setDetailData(result?.data)
          setActiveTab(2)
        } else {
          showError('Error while saving')
        }
      })
      .catch((err) => {
        setIsLoadingPayment(false)
      })
  }

  useEffect(() => {
    if (!isFetching && status === 'success') {
      if (data) {
        setDetailData(data)
        if (data?.data?.payment) {
          setActiveTab(2)
        } else {
          setActiveTab(1)
        }
      }
    }
    if (!isFetching && status === 'error') {
      console.error(error)
    }
  }, [data])

  useEffect(() => {
    fetchBanks()
  }, [])

  return (
    <Row className='payment-section g-0'>
      <Col xs={12} md={6} lg={6}>
        {activeTab === 3 ? (
          <PaymentSuccessImage />
        ) : (
          <PaymentInfo data={detailData?.data} />
        )}
      </Col>
      <Col xs={12} md={6} lg={6}>
        {activeTab === 1 && (
          <PaymentBanks
            banks={banks}
            handleNext={handleSubmit(onPayment)}
            register={register}
            isLoadingPayment={isLoadingPayment}
            watchBank={watchBank}
          />
        )}
        {activeTab === 2 && (
          <PaymentBankDetail
            banks={banks}
            data={detailData?.data?.payment?.detail}
            bank={detailData?.data?.payment?.bank}
            setActiveTab={setActiveTab}
            watchBank={watchBank}
            isLoading={isFetching}
            checkStatus={checkStatus}
            navigateToList={navigateToList}
            handleCopy={handleCopy}
          />
        )}

        {activeTab === 3 && (
          <PaymentSuccess
            navigateToList={navigateToList}
            data={detailData?.data?.payment?.detail}
          />
        )}
      </Col>
    </Row>
  )
}
