import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Input, Label, Row} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import ListMasterModal from '../ListMasterModal'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'
import InputPopupModal from '../../../Helpers/InputPopupModal'

export const ShipperConsigneeInformation = ({errors, control, countryCodeList, clearErrors, setValue, isDetail = false}) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const [showPopup, setShowPopup] = useState({
    consigneeCountryCode: false,
    shipperCountryCode: false,
  })

  const onConfirm = (field, value) => {
    clearErrors(field)
    setValue(field, value)
    setShowPopup({
      ...showPopup,
      [field]: false,
    })
  }

  const checkValidData = (field, data) => {
    let isExists = countryCodeList.find(a => a.code === data)
    if (!isExists) {
      setValue(field, '')
      setShowPopup({...showPopup, [field]: true})
    } else {
      setValue(field, data)
    }
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className=' bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <Row>
          <Col sm={6}> Shipper</Col>
          <Col sm={6} className='d-flex justify-content-between'>
            <span> Consignee</span>
            {isOpen ? (
              <i className='ri-arrow-down-s-line'></i>
            ) : (
              <i className='ri-arrow-up-s-line'></i>
            )}
          </Col>
        </Row>
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col
            lg={6}
            sm={12}
            className='d-flex flex-column'
            style={{gap: '10px'}}
          >
            <ErrorFieldWrapper
              error={errors.shipperAccountNumber}
              message={errors.shipperAccountNumber?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='shipperAccountNumber' className='form-label m-0 w-25'>
                  Account Number
                </Label>
                <Controller
                  id='shipperAccountNumber'
                  name='shipperAccountNumber'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={14}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            <ErrorFieldWrapper
              error={errors.shipperName}
              message={errors.shipperName?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='shipperName' className='form-label m-0 w-25'>
                  Name <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='shipperName'
                  name='shipperName'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={35}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            <ErrorFieldWrapper
              error={errors.shipperStreetAddress}
              message={errors.shipperStreetAddress?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='shipperStreetAddress' className='form-label m-0 w-25'>
                  Street Address <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='shipperStreetAddress'
                  name='shipperStreetAddress'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={35}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            <ErrorFieldWrapper
              error={errors.shipperCity}
              message={errors.shipperCity?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='shipperCity' className='form-label m-0 w-25'>
                  Place<span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='shipperCity'
                  name='shipperCity'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={17}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            <ErrorFieldWrapper
              error={errors.shipperProvince}
              message={errors.shipperProvince?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='shipperProvince' className='form-label m-0 w-25'>
                  State/Province
                </Label>
                <Controller
                  id='shipperProvince'
                  name='shipperProvince'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={9}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            <ErrorFieldWrapper
              error={errors.shipperCountryCode}
              message={errors.shipperCountryCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='shipperCountryCode' className='form-label m-0 w-25'>
                  ISO Country Code<span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='shipperCountryCode'
                  name='shipperCountryCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch gap-1' style={{width: '67.5%'}}>
                      <InputPopupModal
                        fieldName='shipperCountryCode'
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={2}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton onClick={() =>
                          setShowPopup({...showPopup, shipperCountryCode: true})
                        } />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            <ErrorFieldWrapper
              error={errors.shipperPostalCode}
              message={errors.shipperPostalCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='shipperPostalCode' className='form-label m-0 w-25'>
                  Postal Code
                </Label>
                <Controller
                  id='shipperPostalCode'
                  name='shipperPostalCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={9}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            {/*<ErrorFieldWrapper*/}
            {/*  error={errors.shipperContactIdentifier}*/}
            {/*  message={errors.shipperContactIdentifier?.message}*/}
            {/*>*/}
            {/*  <div className='d-flex align-items-center justify-content-between'>*/}
            {/*    <Label htmlFor='shipperContactIdentifier' className='form-label m-0 w-25'>*/}
            {/*      Contact Identifier<span className='mandatory'>*</span>*/}
            {/*    </Label>*/}
            {/*    <Controller*/}
            {/*      id='shipperContactIdentifier'*/}
            {/*      name='shipperContactIdentifier'*/}
            {/*      type='text'*/}
            {/*      control={control}*/}
            {/*      render={({field}) => (*/}
            {/*        <InputTextFhlFwb*/}
            {/*          style={{width: '67.5%'}}*/}
            {/*          {...field}*/}
            {/*          value={field.value}*/}
            {/*          disabled={isDetail}*/}
            {/*          alpha={true}*/}
            {/*          maxLength={3}*/}
            {/*        />*/}
            {/*      )}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</ErrorFieldWrapper>*/}
            <ErrorFieldWrapper
              error={errors.shipperContactNumber}
              message={errors.shipperContactNumber?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='shipperContactNumber' className='form-label m-0 w-25'>
                  Telephone
                </Label>
                <Controller
                  id='shipperContactNumber'
                  name='shipperContactNumber'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={25}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col
            lg={6}
            sm={12}
            className='d-flex flex-column'
            style={{gap: '10px'}}
          >
            <ErrorFieldWrapper
              error={errors.consigneeAccountNumber}
              message={errors.consigneeAccountNumber?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='consigneeAccountNumber' className='form-label m-0 w-25'>
                  Account Number
                </Label>
                <Controller
                  id='consigneeAccountNumber'
                  name='consigneeAccountNumber'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={14}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            <ErrorFieldWrapper
              error={errors.consigneeName}
              message={errors.consigneeName?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='consigneeName' className='form-label m-0 w-25'>
                  Name <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='consigneeName'
                  name='consigneeName'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={35}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            <ErrorFieldWrapper
              error={errors.consigneeStreetAddress}
              message={errors.consigneeStreetAddress?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='consigneeStreetAddress' className='form-label m-0 w-25'>
                  Street Address <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='consigneeStreetAddress'
                  name='consigneeStreetAddress'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={35}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            <ErrorFieldWrapper
              error={errors.consigneeCity}
              message={errors.consigneeCity?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='consigneeCity' className='form-label m-0 w-25'>
                  Place<span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='consigneeCity'
                  name='consigneeCity'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={35}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            <ErrorFieldWrapper
              error={errors.consigneeProvince}
              message={errors.consigneeProvince?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='consigneeProvince' className='form-label m-0 w-25'>
                  State/Province
                </Label>
                <Controller
                  id='consigneeProvince'
                  name='consigneeProvince'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={9}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            <ErrorFieldWrapper
              error={errors.consigneeCountryCode}
              message={errors.consigneeCountryCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='consigneeCountryCode' className='form-label m-0 w-25'>
                  ISO Country Code<span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='consigneeCountryCode'
                  name='consigneeCountryCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch gap-1' style={{width: '67.5%'}}>
                      <InputPopupModal
                        fieldName='consigneeCountryCode'
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={2}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton onClick={() =>
                          setShowPopup({...showPopup, consigneeCountryCode: true})
                        } />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            <ErrorFieldWrapper
              error={errors.consigneePostalCode}
              message={errors.consigneePostalCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='consigneePostalCode' className='form-label m-0 w-25'>
                  Postal Code
                </Label>
                <Controller
                  id='consigneePostalCode'
                  name='consigneePostalCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={9}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
            <ErrorFieldWrapper
              error={errors.consigneeContactNumber}
              message={errors.consigneeContactNumber?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='consigneeContactNumber' className='form-label m-0 w-25'>
                  Telephone
                </Label>
                <Controller
                  id='consigneeContactNumber'
                  name='consigneeContactNumber'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={25}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>

      {
        !isDetail &&
        Object.keys(showPopup).map((key) => {
          return (
            <ListMasterModal
              key={key}
              field={key}
              show={showPopup[key]}
              onCloseClick={() => {
                setShowPopup((v) => ({...v, [key]: !showPopup[key]}))
              }}
              onConfirm={onConfirm}
              dataList={countryCodeList}
              withFilterAlphabet={false}
              mapData={
                {
                  code: 'Country Code',
                  name: 'Name',
                }
              }
              title={'Country Code'}
            />
          )
        })
      }
    </div>
  )
}
