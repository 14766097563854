import {yupResolver} from '@hookform/resolvers/yup'
import ConfirmPermission from 'Components/Common/DeleteModal'
import NleLoginModal from 'Components/Common/NleLoginModal'
import {APP_NAME} from 'configs'
import {showError} from 'Helpers/show_toast'
import {useLocalStorage} from 'Hooks'
import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useLocation} from 'react-router-dom'
import {Button, Card, CardBody, Col, Container, Label, Row} from 'reactstrap'
import {loginAction} from 'Redux/actions/auth'
import {LOGINTYPES} from 'Redux/constants'
import {
  setAutofillValues,
  setNleRefreshToken,
  setNleToken,
} from 'Redux/reducers/auth'
import {requestFirebaseNotificationPermission} from 'Service/Firebase'
import * as Yup from 'yup'

export default function SignIn() {
  const loading = useSelector((state)=> state.auth.loading)
  const [modalReq, setModalReq] = useState({show: false, loading: false})
  const [fcmToken, setFcmToken] = useLocalStorage('fcm_token', null)
  const [deviceToken, setDeviceToken] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [nleLoginModal, setNleLoginModal] = useState(false)

  const location = useLocation()
  const redirectTo = location.state?.from
    ? location.state.from
    : '/app/dashboard'
  const validation = Yup.object().shape({
    user_name: Yup.string()
      .required('username is required')
      // .test("test-email-format", "invalid email format", (value) => {
      //   return validEmail.test(value);
      // })
      .strict(true),
    password: Yup.string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters'),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      user_name: '',
      password: '',
    },
  }
  const {handleSubmit, formState, register} = useForm(formOptions)
  const {errors} = formState
  const dispatch = useDispatch()
  const onSubmit = async (values) => {
    const payload = {
      ...values,
      redirectTo,
      loginType: LOGINTYPES.BASIC,
      fcm_token: fcmToken,
    }
    dispatch(loginAction(payload))
  }

  const checkAndAskPermission = async () => {
    setModalReq((v) => ({...v, loading: true}))
    try {
      const result = await requestFirebaseNotificationPermission()
      setFcmToken(result)
      setModalReq((v) => ({...v, show: false}))
    } catch (error) {
      console.log(error)
    }
    setModalReq((v) => ({...v, loading: false}))
  }

  const getAndSetFcmToken = () => {
    if (!fcmToken && Notification.permission !== 'granted') {
      setModalReq((v) => ({...v, show: true}))
    }
    if (!fcmToken && Notification.permission === 'granted') {
      requestFirebaseNotificationPermission().then((token) => {
        setFcmToken(token)
      })
    }
  }

  const onModalNleLoginSuccess = (data) => {
    const payload = {
      access_token: data.access_token,
      loginType: LOGINTYPES.NLE,
      device_token: deviceToken,
      redirectTo,
      fcm_token: fcmToken,
    }
    dispatch(setNleToken(data.access_token))
    dispatch(setNleRefreshToken(data.refresh_token))
    dispatch(setAutofillValues({username: data.username}))
    dispatch(loginAction(payload))
  }

  document.title = `${APP_NAME} | Login`
  return (
    <div className='auth-page-content'>
      <Container>
        <Row className='justify-content-center'>
          <Col md={8} lg={6} xl={5}>
            <Card className='mt-4'>
              <CardBody className='p-4'>
                <div className='text-center mt-2 mb-0'>
                  <Link to='/' className='d-inline-block auth-logo'>
                    <img
                      src='/logo.svg'
                      alt={APP_NAME+` logo`}
                      height={80}
                    />
                  </Link>
                  <p className='text-muted mt-4 mb-0 auth-label'>
                    SILAHKAN LOGIN UNTUK MASUK KE APLIKASI
                  </p>
                </div>
                <div className='p-2 mt-2'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div
                      className={`mb-3 ${errors.user_name ? 'error-form' : ''}`}
                    >
                      <Label>
                        Username <span className='mandatory'>*</span>
                      </Label>
                      <input
                        type='text'
                        className='form-control'
                        {...register('user_name')}
                      />
                      <div className='error-form-info badge badge-soft-danger'>
                        <img
                          alt=''
                          src='/assets/icons/triangle-alert-fill.svg'
                          className='me-1'
                        />{' '}
                        {errors.user_name?.message}
                      </div>
                    </div>
                    <div
                      className={`mb-3 ${errors.password ? 'error-form' : ''}`}
                    >
                      <Label>
                        Password <span className='mandatory'>*</span>
                      </Label>
                      <div className='input-group'>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className='form-control'
                          {...register('password')}
                        />
                        <button
                          className='btn btn-outline-primary'
                          type='button'
                          id='inputGroupFileAddon04'
                          onClick={() => setShowPassword((v) => !v)}
                        >
                          <i
                            className={`ri ${
                              showPassword ? 'ri-eye-fill' : 'ri-eye-off-fill'
                            }`}
                          ></i>
                        </button>
                      </div>
                      <div className='error-form-info badge badge-soft-danger'>
                        <img
                          alt=''
                          src='/assets/icons/triangle-alert-fill.svg'
                          className='me-1'
                        />{' '}
                        {errors.password?.message}
                      </div>
                    </div>
                    <div className='d-flex justify-content-end'>
                      {/* <div className="form-check">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="auth-remember-check"
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="auth-remember-check"
                        >
                          Remember me
                        </Label>
                      </div> */}

                      <Link to='/forgot-password' className='text-muted'>
                        Forgot password?
                      </Link>
                    </div>
                    <div className='mt-4'>
                      <Button
                        color='success'
                        className='btn btn-success w-100'
                        type='submit'
                        disabled={loading}
                      >
                        {loading ? 'Loading...' : 'Sign In'}
                      </Button>

                      {/* <div className='line mt-3 mb-3 text-center'>
                        <span>or sign in with</span>
                      </div> */}

                      <div className='w-full w-100'>
                        {/* <button
                          type="button"
                          onClick={onGoogleLogin}
                          className="btn rounded-3 d-flex align-items-center justify-content-center btn-outline-dark w-100 mb-3"
                        >
                          <img
                            src="assets/icons/google.svg"
                            className="me-2"
                            alt="google-logo"
                          />{" "}
                          <span>Login by Google</span>
                        </button> */}

                        {/* <button
                          type='button'
                          onClick={() => setNleLoginModal(true)}
                          className='btn rounded-3 d-flex align-items-center justify-content-center btn-outline-dark w-100 mb-3'
                        >
                          <img
                            src='assets/icons/nle-logo.svg'
                            className='me-2'
                            alt='google-logo'
                          />{' '}
                          <span>Login by NLE</span>
                        </button> */}
                      </div>
                    </div>
                    <div className='mt-4 d-flex justify-content-center'>
                      <p>
                        Not Registered?{' '}
                        <Link to='/register' className='fw-bold'>
                          Create an account
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>

            <div className='mt-4 text-center'>
              {/* <p className="mb-0">Don't have an account ? <Link to="/auth-signup-basic" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p> */}
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmPermission
        title='Notification'
        description='Click allow for notification'
        okText='Allow'
        onCloseClick={() => {
          setModalReq((v) => ({...v, show: false}))
          showError('Some features will not work as expected!')
        }}
        loading={modalReq.loading}
        show={modalReq.show}
        onDeleteConfirm={() => {
          checkAndAskPermission()
        }}
      />
      <NleLoginModal
        show={nleLoginModal}
        toggleModal={() => setNleLoginModal((v) => !v)}
        onSuccessLogin={onModalNleLoginSuccess}
      />
    </div>
  )
}
