import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useEffect, useState} from 'react'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Input, Label, Row} from 'reactstrap'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'
import InputNumeric from '../../../Helpers/InputNumeric'
import Select from 'react-select'
import {commisionInformationOptions} from 'Helpers/options'

export const CommissionInformation = ({
  errors,
  control,
  getValues,
  isDetail = false,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [type, setType] = useState('')

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setType(getValues('noCommissionIndicator'))
  }, [getValues])

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Commission Information</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12}>
            <Label className='form-label fw-semibold m-0 text-decoration-underline'>
              Type
            </Label>
            <ErrorFieldWrapper
              error={errors.noCommissionIndicator}
              message={errors.noCommissionIndicator?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='noCommissionIndicator'
                  className='form-label m-0 w-50 w-50'
                >
                  Type Commision
                </Label>
                <Controller
                  id='noCommissionIndicator'
                  name='noCommissionIndicator'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Select
                      {...field}
                      isDisabled={isDetail}
                      options={commisionInformationOptions}
                      value={commisionInformationOptions.find(
                        (v) => v.value === field.value,
                      )}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption?.value)

                        setType(selectedOption?.value)
                      }}
                      className='w-50'
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          {getValues('noCommissionIndicator') === 'Amount' ? (
            <Col lg={4} md={6} sm={12}>
              <Label className='form-label fw-semibold m-0 text-decoration-underline'>
                Commission Amount
              </Label>
              <ErrorFieldWrapper
                error={errors.commissionAmount}
                message={errors.commissionAmount?.message}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label
                    htmlFor='commissionAmount'
                    className='form-label m-0 w-50 w-50'
                  >
                    CASS Settlement Factor
                  </Label>
                  <Controller
                    id='commissionAmount'
                    name='commissionAmount'
                    type='text'
                    control={control}
                    render={({field}) => (
                      <InputNumeric
                        className='w-50'
                        {...field}
                        value={field.value}
                        disabled={isDetail}
                        maxLength={12}
                        isDecimal={true}
                      />
                    )}
                  />
                </div>
              </ErrorFieldWrapper>
            </Col>
          ) : getValues('noCommissionIndicator') === 'Percentage' ? (
            <Col lg={4} md={6} sm={12}>
              <Label className='form-label fw-semibold m-0 text-decoration-underline'>
                Commission Percentage
              </Label>
              <ErrorFieldWrapper
                error={errors.commissionPercentage}
                message={errors.commissionPercentage?.message}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label
                    htmlFor='commissionPercentage'
                    className='form-label m-0 w-50 w-50'
                  >
                    CASS Settlement Factor
                  </Label>
                  <Controller
                    id='commissionPercentage'
                    name='commissionPercentage'
                    type='text'
                    control={control}
                    render={({field}) => (
                      <InputNumeric
                        className='w-50'
                        {...field}
                        value={field.value}
                        disabled={isDetail}
                        maxLength={12}
                        isDecimal={true}
                      />
                    )}
                  />
                </div>
              </ErrorFieldWrapper>
            </Col>
          ) : (
            <Col lg={4} md={6} sm={12}></Col>
          )}
        </Row>
      </Collapse>
    </div>
  )
}
