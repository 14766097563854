import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import {RequestOnlineAgentFormRQ} from '../../../Hooks/Queries/requestOnlineAgentFormRQ'
import RevisionModal from '../../../Components/RequestAgentOnlineForm/RevisionModal'
import {apiBearer} from '../../../Helpers/api'
import {
  REVISION_REQUEST_ONLINE_AGENT_URL,
  SEND_REQUEST_ONLINE_AGENT_URL,
  VIEW_FWB_DETAIL_WITH_FHL_URL,
} from '../../../Helpers/url_helper'
import {showError, showSuccess} from '../../../Helpers/show_toast'
import SuccessModal from '../../../Components/Common/SuccessModal'
import {
  ADMIN,
  getBadgeRequestOnlineClass,
  getOnlineAgentOrderStatusLabel,
  getOnlineAgentPaymentStatusLabel,
  ONLINE_AGENT_ORDER_STATUS,
  ONLINE_AGENT_PAYMENT_STATUS,
  ONLINE_AGENT_TYPE_DOC,
  ONLINE_AGENT_TYPE_DOCUMENT,
} from '../../../Helpers/options'
import {useSelector} from 'react-redux'
import RevisionAgentModal from '../../../Components/FwbFhl/RevisionAgentModal'
import moment from 'moment'
import SitatextCard from 'Components/FwbFhl/SitatextCard'
import {DATA_LIMIT} from 'configs'
import SitaApiModal from 'Components/FwbFhl/SitaApiModal'

export default function DetailFwbWithListFhl(props) {
  const {id} = useParams()
  const navigate = useNavigate()
  const {profile} = useSelector((state) => state.auth)
  const [selectedtab, setSelectedTab] = useState(1)

  const [sitaApiModal, setSitaApiModal] = useState(false)
  const [isLoadingSita, setIsLoadingSita] = useState(false)
  const [dataDetail, setDataDetail] = useState({})
  const [dataSita, setDataSita] = useState({})
  const [successModal, setSuccessModal] = useState(false)
  const [revisionModal, setRevisionModal] = useState(false)
  const [isLoadingRevision, setIsLoadingRevision] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  const linkedToSitaText = (data) => {
    return (
      <Link
        to='#'
        className='link-dark me-2'
        onClick={() => {
          setDataSita(data)
          setSitaApiModal(true)
        }}
        title={'Detail'}
      >
        <i className='ri-eye-fill'></i>
      </Link>
    )
  }
  const linkedToDetailUpload = () => {
    return (
      <Link
        to={`/app/upload/detail/${id}`}
        className='link-dark'
        title={'Detail'}
      >
        <i className='ri-eye-fill'></i>
      </Link>
    )
  }
  const linkedToPaymentPage = (data) => {
    return (
      <>
        {data?.payment_status === ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS ? (
          <Link
            to={`/app/fwb-fhl/${id}/payment/bank`}
            className='link-dark'
            title={'Bayar'}
          >
            <i className='bx bx-money'></i>
          </Link>
        ) : (
          <Link
            to={`/app/fwb-fhl/${id}/payment`}
            className='link-dark'
            title={'Bayar'}
          >
            <i className='bx bx-money'></i>
          </Link>
        )}
      </>
    )
  }
  const linkedToDetailOrPaymentPage = (data) => {
    return (
      <>
        {data?.payment_status === ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS ? (
          <Link
            to={`/app/fwb-fhl/${id}/payment/bank`}
            className='link-dark'
            title={'Bayar'}
          >
            <i className='bx bx-money'></i>
          </Link>
        ) : (
          <Link
            to={`/app/upload/detail/${id}`}
            className='link-dark'
            title={'Bayar'}
          >
            <i className='bx bx-money'></i>
          </Link>
        )}
      </>
    )
  }

  const linkedToEditUpload = () => {
    return (
      <Link to={`/app/upload/edit/${id}`} className='link-dark' title={'Edit'}>
        <i className='bx bxs-edit'></i>
      </Link>
    )
  }
  const linkedToEditFhl = () => {
    return (
      <Link to={`/app/fhl/edit/${id}`} className='link-dark' title={'Edit'}>
        <i className='bx bxs-edit'></i>
      </Link>
    )
  }

  const linkedToDetailFhlFwb = () => {
    return (
      <Link to={`/app/fhl/detail/${id}`} className='link-dark' title={'Detail'}>
        <i className='ri-eye-fill'></i>
      </Link>
    )
  }

  const renderActionUpload = (data) => {
    if (data?.order_status !== null) {
      if (data?.order_status === ONLINE_AGENT_ORDER_STATUS.NEW_REQUEST)
        return linkedToDetailUpload()
      if (
        data?.order_status === ONLINE_AGENT_ORDER_STATUS.DRAFT ||
        data?.order_status === ONLINE_AGENT_ORDER_STATUS.SEND
      )
        return linkedToSitaText(data)
    }

    if (
      data?.payment_status === ONLINE_AGENT_PAYMENT_STATUS.UNPAID ||
      data?.payment_status === ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS
    ) {
      return (
        <>
          {linkedToDetailOrPaymentPage(data)}
          {linkedToEditUpload()}
        </>
      )
    } else if (data?.payment_status === ONLINE_AGENT_PAYMENT_STATUS.PAID) {
      return linkedToSitaText(data)
    } else {
      return linkedToDetailUpload()
    }
  }

  const renderActionFhl = (data) => {
    if (
      data?.payment_status === ONLINE_AGENT_PAYMENT_STATUS.UNPAID ||
      data?.payment_status === ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS
    ) {
      return (
        <>
          {!data?.is_draft && linkedToPaymentPage(data)}
          <span className='m-1'></span>
          {linkedToEditFhl()}
        </>
      )
    } else if (
      data?.payment_status === ONLINE_AGENT_PAYMENT_STATUS.EXPIRED ||
      data?.payment_status === ONLINE_AGENT_PAYMENT_STATUS.FAILED
    ) {
      return linkedToDetailFhlFwb()
    }
    return linkedToSitaText(data)
  }

  const onSendData = () => {
    setIsLoadingSita(true)
    apiBearer
      .patch(SEND_REQUEST_ONLINE_AGENT_URL(dataDetail.id), {})
      .then((result) => {
        if (result?.data?.data) {
          setSuccessModal(true)
        } else {
          setIsLoadingSita(false)
          showError('Error while saving')
        }
      })
      .catch((err) => {
        setIsLoadingSita(false)
      })
  }

  const onRevisionSubmit = async (data) => {
    setIsLoadingRevision(true)

    apiBearer
      .patch(REVISION_REQUEST_ONLINE_AGENT_URL(dataDetail?.id), data)
      .then((result) => {
        setIsLoadingRevision(false)
        setRevisionModal(false)
        if (result?.data?.data) {
          setSuccessModal(true)
          setDataDetail({})
        } else {
          showError('Error while saving')
        }
      })
      .catch((err) => {
        setIsLoadingRevision(false)
      })
  }

  const onClickRevision = async (e) => {
    setRevisionModal(true)
  }

  const onCloseClick = () => {
    setSitaApiModal(false)
  }

  const navigateToList = () => {
    if (isAdmin) {
      navigate('/app/request-agent-online-form')
    } else {
      navigate('/app/fwb-fhl')
    }
  }

  useEffect(() => {
    apiBearer.get(VIEW_FWB_DETAIL_WITH_FHL_URL(id)).then((result) => {
      if (result?.data?.data) {
        setDataDetail(result?.data?.data)
      }
    })
  }, [id])

  useEffect(() => {
    setIsAdmin(profile.role === ADMIN)
  }, [navigate, profile.is_approved, profile.role])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Request Agent Online Form' />
        {
          <div className='mb-3 border-bottom border-bottom-2'>
            <Button
              onClick={() => setSelectedTab(1)}
              color='light'
              style={{
                borderBottom: selectedtab === 1 ? '1px solid #3F5F29' : '',
                borderRadius: 0,
                fontWeight: 600,
              }}
            >
              Sitatext
            </Button>
            <Button
              onClick={() => setSelectedTab(2)}
              color='light'
              style={{
                borderBottom: selectedtab === 2 ? '1px solid #3F5F29' : '',
                borderRadius: 0,
                fontWeight: 600,
              }}
            >
              List FHL
            </Button>
          </div>
        }
        <TabContent activeTab={selectedtab}>
          <TabPane tabId={1}>
            <Card style={{width: 'max-content', maxWidth: '100%'}}>
              <CardBody className='g-0'>
                <SitatextCard
                  onCloseClick={navigateToList}
                  onSendClick={() => onSendData()}
                  data={dataDetail}
                  isLoading={isLoadingSita}
                  isUpload={
                    dataDetail.type === ONLINE_AGENT_TYPE_DOCUMENT.UPLOAD
                  }
                  onRevision={onClickRevision}
                  isAdmin={isAdmin}
                />
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId={2}>
            {' '}
            <Card>
              <CardBody className='card-body'>
                <span className='fw-bold fs-5'>List Fhl</span>
                <div className='table-responsive mt-4'>
                  <Table className='table-bordered align-middle table-nowrap mb-0'>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Action</th>
                        <th>No HWB</th>
                        <th>Upload Date</th>
                        <th>Shipper Name</th>
                        <th>Consigne name</th>
                        <th>Send date</th>
                        <th>Payment Status</th>
                        <th>Order Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataDetail?.related_fhl &&
                      dataDetail?.related_fhl?.length > 0 ? (
                        dataDetail?.related_fhl?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td className='fs-15'>
                                {item?.type ===
                                  ONLINE_AGENT_TYPE_DOCUMENT.UPLOAD &&
                                  renderActionUpload(item)}
                                {item?.type ===
                                  ONLINE_AGENT_TYPE_DOCUMENT.FHL &&
                                  renderActionFhl(item)}
                              </td>
                              <td>{item?.form_no}</td>
                              <td>
                                {item?.upload_date
                                  ? moment(item?.upload_date).format(
                                      'DD MMMM YYYY',
                                    )
                                  : ''}
                              </td>
                              <td>{item?.shipper_name}</td>
                              <td>{item?.consignee_name}</td>
                              <td>
                                {item?.send_date
                                  ? moment(item?.send_date).format(
                                      'DD MMMM YYYY',
                                    )
                                  : ''}
                              </td>
                              <td>
                                <span
                                  className={getBadgeRequestOnlineClass(
                                    item?.payment_status,
                                  )}
                                >
                                  {getOnlineAgentPaymentStatusLabel(
                                    item?.payment_status,
                                  )}
                                </span>
                              </td>
                              <td>
                                <span
                                  className={getBadgeRequestOnlineClass(
                                    item?.order_status,
                                  )}
                                >
                                  {getOnlineAgentOrderStatusLabel(
                                    item?.order_status,
                                  )}
                                </span>
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={9} className='text-center'>
                            <span className='text-danger'>No Data</span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </Container>

      <RevisionModal
        onCloseClick={() => setRevisionModal((v) => !v)}
        show={revisionModal}
        isLoadingRevision={isLoadingRevision}
        onSubmitClick={onRevisionSubmit}
      />

      <SitaApiModal
        onCloseClick={() => onCloseClick()}
        onSendClick={() => onSendData()}
        show={sitaApiModal}
        data={dataSita}
        isLoading={isLoadingSita}
        isUpload={dataSita.type === ONLINE_AGENT_TYPE_DOCUMENT.UPLOAD}
        onRevision={onClickRevision}
      />

      {/* <RevisionAgentModal
        onCloseClick={() => setRevisionAgentModal((v) => !v)}
        show={revisionAgentModal}
        isLoadingRevision={isLoadingRevision}
        onSubmitClick={onRevisionSubmit}
        detailData={data?.data}
      /> */}

      <SuccessModal
        onCloseClick={() => {
          setSuccessModal((v) => !v)

          setIsLoadingSita(false)
        }}
        show={successModal}
        text='Revision berhasil Terkirim'
      />
    </div>
  )
}
