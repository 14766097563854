import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {Controller} from 'react-hook-form'
import {Button, Col, Collapse, Label, Row} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import React, {useState} from 'react'
import ListMasterModal from '../ListMasterModal'
import InputPopupModal from '../../../Helpers/InputPopupModal'

export const Routing = ({
                          errors,
                          control,
                          setValue,
                          fields,
                          append,
                          remove,
                          clearErrors,
                          careerCodeList,
                          airportList,
                          isDetail = false,
                        }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [fieldActive, setFieldActive] = useState('')

  const [showPopup, setShowPopup] = useState({
    airport: false,
    carrierCode: false,
  })

  const openModal = (type, field) => {
    setFieldActive(field)
    setShowPopup({...showPopup, [type]: true})
  }
  const mapDataModal = {
    airport: {
      title: 'Airport City Code',
      mapData: {
        name: 'CITY PORT NAME',
        code: 'CITY PORT CODE',
      },
      data: airportList,
    },
    carrierCode: {
      title: 'Carrier Code',
      mapData: {
        description: 'DESCRIPTION',
        code: 'CODE',
      },
      data: careerCodeList,
    },
  }

  const checkValidData = (field, data) => {
    const lastWord = field.split('.').pop()
    const mapModal = {
      destinationAirportCode: 'airport',
      onwardDestinationAirportCode: 'airport',
      destinationCarrierCode: 'carrierCode',
      onwardDestinationCarrierCode: 'carrierCode',
    }

    let isExists = mapDataModal[mapModal[lastWord]].data.find(a => a.code === data)
    if (!isExists) {
      setValue(field, '')
      openModal(mapModal[lastWord], field)
    } else {
      setValue(field, data)
    }
  }

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const onConfirm = (type, value) => {
    clearErrors(fieldActive)
    setValue(fieldActive, value)
    setShowPopup({...showPopup, [type]: false})
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Routing</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1 mb-2'>
          <Col md={12}>
            <Label className='form-label m-0 text-decoration-underline'>
              Destination/Carrier
            </Label>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.destinationAirportCode}
              message={errors.destinationAirportCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor={`destinationAirportCode`} className='form-label m-0 w-50'>
                  Airport/City Code
                </Label>
                <Controller
                  name='destinationAirportCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex w-50 align-items-stretch gap-1'>
                      <InputPopupModal
                        fieldName={`destinationAirportCode`}
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={3}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton
                          onClick={() => openModal('airport', 'destinationAirportCode')}
                        />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.destinationCarrierCode}
              message={errors.destinationCarrierCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor={`destinationCarrierCode`} className='form-label m-0 w-50'>
                  Carrier Code <span className='mandatory'>*</span>
                </Label>
                <Controller
                  name='destinationCarrierCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex w-50 align-items-stretch gap-1'>
                      <InputPopupModal
                        fieldName={`destinationCarrierCode`}
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={2}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton
                          onClick={() =>
                            openModal('carrierCode', 'destinationCarrierCode')
                          }
                        />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
        <Row className='gy-2 ps-1 mb-2'>
          <Col md={12}>
            <Label className='form-label m-0 text-decoration-underline'>
              Onward Destination/Carrier
            </Label>
          </Col>
        </Row>
        {fields.map((item, index) => (
          <Row key={item.id} className='gy-2 ps-1 mb-2'>
            <Col lg={4} md={6} sm={12}>
              <ErrorFieldWrapper
                error={errors.routing?.[index]?.onwardDestinationAirportCode}
                message={errors.routing?.[index]?.onwardDestinationAirportCode?.message}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label htmlFor={`routing.${index}.onwardDestinationAirportCode`} className='form-label m-0 w-50'>
                    Airport/City Code <span className='mandatory'>*</span>
                  </Label>
                  <Controller
                    name={`routing.${index}.onwardDestinationAirportCode`}
                    type='text'
                    control={control}
                    render={({field}) => (
                      <div className='d-flex w-50 align-items-stretch gap-1'>
                        <InputPopupModal
                          fieldName={`routing.${index}.onwardDestinationAirportCode`}
                          checkValidData={checkValidData}
                          {...field}
                          value={field.value}
                          maxLength={3}
                          disabled={isDetail}
                        />
                        {
                          !isDetail && <ActionButton
                            onClick={() =>
                              openModal('airport', `routing.${index}.onwardDestinationAirportCode`)
                            }
                          />
                        }
                      </div>
                    )}
                  />
                </div>
              </ErrorFieldWrapper>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <ErrorFieldWrapper
                error={errors.routing?.[index]?.onwardDestinationCarrierCode}
                message={errors.routing?.[index]?.onwardDestinationCarrierCode?.message}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label htmlFor={`routing.${index}.onwardDestinationCarrierCode`} className='form-label m-0 w-50'>
                    Carrier Code
                  </Label>
                  <Controller
                    name={`routing.${index}.onwardDestinationCarrierCode`}
                    type='text'
                    control={control}
                    render={({field}) => (
                      <div className='d-flex w-50 align-items-stretch gap-1'>
                        <InputPopupModal
                          fieldName={`routing.${index}.onwardDestinationCarrierCode`}
                          checkValidData={checkValidData}
                          {...field}
                          value={field.value}
                          maxLength={2}
                          disabled={isDetail}
                        />
                        {
                          !isDetail && <ActionButton
                            onClick={() =>
                              openModal('carrierCode', `routing.${index}.onwardDestinationCarrierCode`)
                            }
                          />
                        }
                      </div>
                    )}
                  />
                </div>
              </ErrorFieldWrapper>
            </Col>
            <Col lg={4} md={6} sm={12}>
              {!isDetail && index > 0 ? (
                <ActionButton onClick={() => remove(index)} action='remove'/>
              ) : null}
            </Col>
          </Row>
        ))}
        {
          !isDetail && (
            <Row className='gy-2 ps-1'>
              <Col lg={4} md={6} sm={12}>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='m-0 w-50'></div>
                  <div className='w-50'>
                    <Button
                      type='button'
                      disabled={fields.length === 2}
                      className='btn btn-light'
                      style={{width: 120, color: '#69809A', backgroundColor: '#E7EBEF'}}
                      onClick={() => append({
                        onwardDestinationAirportCode: '',
                        onwardDestinationCarrierCode: '',
                      })}
                    >
                      Add Row
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          )
        }
      </Collapse>

      {
        !isDetail &&
        Object.keys(mapDataModal).map((key) => {
          return (
            <ListMasterModal
              key={key}
              field={key}
              show={showPopup[key]}
              onCloseClick={() => {
                setShowPopup((v) => ({...v, [key]: !showPopup[key]}))
              }}
              onConfirm={onConfirm}
              dataList={mapDataModal[key].data}
              withFilterAlphabet={key === 'airport'}
              mapData={mapDataModal[key].mapData}
              title={mapDataModal[key].title}
            />
          )
        })
      }
    </div>
  )
}
