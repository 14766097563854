import {Container, Row} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {useState} from 'react'
import DeleteModal from 'Components/Common/DeleteModal'
import {airlineRQ} from 'Hooks/Queries'
import withTable from 'Components/Common/Table/withTable'
import {useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {DATA_LIMIT} from 'configs'
import AirlineItem from 'Components/AirlineItem'

export default function ListAirline() {
  const [deleteAirlineId, setDeleteAirlineId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const {status, data, error, isFetching, refetch} = airlineRQ.useGetDataList()(
    false,
    keyword,
    offset,
    limit,
  )

  const useQuery = useQueryClient()
  const navigate = useNavigate()

  const onDeleteSuccess = (key) => {
    setDeleteModal(false)
    setDeleteAirlineId(null)
    useQuery.invalidateQueries({queryKey: [key]})
    refetch()
  }
  const {mutate: deleteTruck} = airlineRQ.useDeleteData(onDeleteSuccess)()

  const onDeleteClick = (id) => {
    setDeleteModal(true)
    setDeleteAirlineId(id)
  }

  const onDeleteConfirm = () => {
    deleteTruck(deleteAirlineId)
  }

  const onAddButtonClicked = () => {
    navigate('/app/airline/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const AirlineTable = withTable(
    AirlineItem,
    'airline',
    ['NO', 'Action', 'CODE', 'SITATEXT PREFIX', 'NAME', 'FHL VERSION'],
    onDeleteClick,
    onAddButtonClicked,
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Airline' />

        <Row>
          <AirlineTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  )
}
