import React from 'react'
import {TabPane} from 'reactstrap'
import {AWBConsignmentDetails} from './AWBConsignmentDetails'
import {FlightBooking} from './FlightBooking'
import {ShipperConsigneeInformation} from './ShipperConsigneeInformation'
import {Routing} from './Routing'

export const Step1Fwb = ({
                           errors,
                           control,
                           setValue,
                           setError,
                           getValues,
                           clearErrors,
                           airportList,
                           careerCodeList,
                           countryCodeList,
                           fieldsFlightBookings,
                           appendFlightBookings,
                           removeFlightBookings,
                           fieldsRouting,
                           appendRouting,
                           removeRouting,
                           airlineList,
                           isDetail = false,
                         }) => {

  return (
    <TabPane tabId={1}>
      <AWBConsignmentDetails
        errors={errors}
        control={control}
        setValue={setValue}
        setError={setError}
        getValues={getValues}
        clearErrors={clearErrors}
        airportList={airportList}
        airlineList={airlineList}
        isDetail={isDetail}
      />
      <FlightBooking
        errors={errors}
        control={control}
        setValue={setValue}
        getValues={getValues}
        clearErrors={clearErrors}
        careerCodeList={careerCodeList}
        append={appendFlightBookings}
        remove={removeFlightBookings}
        fields={fieldsFlightBookings}
        isDetail={isDetail}
      />
      <Routing
        errors={errors}
        control={control}
        setValue={setValue}
        getValues={getValues}
        clearErrors={clearErrors}
        careerCodeList={careerCodeList}
        airportList={airportList}
        append={appendRouting}
        remove={removeRouting}
        fields={fieldsRouting}
        isDetail={isDetail}
      />
      <ShipperConsigneeInformation
        errors={errors}
        control={control}
        setValue={setValue}
        getValues={getValues}
        clearErrors={clearErrors}
        countryCodeList={countryCodeList}
        isDetail={isDetail}
      />
    </TabPane>
  )
}
