import ConfirmModal from 'Components/Common/ConfirmModal'
import ImageWithLink from 'Components/Common/ImageWithLink'
import {apiBearer} from 'Helpers/api'
import {showSuccess} from 'Helpers/show_toast'
import {APPROVE_AGENT_URL, LIST_BANKS_URL} from 'Helpers/url_helper'
import {AgentRQ} from 'Hooks/Queries'
import {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import { capitalize } from 'lodash'
import { formatNumber } from 'Helpers/number'

export default function DetailAgent(props) {
  const {id} = useParams()
  const navigate = useNavigate()
  const [confirmModal, setConfirmModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const {data, error, isFetching, status, refetch} = AgentRQ.useGetData(id)()
  const [bank, setBank] = useState(null)

  const approve = () => {
    setLoading(true)
    apiBearer
      .patch(APPROVE_AGENT_URL(id))
      .then(() => {
        showSuccess('Approved successfully')
        refetch()
      })
      .finally(() => {
        setLoading(false)
        setConfirmModal(false)
      })
  }
  const fetchBanks = async () => {
    const result = await apiBearer.get(LIST_BANKS_URL)
    const {data:dataBank} = result
    if (dataBank.success) {
      const selectedBank = dataBank?.data.find(
        (v) => v.code === data?.data?.bank_name,
      )
      setBank(selectedBank.name)
    }
  }

  useEffect(() => {
    if (!isFetching && status === 'success') {
      fetchBanks()
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Agent' pageTitle='Agent' />
        <Row>
          <Col lg={12}>
            <form onSubmit={() => {}}>
              <Card>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                  <h4 className=''>Detail Agent</h4>
                  {data?.data?.is_approved ? (
                    <Badge color='success'>APPROVED </Badge>
                  ) : (
                    <Badge color='warning'>PENDING </Badge>
                  )}
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col md={6}>
                      <Label htmlFor='name' className='form-label'>
                        Name
                      </Label>
                      <Input
                        disabled
                        id='name'
                        name='name'
                        type='name'
                        value={data?.data?.name ?? ''}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='province' className='form-label'>
                        Province
                      </Label>
                      <Input
                        disabled
                        id='province'
                        name='province'
                        type='text'
                        value={data?.data?.province?.name ?? ''}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='city' className='form-label'>
                        City
                      </Label>
                      <Input
                        disabled
                        id='city'
                        name='city'
                        type='text'
                        value={data?.data?.city?.name ?? ''}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='district' className='form-label'>
                        District
                      </Label>
                      <Input
                        disabled
                        id='district'
                        name='district'
                        type='text'
                        value={data?.data?.district?.name ?? ''}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='village' className='form-label'>
                        Village
                      </Label>
                      <Input
                        disabled
                        id='village'
                        name='village'
                        type='text'
                        value={data?.data?.village?.name ?? ''}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='zipcode' className='form-label'>
                        Zipcode
                      </Label>
                      <Input
                        disabled
                        id='zipcode'
                        name='zipcode'
                        type='zipcode'
                        value={data?.data?.zip_code ?? ''}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='picName' className='form-label'>
                        PIC Name
                      </Label>
                      <Input
                        disabled
                        id='picName'
                        name='picName'
                        type='picName'
                        value={data?.data?.pic_name ?? ''}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='picEmail' className='form-label'>
                        PIC Email
                      </Label>
                      <Input
                        disabled
                        id='picEmail'
                        name='picEmail'
                        type='picEmail'
                        value={data?.data?.pic_email ?? ''}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='picPhone' className='form-label'>
                        PIC Phone
                      </Label>
                      <Input
                        disabled
                        id='picPhone'
                        name='picPhone'
                        type='picPhone'
                        value={data?.data?.pic_phone ?? ''}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='address' className='form-label'>
                        Address
                      </Label>
                      <Input
                        disabled
                        id='address'
                        name='address'
                        type='address'
                        value={data?.data?.address ?? ''}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='bankAccount' className='form-label'>
                        Bank Account
                      </Label>
                      <Input
                        disabled
                        id='bankAccount'
                        name='bankAccount'
                        type='bankAccount'
                        value={data?.data?.bank_account ?? ''}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='bank' className='form-label'>
                        Bank
                      </Label>
                      <Input
                        disabled
                        id='bank'
                        name='bank'
                        type='text'
                        value={bank ?? ''}
                      />
                    </Col>

                    <Col md={6}>
                      <Label htmlFor='discountType' className='form-label'>
                        Discount Type
                      </Label>
                      <Input
                        disabled
                        id='discountType'
                        name='discountType'
                        type='text'
                        value={capitalize(data?.data?.discounts[0]?.value_type ?? '')}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor='discountValue' className='form-label'>
                        Discount Amount
                      </Label>
                      <Input
                        disabled
                        id='discountValue'
                        name='discountValue'
                        type='text'
                        value={data?.data?.discounts[0]?.value_type === 'PERCENT'
                          ? `${data?.data?.discounts[0]?.value ?? ''}%`
                          : `Rp. ${formatNumber(data?.data?.discounts[0]?.value)}`
                        }
                      />
                    </Col>
                    
                    <Col md={6}>
                      <Label htmlFor='docAttachment' className='form-label'>
                        Dokumen
                      </Label>
                      {data?.data?.doc ? (
                        <ImageWithLink url={data?.data?.doc} />
                      ) : (
                        <input
                          type='text'
                          className='form-control'
                          disabled
                          value='Belum ada dokumen'
                        />
                      )}
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/agents' className='btn btn-outline-dark'>
                    Back
                  </Link>
                  {data?.data?.is_approved ? null : (
                    <button
                      className='btn btn-primary'
                      type='button'
                      onClick={() => setConfirmModal((v) => !v)}
                    >
                      {loading ? 'Approving...' : 'Approve'}
                    </button>
                  )}
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
      <ConfirmModal
        description='Yakin ingin approve data agent ini?'
        loading={loading}
        onConfirm={approve}
        onCloseClick={() => setConfirmModal((v) => !v)}
        show={confirmModal}
      />
    </div>
  )
}
