import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Label, Row} from 'reactstrap'
import ListMasterModal from '../ListMasterModal'
import {ActionButton} from '../ActionButton'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'
import InputPopupModal from '../../../Helpers/InputPopupModal'
import {
  accountInformationIdentifierOptions,
  officeFunctionDesignatorOptions,
  participantIdentifierOptions,
} from '../../../Helpers/options'
import Select from 'react-select'

export const SenderReference = ({
  errors,
  control,
  clearErrors,
  setValue,
  airportList,
  isDetail = false,
  trigger,
  fieldsSenderOffice,
  fieldsSenderParticipant,
}) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const [showPopup, setShowPopup] = useState({
    senderDestinationAirport: false,
    senderOfficeAirportCode: false,
  })

  const onConfirm = (field, value) => {
    clearErrors(field)
    setValue(field, value)
    setShowPopup({
      ...showPopup,
      [field]: false,
    })

    if (field === 'senderOfficeAirportCode') {
      trigger(fieldsSenderOffice)
    } else if (field === 'senderDestinationAirport') {
      trigger(fieldsSenderParticipant)
    }
  }

  const checkValidData = (field, data) => {
    console.log(field);
    
    let isExists = airportList.find((a) => a.code === data)
    if (!isExists) {
      setValue(field, '')
      setShowPopup({
        ...showPopup,
        [field]: true,
      })
    } else {
      setValue(field, data)
    }
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Sender Reference</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12}>
            <Label className='form-label fw-semibold m-0 text-decoration-underline'>
              Sender Office Message Address
            </Label>
            <ErrorFieldWrapper
              error={errors.senderOfficeAirportCode}
              message={errors.senderOfficeAirportCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='senderOfficeAirportCode'
                  className='form-label m-0 w-50 w-50'
                >
                  Airport/City Code
                </Label>
                <Controller
                  id='senderOfficeAirportCode'
                  name='senderOfficeAirportCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex w-50 align-items-stretch gap-1'>
                      <InputPopupModal
                        fieldName={`senderOfficeAirportCode`}
                        checkValidData={checkValidData}
                        additionalAction={() => trigger(fieldsSenderOffice)}
                        {...field}
                        value={field.value}
                        maxLength={3}
                        disabled={isDetail}
                      />
                      {!isDetail && (
                        <ActionButton
                          onClick={() =>
                            setShowPopup({
                              ...showPopup,
                              senderOfficeAirportCode: true,
                            })
                          }
                        />
                      )}
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Label className='form-label m-0 text-decoration-underline'></Label>
            <ErrorFieldWrapper
              error={errors.senderOfficeFunctionDesignator}
              message={errors.senderOfficeFunctionDesignator?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='senderOfficeFunctionDesignator'
                  className='form-label m-0 w-50 w-50'
                >
                  Office Function Designator
                </Label>
                <Controller
                  id='senderOfficeFunctionDesignator'
                  name='senderOfficeFunctionDesignator'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Select
                      {...field}
                      isSearchable={false}
                      className='w-50'
                      isDisabled={isDetail}
                      isClearable
                      defaultOptions
                      value={
                        officeFunctionDesignatorOptions.find(
                          (option) => option.value === field.value,
                        ) || null
                      }
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption?.value)
                        trigger(fieldsSenderOffice)
                      }}
                      options={officeFunctionDesignatorOptions}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Label className='form-label m-0 text-decoration-underline'></Label>
            <ErrorFieldWrapper
              error={errors.senderCompanyDesignator}
              message={errors.senderCompanyDesignator?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='senderCompanyDesignator'
                  className='form-label m-0 w-50 w-50'
                >
                  Company Designator
                </Label>
                <Controller
                  id='senderCompanyDesignator'
                  name='senderCompanyDesignator'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={2}
                      onBlur={() => {
                        trigger(fieldsSenderOffice)}}
                    
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Label className='form-label fw-semibold m-0 text-decoration-underline'>
              Sender Participant Identification
            </Label>
            <ErrorFieldWrapper
              error={errors.senderParticipantIdentifier}
              message={errors.senderParticipantIdentifier?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='senderParticipantIdentifier'
                  className='form-label m-0 w-50 w-50'
                >
                  Participant Identifier
                </Label>
                <Controller
                  id='senderParticipantIdentifier'
                  name='senderParticipantIdentifier'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Select
                      {...field}
                      isSearchable={false}
                      className='w-50'
                      isDisabled={isDetail}
                      isClearable
                      defaultOptions
                      value={
                        participantIdentifierOptions.find(
                          (option) => option.value === field.value,
                        ) || null
                      }
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption?.value)
                        trigger(fieldsSenderParticipant)
                      }}
                      options={participantIdentifierOptions}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Label className='form-label m-0'></Label>
            <ErrorFieldWrapper
              error={errors.senderParticipantCode}
              message={errors.senderParticipantCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='senderParticipantCode'
                  className='form-label m-0 w-50 w-50'
                >
                  Participant Code
                </Label>
                <Controller
                  id='senderParticipantCode'
                  name='senderParticipantCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={17}
                      onBlur={(e) => {
                        trigger(fieldsSenderParticipant)
                      }}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Label className='form-label m-0'></Label>
            <ErrorFieldWrapper
              error={errors.senderDestinationAirport}
              message={errors.senderDestinationAirport?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='senderDestinationAirport'
                  className='form-label m-0 w-50 w-50'
                >
                  Airport/City Code
                </Label>
                <Controller
                  id='senderDestinationAirport'
                  name='senderDestinationAirport'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex w-50 align-items-stretch gap-1'>
                      <InputPopupModal
                        fieldName={`senderDestinationAirport`}
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={3}
                        disabled={isDetail}
                        additionalAction={() => trigger(fieldsSenderParticipant)}
                      />
                      {!isDetail && (
                        <ActionButton
                          onClick={() =>
                            setShowPopup({
                              ...showPopup,
                              senderDestinationAirport: true,
                            })
                          }
                        />
                      )}
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <Label className='form-label fw-semibold m-0 text-decoration-underline'>
              Sender Office File Reference
            </Label>
            <ErrorFieldWrapper
              error={errors.senderOfficeFileReference}
              message={errors.senderOfficeFileReference?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='senderOfficeFileReference'
                  className='form-label m-0 w-50 w-50'
                >
                  File Reference
                </Label>
                <Controller
                  id='senderOfficeFileReference'
                  name='senderOfficeFileReference'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={15}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>

      {!isDetail &&
        Object.keys(showPopup).map((key) => {
          return (
            <ListMasterModal
              key={key}
              field={key}
              show={showPopup[key]}
              onCloseClick={() => {
                setShowPopup((v) => ({...v, [key]: !showPopup[key]}))
              }}
              onConfirm={onConfirm}
              dataList={airportList}
              title={`Airport City Code`}
              mapData={{
                name: 'CITY PORT NAME',
                code: 'CITY PORT CODE',
              }}
            />
          )
        })}
    </div>
  )
}
