import {useQueryClient} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import DeleteModal from 'Components/Common/DeleteModal'
import withTable from 'Components/Common/Table/withTable'
import AdditionalCostItem from 'Components/Master/AdditionalCostItem'
import {DATA_LIMIT} from 'configs'
import {AdditionalCostTruckingRQ} from 'Hooks/Queries'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Container, Row} from 'reactstrap'

export default function ListAdditionalCost() {
  const [deleteAdditionalCostId, setDeleteAdditionalCostId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const {status, data, error, isFetching, refetch} =
    AdditionalCostTruckingRQ.useGetDataList()(false, keyword, offset, limit)

  const useQuery = useQueryClient()
  const navigate = useNavigate()

  const onDeleteSuccess = (key) => {
    setDeleteModal(false)
    setDeleteAdditionalCostId(null)
    useQuery.invalidateQueries({queryKey: [key]})
    refetch()
  }
  const {mutate: deleteTruck} =
    AdditionalCostTruckingRQ.useDeleteData(onDeleteSuccess)()

  const onDeleteClick = (id) => {
    setDeleteModal(true)
    setDeleteAdditionalCostId(id)
  }

  const onDeleteConfirm = () => {
    deleteTruck(deleteAdditionalCostId)
  }

  const onAddButtonClicked = () => {
    navigate('/app/struktur-harga/trucking/biaya/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const GeneralSettingTable = withTable(
    AdditionalCostItem,
    'struktur-harga/trucking/biaya',
    ['NO', 'Action', 'Deskripsi', 'Jumlah'],
    onDeleteClick,
    onAddButtonClicked,
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Biaya' />

        <Row>
          <GeneralSettingTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  )
}
