import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { APP_NAME } from '../../configs';

const BreadCrumb = ({ title }) => {
    
    document.title =`${APP_NAME} | ${title}`

    const disabledLinks = ['create', 'edit', 'detail']
    const { pathname } = useLocation();
    const pathNames = pathname.split('/');
    const activePath =  pathNames[pathNames.length -1];
    const renderBreadCrumbItems = () => pathNames.map((v, index)=> (
                index > 1 && (
                    <li key={index} className={`breadcrumb-item ${v===activePath?'active':''}`}>
                        <Link to={`${pathNames.filter((v,i)=> i <= index && !disabledLinks.includes(v)).join('/')}`}>
                            { v.replaceAll('-', ' ').replace(/^[a-z]/, (match)=>match.toLocaleUpperCase()) }
                        </Link>
                    </li>
                    )
            ) 
        )
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{title}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                {renderBreadCrumbItems()}
                                {/* <li className="breadcrumb-item"><Link to="#">{pageTitle}</Link></li>
                                <li className="breadcrumb-item active">{title}</li> */}
                            </ol>
                        </div>

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;