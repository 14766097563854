import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Label, Row} from 'reactstrap'
import InputNumeric from '../../../Helpers/InputNumeric'
import InputPopupModal from '../../../Helpers/InputPopupModal'
import {ActionButton} from '../ActionButton'
import ListMasterModal from '../ListMasterModal'

export const CCChargesAndDestinationCurrency = ({errors, control, clearErrors, setValue, currencyList, isDetail = false}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [showPopup, setShowPopup] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  const onConfirm = (field, value) => {
    clearErrors('destinationCurrencyCode')
    setValue('destinationCurrencyCode', value)
    setShowPopup(false)
  }
  const checkValidData = (field, data) => {
    let isExists = currencyList.find(a => a.code === data)
    if (!isExists) {
      setValue(field, '')
      setShowPopup(true)
    } else {
      setValue(field, data)
    }
  }
  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> CC Charges And Destination Currency</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12}>
            <Label className='form-label fw-semibold m-0 text-decoration-underline'>
              Destination Currency
            </Label>
            <ErrorFieldWrapper
              error={errors.destinationCurrencyCode}
              message={errors.destinationCurrencyCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='destinationCurrencyCode'
                  className='form-label m-0 w-50 w-50'
                >
                  ISO Currency Code
                </Label>
                <Controller
                  id='destinationCurrencyCode'
                  name='destinationCurrencyCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch w-50 gap-1'>
                      <InputPopupModal
                        fieldName='destinationCurrencyCode'
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={3}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton
                          onClick={() =>
                            setShowPopup(true)
                          }
                        />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Label className='form-label fw-semibold m-0 text-decoration-underline'>
              Currency Conversation Rate
            </Label>
            <ErrorFieldWrapper
              error={errors.currencyConversationRate}
              message={errors.currencyConversationRate?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='currencyConversationRate'
                  className='form-label m-0 w-50 w-50'
                >
                  Rate of Exchange
                </Label>
                <Controller
                  id='currencyConversationRate'
                  name='currencyConversationRate'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={11} isDecimal={true} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Label className='form-label fw-semibold m-0 text-decoration-underline'>
              CC Charge and Destination Currency
            </Label>
            <ErrorFieldWrapper
              error={errors.ccChargeDestinationCurrency}
              message={errors.ccChargeDestinationCurrency?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='ccChargeDestinationCurrency'
                  className='form-label m-0 w-50 w-50'
                >
                  Change Amount
                </Label>
                <Controller
                  id='ccChargeDestinationCurrency'
                  name='ccChargeDestinationCurrency'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={12} isDecimal={true} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Label className='form-label fw-semibold m-0 text-decoration-underline'>
              Charge at Destination
            </Label>
            <ErrorFieldWrapper
              error={errors.chargeDestinationAmount}
              message={errors.chargeDestinationAmount?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='chargeDestinationAmount'
                  className='form-label m-0 w-50 w-50'
                >
                  Change Amount
                </Label>
                <Controller
                  id='chargeDestinationAmount'
                  name='chargeDestinationAmount'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={12} isDecimal={true} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <Label className='form-label fw-semibold m-0 text-decoration-underline'>
              Total Collect Charges
            </Label>
            <ErrorFieldWrapper
              error={errors.totalCollectChargeAmount}
              message={errors.totalCollectChargeAmount?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='totalCollectChargeAmount'
                  className='form-label m-0 w-50 w-50'
                >
                  Change Amount
                </Label>
                <Controller
                  id='totalCollectChargeAmount'
                  name='totalCollectChargeAmount'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={12} isDecimal={true} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>
      {
        !isDetail && <ListMasterModal
          show={showPopup}
          onCloseClick={() => {
            setShowPopup((v) => (!showPopup))
          }}
          onConfirm={onConfirm}
          dataList={currencyList}
          withFilterAlphabet={false}
          mapData={
            {
              code: 'Code',
              name: 'Currency',
            }
          }
          title={`Currency`}
        />
      }
    </div>
  )
}
