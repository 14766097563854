import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import Select from 'react-select'
import {Col, Collapse, Label, Row} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import ListMasterModal from '../ListMasterModal'
import InputNumeric from '../../../Helpers/InputNumeric'
import InputPopupModal from '../../../Helpers/InputPopupModal'
import {chargeCodeOptions} from '../../../Helpers/options'

export const ChargeDeclaration = ({
                                    errors,
                                    control,
                                    chargeCodeList,
                                    currencyList,
                                    clearErrors,
                                    setValue,
                                    isDetail = false,
                                  }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [fieldActive, setFieldActive] = useState('')
  const [showPopup, setShowPopup] = useState({
    currencyCode: false,
    chargeCode: false,
  })

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const openModal = (type, field) => {
    setFieldActive(field)
    setShowPopup({...showPopup, [type]: true})
  }

  const mapDataModal = {
    currencyCode: {
      title: 'Currency',
      mapData: {
        code: 'Code',
        name: 'Currency',
      },
      data: currencyList,
    },
    chargeCode: {
      title: 'Charge Code',
      mapData: {
        description: 'DESCRIPTION',
        code: 'CODE',
      },
      data: chargeCodeList,
    },
  }

  const onConfirm = (type, value) => {
    clearErrors(fieldActive)
    setValue(fieldActive, value)
    setShowPopup({...showPopup, [type]: false})
  }

  const checkValidData = (field, data) => {
    const lastWord = field.split('.').pop()
    const mapModal = {
      currencyCode: 'currencyCode',
      chargeDeclarationsChargeCode: 'chargeCode',
    }

    let isExists = mapDataModal[mapModal[lastWord]].data.find(a => a.code === data)
    if (!isExists) {
      setValue(field, '')
      openModal(mapModal[lastWord], field)
    } else {
      setValue(field, data)
    }
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Charge Declarations</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.currencyCode}
              message={errors.currencyCode?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='currencyCode' className='form-label m-0 w-50'>
                  ISO Currency Code<span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='currencyCode'
                  name='currencyCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch w-50 gap-1'>
                      <InputPopupModal
                        fieldName={`currencyCode`}
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={3}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton
                          onClick={() => openModal('currencyCode', 'currencyCode')}
                        />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.chargeDeclarationsChargeCode}
              message={errors.chargeDeclarationsChargeCode?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='chargeDeclarationsChargeCode' className='form-label m-0 w-50'>
                  Charge Code
                </Label>
                <Controller
                  id='chargeDeclarationsChargeCode'
                  name='chargeDeclarationsChargeCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch w-50 gap-1'>
                      <InputPopupModal
                        fieldName={`chargeDeclarationsChargeCode`}
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={3}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton
                          onClick={() => openModal('chargeCode', 'chargeDeclarationsChargeCode')}
                        />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.valuation}
              message={errors.valuation?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='valuation' className='form-label m-0 w-50'>
                  Weight / Valuation
                </Label>
                <Controller
                  id='valuation'
                  name='valuation'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Select
                      className='w-50'
                      {...field}
                      isSearchable={false}
                      cacheOptions
                      isDisabled={isDetail}
                      isClearable
                      defaultOptions
                      value={chargeCodeOptions.find(option => option.value === field.value) || null}
                      onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                      options={chargeCodeOptions}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.otherCharges}
              message={errors.otherCharges?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='otherCharges' className='form-label m-0 w-50'>
                  Other Charges
                </Label>
                <Controller
                  id='otherCharges'
                  name='otherCharges'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Select
                      className='w-50'
                      {...field}
                      isSearchable={false}
                      cacheOptions
                      isClearable
                      defaultOptions
                      isDisabled={isDetail}
                      value={chargeCodeOptions.find(option => option.value === field.value) || null}
                      onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                      options={chargeCodeOptions}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.declaredValueForCustoms}
              message={errors.declaredValueForCustoms?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='declaredValueForCustoms' className='form-label m-0 w-50'>
                  Declared Value for Customs
                </Label>
                <Controller
                  id='declaredValueForCustoms'
                  name='declaredValueForCustoms'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch w-50 gap-1'>
                      <InputNumeric {...field} value={field.value} disabled={isDetail} maxLength={12} placeholder={'NCV'} isDecimal={true} />
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.declaredValueForCarriage}
              message={errors.declaredValueForCarriage?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='declaredValueForCarriage' className='form-label m-0 w-50'>
                  Declared Value for Carriage
                </Label>
                <Controller
                  id='declaredValueForCarriage'
                  name='declaredValueForCarriage'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch w-50 gap-1'>
                      <InputNumeric {...field} value={field.value} disabled={isDetail} maxLength={12} placeholder={'NVD'} isDecimal={true} />
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.amountOfInsurance}
              message={errors.amountOfInsurance?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='amountOfInsurance' className='form-label m-0 w-50'>
                  Amount of Insurances
                </Label>
                <Controller
                  id='amountOfInsurance'
                  name='amountOfInsurance'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch w-50 gap-1'>
                      <InputNumeric {...field} value={field.value} disabled={isDetail} maxLength={11} placeholder={'XXX'} isDecimal={true} />
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>

      {
        !isDetail &&
        Object.keys(mapDataModal).map((key) => {
          return (
            <ListMasterModal
              key={key}
              field={key}
              show={showPopup[key]}
              onCloseClick={() => {
                setShowPopup((v) => ({...v, [key]: !showPopup[key]}))
              }}
              onConfirm={onConfirm}
              dataList={mapDataModal[key].data}
              withFilterAlphabet={false}
              mapData={mapDataModal[key].mapData}
              title={mapDataModal[key].title}
            />
          )
        })
      }
    </div>
  )
}
