import React from 'react'
import {Modal, ModalBody, ModalHeader} from 'reactstrap'

const OptionModal = ({
  show,
  onCloseClick,
  onConfirm,
  loading,
  title,
  header,
  children,
}) => {
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      id='exampleModal'
    >
      <ModalHeader toggle={onCloseClick}>
      </ModalHeader>
      <div className='text-center'>
        <div className='w-100 fs-15 mx-auto'>
          <h4 className='mx-auto pb-1' style={{fontSize: '18px', borderBottom: '1px solid #ccc', width: 'fit-content'}}>{title}</h4>
        </div>
        {header ? header : null}
      </div>
      <ModalBody style={{maxHeight: '70dvh', overflowY: 'auto'}}>
        {children ? children : null}
      </ModalBody>
    </Modal>
  )
}

export default OptionModal
