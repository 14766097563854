import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Label, Row} from 'reactstrap'
import React, {useEffect, useState} from 'react'
import {ActionButton} from '../ActionButton'
import ListMasterModal from '../ListMasterModal'
import MultiListMasterModal from '../MultiListMasterModal'
import Select from 'react-select'
import {weightCodeOptions} from '../../../Helpers/options'
import InputNumeric from '../../../Helpers/InputNumeric'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'
import InputPopupModal from '../../../Helpers/InputPopupModal'

export const HouseWaybillSummaryDetails = ({
                                             errors,
                                             control,
                                             setValue,
                                             clearErrors,
                                             airportList,
                                             handlingCodeList,
                                             selected,
                                             setError,
                                             isDetail = false,
                                           }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [showPopup, setShowPopup] = useState({
    hwbOrigin: false,
    hwbDestination: false,
  })

  const [showPopupSpecial, setShowPopupSpecial] = useState(false)
  const [selectedSpecialCodes, setSelectedSpecialCodes] = useState([])

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const checkValidData = (field, data) => {
    let isExists = airportList.find(a => a.code === data)
    if (!isExists) {
      setValue(field, '')
      setShowPopup({...showPopup, [field]: true})
    } else {
      setValue(field, data)
    }
  }

  const checkValidDataHandlingCode = (field, data) => {
    const errorData = []
    const validData = []
    clearErrors(field)
    let codes = data
      .trim()
      .split(',')
      .map((code) => code.trim().toUpperCase())
    codes = [...new Set(codes)]

    codes.forEach((code) => {
      const foundData = handlingCodeList.find((a) => a.code === code.trim())
      foundData ? validData.push(foundData) : errorData.push(code)
    })
    if (validData.length > 0) {
      const newData = validData.map(item => item.code)
      setValue(field, newData)
    } else {
      setValue(field, [])
    }
    setSelectedSpecialCodes(validData)

    if (errorData.length > 0) {
      const errorMessage = `${errorData.join(', ')} tidak valid`
      setError(field, {type: 'manual', message: errorMessage})
    }
  }

  const onConfirm = (field, value) => {
    clearErrors(field)
    setValue(field, value)
    setShowPopup({
      ...showPopup,
      [field]: false,
    })
  }

  const onConfirmSpecialCode = (value) => {
    const newData = value.map(item => item.code)
    clearErrors('hwbSpecialHandlingCode')
    setValue('hwbSpecialHandlingCode', newData)
    setSelectedSpecialCodes(value)
    setShowPopupSpecial(false)
  }

  useEffect(() => {
    const values = handlingCodeList.filter(item => selected.includes(item.code))
    setSelectedSpecialCodes(values)
  }, [selected, handlingCodeList])

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> House Waybill Summary Details</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.hwbSerialNumber}
              message={errors.hwbSerialNumber?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='hwbSerialNumber'
                  className='form-label m-0 w-50 w-50'
                >
                  HWB Serial Number <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='hwbSerialNumber'
                  name='hwbSerialNumber'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb className='w-50' {...field} value={field.value} disabled={isDetail}
                                     maxLength={12} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.hwbOrigin}
              message={errors.hwbOrigin?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='hwbOrigin' className='form-label m-0 w-50'>
                  Origin <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='hwbOrigin'
                  name='hwbOrigin'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex w-50 align-items-stretch gap-1'>
                      <InputPopupModal
                        fieldName='hwbOrigin'
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={3}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton
                          onClick={() =>
                            setShowPopup({...showPopup, hwbOrigin: true})
                          }
                        />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.hwbDestination}
              message={errors.hwbDestination?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='hwbDestination' className='form-label m-0 w-50'>
                  Destination <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='hwbDestination'
                  name='hwbDestination'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex w-50 align-items-stretch gap-1'>
                      <InputPopupModal
                        fieldName='hwbDestination'
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={3}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton
                          onClick={() =>
                            setShowPopup({...showPopup, hwbDestination: true})
                          }
                        />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.hwbNumberOfPieces}
              message={errors.hwbNumberOfPieces?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='hwbNumberOfPieces'
                  className='form-label m-0 w-50'
                >
                  Number of Pieces <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='hwbNumberOfPieces'
                  name='hwbNumberOfPieces'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={4} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.hwbWeightCode}
              message={errors.hwbWeightCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='hwbWeightCode' className='form-label m-0 w-50'>
                  Weight Code <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='hwbWeightCode'
                  name='hwbWeightCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Select
                      className='w-50'
                      {...field}
                      isSearchable={false}
                      cacheOptions
                      isDisabled={isDetail}
                      isClearable
                      defaultOptions
                      value={weightCodeOptions.find(option => option.value === field.value) || null}
                      onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                      options={weightCodeOptions}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.hwbWeight}
              message={errors.hwbWeight?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='hwbWeight' className='form-label m-0 w-50'>
                  Weight <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='hwbWeight'
                  name='hwbWeight'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={7} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.hwbSlac}
              message={errors.hwbSlac?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='hwbSlac' className='form-label m-0 w-50'>
                  SLAC
                </Label>
                <Controller
                  id='hwbSlac'
                  name='hwbSlac'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={5} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={8} md={12} sm={12}>
            <ErrorFieldWrapper
              error={errors.hwbManifestDescriptionOfGoods}
              message={errors.hwbManifestDescriptionOfGoods?.message}
            >
              <div className='d-flex align-items-center justify-content-between gap-2'>
                <Label
                  htmlFor='hwbManifestDescriptionOfGoods'
                  className='form-label m-0'
                  style={{maxWidth: '24.5%'}}
                >
                  Manifest Description of Goods{' '}
                  <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='hwbManifestDescriptionOfGoods'
                  name='hwbManifestDescriptionOfGoods'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      style={{
                        width: '75.5%',
                      }}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={15}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <ErrorFieldWrapper
              error={errors.hwbSpecialHandlingCode}
              message={errors.hwbSpecialHandlingCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='hwbSpecialHandlingCode' className='form-label m-0 ' style={{ maxWidth: '16%'}}>
                  Special Handling Code
                </Label>
                <Controller
                  id='hwbSpecialHandlingCode'
                  name='hwbSpecialHandlingCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch gap-1' style={{width: '84%'}}>
                      <InputPopupModal
                        fieldName='hwbSpecialHandlingCode'
                        checkValidData={checkValidDataHandlingCode}
                        {...field}
                        value={field.value}
                        maxLength={35}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton onClick={() =>
                          setShowPopupSpecial(true)
                        } />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>
      {!isDetail && Object.keys(showPopup).map((key) => {
        return (
          <ListMasterModal
            key={key}
            field={key}
            show={showPopup[key]}
            onCloseClick={() => {
              setShowPopup((v) => ({...v, [key]: !showPopup[key]}))
            }}
            onConfirm={onConfirm}
            dataList={airportList}
            mapData={
              {
                name: 'CITY PORT NAME',
                code: 'CITY PORT CODE',
              }
            }
            title={`Airport City Code`}
          />
        )
      })}

      {
        !isDetail && (
          <MultiListMasterModal
            key={'hwbSpecialHandlingCode'}
            show={showPopupSpecial}
            onCloseClick={() => {
              setShowPopupSpecial((v) => (!showPopupSpecial))
            }}
            onConfirm={onConfirmSpecialCode}
            dataList={handlingCodeList}
            selectedItems={selectedSpecialCodes}
            mapData={
              {
                description: 'Description',
                code: 'Code',
              }
            }
            title={`Special Handling Code`}
          />
        )
      }

    </div>
  )
}
