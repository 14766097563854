import React, {useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {informationIdentifierRQ} from 'Hooks/Queries'
import {Controller, useForm} from 'react-hook-form'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import BreadCrumb from 'Components/Common/BreadCrumb'

export default function EditCreateInformationIdentifier(props) {
  const {id} = useParams()
  const navigate = useNavigate()

  const validation = Yup.object().shape({
    code: Yup.string()
      .required('Code tidak boleh kosong'),
    description: Yup.string()
      .required('Description tidak boleh kosong')
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      code: '',
      description:'',
    },
  }
  const {
    handleSubmit,
    formState,
    reset,
    control,
  } = useForm(formOptions)
  const {errors} = formState

  const {mutate: updateInformationIdentifier, isLoading: isLoadingUpdate} =
    informationIdentifierRQ.useUpdateData(navigate)()

  const _updateInformationIdentifier = (values) => {
    const body = {
      id,
      code: values.code,
      description: values.description
    }
    updateInformationIdentifier(body)
  }

  const {mutate: addInformationIdentifier, isLoading: isLoadingCreate} =
    informationIdentifierRQ.useAddData(navigate)()

  const _createInformationIdentifier = async (values) => {

    addInformationIdentifier(values)
  }

  const {data, error, isFetching, status} = informationIdentifierRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      reset({
        code: data.data?.code ?? '',
        description: data.data?.description ?? '',
      })

  
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
  }, [status, isFetching, data, reset, error])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Information Identifier' pageTitle='Information Identifier' />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(id ? _updateInformationIdentifier : _createInformationIdentifier)}>
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Update' : 'Create'} Information Identifiers
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.code}
                        message={errors.code?.message}
                      >
                        <Label htmlFor='code' className='form-label'>
                          CODE
                        </Label>
                        <Controller
                          id='code'
                          name='code'
                          type='text'
                          control={control}
                          render={({field}) => (
                            <Input
                              {...field}
                              value={field.value}
                              placeholder='Code'
                          
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.description}
                        message={errors.description?.message}
                      >
                        <Label htmlFor='description' className='form-label'>
                          DESCRIPTION
                        </Label>
                        <Controller
                          id='description'
                          name='description'
                          type='text'
                          control={control}
                          render={({field}) => (
                            <Input
                              {...field}
                              value={field.value}
                              placeholder='Description'
                          
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/online-agent-form/information-identifier' className='btn btn-danger'>
                    Cancel
                  </Link>
                  <Button
                    color='success'
                    type='submit'
                    disabled={isLoadingCreate || isLoadingUpdate}
                  >
                    {isLoadingCreate || isLoadingUpdate
                      ? 'Loading...'
                      : id
                      ? 'Update'
                      : 'Create'}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
