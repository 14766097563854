import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller, useFieldArray} from 'react-hook-form'
import {Collapse, Row, Table} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import InputNumeric from '../../../Helpers/InputNumeric'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'
import {rateClassOptions, weightCodeOptions} from '../../../Helpers/options'
import Select from 'react-select'

export const RateDescription = ({
  errors,
  control,
  fields,
  remove,
  append,
  setValue,
  getValues,
  onCalculateCharge,
  isDetail = false,
}) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const onCalculate = (index) => {
    const chargeWeight = getValues(`rates.${index}.rateChargeableWeight`)
    const charge = getValues(`rates.${index}.rateCharge`)
    if (charge && chargeWeight) {
      setValue(
        `rates.${index}.rateTotal`,
        parseFloat(charge) * parseFloat(chargeWeight),
      )
      onCalculateCharge()
    }
  }
  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Rate Description</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Table className='table-hover table-bordered  table-nowrap mb-0'>
            <thead>
              <tr>
                <th
                  scope='col'
                  className='text-start align-middle text-wrap'
                  width={'77px'}
                >
                  No. Of Pieces
                </th>
                <th
                  scope='col'
                  className='text-start align-middle text-wrap'
                  width={'86px'}
                >
                  Gross Weight
                </th>
                <th
                  scope='col'
                  className='text-start align-middle text-wrap'
                  width={'120px'}
                >
                  Kode K/L
                </th>
                <th
                  scope='col'
                  className='text-start align-middle text-wrap'
                  width={'170px'}
                >
                  Rate Class
                </th>
                <th
                  scope='col'
                  className='text-start align-middle text-wrap'
                  width={'100px'}
                >
                  Commodity Item No
                </th>
                <th
                  scope='col'
                  className='text-start align-middle text-wrap'
                  width={'100px'}
                >
                  Chargeable Weight
                </th>
                <th
                  scope='col'
                  className='text-start align-middle text-wrap'
                  width={'100px'}
                >
                  Rate Charge
                </th>
                <th
                  scope='col'
                  className='text-start align-middle text-wrap'
                  width={'100px'}
                >
                  Total
                </th>
                <th scope='col'></th>
                <th scope='col' className='text-start align-middle text-wrap'>
                  Nature and Quantity of Goods
                </th>
              </tr>
            </thead>
            <tbody>
              {fields?.map((field, index) => (
                <RateDescriptionRow
                  key={index}
                  onCalculate={onCalculate}
                  index={index}
                  errors={errors}
                  control={control}
                  removeRate={remove}
                  appendRate={append}
                  getValues={getValues}
                  onCalculateCharge={onCalculateCharge}
                  isDetail={isDetail}
                  setValue={setValue}
                />
              ))}
            </tbody>
          </Table>
        </Row>
      </Collapse>
    </div>
  )
}

function RateDescriptionRow({
  onCalculate,
  onCalculateCharge,
  control,
  removeRate,
  appendRate,
  setValue,
  getValues,
  index,
  errors,
  isDetail,
}) {
  const {append, fields, remove} = useFieldArray({
    control,
    name: `rates.${index}.rateNatureQtyGoods`,
  })
  return (
    <tr>
      <td>
        <ErrorFieldWrapper
          error={errors.rates?.[index]?.ratePieces}
          message={errors.rates?.[index]?.ratePieces?.message}
          textClassName='text-start text-wrap lh-sm'
        >
          <Controller
            name={`rates.${index}.ratePieces`}
            type='text'
            control={control}
            render={({field}) => (
              <InputNumeric
                {...field}
                value={field.value}
                disabled={isDetail}
                maxLength={4}
              />
            )}
          />
        </ErrorFieldWrapper>
      </td>
      <td>
        <ErrorFieldWrapper
          error={errors.rates?.[index]?.rateGrossWeight}
          message={errors.rates?.[index]?.rateGrossWeight?.message}
          textClassName='text-start text-wrap lh-sm'
        >
          <Controller
            name={`rates.${index}.rateGrossWeight`}
            type='text'
            control={control}
            render={({field}) => (
              <InputNumeric
                {...field}
                value={field.value}
                disabled={isDetail}
                isDecimal={true}
                maxLength={7}
              />
            )}
          />
        </ErrorFieldWrapper>
      </td>
      <td>
        <ErrorFieldWrapper
          error={errors.rates?.[index]?.rateKode}
          message={errors.rates?.[index]?.rateKode?.message}
          textClassName='text-start text-wrap lh-sm'
        >
          <Controller
            name={`rates.${index}.rateKode`}
            type='text'
            control={control}
            render={({field}) => (
              <Select
                {...field}
                isSearchable={false}
                cacheOptions
                isDisabled={isDetail}
                defaultOptions
                value={
                  weightCodeOptions.find(
                    (option) => option.value === field.value,
                  ) || null
                }
                onChange={(selectedOption) =>
                  field.onChange(selectedOption?.value)
                }
                options={weightCodeOptions}
              />
            )}
          />
        </ErrorFieldWrapper>
      </td>
      <td>
        <ErrorFieldWrapper
          error={errors.rates?.[index]?.rateClass}
          message={errors.rates?.[index]?.rateClass?.message}
          textClassName='text-start text-wrap lh-sm'
        >
          <Controller
            name={`rates.${index}.rateClass`}
            type='text'
            control={control}
            render={({field}) => (
              <Select
                {...field}
                isSearchable={true}
                cacheOptions
                isDisabled={isDetail}
                defaultOptions
                value={
                  rateClassOptions.find(
                    (option) => option.value === field.value,
                  ) || null
                }
                onChange={(selectedOption) =>
                  field.onChange(selectedOption?.value)
                }
                options={rateClassOptions}
              />
            )}
          />
        </ErrorFieldWrapper>
      </td>
      <td>
        <ErrorFieldWrapper
          error={errors.rates?.[index]?.rateCommodityItemNo}
          message={errors.rates?.[index]?.rateCommodityItemNo?.message}
          textClassName='text-start text-wrap lh-sm'
        >
          <Controller
            name={`rates.${index}.rateCommodityItemNo`}
            type='text'
            control={control}
            render={({field}) => (
              <InputNumeric
                {...field}
                value={field.value}
                disabled={isDetail}
                maxLength={7}
              />
            )}
          />
        </ErrorFieldWrapper>
      </td>
      <td>
        <ErrorFieldWrapper
          error={errors.rates?.[index]?.rateChargeableWeight}
          message={errors.rates?.[index]?.rateChargeableWeight?.message}
          textClassName='text-start text-wrap lh-sm'
        >
          <Controller
            name={`rates.${index}.rateChargeableWeight`}
            type='text'
            control={control}
            render={({field}) => (
              <InputNumeric
                {...field}
                value={field.value}
                disabled={isDetail}
                onChange={(e) => {
                  field.onChange(e.target.value)
                  onCalculate(index)
                }}
                isDecimal={true}
                maxLength={7}
              />
            )}
          />
        </ErrorFieldWrapper>
      </td>
      <td>
        <ErrorFieldWrapper
          error={errors.rates?.[index]?.rateCharge}
          message={errors.rates?.[index]?.rateCharge?.message}
          textClassName='text-start text-wrap lh-sm'
        >
          <Controller
            name={`rates.${index}.rateCharge`}
            type='text'
            control={control}
            render={({field}) => (
              <InputNumeric
                {...field}
                value={field.value}
                disabled={isDetail}
                onChange={(e) => {
                  field.onChange(e.target.value)
                  onCalculate(index)
                }}
                isDecimal={true}
                maxLength={8}
              />
            )}
          />
        </ErrorFieldWrapper>
      </td>
      <td>
        <ErrorFieldWrapper
          error={errors.rates?.[index]?.rateTotal}
          message={errors.rates?.[index]?.rateTotal?.message}
          textClassName='text-start text-wrap lh-sm'
        >
          <Controller
            name={`rates.${index}.rateTotal`}
            type='text'
            control={control}
            render={({field}) => (
              <InputNumeric
                {...field}
                value={field.value}
                disabled={isDetail}
                maxLength={12}
                isDecimal={true}
                onChange={(e) => {
                  field.onChange(e.target.value)
                  onCalculateCharge()
                }}
              />
            )}
          />
        </ErrorFieldWrapper>
      </td>
      <td>
        {index >= 1 ? (
          <ActionButton onClick={() => removeRate(index)} action='remove' />
        ) : (
          <ActionButton
            onClick={() =>
              appendRate({
                ratePieces: '',
                rateGrossWeight: '',
                rateKode: getValues('awbWeightCode'),
                rateClass: '',
                rateCommodityItemNo: '',
                rateChargeableWeight: '',
                rateCharge: '',
                rateTotal: '',
                rateNatureQtyGoods: [{value:""}],
              })
            }
            action='add'
          />
        )}
      </td>
      <td className='d-flex flex-column gap-2'>
        {
          fields && fields?.map((field, idx) => {
            return <ErrorFieldWrapper
            error={errors.rates?.[index]?.rateNatureQtyGoods?.[idx]?.value}
            message={errors.rates?.[index]?.rateNatureQtyGoods?.[idx]?.value?.message}
            textClassName='text-start text-wrap lh-sm'
          >
            <Controller
              name={`rates.${index}.rateNatureQtyGoods.${idx}.value`}
              type='text'
              control={control}
              render={({field}) => (
                <div className='d-flex align-item-stretch'>
                  <InputTextFhlFwb
                    {...field}
                    value={field.value }
                    disabled={isDetail}
                    maxLength={20}
                    slant={true}
                  
                  />
                  <td>
                    {idx >= 1 ? (
                      <ActionButton
                        onClick={() => remove(idx)}
                        action='remove'
                      />
                    ) : (
                      <ActionButton onClick={() => {
                        append("")}} action='add' />
                    )}
                  </td>
                </div>
              )}
            />
          </ErrorFieldWrapper>
          })
        }
        
      </td>
    </tr>
  )
}
