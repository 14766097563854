import React from "react";
import { TopupRQ } from "Hooks/Queries";
import {Link, useParams} from 'react-router-dom'
import { Card, CardBody, CardFooter, Container, Row } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { TOPUP_STATUS_CONSTANTS } from "Helpers/options";
import { formatNumber } from "Helpers/number";
import { topupStatus } from "Components/Topup/helpers";

export default function DetailTopup() {
  const { id } = useParams();
  const { data } = TopupRQ.useGetData(id)();

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Topup Detail" />
        <Card>
          <CardBody className="card-body">
            <h2 className="mb-3">INV #{data?.data?.code ?? "-"}</h2>
            <Row className="text-muted">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th
                      style={{ background: "rgb(243, 246, 249)" }}
                      colSpan={2}
                    >
                      General
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th style={{ maxWidth: "20%" }}>Jumlah</th>
                    <td>Rp. {formatNumber(data?.data?.amount) ?? "-"}</td>
                  </tr>
                  <tr>
                    <th style={{ maxWidth: "20%" }}>Status</th>
                    <td>{topupStatus(data?.data?.status ?? "")}</td>
                  </tr>
                  <tr>
                    <th
                      style={{ background: "rgb(243, 246, 249)" }}
                      colSpan={2}
                    >
                      System
                    </th>
                  </tr>
                  <tr>
                    <th style={{ maxWidth: "20%" }}>Tanggal</th>
                    <td>{data?.data?.date ?? "-"}</td>
                  </tr>
                </tbody>
              </table>
              <div className="table-responsive table-card "></div>
            </Row>
          </CardBody>
          <CardFooter className="align-items-center d-flex justify-content-end gap-3">
            <Link to="/app/topups" className="btn btn-outline-dark">
              Kembali
            </Link>
            {data?.data?.status === TOPUP_STATUS_CONSTANTS.PENDING ? (
                <Link to={`/app/topups/payment/${id}`} className='btn btn-primary'>
                  Bayar
                </Link>
            ) : null}
          </CardFooter>
        </Card>
      </Container>
    </div>
  );
}
