import {DATA_LIMIT} from 'configs'
import React from 'react'
import {Link} from 'react-router-dom'

const CustomInfoIdentifierItem = ({
  page,
  index,
  id,
  code,
  description,
  onDeleteClick,
  table,
}) => {
  return (
    <tr>
      <td className='fw-medium'>{index + page + 1}</td>
      <td>
        <div className='hstack gap-3 fs-15'>
          <Link to={`/app/${table}/edit/${id}`} className='link-warning'>
            <i className='ri-edit-2-line'></i>
          </Link>
          <Link
            to='#'
            className='link-danger'
            onClick={() => {
              onDeleteClick(id)
            }}
          >
            <i className='ri-delete-bin-5-line'></i>
          </Link>
        </div>
      </td>
      <td>{code}</td>
      <td>{description}</td>
    </tr>
  )
}

export default CustomInfoIdentifierItem
