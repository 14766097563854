import { CREATE_COUNTRY_CODE_URL, DELETE_COUNTRY_CODE_URL, LIST_COUNTRY_CODE_URL, UPDATE_COUNTRY_CODE_URL, VIEW_COUNTRY_CODE_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'country-code';
const REDIRECT_URL = '/app/online-agent-form/country-code';

export const countryCodeRQ = new RQ(
  QUERY_KEY,
  LIST_COUNTRY_CODE_URL,
  VIEW_COUNTRY_CODE_URL,
  CREATE_COUNTRY_CODE_URL,
  UPDATE_COUNTRY_CODE_URL,
  DELETE_COUNTRY_CODE_URL,
  REDIRECT_URL
);