import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import {RequestOnlineAgentFormRQ} from '../../../Hooks/Queries/requestOnlineAgentFormRQ'
import RevisionModal from '../../../Components/RequestAgentOnlineForm/RevisionModal'
import {apiBearer} from '../../../Helpers/api'
import {
  PROCESS_UPLOAD_REQUEST_ONLINE_AGENT_URL,
  REVISION_UPLOAD_REQUEST_ONLINE_AGENT_URL,
} from '../../../Helpers/url_helper'
import {showError, showSuccess} from '../../../Helpers/show_toast'
import SuccessModal from '../../../Components/Common/SuccessModal'
import {
  ADMIN,
  getDocumentLabel,
  ONLINE_AGENT_ORDER_STATUS,
  ONLINE_AGENT_PAYMENT_STATUS,
  ONLINE_AGENT_TYPE_DOC,
} from '../../../Helpers/options'
import {useSelector} from 'react-redux'
import RevisionAgentModal from '../../../Components/FwbFhl/RevisionAgentModal'
import moment from 'moment'

export default function DetailUploadOnlineAgentForm(props) {
  const {id} = useParams()
  const navigate = useNavigate()
  const {profile} = useSelector((state) => state.auth)

  const {data} = RequestOnlineAgentFormRQ.useGetData(id)()
  const [revisionModal, setRevisionModal] = useState(false)
  const [revisionAgentModal, setRevisionAgentModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)

  const [isLoadingRevision, setIsLoadingRevision] = useState(false)
  const [isLoadingProcess, setIsLoadingProcess] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [listFile, setListFile] = useState([])
  const [selectedtab, setSelectedTab] = useState(1)

  const onClickRevision = async (e) => {
    setRevisionModal(true)
  }

  const onClickRevisionAgent = async (e) => {
    setRevisionAgentModal(true)
  }

  const onClickPayment = async () => {
    navigate(`/app/fwb-fhl/${id}/payment`)
  }

  const navigateToList = async () => {
    if (isAdmin) {
      navigate('/app/request-agent-online-form')
    } else {
      navigate('/app/fwb-fhl')
    }
  }

  const onRevisionSubmit = async (data) => {
    setIsLoadingRevision(true)

    apiBearer
      .patch(REVISION_UPLOAD_REQUEST_ONLINE_AGENT_URL(id), data)
      .then((result) => {
        setIsLoadingRevision(false)
        setRevisionModal(false)
        if (result?.data?.data) {
          setSuccessModal(true)
        } else {
          showError('Error while saving')
        }
      })
      .catch((err) => {
        setIsLoadingRevision(false)
      })
  }

  useEffect(() => {
    const files = data?.data?.upload?.files ?? []
    if (files?.length > 0) {
      const latestFiles = data?.data?.upload?.files[files?.length - 1].file
      setListFile(latestFiles)
    } else {
      setListFile([])
    }
  }, [data?.data?.upload?.files])

  const onProcessSubmit = async () => {
    if (
      data?.data?.order_status === 'PROCESS' ||
      data?.data?.order_status === 'REVISION_DRAFT'
    ) {
      navigateToEditPage()
    } else {
      setIsLoadingProcess(true)

      apiBearer
        .patch(PROCESS_UPLOAD_REQUEST_ONLINE_AGENT_URL(id), {})
        .then((result) => {
          setIsLoadingRevision(false)
          if (result?.data?.data) {
            showSuccess('Data berhasil di prosess')
            navigateToList()
          } else {
            showError('Error while saving')
          }
        })
        .catch((err) => {
          setIsLoadingProcess(false)
        })
    }
  }

  const navigateToEditPage = () => {
    if (data?.data?.type_doc === ONLINE_AGENT_TYPE_DOC.FHL_V5) {
      navigate(`/app/fhl/edit/${data?.data?.id}`)
    } else if (data?.data?.type_doc === ONLINE_AGENT_TYPE_DOC.FHL_V4) {
      navigate(`/app/fhl-v4/edit/${data?.data?.id}`)
    } else if (data?.data?.type_doc === ONLINE_AGENT_TYPE_DOC.FWB_V16) {
      navigate(`/app/fwb/edit/${data?.data?.id}`)
    }
  }

  useEffect(() => {
    setIsAdmin(profile.role === ADMIN)
  }, [navigate, profile.is_approved, profile.role])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Request Agent Online Form' />
        {isAdmin && (
          <div className='mb-3 border-bottom border-bottom-2'>
            <Button
              onClick={() => setSelectedTab(1)}
              color='light'
              style={{
                borderBottom: selectedtab === 1 ? '1px solid #3F5F29' : '',
                borderRadius: 0,
                fontWeight: 600,
              }}
            >
              Detail
            </Button>
            <Button
              onClick={() => setSelectedTab(2)}
              color='light'
              style={{
                borderBottom: selectedtab === 2 ? '1px solid #3F5F29' : '',
                borderRadius: 0,
                fontWeight: 600,
              }}
            >
              Revision History
            </Button>
          </div>
        )}
        <TabContent activeTab={selectedtab}>
          <TabPane tabId={1}>
            <Card>
              <CardBody className='card-body'>
                <div className='table-responsive'>
                  <Table className='table-bordered align-middle table-nowrap mb-0'>
                    <tbody>
                      <tr>
                        <th style={{width: '20%'}} className='text-uppercase'>
                          Type Document
                        </th>
                        <td>{getDocumentLabel(data?.data?.type_doc) ?? '-'}</td>
                      </tr>
                      {data?.data?.upload?.latest_date_revision && (
                        <tr>
                          <th
                            style={{maxWidth: '20%'}}
                            className='text-uppercase'
                          >
                            Upload Date
                          </th>
                          <td>
                            {data?.data?.upload_date
                              ? moment(data?.data?.upload_date).format(
                                  'DD-MM-YYYY',
                                )
                              : '-'}
                          </td>
                        </tr>
                      )}
                      {data?.data?.upload?.latest_date_revision && (
                        <tr>
                          <th
                            style={{maxWidth: '20%'}}
                            className='text-uppercase'
                          >
                            Revision Date
                          </th>
                          <td>
                            {data?.data?.upload?.latest_date_revision
                              ? moment(
                                  data?.data?.upload?.latest_date_revision,
                                ).format('DD-MM-YYYY')
                              : '-'}
                          </td>
                        </tr>
                      )}
                      <tr>
                        <th
                          style={{maxWidth: '20%', verticalAlign: 'top'}}
                          className='text-uppercase'
                        >
                          File
                        </th>
                        <td>
                          <ul className='m-0 ps-3'>
                            {(listFile?.length > 0 ? listFile : []).map(
                              (file, idx) => (
                                <li>
                                  <a
                                    key={idx}
                                    className='text-decoration-underline'
                                    target={file?.url ? '_blank' : ''}
                                    rel='noreferrer'
                                    href={file?.url ? file?.url : '#'}
                                  >
                                    Preview
                                  </a>
                                </li>
                              ),
                            )}
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <th
                          style={{maxWidth: '20%'}}
                          className='text-uppercase'
                        >
                          Keterangan
                        </th>
                        <td>{data?.data?.upload?.description ?? '-'}</td>
                      </tr>

                      {data?.data?.upload?.latest_revision && (
                        <tr>
                          <th
                            style={{maxWidth: '20%'}}
                            className='text-uppercase'
                          >
                            Revision
                          </th>
                          <td>{data?.data?.upload?.latest_revision ?? '-'}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                <Button
                  color='btn btn-light'
                  type='button'
                  onClick={navigateToList}
                >
                  Kembali
                </Button>
                {isAdmin &&
                  (data?.data?.order_status ===
                    ONLINE_AGENT_ORDER_STATUS.NEW_REQUEST ||
                    data?.data?.order_status ===
                      ONLINE_AGENT_ORDER_STATUS.WAITING_VERIFICATION_DOC ||
                    data?.data?.order_status ===
                      ONLINE_AGENT_ORDER_STATUS.PROCESS ||
                    data?.data?.order_status ===
                      ONLINE_AGENT_ORDER_STATUS.REVISION_DRAFT) && (
                    <>
                      <Button
                        color='btn btn-primary'
                        type='button'
                        onClick={onProcessSubmit}
                        disabled={isLoadingProcess}
                      >
                        {isLoadingProcess ? 'Loading...' : 'Prosess'}
                      </Button>
                      {data?.data?.order_status !==
                        ONLINE_AGENT_ORDER_STATUS.PROCESS &&
                        data?.data?.order_status !==
                          ONLINE_AGENT_ORDER_STATUS.REVISION_DRAFT && (
                          <Button
                            color='btn btn-warning'
                            type='button'
                            onClick={onClickRevision}
                          >
                            Revision
                          </Button>
                        )}
                    </>
                  )}
                {!isAdmin &&
                  (data?.data?.payment_status ===
                    ONLINE_AGENT_PAYMENT_STATUS.UNPAID ||
                    data?.data?.payment_status ===
                      ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS) && (
                    <>
                      <Button
                        color='btn btn-info'
                        type='button'
                        onClick={onClickPayment}
                      >
                        Pay
                      </Button>
                    </>
                  )}
                {!isAdmin &&
                  data?.data?.order_status ===
                    ONLINE_AGENT_ORDER_STATUS.DOC_REVISION && (
                    <>
                      <Button
                        color='btn btn-warning'
                        type='button'
                        onClick={onClickRevisionAgent}
                      >
                        Revision
                      </Button>
                    </>
                  )}
              </CardFooter>
            </Card>
          </TabPane>
          <TabPane tabId={2}>
            {' '}
            <Card>
              <CardBody className='card-body'>
                <div className='table-responsive'>
                  <Table className='table-bordered align-middle table-nowrap mb-0'>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Revision Date</th>
                        <th>File</th>
                        <th>Keterangan</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.upload?.files &&
                        (data?.data?.upload?.files).map((item, idx) => (
                          <tr key={idx}>
                            <td style={{width: 50, verticalAlign: 'top'}}>
                              {idx + 1}
                            </td>
                            <td style={{width: '20%', verticalAlign: 'top'}}>
                              {item?.date || data?.data?.upload_date
                                ? moment(
                                    item?.date || data?.data?.upload_date,
                                  ).format('DD-MM-YYYY')
                                : '-'}
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                              {item?.file &&
                                (item?.file).map((file, idx) => (
                                  <>
                                    <a
                                      key={idx}
                                      className='text-decoration-underline'
                                      target={file?.url ? '_blank' : ''}
                                      rel='noreferrer'
                                      href={file?.url ? file?.url : '#'}
                                    >
                                      Preview
                                    </a>
                                    <br />
                                  </>
                                ))}
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                              {item?.description}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </Container>

      <RevisionModal
        onCloseClick={() => setRevisionModal((v) => !v)}
        show={revisionModal}
        isLoadingRevision={isLoadingRevision}
        onSubmitClick={onRevisionSubmit}
      />

      <RevisionAgentModal
        onCloseClick={() => setRevisionAgentModal((v) => !v)}
        show={revisionAgentModal}
        isLoadingRevision={isLoadingRevision}
        onSubmitClick={onRevisionSubmit}
        detailData={data?.data}
      />

      <SuccessModal
        onCloseClick={navigateToList}
        show={successModal}
        text='Revision berhasil Terkirim'
      />
    </div>
  )
}
