import React, {useEffect, useRef, useState} from 'react'
import { Button, Input, ModalBody, ModalFooter, Table } from 'reactstrap';
import OptionModal from './OptionModal';

const MultiListMasterModal = ({
                                show,
                                onCloseClick,
                                onConfirm,
                                title,
                                dataList,
                                selectedItems = [],
                                withSearch = true,
                                mapData = { code: 'Code', name: 'Name' },
                              }) => {
  const [displayedDataList, setDisplayedDataList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [checkedItems, setCheckedItems] = useState([]);

  const selectedIdsRef = useRef([]);

  useEffect(() => {
    selectedIdsRef.current = selectedItems.map(item => item.id);
  }, [selectedItems]);

  useEffect(() => {
    setDisplayedDataList(dataList);
    setCheckedItems(selectedIdsRef.current);
  }, [dataList, show]);



  const filterData = (value, isSearch) => {
    const keys = Object.keys(mapData);
    let filteredData = [];

    if (isSearch) {
      filteredData = dataList.filter(
        (item) =>
          item?.[keys[0]]?.toLowerCase().includes(value.toLowerCase()) ||
          item?.[keys[1]]?.toLowerCase().includes(value.toLowerCase())
      );
    } else {
      setSearchValue('');
      filteredData = dataList.filter((item) =>
        item?.code?.toUpperCase().startsWith(value)
      );
    }

    setDisplayedDataList(filteredData);
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSubmit = () => {
    const selectedData = dataList.filter((item) => checkedItems.includes(item.id));
    onConfirm(selectedData);
  };

  const renderData = () => {
    const keys = Object.keys(mapData);
    const titles = Object.values(mapData);

    return (
      <div>
        <Table bordered className="w-100">
          <thead>
          <tr>
            <th>#</th>
            <th>{titles[0]}</th>
            <th>{titles[1]}</th>
          </tr>
          </thead>
          <tbody>
          {displayedDataList?.map((item) => (
            <tr key={item.id} className="cursor-pointer" onClick={() => handleCheckboxChange(item.id)}>
              <td>
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  checked={checkedItems.includes(item.id)}
                  onChange={() => handleCheckboxChange(item.id)}
                  onClick={(e) => e.stopPropagation()}
                />
              </td>
              <td>{item?.[keys[0]] ?? '-'}</td>
              <td>{item?.[keys[1]] ?? '-'}</td>
            </tr>
          ))}
          {displayedDataList.length < 1 && (
            <tr>
              <td colSpan={3} className="text-center">
                <span className="text-danger">No Data</span>
              </td>
            </tr>
          )}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <OptionModal show={show} onCloseClick={onCloseClick} title={title}>
      <div className="text-center">
        {withSearch && (
          <div className="d-flex flex-column align-items-end mb-3">
            <Input
              className="w-50"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  filterData(searchValue, true);
                }
              }}
            />
          </div>
        )}
      </div>
      <ModalBody style={{maxHeight: '50dvh', minHeight: '50dvh', overflowY: 'auto'}}  className="p-0 mt-4">
        {renderData()}
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <Button type="button" color="btn btn-light" className="btn w-sm" onClick={onCloseClick}>
            Cancel
          </Button>
          <Button type="button" color="btn btn-primary" className="btn w-sm" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </ModalFooter>
    </OptionModal>
  );
};

export default MultiListMasterModal;
