import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { accountTypes, CUSTOMER, FORWARDERS } from "Helpers/options";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { showError, showSuccess } from "Helpers/show_toast";
import { apiBasic } from "Helpers/api";
import { BASE_API_URL } from "configs";
import ConnectAccount from "./ConnectAccount";
import { setAutofillValues, setNleRefreshToken, setNleToken } from "Redux/reducers/auth";
import NleLoginModal from "Components/Common/NleLoginModal";
import { capitalize } from "lodash";

export default function FormRegister() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const [fcmToken, setFcmToken] = useLocalStorage('fcm_token', null)
  // const [deviceToken, setDeviceToken] = useState(null)
  const [nleLoginModal, setNleLoginModal] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { nleToken, autofill } = useSelector(
    (state) => state.auth
  );
  const [loading, setLoading] = useState(false);
  const validation = Yup.object().shape({
    name: Yup.string()
      .required("Full Name is required")
      .min(3, "Full Name must be at least 3 characters"),
    user_name: Yup.string()
      .required("Username is required")
      .min(3, "Username must be at least 3 characters")
      .strict(true),
    email: Yup.string()
      .required("Email is required")
      // .min("test-email-format", "invalid email format", (value) => {
      //   return validEmail.test(value);
      // })
      .strict(true),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm password not match"),
    accountType: Yup.object()
      // .shape({
      //   label: Yup.string().required("Account Type is required"),
      //   value: Yup.string().required("Account Type is required"),
      // })
      // .nullable() // for handling null value when clearing options via clicking "x"
      .required("Account Type is required"),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: "",
      email: "",
      user_name: "",
      password: "",
      confirm_password: "",
    },
  };
  const { handleSubmit, formState, control, register, watch, setValue, reset } =
    useForm(formOptions);

  const { errors } = formState;

  const onSubmit = async (values) => {
    const body = {...values};
    body.role = values.accountType.value;
    delete body["accountType"];
    if (values.accountType.value === CUSTOMER && !nleToken) {
      showError("Customer should connect to NLE");
      return;
    }
    setLoading(true);
    if (values.accountType.value === CUSTOMER) {
      body.nle_token = nleToken;
    }
    try {
      await apiBasic.post(BASE_API_URL + "/auth/register", body);
      if (values.accountType.value === CUSTOMER) {
        showSuccess("Register is success");
      } else {
        showSuccess(
          "Register is success, check you email address to verify account"
        );
      }
      reset()
      navigate('/login')
    } catch (error) {
      showError(
        error.response?.data?.message ??
          "Something wrong, please check the input"
      );
      console.log(error.response);
    }
    setLoading(false);
  };

  const { accountType } = watch();

  useEffect(() => {
    setValue("email", autofill?.email ?? "");
    setValue("name", autofill?.name ?? "");
    setValue("user_name", autofill?.username ?? "");
  }, [autofill?.email, autofill?.name, autofill?.username, setValue]);

  const onModalNleLoginSuccess = (data) => {
    dispatch(setNleToken(data.access_token))
    dispatch(setNleRefreshToken(data.refresh_token))
    dispatch(setAutofillValues({username: data.username}))
    setNleLoginModal(false)
  }
  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={`mb-3 ${errors.accountType ? "error-form" : ""}`}>
        <label htmlFor="accountType" className="form-label">
          Account Type <span className="mandatory">*</span>
        </label>
        <div className="position-relative">
          <Controller
            id="accountType"
            name="accountType"
            control={control}
            render={({ field }) => (
              <Select
                {...register("accountType")}
                isClearable
                isSearchable={false}
                options={accountTypes.map((v) => ({
                  label: v === FORWARDERS ? "Agent": capitalize(v),
                  value: v,
                }))}
                onChange={(v) => {
                  field.onChange(v);
                }}
              />
            )}
          />
        </div>
        <div className="error-form-info badge badge-soft-danger">
          <img
            alt=""
            src="/assets/icons/triangle-alert-fill.svg"
            className="me-1"
          />{" "}
          {errors.accountType?.message}
        </div>
      </div>
      <ConnectAccount 
        isCustomer={accountType?.value === CUSTOMER}  
        openModalNle={()=>setNleLoginModal(true)}
      />
      <div className={`mb-3 ${errors.name ? "error-form" : ""}`}>
        <label htmlFor="name">
          Name <span className="mandatory">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          placeholder="Enter your name"
          {...register("name")}
        />
        <div className="error-form-info badge badge-soft-danger">
          <img
            alt=""
            src="/assets/icons/triangle-alert-fill.svg"
            className="me-1"
          />{" "}
          {errors.name?.message}
        </div>
      </div>
      <div className={`mb-3 ${errors.user_name ? "error-form" : ""}`}>
        <label htmlFor="username">
          Username <span className="mandatory">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="username"
          placeholder="Enter your username"
          {...register("user_name")}
        />
        <div className="error-form-info badge badge-soft-danger">
          <img
            alt=""
            src="/assets/icons/triangle-alert-fill.svg"
            className="me-1"
          />{" "}
          {errors.user_name?.message}
        </div>
      </div>
      <div className={`mb-3 ${errors.email ? "error-form" : ""}`}>
        <label htmlFor="email">
          Email <span className="mandatory">*</span>
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          placeholder="Enter your email"
          {...register("email")}
        />

        <div className="error-form-info badge badge-soft-danger">
          <img
            alt=""
            src="/assets/icons/triangle-alert-fill.svg"
            className="me-1"
          />{" "}
          {errors.email?.message}
        </div>
      </div>
      <div className={`mb-3 ${errors.password ? "error-form" : ""}`}>
        <label htmlFor="password">Password</label>
        <div className="input-group">
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            id="name"
            placeholder="Enter your password"
            {...register("password")}
          />
          <button
            className="btn btn-outline-primary"
            type="button"
            id="inputGroupFileAddon04"
            onClick={() => setShowPassword((v) => !v)}
          >
            <i
              className={`ri ${
                showPassword ? "ri-eye-fill" : "ri-eye-off-fill"
              }`}
            ></i>
          </button>
        </div>
        <div className="error-form-info badge badge-soft-danger">
          <img
            alt=""
            src="/assets/icons/triangle-alert-fill.svg"
            className="me-1"
          />{" "}
          {errors.password?.message}
        </div>
      </div>
      <div className={`mb-3 ${errors.confirm_password ? "error-form" : ""}`}>
        <label htmlFor="confirm_password">
          Confirm Password <span className="mandatory">*</span>
        </label>
        <div className="input-group">
          <input
            type={showConfirmPassword ? "text" : "password"}
            className="form-control"
            id="name"
            placeholder="Enter your confirm password"
            {...register("confirm_password")}
          />
          <button
            className="btn btn-outline-primary"
            type="button"
            id="inputGroupFileAddon04"
            onClick={() => setShowConfirmPassword((v) => !v)}
          >
            <i
              className={`ri ${
                showConfirmPassword ? "ri-eye-fill" : "ri-eye-off-fill"
              }`}
            ></i>
          </button>
        </div>
        <div className="error-form-info badge badge-soft-danger">
          <img
            alt=""
            src="/assets/icons/triangle-alert-fill.svg"
            className="me-1"
          />{" "}
          {errors.confirm_password?.message}
        </div>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="auth-remember-check"
        />
        <label className="form-check-label" htmlFor="auth-remember-check">
          I’ve read and aggre to terms & conditions{" "}
          <span className="mandatory">*</span>
        </label>
      </div>
      <div className="mt-4">
        <button color="success" className="btn btn-success w-100" type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Create Account'}
        </button>
      </div>
      <div className="mt-4 d-flex justify-content-end">
        <p>
          Already have an account?{" "}
          <Link to="/login" className="fw-bold">
            Login Now
          </Link>
        </p>
      </div>
    </form>
    <NleLoginModal
        show={nleLoginModal}
        toggleModal={() => setNleLoginModal((v) => !v)}
        onSuccessLogin={onModalNleLoginSuccess}
      />
    </>
  );
}
