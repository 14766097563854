import * as Yup from 'yup'
import {phoneRegExp, validNumbers} from './regex_validation'

export const customValidation = (name, {
  required = true,
  isNumber = false,
  isPhone = false,
  isFreeText = false,
  isAwb = false,
  max,
  min,
  length,
} = {}) => {
  let validation = Yup.string()
  if (required) {
    validation = validation.required(`${name} tidak boleh kosong`)
  }
  if (isNumber) {
    if (!required) {
      validation = validation.test(
        'is-number',
        `${name} hanya boleh berisi angka`,
        (value) => {
          if (!value) return true
          return validNumbers.test(value)
        },
      )
    } else {
      validation = validation.matches(validNumbers, {
        message: `${name} hanya boleh berisi angka`,
      })
    }
  }
  if (isPhone) {
    if (!required) {
      validation = validation.test(
        'is-phone',
        `${name} harus format phone`,
        (value) => {
          if (!value) return true
          return phoneRegExp.test(value)
        },
      )
    } else {
      validation = validation.matches(phoneRegExp, {
        message: `${name} hanya format phone`,
      })
    }
  }
  if (isFreeText) {
    validation = validation.matches(/^[a-zA-Z0-9 ,.\-_&"]*$/,
      `${name} hanya boleh mengandung huruf, angka, spasi, dan karakter tertentu (, . - _ & ")`,
    ).notOneOf(['/'], `${name} tidak boleh mengandung karakter '/'`)
  }
  if (max) {
    validation = validation.max(max, `${name} tidak boleh lebih dari ${max} karakter`)
  }
  if (min) {
    validation = validation.min(min, `${name} tidak boleh kurang dari ${min} karakter`)
  }
  if (length) {
    validation = validation.length(length, `${name} harus ${length} karakter`)
  }
  return validation
}

export const linkedFieldsValidation = (field, fieldName, fieldLists) => {
  const dependentFields = fieldLists.filter(f => f !== field);

  return Yup.string()
    .nullable()
    .notRequired()
    .test(
      "linked-required",
      `${fieldName} harus diisi jika salah satu field lain ada yang diisi`,
      function (value) {
        const { parent } = this;
        const hasValueInOthers = dependentFields.some(f => !!parent[f]);

        return !(!value && hasValueInOthers);
      }
    );
}


export const linkedFieldValidation = (fieldName, anotherField, anotherFieldName) => {

  return Yup.string()
    .nullable()
    .notRequired()
    .test(
      "linked-required",
      `${fieldName} harus diisi jika ${anotherFieldName} juga diisi`,
      function (value) {
        const { parent } = this;

        return !(!value && parent[anotherField]);
      }
    );
}

export const optionalPhoneValidation = (fieldName) => {
  return Yup.string()
    .nullable()
    .notRequired()
    .test(
      'minLength',
      `${fieldName} harus format phone`,
      (val) => !val || phoneRegExp.test(val),
    );
}
