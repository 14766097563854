import {Container, Row} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {useState} from 'react'
// import ConfirmModal from 'Components/Common/ConfirmModal'
import {TruckingOrderRQ} from 'Hooks/Queries'
import withTable from 'Components/Common/Table/withTable'
import {TruckingOrderItem} from 'Components/TruckingOrder/TruckingOrderItem'
// import { useQueryClient } from '@tanstack/react-query'
// import { useNavigate } from 'react-router-dom'
import {DATA_LIMIT} from 'configs'

export default function ListTruckingOrder() {
  // const [rejectTruckingId, setRejectTruckingId] = useState(null);
  // const [rejectModal, setRejectModal] = useState(false);
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const {
    status,
    data,
    error,
    isFetching,
    // refetch
  } = TruckingOrderRQ.useGetDataList()(false, keyword, offset, limit)

  // const useQuery = useQueryClient();
  // const navigate = useNavigate();

  // const onRejectSuccess = (key) => {
  //   setRejectModal(false);
  //   setRejectTruckingId(null)
  //   useQuery.invalidateQueries({queryKey:[key]})
  //   refetch()
  // }
  // const { mutate: rejectTruck } = TruckingOrderRQ.useRejectData(onRejectSuccess)();

  // const onRejectClick = (id) => {
  //   setRejectModal(true);
  //   setRejectTruckingId(id);
  // }

  // const onRejectConfirm = () => {
  //   rejectTruck(rejectTruckingId);
  // }

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const TruckingTable = withTable(TruckingOrderItem, 'trucking-order', [
    'NO',
    'Action',
    'Kode Booking',
    'Tanggal Booking',
    'Status Pembayaran',
    'Status Order',
    'Status Pengiriman',
  ])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Trucking Order' />

        <Row>
          <TruckingTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      {/* <ConfirmModal
        onRejectConfirm={() => onRejectConfirm()}
        onCloseClick={() => setRejectModal(v => !v)}
        show={rejectModal}
        loading={false}
      /> */}
    </div>
  )
}
