import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Label, Row} from 'reactstrap'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'

export const AgentReferenceData = ({errors, control, isDetail = false}) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Agent Reference Data</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.fileReference}
              message={errors.fileReference?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='fileReference' className='form-label m-0 w-25'>
                  File Reference
                </Label>
                <Controller
                  id='fileReference'
                  name='fileReference'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch gap-1' style={{width: '67.5%'}}>
                      <InputTextFhlFwb
                        {...field}
                        value={field.value}
                        disabled={isDetail}
                        maxLength={15}
                      />
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>
    </div>
  )
}
