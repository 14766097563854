import FwbFhlItem from 'Components/FwbFhl/FwbFhlItem'
import BreadCrumb from 'Components/Common/BreadCrumb'
import withTable from 'Components/Common/Table/withTable'
import {APP_NAME, DATA_LIMIT} from 'configs'
import {ADMIN, ONLINE_AGENT_TYPE_DOCUMENT} from 'Helpers/options'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap'
import {RequestOnlineAgentFormRQ} from '../../Hooks/Queries/requestOnlineAgentFormRQ'
import SitaApiModal from '../../Components/FwbFhl/SitaApiModal'
import {apiBearer} from '../../Helpers/api'
import {
  PROCESS_UPLOAD_REQUEST_ONLINE_AGENT_URL,
  REVISION_REQUEST_ONLINE_AGENT_URL,
  REVISION_UPLOAD_REQUEST_ONLINE_AGENT_URL,
  SEND_REQUEST_ONLINE_AGENT_URL,
} from '../../Helpers/url_helper'
import {showError, showSuccess} from '../../Helpers/show_toast'
import SuccessModal from '../../Components/Common/SuccessModal'
import RevisionAgentModal from 'Components/FwbFhl/RevisionAgentModal'
import RevisionModal from 'Components/RequestAgentOnlineForm/RevisionModal'

const ListFwbFhl = () => {
  const {profile} = useSelector((state) => state.auth)

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const [sitaApiModal, setSitaApiModal] = useState(false)
  const [isLoadingSita, setIsLoadingSita] = useState(false)
  const [dataSita, setDataSita] = useState({})
  const navigate = useNavigate()
  const [successModal, setSuccessModal] = useState(false)
  const [revisionModal, setRevisionModal] = useState(false)
  const [isLoadingRevision, setIsLoadingRevision] = useState(false)

  const listRQ = RequestOnlineAgentFormRQ.useGetDataList()(
    false,
    keyword,
    offset,
    limit,
    '',
  )

  const onAddFhlV4ButtonClicked = () => {
    navigate('/app/fhl-v4/create')
  }

  const onAddFhlButtonClicked = () => {
    navigate('/app/fhl/create')
  }

  const onAddFwbButtonClicked = () => {
    navigate('/app/fwb/create')
  }

  const onAddUploadButtonClicked = () => {
    navigate('/app/upload/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value);
setOffset(0);
  }

  const onSendData = () => {
    setIsLoadingSita(true)
    apiBearer
      .patch(SEND_REQUEST_ONLINE_AGENT_URL(dataSita.id), {})
      .then((result) => {
        if (result?.data?.data) {
          onCloseClick()
          setSuccessModal(true)
        } else {
          setIsLoadingSita(false)
          showError('Error while saving')
        }
      })
      .catch((err) => {
        setIsLoadingSita(false)
      })
  }

  const onRevisionSubmit = async (data) => {
    setIsLoadingRevision(true)

    apiBearer
      .patch(REVISION_REQUEST_ONLINE_AGENT_URL(dataSita?.id), data)
      .then((result) => {
        setIsLoadingRevision(false)
        setRevisionModal(false)
        if (result?.data?.data) {
          setSuccessModal(true)
          setDataSita({});
        } else {
          showError('Error while saving')
        }
      })
      .catch((err) => {
        setIsLoadingRevision(false)
      })
  }

  const onSitaApiClick = (data) => {
    setDataSita(data)
    setSitaApiModal(true)
  }

  const onCloseClick = () => {
    setIsLoadingSita(false)
    setSitaApiModal(false)
  }

  const onCloseClickSuccess = () => {
    setSuccessModal(false)
    listRQ.refetch()
  }

  const onClickRevision = async (e) => {
    setSitaApiModal(false)
    setRevisionModal(true)
  }

  const CustomAddButton = () => {
    const [dropdownOpen, setDropdownOpen] = React.useState(false)
    return (
      <Dropdown
        toggle={() => {
          setDropdownOpen((prevState) => !prevState)
        }}
        isOpen={dropdownOpen}
      >
        <DropdownToggle
          tag='button'
          className='d-flex justify-content-between align-items-center btn btn-sm btn-primary'
        >
          <i className='ri-add-line label-icon align-middle fs-16 me-1'></i>
          <span className='btn btn-sm p-0 btn-primary waves-effect waves-light fw-bold'>
            {'Create'}
          </span>
        </DropdownToggle>
        <DropdownMenu
          className={`dropdown-menu-end d-flex flex-column ${
            dropdownOpen ? 'd-block' : 'd-none'
          }`}
        >
          <DropdownItem onClick={onAddFhlV4ButtonClicked}>FHL V4</DropdownItem>
          <DropdownItem onClick={onAddFhlButtonClicked}>FHL V5</DropdownItem>
          <DropdownItem onClick={onAddFwbButtonClicked}>FWB</DropdownItem>
          <DropdownItem onClick={onAddUploadButtonClicked}>
            Upload Doc
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  const FhlTable = withTable(
    FwbFhlItem,
    'fwb-fhl',
    [
      'NO',
      'Action',
      'TYPE DOCUMENT',
      'NO AWB/HWB',
      'UPLOAD DATE',
      'SHIPPER NAME',
      'CONSIGNEE NAME',
      'SEND DATE',
      'PAYMENT STATUS',
      'ORDER STATUS',
    ],
    null,
    null,
    onSitaApiClick,
    null,
    <CustomAddButton />,
  )

  useEffect(() => {
    if (!profile.is_approved && profile.role !== ADMIN) {
      navigate('/app/profile')
    }
  }, [navigate, profile.is_approved, profile.role])
  document.title = `${APP_NAME} | FWB / FHL`
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='FWB / FHL' pageTitle='FWB / FHL' />

        <Row>
          <FhlTable
            data={listRQ.data}
            isFetching={listRQ.isFetching}
            loading={listRQ.isFetching}
            error={listRQ.error}
            status={listRQ.status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <SitaApiModal
        onCloseClick={() => onCloseClick()}
        onSendClick={() => onSendData()}
        show={sitaApiModal}
        data={dataSita}
        isLoading={isLoadingSita}
        isUpload={dataSita.type === ONLINE_AGENT_TYPE_DOCUMENT.UPLOAD}
        onRevision={onClickRevision}
      />
      <SuccessModal
        onCloseClick={onCloseClickSuccess}
        show={successModal}
        text='Data berhasil Terkirim'
      />

      <RevisionModal
        onCloseClick={() => setRevisionModal((v) => !v)}
        show={revisionModal}
        isLoadingRevision={isLoadingRevision}
        onSubmitClick={onRevisionSubmit}
      />
    </div>
  )
}

export default ListFwbFhl
