import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Label, Row} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'

export const FreeTextDescriptionOfGoods = ({errors, control, fields, remove, append, isDetail = false}) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Free Text Description of Goods</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>


        <Row className='gy-2 ps-1'>
          {
            fields?.map((field, index) => (
              <Col lg={12} sm={12} key={index}>
                <ErrorFieldWrapper
                  error={errors.freeText?.[index]?.value}
                  message={errors.freeText?.[index]?.value?.message}
                >
                  <div className='d-flex align-items-center justify-content-between'>
                    <Label htmlFor='freeText' className='form-label m-0'>
                      Free Text
                    </Label>
                    <Controller
                      id='freeText'
                      name={`freeText.${index}.value`}
                      type='text'
                      control={control}
                      render={({field}) => (
                        <div className='d-flex gap-2' style={{width: '84.2%'}}>
                          <InputTextFhlFwb
                            {...field}
                            maxLength={65}
                            value={field.value}
                            disabled={isDetail}
                          />
                          {!isDetail && index >= 1 ? (
                            <ActionButton onClick={() => remove(index)} action='remove' />
                          ) : null}
                          {
                            !isDetail && <ActionButton onClick={() => append({value: ''})} disabled={fields.length === 9} action='add' />
                          }
                        </div>
                      )}
                    />
                  </div>
                </ErrorFieldWrapper>
              </Col>
            ))
          }
        </Row>
      </Collapse>
    </div>
  )
}
