import {load} from 'Helpers/localStorage'
import {useEffect} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'

export default function AdminLayout() {
  const navigate = useNavigate()

  useEffect(() => {
    let mounted = true

    if (mounted) {
      const profile = load('profile')
      if (profile?.role !== 'ADMIN') navigate('/')
    }

    return () => {
      mounted = false
    }
  }, [navigate])

  return <Outlet />
}
