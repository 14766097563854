import {Container, Row} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {useState} from 'react'
import DeleteModal from 'Components/Common/DeleteModal'
import {AgreementRQ} from 'Hooks/Queries'
import withTable from 'Components/Common/Table/withTable'
import {useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {DATA_LIMIT} from 'configs'
import AgreementItem from 'Components/Agreement/AgreementItem'

export default function ListAgreement() {
  const [deleteAgreementId, setDeleteAgreementId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const {status, data, error, isFetching, refetch} =
    AgreementRQ.useGetDataList()(false, keyword, offset, limit)

  const useQuery = useQueryClient()
  const navigate = useNavigate()

  const onDeleteSuccess = (key) => {
    setDeleteModal(false)
    setDeleteAgreementId(null)
    useQuery.invalidateQueries({queryKey: [key]})
    refetch()
  }
  const {mutate: deleteTruck} = AgreementRQ.useDeleteData(onDeleteSuccess)()

  const onDeleteClick = (id) => {
    setDeleteModal(true)
    setDeleteAgreementId(id)
  }

  const onDeleteConfirm = () => {
    deleteTruck(deleteAgreementId)
  }

  const onAddButtonClicked = () => {
    navigate('/app/agreement/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const AgreementTable = withTable(
    AgreementItem,
    'agreement',
    [
      'NO',
      'Aksi',
      'ID Agreement',
      'vendor trucking',
      'tanggal approve',
      'tanggal reject',
      'status approval',
    ],
    onDeleteClick,
    onAddButtonClicked,
  )

  console.log(data)
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Agreement' />
        <Row>
          <AgreementTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  )
}
