import React from 'react'
import {Link} from 'react-router-dom'
import moment from 'moment/moment'
import {
  getBadgeRequestOnlineClass,
  getDocumentLabel,
  getOnlineAgentOrderStatusLabel,
  getOnlineAgentPaymentStatusLabel,
  ONLINE_AGENT_ORDER_STATUS,
  ONLINE_AGENT_PAYMENT_STATUS,
  ONLINE_AGENT_TYPE_DOC,
  ONLINE_AGENT_TYPE_DOCUMENT,
} from '../../Helpers/options'

const FwbFhlItem = ({
  page,
  index,
  id,
  consignee_name,
  shipper_name,
  table,
  type_doc,
  form_no,
  type,
  send_date,
  upload_date,
  order_status,
  sita_text,
  payment_status,
  additionalActions,
  is_draft,
}) => {
  const linkedToSitaText = () => {
    return (
      <Link
        to='#'
        className='link-dark'
        onClick={() => {
          additionalActions({sita_text, id, order_status, type, type_doc})
        }}
        title={'Detail'}
      >
        <i className='ri-eye-fill'></i>
      </Link>
    )
  }
  const linkedToDetailUpload = () => {
    return (
      <Link
        to={`/app/upload/detail/${id}`}
        className='link-dark'
        title={'Detail'}
      >
        <i className='ri-eye-fill'></i>
      </Link>
    )
  }
  const linkedToPaymentPage = () => {
    return (
      <>
        {payment_status === ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS ? (
          <Link
            to={`/app/fwb-fhl/${id}/payment/bank`}
            className='link-dark'
            title={'Bayar'}
          >
            <i className='bx bx-money'></i>
          </Link>
        ) : (
          <Link
            to={`/app/${table}/${id}/payment`}
            className='link-dark'
            title={'Bayar'}
          >
            <i className='bx bx-money'></i>
          </Link>
        )}
      </>
    )
  }
  const linkedToDetailOrPaymentPage = () => {
    return (
      <>
        {payment_status === ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS ? (
          <Link
            to={`/app/fwb-fhl/${id}/payment/bank`}
            className='link-dark'
            title={'Bayar'}
          >
            <i className='bx bx-money'></i>
          </Link>
        ) : (
          <Link
            to={`/app/upload/detail/${id}`}
            className='link-dark'
            title={'Bayar'}
          >
            <i className='bx bx-money'></i>
          </Link>
        )}
      </>
    )
  }

  const linkedToEditUpload = () => {
    return (
      <Link to={`/app/upload/edit/${id}`} className='link-dark' title={'Edit'}>
        <i className='bx bxs-edit'></i>
      </Link>
    )
  }
  const linkedToEditFhl = () => {
    let path = 'fhl'
    if (type_doc === ONLINE_AGENT_TYPE_DOC.FHL_V4) path = 'fhl-v4'
    return (
      <Link to={`/app/${path}/edit/${id}`} className='link-dark' title={'Edit'}>
        <i className='bx bxs-edit'></i>
      </Link>
    )
  }
  const linkedToEditFwb = () => {
    return (
      <Link to={`/app/fwb/edit/${id}`} className='link-dark' title={'Edit'}>
        <i className='bx bxs-edit'></i>
      </Link>
    )
  }
  const linkedToDetailFhlFwb = () => {
    if (type_doc === ONLINE_AGENT_TYPE_DOC.FHL_V5) {
      return (
        <Link
          to={`/app/fhl/detail/${id}`}
          className='link-dark'
          title={'Detail'}
        >
          <i className='ri-eye-fill'></i>
        </Link>
      )
    } else if (type_doc === ONLINE_AGENT_TYPE_DOC.FHL_V4) {
      return (
        <Link
          to={`/app/fhl-v4/detail/${id}`}
          className='link-dark'
          title={'Detail'}
        >
          <i className='ri-eye-fill'></i>
        </Link>
      )
    }
    return (
      <Link to={`/app/fwb/detail/${id}`} className='link-dark' title={'Detail'}>
        <i className='ri-eye-fill'></i>
      </Link>
    )
  }

  const renderActionUpload = () => {
    if (order_status !== null) {
      if (order_status === ONLINE_AGENT_ORDER_STATUS.NEW_REQUEST)
        return linkedToDetailUpload()
      if (
        order_status === ONLINE_AGENT_ORDER_STATUS.DRAFT ||
        order_status === ONLINE_AGENT_ORDER_STATUS.SEND
      ) {
        
        return type_doc === ONLINE_AGENT_TYPE_DOC.FWB_V16
          ? linkedToDetailFhlFwb()
          : linkedToSitaText()
      }
    }

    if (
      payment_status === ONLINE_AGENT_PAYMENT_STATUS.UNPAID ||
      payment_status === ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS
    ) {
      return (
        <>
          {linkedToDetailOrPaymentPage()}
          {linkedToEditUpload()}
        </>
      )
    } else if (payment_status === ONLINE_AGENT_PAYMENT_STATUS.PAID) {
      // return linkedToSitaText()
      return linkedToDetailUpload()
    } else {
      return linkedToDetailUpload()
    }
  }

  const renderActionFhl = () => {
    if (
      payment_status === ONLINE_AGENT_PAYMENT_STATUS.UNPAID ||
      payment_status === ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS
    ) {
      return (
        <>
          {!is_draft && linkedToPaymentPage()}
          {linkedToEditFhl()}
        </>
      )
    } else if (
      payment_status === ONLINE_AGENT_PAYMENT_STATUS.EXPIRED ||
      payment_status === ONLINE_AGENT_PAYMENT_STATUS.FAILED
    ) {
      return linkedToDetailFhlFwb()
    }
    return linkedToSitaText()
  }

  const renderActionFwb = () => {
    if (
      payment_status === ONLINE_AGENT_PAYMENT_STATUS.UNPAID ||
      payment_status === ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS
    ) {
      return (
        <>
          {linkedToPaymentPage()}
          {linkedToEditFwb()}
        </>
      )
    } else if (
      payment_status === ONLINE_AGENT_PAYMENT_STATUS.EXPIRED ||
      payment_status === ONLINE_AGENT_PAYMENT_STATUS.FAILED
    ) {
      return linkedToDetailFhlFwb()
    }
    return linkedToDetailFhlFwb()
  }

  return (
    <tr>
      <td className='fw-medium'>{index + page + 1}</td>
      <td>
        <div className='hstack gap-3 fs-15'>
          {type === ONLINE_AGENT_TYPE_DOCUMENT.UPLOAD && renderActionUpload()}
          {type === ONLINE_AGENT_TYPE_DOCUMENT.FHL && renderActionFhl()}
          {type === ONLINE_AGENT_TYPE_DOCUMENT.FWB && renderActionFwb()}
        </div>
      </td>
      <td>{getDocumentLabel(type_doc)}</td>
      <td>{form_no ?? ''}</td>
      <td>{upload_date ? moment(upload_date).format('DD MMMM YYYY') : ''}</td>
      <td>{shipper_name}</td>
      <td>{consignee_name}</td>
      <td>{send_date ? moment(send_date).format('DD MMMM YYYY') : ''}</td>
      <td>
        <span className={getBadgeRequestOnlineClass(payment_status)}>
          {getOnlineAgentPaymentStatusLabel(payment_status)}
        </span>
      </td>
      <td>
        <span className={getBadgeRequestOnlineClass(order_status)}>
          {getOnlineAgentOrderStatusLabel(order_status)}
        </span>
      </td>
    </tr>
  )
}

export default FwbFhlItem
