import {useMutation, useQuery} from '@tanstack/react-query'
import {apiBearer} from '../../Helpers/api'
import {showSuccess} from '../../Helpers/show_toast'

export default class RQ {
  QUERY_KEY = ''
  LIST_URL = ''
  VIEW_URL = function (id) {}
  CREATE_URL = ''
  UPDATE_URL = function (id) {}
  DELETE_URL = function (id) {}
  REDIRECT_URL = ''

  constructor(
    QUERY_KEY,
    LIST_URL,
    VIEW_URL,
    CREATE_URL,
    UPDATE_URL,
    DELETE_URL,
    REDIRECT_URL,
  ) {
    this.QUERY_KEY = QUERY_KEY
    this.LIST_URL = LIST_URL
    this.VIEW_URL = VIEW_URL
    this.CREATE_URL = CREATE_URL
    this.UPDATE_URL = UPDATE_URL
    this.DELETE_URL = DELETE_URL
    this.REDIRECT_URL = REDIRECT_URL
  }

  useGetDataList() {
    return (
      isSearch = false,
      keyword = '',
      offset = 0,
      limit = 2,
      additionalParams = {},
    ) =>
      useQuery(
        [this.QUERY_KEY, isSearch, keyword, offset, limit],
        async () => {
          const params = {
            page: offset,
            size: limit,
            search: keyword,
            ...additionalParams,
          }
          const res = await apiBearer.get(this.LIST_URL, {params})
          return res.data?.data
        },
        // {
        //   enabled:
        //     (!isSearch && keyword === "") || (isSearch && keyword !== ""),
        // }
      )
  }

  useGetData(id) {
    return (
      refetchInterval = false
    ) =>
      {
        return useQuery(
        [this.QUERY_KEY + '_VIEW', refetchInterval],
        async () => {
          const res = await apiBearer.get(this.VIEW_URL(id))
          return res.data
        },
        {
          enabled: id !== undefined,
          cacheTime: !refetchInterval ? 1000 * (60 *5) : 1000,
          refetchInterval: refetchInterval,
        },
      )}
  }

  useAddData(navigate) {
    return () =>
      useMutation({
        mutationFn: (newData) => {
          return apiBearer.post(this.CREATE_URL, newData)
        },
        onSuccess: async () => {
          showSuccess('New data added successfully')
          navigate(this.REDIRECT_URL)
        },
        onError: async (error) => {},
      })
  }

  useUpdateData(navigate) {
    return () =>
      useMutation({
        mutationFn: (newData) => {
          const id = newData.id
          delete newData.id
          const payload = newData
          return apiBearer.patch(this.UPDATE_URL(id), payload)
        },
        onSuccess: async () => {
          showSuccess('Data is updated successfully')
          navigate(this.REDIRECT_URL)
        },
        onError: async (error) => {
          console.log(error)
        },
      })
  }

  useUpdateFormData(navigate) {
    return () =>
      useMutation({
        mutationFn: (newData) => {
          console.log(newData)
          const id = newData.get('id')
          delete newData.get('id')
          const payload = newData
          return apiBearer.patch(this.UPDATE_URL(id), payload)
        },
        onSuccess: async () => {
          showSuccess('Data is updated successfully')
          navigate(this.REDIRECT_URL)
        },
        onError: async (error) => {
          console.log(error)
        },
      })
  }

  useDeleteData(successCallback = null) {
    return () =>
      useMutation({
        mutationFn: (id) => {
          return apiBearer.delete(this.DELETE_URL(id))
        },
        onSuccess: async (d) => {
          showSuccess('Data is deleted successfully')
          if (typeof successCallback === 'function')
            successCallback(this.QUERY_KEY)
        },
        onError: async (error) => {
          console.error(error)
        },
      })
  }
}
