import {Container, Row} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {useState} from 'react'
import DeleteModal from 'Components/Common/DeleteModal'
import {truckBookingRQ} from 'Hooks/Queries'
import withTable from 'Components/Common/Table/withTable'
import {useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {DATA_LIMIT} from 'configs'
import TruckBookingItem from 'Components/TruckBooking/Item'

export default function ListTruckBooking() {
  const [deleteTruckBookingId, setDeleteTruckBookingId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const {status, data, error, isFetching, refetch} =
    truckBookingRQ.useGetDataList()(false, keyword, offset, limit)

  const useQuery = useQueryClient()
  const navigate = useNavigate()

  const onDeleteSuccess = (key) => {
    setDeleteModal(false)
    setDeleteTruckBookingId(null)
    useQuery.invalidateQueries({queryKey: [key]})
    refetch()
  }
  const {mutate: deleteTruck} = truckBookingRQ.useDeleteData(onDeleteSuccess)()

  const onDeleteClick = (id) => {
    setDeleteModal(true)
    setDeleteTruckBookingId(id)
  }

  const onDeleteConfirm = () => {
    deleteTruck(deleteTruckBookingId)
  }

  const onAddButtonClicked = () => {
    navigate('/app/truck-book/booking/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const TruckBookingTable = withTable(
    TruckBookingItem,
    'truck-book/booking',
    [
      'NO',
      'Action',
      'Kode Booking',
      'date',
      'asal',
      'tujuan',
      'harga',
      'Status Pembayaran',
      'Status Order',
      'Status Pengiriman',
    ],
    onDeleteClick,
    onAddButtonClicked,
    {},
    'Booking Baru',
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='List Booking' />

        <Row>
          <TruckBookingTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  )
}
