import {Container, Row} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {useState} from 'react'
import DeleteModal from 'Components/Common/DeleteModal'
import {rolesRQ} from 'Hooks/Queries'
import withTable from 'Components/Common/Table/withTable'
import {useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {DATA_LIMIT} from 'configs'
import RoleItem from 'Components/RoleItem'

export default function ListRole() {
  const [deleteRoleId, setDeleteRoleId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const {status, data, error, isFetching, refetch} = rolesRQ.useGetDataList()(
    false,
    keyword,
    offset,
    limit,
  )

  const useQuery = useQueryClient()
  const navigate = useNavigate()

  const onDeleteSuccess = (key) => {
    setDeleteModal(false)
    setDeleteRoleId(null)
    useQuery.invalidateQueries({queryKey: [key]})
    refetch()
  }
  const {mutate: deleteTruck} = rolesRQ.useDeleteData(onDeleteSuccess)()

  const onDeleteClick = (id) => {
    setDeleteModal(true)
    setDeleteRoleId(id)
  }

  const onDeleteConfirm = () => {
    deleteTruck(deleteRoleId)
  }

  const onAddButtonClicked = () => {
    navigate('/app/permission/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const RoleTable = withTable(
    RoleItem,
    'permission',
    ['NO', 'Aksi', 'permission', 'hak akses', 'jumlah'],
    onDeleteClick,
    onAddButtonClicked,
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Permission' />
        <Row>
          <RoleTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  )
}
