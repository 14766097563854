import { CREATE_CUSTOM_INFO_IDENTIFIER_URL, DELETE_CUSTOM_INFO_IDENTIFIER_URL, LIST_CUSTOM_INFO_IDENTIFIER_URL, UPDATE_CUSTOM_INFO_IDENTIFIER_URL, VIEW_CUSTOM_INFO_IDENTIFIER_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'custom-info-identifier';
const REDIRECT_URL = '/app/online-agent-form/custom-info-identifier';

export const customInfoIdentifierRQ = new RQ(
  QUERY_KEY,
  LIST_CUSTOM_INFO_IDENTIFIER_URL,
  VIEW_CUSTOM_INFO_IDENTIFIER_URL,
  CREATE_CUSTOM_INFO_IDENTIFIER_URL,
  UPDATE_CUSTOM_INFO_IDENTIFIER_URL,
  DELETE_CUSTOM_INFO_IDENTIFIER_URL,
  REDIRECT_URL
);