import { 
  CREATE_ONLINE_AGENT_FORM_RATE_URL, 
  DELETE_ONLINE_AGENT_FORM_RATE_URL, 
  LIST_ONLINE_AGENT_FORM_RATE_URL, 
  UPDATE_ONLINE_AGENT_FORM_RATE_URL, 
  VIEW_ONLINE_AGENT_FORM_RATE_URL 
} from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'online-agent-form-rate';
const REDIRECT_URL = '/app/struktur-harga/online-agent-form/rate';

export const OnlineAgentFormRateRQ = new RQ(
  QUERY_KEY, 
  LIST_ONLINE_AGENT_FORM_RATE_URL, 
  VIEW_ONLINE_AGENT_FORM_RATE_URL, 
  CREATE_ONLINE_AGENT_FORM_RATE_URL, 
  UPDATE_ONLINE_AGENT_FORM_RATE_URL, 
  DELETE_ONLINE_AGENT_FORM_RATE_URL, 
  REDIRECT_URL
);