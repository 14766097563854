import {yupResolver} from '@hookform/resolvers/yup'
import ImageTextPreview from 'Components/Common/ImageTextPreview'
import {apiBearer} from 'Helpers/api'
import {
  LIST_BANKS_URL,
  LIST_CITIES_URL,
  LIST_DISTRICTS_URL,
  LIST_PROVINCES_URL,
  LIST_VILLAGES_URL,
} from 'Helpers/url_helper'
import {AgentRQ} from 'Hooks/Queries'
import {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import ErrorFieldWrapper from '../../Components/Common/ErrorFieldWrapper'
import { validNumbers } from 'Helpers/regex_validation'

export default function EditCreateAgent(props) {
  const {id} = useParams()
  const navigate = useNavigate()

  const [province, setProvince] = useState(null)
  const [provinceOptions, setProvinceOptions] = useState([])
  const [city, setCity] = useState(null)
  const [cityOptions, setCityOptions] = useState([])
  const [district, setDistrict] = useState(null)
  const [districtOptions, setDistrictOptions] = useState([])
  const [village, setVillage] = useState(null)
  const [villageOptions, setVillageOptions] = useState([])
  const [bank, setBank] = useState(null)
  const [bankOptions, setBankOptions] = useState([])
  const [discountType, setDiscountType] = useState(null)
  const discountTypeOptions = [
    {label: 'Percent', value: 'PERCENT'},
    {label: 'Amount', value: 'AMOUNT'},
  ]

  const getPlaceHolder = () => {
    if (!discountType?.value) return 'Pilih discount type terlebih dahulu'
    if (discountType?.value === 'PERCENT') return 'Masukkan value (%)'
    else return 'Masukkan value'
  }

  const validation = Yup.object().shape({
    name: Yup.string().required('Name harus diisi'),
    picName: Yup.string().required('PIC Name harus diisi'),
    picEmail: Yup.string().required('PIC Email harus diisi'),
    picPhone: Yup.string().required('PIC Phone harus diisi').matches(validNumbers, {message:'Zip code hanya boleh berisi angka'}),
    address: Yup.string().required('Address harus diisi'),
    bankAccount: Yup.string().required('Bank account harus diisi'),
    bank: Yup.string().required('Bank name harus diisi'),
    province: Yup.string().required('Province harus diisi'),
    city: Yup.string().required('City harus diisi'),
    district: Yup.string().required('District harus diisi'),
    village: Yup.string().required('Village harus diisi'),
    zipcode: Yup.string().required('Zipcode harus diisi').matches(validNumbers, {message:'Zip code hanya boleh berisi angka'}),
    discountType: Yup.string().required('Discount type harus diisi'),
    discountValue: Yup.string().required('Discount value harus diisi').matches(validNumbers, {message:'Discount value hanya boleh berisi angka'}),
    docAttachment: Yup.mixed(),
    // .test("required", "You need to provide a file", value => value && value.length > 0)
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
      picName: '',
      picEmail: '',
      picPhone: '',
      address: '',
      bankAccount: '',
      bank: '',
      province: '',
      city: '',
      district: '',
      village: '',
      zipcode: '',
      discountType: '',
      discountValue: '',
    },
  }
  const {handleSubmit, formState, control, reset, register} =
    useForm(formOptions)
  const {errors} = formState

  // console.log(errors);

  const {mutate: updateAgent, isLoading: updateLoading} = AgentRQ.useUpdateFormData(navigate)()

  const _updateAgent = (values) => {
    const data = JSON.stringify({
      name: values.name,
      province_id: values.province,
      city_id: values.city,
      district_id: values.district,
      village_id: values.village,
      address: values.address,
      zip_code: values.zipcode,
      pic_name: values.picName,
      pic_email: values.picEmail,
      pic_phone: values.picPhone,
      bank_account: values.bankAccount,
      bank_name: values.bank,
      online_agent_form_discount:{
        value_type: values.discountType,
        value: values.discountValue
      }
    })

    const body = new FormData()
    body.append('id', id)
    body.append('data', data)
    if (values.docAttachment) {
      body.append('doc', values.docAttachment)
    }

    updateAgent(body)
  }

  const {mutate: addAgent, isLoading: createLoading} = AgentRQ.useAddData(navigate)()

  const _createAgent = async (values) => {
    const data = JSON.stringify({
      name: values.name,
      province_id: values.province,
      city_id: values.city,
      district_id: values.district,
      village_id: values.village,
      address: values.address,
      zip_code: values.zipcode,
      pic_name: values.picName,
      pic_email: values.picEmail,
      pic_phone: values.picPhone,
      bank_account: values.bankAccount,
      bank_name: values.bank,
      online_agent_form_discount:{
        value_type: values.discountType,
        value: values.discountValue
      }
    })

    const body = new FormData()
    body.append('data', data)
    body.append('doc', values.docAttachment)

    addAgent(body)
  }

  const {data: detailData, error, isFetching, status} = AgentRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      if (id) {
        // setProvince(data.data?.province?.id)
        onProvinceSelected({
          label: detailData.data?.province?.name,
          value: detailData.data?.province?.id,
        })
        // setCity(data.data?.city?.id)
        onCitySelected({
          label: detailData.data?.city?.name,
          value: detailData.data?.city?.id,
        })
        // setDistrict(data.data?.district?.id)
        onDistrictSelected({
          label: detailData.data?.district?.name,
          value: detailData.data?.district?.id,
        })
        setVillage({
          label: detailData.data?.village?.name,
          value: detailData.data?.village?.id,
        })
      }
      reset({
        name: detailData.data?.name ?? '',
        picName: detailData.data?.pic_name ?? '',
        picEmail: detailData.data?.pic_email ?? '',
        picPhone: detailData.data?.pic_phone ?? '',
        address: detailData.data?.address ?? '',
        bankAccount: detailData.data?.bank_account ?? '',
        bank: detailData.data?.bank_name ?? '',
        province: detailData.data?.province?.id ?? '',
        city: detailData.data?.city?.id ?? '',
        district: detailData.data?.district?.id ?? '',
        village: detailData.data?.village?.id ?? '',
        zipcode: detailData.data?.zip_code ?? '',
        discountType: detailData.data?.discounts[0]?.value_type ?? '',
        discountValue: detailData.data?.discounts[0]?.value ?? '',
      })
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  useEffect(() => {
    fetchProvinces()
    fetchBanks()
  }, [])

  useEffect(() => {
    if (id && detailData?.data?.bank_name && bankOptions.length > 0) {
      const selectedBank = bankOptions.find(
        (v) => v.value === detailData.data.bank_name,
      )
      if (selectedBank) setBank(selectedBank)
    }
  }, [detailData?.data?.bank_name, id, bankOptions])

  useEffect(() => {
    if (id && detailData?.data?.discounts ) {
      const selectedDiscountType = discountTypeOptions.find(
        (v) => v.value === detailData.data?.discounts[0]?.value_type,
      )

      setDiscountType(selectedDiscountType)
    }
  }, [])

  const fetchBanks = async (input) => {
    const result = await apiBearer.get(LIST_BANKS_URL)
    const {data} = result
    if (data.success) {
      setBankOptions(data.data.map((v) => ({label: v?.name, value: v.code})))
    }
  }

  const fetchProvinces = async (input) => {
    const result = await apiBearer.get(LIST_PROVINCES_URL + '?keyword=' + input)
    const {data} = result
    if (data.success) {
      setProvinceOptions(data.data.map((v) => ({label: v?.name, value: v?.id})))
    }
  }

  const onProvinceSelected = async (e) => {
    setProvince(e)
    console.log(e)
    if (e && e.value) {
      const result = await apiBearer.get(LIST_CITIES_URL(e.value))
      const {data} = result
      if (data.success) {
        setCityOptions(data.data.map((v) => ({label: v?.name, value: v?.id})))
      }
    } else {
      setCityOptions([])
      setDistrictOptions([])
      setVillageOptions([])
      setCity(null)
      setDistrict(null)
      setVillage(null)
    }
  }

  const onCitySelected = async (e) => {
    setCity(e)
    if (e) {
      const result = await apiBearer.get(LIST_DISTRICTS_URL(e.value))
      const {data} = result
      if (data.success) {
        setDistrictOptions(
          data.data.map((v) => ({label: v?.name, value: v?.id})),
        )
      }
    } else {
      setDistrictOptions([])
      setDistrict(null)
    }
  }

  const onDistrictSelected = async (e) => {
    setDistrict(e)
    if (e) {
      const result = await apiBearer.get(LIST_VILLAGES_URL(e.value))
      const {data} = result
      if (data.success) {
        setVillageOptions(
          data.data.map((v) => ({label: v?.name, value: v?.id})),
        )
      }
    } else {
      setVillageOptions([])
      setVillage(null)
    }
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Agent' pageTitle='Agent' />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(id ? _updateAgent : _createAgent)}>
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Edit' : 'Create'} Agent
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors?.name}
                        message={errors?.name?.message}
                      >
                        <Label htmlFor='name' className='form-label'>
                          Name
                        </Label>
                        <Controller
                          id='name'
                          name='name'
                          type='name'
                          control={control}
                          render={({field}) => (
                            <Input {...field} value={field.value} />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.province}
                        message={errors.province?.message}
                      >
                        <Label htmlFor='province' className='form-label'>
                          Province
                        </Label>
                        <Controller
                          id='province'
                          name='province'
                          type='text'
                          control={control}
                          render={({field: {onChange, ...field}}) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={province ?? ''}
                              options={provinceOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value)
                                onProvinceSelected(e)
                              }}
                              placeholder='Search by name'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.city}
                        message={errors.city?.message}
                      >
                        <Label htmlFor='city' className='form-label'>
                          City
                        </Label>
                        <Controller
                          id='city'
                          name='city'
                          type='text'
                          control={control}
                          render={({field: {onChange, ...field}}) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={city ?? ''}
                              options={cityOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value)
                                onCitySelected(e)
                              }}
                              placeholder='Search by name'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.district}
                        message={errors.district?.message}
                      >
                        <Label htmlFor='district' className='form-label'>
                          District
                        </Label>
                        <Controller
                          id='district'
                          name='district'
                          type='text'
                          control={control}
                          render={({field: {onChange, ...field}}) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={district ?? ''}
                              options={districtOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value)
                                onDistrictSelected(e)
                              }}
                              placeholder='Search by name'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.village}
                        message={errors.village?.message}
                      >
                        <Label htmlFor='village' className='form-label'>
                          Village
                        </Label>
                        <Controller
                          id='village'
                          name='village'
                          type='text'
                          control={control}
                          render={({field: {onChange, ...field}}) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={village ?? ''}
                              options={villageOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value)
                                setVillage(e)
                              }}
                              placeholder='Search by name'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.zipcode}
                        message={errors.zipcode?.message}
                      >
                        <Label htmlFor='zipcode' className='form-label'>
                          Zipcode
                        </Label>
                        <Controller
                          id='zipcode'
                          name='zipcode'
                          type='zipcode'
                          control={control}
                          render={({field}) => (
                            <Input {...field} value={field.value} />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.picName}
                        message={errors.picName?.message}
                      >
                        <Label htmlFor='picName' className='form-label'>
                          PIC Name
                        </Label>
                        <Controller
                          id='picName'
                          name='picName'
                          type='picName'
                          control={control}
                          render={({field}) => (
                            <Input {...field} value={field.value} />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.picEmail}
                        message={errors.picEmail?.message}
                      >
                        <Label htmlFor='picEmail' className='form-label'>
                          PIC Email
                        </Label>
                        <Controller
                          id='picEmail'
                          name='picEmail'
                          type='picEmail'
                          control={control}
                          render={({field}) => (
                            <Input {...field} value={field.value} />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.picPhone}
                        message={errors.picPhone?.message}
                      >
                        <Label htmlFor='picPhone' className='form-label'>
                          PIC Phone
                        </Label>
                        <Controller
                          id='picPhone'
                          name='picPhone'
                          type='picPhone'
                          control={control}
                          render={({field}) => (
                            <Input {...field} value={field.value} />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.address}
                        message={errors.address?.message}
                      >
                        <Label htmlFor='address' className='form-label'>
                          Address
                        </Label>
                        <Controller
                          id='address'
                          name='address'
                          type='address'
                          control={control}
                          render={({field}) => (
                            <Input {...field} value={field.value} />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.bankAccount}
                        message={errors.bankAccount?.message}
                      >
                        <Label htmlFor='bankAccount' className='form-label'>
                          Bank Account
                        </Label>
                        <Controller
                          id='bankAccount'
                          name='bankAccount'
                          type='bankAccount'
                          control={control}
                          render={({field}) => (
                            <Input {...field} value={field.value} />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.bank}
                        message={errors.bank?.message}
                      >
                        <Label htmlFor='bank' className='form-label'>
                          Bank
                        </Label>
                        <Controller
                          id='bank'
                          name='bank'
                          type='text'
                          control={control}
                          render={({field: {onChange, ...field}}) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={bank ?? ''}
                              options={bankOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value)
                                setBank(e)
                              }}
                              placeholder='Search by name'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.discountType}
                        message={errors.discountType?.message}
                      >
                        <Label htmlFor='discountType' className='form-label'>
                          Discount Type
                        </Label>
                        <Controller
                          id='discountType'
                          name='discountType'
                          type='text'
                          control={control}
                          render={({field: {onChange, ...field}}) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={discountType ?? ''}
                              options={discountTypeOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value)
                                  setDiscountType(e)
                              }}
                              placeholder='Select Discount Type'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.discountValue}
                        message={errors.discountValue?.message}
                      >
                        <Label htmlFor='discountValue' className='form-label'>
                          Discount Amount
                        </Label>
                        <Controller
                          id='discountValue'
                          name='discountValue'
                          type='discountValue'
                          control={control}
                          render={({field}) => (
                            <Input {...field} value={field.value} type='number' placeholder={getPlaceHolder()} />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.docAttachment}
                        message={errors.docAttachment?.message}
                      >
                        <div className='d-flex justify-content-between'>
                          <Label htmlFor='ktp' className='form-label'>
                            Dokumen{' '}
                          </Label>
                          {detailData?.data?.doc ? (
                            <ImageTextPreview link={detailData?.data?.doc} />
                          ) : null}
                        </div>
                        <Controller
                          id='docAttachment'
                          name='docAttachment'
                          control={control}
                          render={({field}) => (
                            <Input
                              className='form-control'
                              type='file'
                              onChange={(e) =>
                                field.onChange({
                                  target: {
                                    value: e.target.files[0],
                                    name: field?.name,
                                  },
                                })
                              }
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/agents' className='btn btn-danger'>
                    Cancel
                  </Link>
                  <Button color='success' type='submit' disabled={updateLoading || createLoading}>
                    {updateLoading || createLoading ? 'Loading...' :(id ? 'Update' : 'Create')}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
