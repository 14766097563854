import { CREATE_HANDLING_CODE_URL, DELETE_HANDLING_CODE_URL, LIST_HANDLING_CODE_URL, UPDATE_HANDLING_CODE_URL, VIEW_HANDLING_CODE_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'handling-code';
const REDIRECT_URL = '/app/online-agent-form/handling-code';

export const handlingCodeRQ = new RQ(
  QUERY_KEY,
  LIST_HANDLING_CODE_URL,
  VIEW_HANDLING_CODE_URL,
  CREATE_HANDLING_CODE_URL,
  UPDATE_HANDLING_CODE_URL,
  DELETE_HANDLING_CODE_URL,
  REDIRECT_URL
);