import BreadCrumb from 'Components/Common/BreadCrumb'
import {DATA_LIMIT} from 'configs'
import React, {useState} from 'react'
import {Container, Row} from 'reactstrap'
import withTable from '../../Components/Common/Table/withTable'
import {RequestOnlineAgentFormRQ} from '../../Hooks/Queries/requestOnlineAgentFormRQ'
import {SentMessageItem} from '../../Components/SentMessage/SentMessageItem'
import SitaApiModal from '../../Components/FwbFhl/SitaApiModal'
const ListMessage = () => {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const [sitaApiModal, setSitaApiModal] = useState(false)
  const [dataSita, setDataSita] = useState({})

  const listRQ = RequestOnlineAgentFormRQ.useGetDataList()(
    false,
    keyword,
    offset,
    limit,
    'SEND',
  )

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onSitaApiClick = (data) => {
    setDataSita(data)
    setSitaApiModal(true)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const SentMessageTable = withTable(
    SentMessageItem,
    'fwb-fhl',
    [
      'NO',
      'ACTION',
      'TYPE DOCUMENT',
      'SHIPPER NAME',
      'CONSIGNEE NAME',
      'CREATED AT',
      'SEND AT',
    ],
    null,
    null,
    onSitaApiClick,
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='List Message Terkirim' />
        <Row>
          <SentMessageTable
            data={listRQ.data}
            isFetching={listRQ.isFetching}
            loading={listRQ.isFetching}
            error={listRQ.error}
            status={listRQ.status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            fetchNextPage={listRQ.fetchNextPage}
            hasNextPage={listRQ.hasNextPage}
            isFetchingNextPage={listRQ.isFetchingNextPage}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <SitaApiModal
        onCloseClick={() => setSitaApiModal((v) => !v)}
        show={sitaApiModal}
        data={dataSita}
      />
    </div>
  )
}

export default ListMessage
