import { useMutation, useQuery } from "@tanstack/react-query";
import { CREATE_TOPUP_URL, LIST_TOPUP_URL, PROFILE_BALANCE_URL, VIEW_TOPUP_URL } from "Helpers/url_helper";
import { apiBearer } from "../../Helpers/api";
import { showSuccess } from "../../Helpers/show_toast";

class TopupRQClass {
  QUERY_KEY = "";
  LIST_URL = "";
  VIEW_URL = function (id) {};
  PROFILE_BALANCE_URL = '';
  CREATE_URL = "";

  constructor(
    QUERY_KEY,
    LIST_URL,
    VIEW_URL,
    PROFILE_BALANCE_URL,
    CREATE_URL
  ) {
    this.QUERY_KEY = QUERY_KEY;
    this.LIST_URL = LIST_URL;
    this.VIEW_URL = VIEW_URL;
    this.PROFILE_BALANCE_URL = PROFILE_BALANCE_URL;
    this.CREATE_URL = CREATE_URL;
  }

  useGetDataList() {
    return (isSearch = false, keyword = "", offset = 0, limit = 10) =>
      useQuery(
        [this.QUERY_KEY, isSearch, keyword, offset, limit],
        async () => {
          const params = {
            page: offset,
            size: limit,
            search: keyword
          };
          console.log(params)
          const res = await apiBearer.get(this.LIST_URL, {params});
          return res.data?.data;
        }
      );
  }

  useGetSaldo() {
    return () =>
      useQuery(
        [this.QUERY_KEY + "_VIEW"],
        async () => {
          const res = await apiBearer.get(this.PROFILE_BALANCE_URL);
          return res.data;
        },
        // {
        //   enabled: id !== undefined,
        // }
      );
  }

  useAddData(navigate) {
    return () =>
      useMutation({
        mutationFn: (newData) => {
          return apiBearer.post(this.CREATE_URL, newData);
        },
        onSuccess: async (data) => {
          // console.log(this.useGetData(data.data.data)())
          showSuccess("New data added successfully");
          navigate('/app/topups')
          // window.location.replace(data.data.data);
        },
        onError: async (error) => {},
      });
  }

  useGetData(id) {
    return () =>
      useQuery(
        [this.QUERY_KEY + "_DETAIL"],
        async () => {
          const res = await apiBearer.get(this.VIEW_URL(id));
          return res.data;
        },
        {
          enabled: id !== undefined,
        }
      );
  }
}

const QUERY_KEY = 'topups';

export const TopupRQ = new TopupRQClass(
  QUERY_KEY,
  LIST_TOPUP_URL, 
  VIEW_TOPUP_URL, 
  PROFILE_BALANCE_URL,
  CREATE_TOPUP_URL, 
)
