import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import {Button, Col, Label, Modal, ModalBody, Row} from 'reactstrap'
import ErrorFieldWrapper from '../Common/ErrorFieldWrapper'
import {yupResolver} from '@hookform/resolvers/yup'
import {useForm} from 'react-hook-form'
import * as Yup from 'yup'
import {toBase64} from '../../Helpers/fileToBase64'
import ImageTextPreview from '../Common/ImageTextPreview'

let idd = 0
const initialDocs = [
  {
    id: idd++,
    doc: '',
  },
]
const RevisionAgentModal = ({
                              show,
                              isLoadingRevision,
                              onCloseClick,
                              onSubmitClick,
                              detailData,
                            }) => {

  const [docs, setDocs] = useState(initialDocs)

  const validation = Yup.object().shape({
    docs: Yup.mixed(),
    revision: Yup.string().required('Keterangan tidak boleh kosong'),
  })

  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      docs: [],
      revision: '',
    },
  }
  const {handleSubmit, formState, register, reset, control} =
    useForm(formOptions)
  const {errors} = formState

  const _onSubmit = async (values) => {
    const formData = values
    const docsFiles = docs.map((doc) => ({
      id:'',
      doc: doc.doc
    }))
    formData.files = docsFiles
    onSubmitClick(formData)
  }

  const onChangeDocs = async (id, file) => {
    try {
      const base64File = await toBase64(file)
      setDocs((prevDocs) =>
        prevDocs.map((prevDoc) => {
          if (prevDoc.id === id) {
            return {...prevDoc, doc: base64File}
          }
          return prevDoc
        }),
      )
    } catch (error) {
      const fileEl = document.getElementById(id)
      if (fileEl) {
        fileEl.value = []
      }
    }
  }

  const onAddDocInputs = () => {
    setDocs([
      ...docs,
      {
        id: idd++,
        doc: '',
      },
    ])
  }

  const onRemoveDocInputs = (id) => {
    setDocs((prevs) => prevs.filter((prev) => prev.id !== id))
  }

  useEffect(() => {
      if (detailData) {
        reset({
          revision: detailData.upload?.description,
        })
      }
  }, [detailData, reset])

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} backdrop="static">
      <ModalBody className='py-3 px-5'>
        <div className='mt-2 text-center'>
          <div className='mt-2 pt-2 fs-15'>
            <p className='fw-bold mb-0'>
              Revision
            </p>
          </div>
        </div>
        <form onSubmit={handleSubmit(_onSubmit)}>
          <div className='mt-2'>
            <ErrorFieldWrapper
              error={errors.revision}
              message={errors.revision?.message}
            >
              <Label htmlFor='revision'>
                Keterangan <span className='mandatory'>*</span>
              </Label>
              <textarea className='form-control' {...register('revision')} />
            </ErrorFieldWrapper>
          </div>
          <Row className='mt-3'>
            {docs.map((doc, i) => (
              <Col lg={12} sm={12} key={doc.id}>
                <div>
                  <div className='d-flex justify-content-between'>
                    <Label>
                      File Upload{' '}
                      <span className='mandatory'>*</span>
                    </Label>
                    {doc?.url && typeof doc?.id !== 'number' ? (
                      <ImageTextPreview link={doc.url} />
                    ) : null}
                  </div>
                  <div className='mb-3 d-flex align-items-end'>
                    <input
                      type='file'
                      className='form-control'
                      onChange={(e) =>
                        onChangeDocs(doc.id, e.target.files[0])
                      }
                    />
                    {i >= 1 ? (
                      <button
                        className='btn mx-1 btn-danger'
                        onClick={() => onRemoveDocInputs(doc.id)}
                        type='button'
                      >
                        <i className='ri-subtract-fill '></i>
                      </button>
                    ) : null}
                    <button
                      className='btn mx-1 btn-success'
                      onClick={onAddDocInputs}
                      type='button'
                    >
                      <i className='ri-add-fill '></i>
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>
            <Button
              type='button'
              color='btn btn-light'
              className='btn w-sm'
              data-bs-dismiss='modal'
              onClick={onCloseClick}
            >
              Cancel
            </Button>
            <Button
              type='submit'
              color='btn btn-primary'
              className='btn w-sm'
              disabled={isLoadingRevision}
            >
              {isLoadingRevision
                ? 'Loading...'
                : 'Submit'}
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  )
}

RevisionAgentModal.propTypes = {
  onCloseClick: PropTypes.func,
  onSubmitClick: PropTypes.func,
  show: PropTypes.any,
}

export default RevisionAgentModal