import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useFieldArray, useForm} from 'react-hook-form'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {MasterAWBConsignmentDetails} from 'Components/FwbFhl/Fhl/MasterAWBConsignmentDetails'
import {HouseWaybillSummaryDetails} from 'Components/FwbFhl/Fhl/HouseWaybillSummaryDetails'
import {FreeTextDescriptionOfGoods} from 'Components/FwbFhl/Fhl/FreeTextDescriptionOfGoods'
import {HarmonisedTariffScheduleInformation} from 'Components/FwbFhl/Fhl/HarmonisedTariffScheduleInformation'
import {OtherCustomsInformation} from 'Components/FwbFhl/Fhl/OtherCustomsInformation'
import {ShipperConsigneeNameAndAddress} from 'Components/FwbFhl/Fhl/ShipperConsigneeNameAndAddress'
import {ChargeDeclaration} from 'Components/FwbFhl/Fhl/ChargeDeclaration'
import {RequestOnlineAgentFormRQ} from '../../../Hooks/Queries/requestOnlineAgentFormRQ'
import {
  ADMIN,
  ONLINE_AGENT_ORDER_STATUS,
  ONLINE_AGENT_PAYMENT_STATUS,
  ONLINE_AGENT_TYPE_DOCUMENT,
} from '../../../Helpers/options'
import {useSelector} from 'react-redux'
import RevisionModal from '../../../Components/RequestAgentOnlineForm/RevisionModal'
import {apiBearer} from '../../../Helpers/api'
import {
  REVISION_REQUEST_ONLINE_AGENT_URL,
  SEND_REQUEST_ONLINE_AGENT_URL,
} from '../../../Helpers/url_helper'
import {showError} from '../../../Helpers/show_toast'
import SuccessModal from '../../../Components/Common/SuccessModal'

export default function DetailFhl() {
  const {id} = useParams()
  const navigate = useNavigate()
  const {profile} = useSelector((state) => state.auth)
  const [isAdmin, setIsAdmin] = useState(false)
  const [revisionModal, setRevisionModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [isLoadingRevision, setIsLoadingRevision] = useState(false)
  const [isLoadingProcess, setIsLoadingProcess] = useState(false)

  useEffect(() => {
    setIsAdmin(profile.role === ADMIN)
  }, [navigate, profile.is_approved, profile.role])

  const validation = Yup.object().shape({})
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {},
  }
  const {
    formState,
    reset,
    control,
    getValues,
    setValue,
    clearErrors,
    setError,
  } = useForm(formOptions)

  const {fields: fieldFreeTexts, append: appendFreeText, remove: removeFreeText} = useFieldArray({
    control,
    name: 'freeText',
  })

  const {fields: fieldHarmonised, append: appendHarmonised, remove: removeHarmonised} = useFieldArray({
    control,
    name: 'harmonisedCommodityCode',
  })
  const {fields: fieldsOtherCustomInfo, append: appendOtherCustomInfo, remove: removeOtherCustomInfo} = useFieldArray({
    control,
    name: 'otherCustomInfo',
  })
  const [selectedSpecialCodes, setSelectedSpecialCodes] = useState([])
  const {errors} = formState

  const validateType = (detailData) => {
    if (detailData?.data.type === ONLINE_AGENT_TYPE_DOCUMENT.UPLOAD && !detailData?.data?.data?.hwb) {
      navigate(`/app/upload/detail/${detailData.data.id}`)
    }
    if (detailData?.data.type === ONLINE_AGENT_TYPE_DOCUMENT.FWB) {
      navigate(`/app/fwb/detail/${detailData.data.id}`)
    }
  }
  const setValueEdit = (detailData) => {
    const fhl = detailData?.data?.data
    reset({
      awbSerialNumber: fhl?.awb?.air_way_bill_no ?? '',
      airlinePrefix: fhl?.awb?.air_line ?? '',
      awbOrigin: fhl?.awb?.origin_code ?? '',
      awbDestination: fhl?.awb?.destination_code ?? '',
      awbShipmentDescriptionCode: fhl?.awb?.shipment_description_code ?? '',
      awbNumberOfPieces: fhl?.awb?.number_of_pieces ?? '',
      awbWeightCode: fhl?.awb?.weight_code ?? '',
      awbWeight: fhl?.awb?.weight ?? '',

      hwbSerialNumber: fhl?.hwb?.origin_code ?? '',
      hwbOrigin: fhl?.hwb?.origin_code ?? '',
      hwbDestination: fhl?.hwb?.destination_code ?? '',
      hwbNumberOfPieces: fhl?.hwb?.number_of_pieces ?? '',
      hwbWeightCode: fhl?.hwb?.weight_code ?? '',
      hwbWeight: fhl?.hwb?.weight ?? '',
      hwbSlac: fhl?.hwb?.slac ?? '',
      hwbManifestDescriptionOfGoods: fhl?.hwb?.manifest_description_of_goods ?? '',
      hwbSpecialHandlingCode: fhl?.hwb?.special_handling_code ?? '',

      shipperStreetAddress: fhl?.shipper?.street_address ?? '',
      shipperStreetAddress2: fhl?.shipper?.street_address2 ?? '',
      shipperName: fhl?.shipper?.name ?? '',
      shipperName2: fhl?.shipper?.name2 ?? '',
      shipperCity: fhl?.shipper?.city ?? '',
      shipperProvince: fhl?.shipper?.province ?? '',
      shipperCountryCode: fhl?.shipper?.country_code ?? '',
      shipperPostalCode: fhl?.shipper?.postal_code ?? '',
      shipperContactIdentifier: fhl?.shipper?.contact_identifier ?? '',
      shipperContactNumber: fhl?.shipper?.contact_number ?? '',

      consigneeStreetAddress: fhl?.consignee?.street_address ?? '',
      consigneeStreetAddress2: fhl?.consignee?.street_address2 ?? '',
      consigneeName: fhl?.consignee?.name ?? '',
      consigneeName2: fhl?.consignee?.name2 ?? '',
      consigneeCity: fhl?.consignee?.city ?? '',
      consigneeProvince: fhl?.consignee?.province ?? '',
      consigneeCountryCode: fhl?.consignee?.country_code ?? '',
      consigneePostalCode: fhl?.consignee?.postal_code ?? '',
      consigneeContactIdentifier: fhl?.consignee?.contact_identifier ?? '',
      consigneeContactNumber: fhl?.consignee?.contact_number ?? '',

      otherCustomInfo: fhl?.other_information?.map(oc => ({
        countryCode: oc?.iso_country_code ?? '',
        customInfoIdentifier: oc?.custom_info_identifier ?? '',
        informationIdentifier: oc?.information_identifier ?? '',
        suplementaryCustomInformation: oc?.supplementary_information ?? '',
      })) ?? [
        {
          countryCode: '',
          customInfoIdentifier: '',
          informationIdentifier: '',
          suplementaryCustomInformation: '',
        },
      ],

      currencyCode: fhl?.charge_declarations?.iso_currency_code ?? '',
      valuation: fhl?.charge_declarations?.valuation ?? '',
      otherCharges: fhl?.charge_declarations?.other_charges ?? '',
      declaredValueForCarriage: fhl?.charge_declarations?.declared_value_for_carriage ?? '',
      declaredValueForCustoms: fhl?.charge_declarations?.declared_value_for_customs ?? '',
      declaredValueForInsurance: fhl?.charge_declarations?.declared_value_for_insurance ?? '',

      freeText: fhl?.description_of_goods?.map(value => ({value})),
      harmonisedCommodityCode: fhl?.harmonised_tariff_schedule?.map(value => ({value})),

    })

    setSelectedSpecialCodes(fhl?.hwb?.special_handling_code ?? [])
  }

  const {
    data: detailData,
    error,
    isFetching,
    status,
  } = RequestOnlineAgentFormRQ.useGetData(id)()
  useEffect(() => {
    if (!isFetching && status === 'success') {
      if (detailData) {
        validateType(detailData)
        setValueEdit(detailData)
      }
    }
    if (!isFetching && status === 'error') {
      console.error(error)
    }
  }, [detailData, reset])


  const navigateToList = () => {
    if (isAdmin) {
      navigate('/app/request-agent-online-form')
    } else {
      navigate('/app/fwb-fhl')
    }
  }

  const onRevisionSubmit = async (data) => {
    setIsLoadingRevision(true)

    apiBearer.patch(REVISION_REQUEST_ONLINE_AGENT_URL(id), data).then((result) => {
      onCloseRevision()
      if (result?.data?.data) {
        setSuccessModal(true)
      } else {
        showError('Error while saving')
      }
    }).catch((err) => {
      setIsLoadingRevision(false)
    })
  }

  const onProcessSubmit = async () => {
    setIsLoadingProcess(true)
    apiBearer.patch(SEND_REQUEST_ONLINE_AGENT_URL(id), {}).then((result) => {
      setIsLoadingProcess(false)
      if (result?.data?.data) {
        setSuccessModal(true)
      } else {
        showError('Error while saving')
      }
    }).catch((err) => {
      setIsLoadingProcess(false)
    })
  }

  const onClickRevision = async (e) => {
    setRevisionModal(true)
  }

  const onCloseRevision = () => {
    setRevisionModal(false)
    setIsLoadingRevision(false)
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='FHL' pageTitle='FHL' />
        <Row>
          <Col lg={12}>
            <form onSubmit={() => {
            }}>
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    Detail FHL
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <MasterAWBConsignmentDetails
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    clearErrors={clearErrors}
                    airportList={[]}
                    setError={setError}
                    isDetail={true}
                    isAdmin={isAdmin}
                  />
                  <HouseWaybillSummaryDetails
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    clearErrors={clearErrors}
                    airportList={[]}
                    handlingCodeList={[]}
                    selected={selectedSpecialCodes}
                    isDetail={true}
                    setError={setError}
                  />
                  <FreeTextDescriptionOfGoods
                    errors={errors}
                    control={control}
                    fields={fieldFreeTexts}
                    append={appendFreeText}
                    remove={removeFreeText}
                    isDetail={true}
                  />
                  <HarmonisedTariffScheduleInformation
                    errors={errors}
                    control={control}
                    fields={fieldHarmonised}
                    append={appendHarmonised}
                    remove={removeHarmonised}
                    isDetail={true}
                  />
                  <OtherCustomsInformation
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    clearErrors={clearErrors}
                    countryCodeList={[]}
                    customInfoList={[]}
                    informationList={[]}
                    remove={removeOtherCustomInfo}
                    append={appendOtherCustomInfo}
                    fields={fieldsOtherCustomInfo}
                    isDetail={true}
                  />
                  <ShipperConsigneeNameAndAddress
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    isDetail={true}
                  />
                  <ChargeDeclaration
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    currencyList={[]}
                    isDetail={true}
                  />
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-center gap-3'>
                  <Button
                    type='button'
                    className='btn btn-light'
                    onClick={navigateToList}
                    style={{
                      width: 120,
                      color: '#69809A',
                      backgroundColor: '#E7EBEF',
                    }}
                  >
                    Cancel
                  </Button>
                  {
                    (detailData?.data.order_status !== ONLINE_AGENT_ORDER_STATUS.SEND && detailData?.data.payment_status === ONLINE_AGENT_PAYMENT_STATUS.PAID) && (
                      <>
                        <Button
                          type='button'
                          color='primary'
                          className='btn btn-primary'
                          onClick={onProcessSubmit}
                          disabled={isLoadingProcess}
                          style={{
                            width: 120,
                          }}
                        >
                          {
                            isLoadingProcess
                              ? 'Loading...'
                              : 'Send'
                          }
                        </Button>
                        <Button color='btn btn-warning' type='button' onClick={onClickRevision} style={{
                          width: 120,
                        }}>
                          Revision
                        </Button>
                      </>
                    )
                  }
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>

      <RevisionModal
        onCloseClick={onCloseRevision}
        show={revisionModal}
        isLoadingRevision={isLoadingRevision}
        onSubmitClick={onRevisionSubmit}
      />

      <SuccessModal
        onCloseClick={navigateToList}
        show={successModal}
        text='Data berhasil Terkirim'
      />
    </div>
  )
}
