import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {Controller} from 'react-hook-form'
import {Button, Col, Collapse, Label, Row} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import React, {useState} from 'react'
import ListMasterModal from '../ListMasterModal'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'
import InputPopupModal from '../../../Helpers/InputPopupModal'

export const OtherCustomsInformation = ({
                                          errors,
                                          control,
                                          clearErrors,
                                          setValue,
                                          countryCodeList,
                                          customInfoList,
                                          informationList,
                                          fields, append, remove,
                                          isDetail = false,
                                        }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [fieldActive, setFieldActive] = useState('')
  const [showPopup, setShowPopup] = useState({
    countryCode: false,
    customInfoIdentifier: false,
    informationIdentifier: false,
  })

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const openModal = (type, field) => {
    setFieldActive(field)
    setShowPopup({...showPopup, [type]: true})
  }

  const onConfirm = (type, value) => {
    clearErrors(fieldActive)
    setValue(fieldActive, value)
    setShowPopup({...showPopup, [type]: false})
  }

  const checkValidData = (field, data) => {
    const lastWord = field.split('.').pop();

    let isExists = mapDataModal[lastWord].data.find(a => a.code === data)
    if (!isExists) {
      setValue(field, '')
      openModal(lastWord, field)
    } else {
      setValue(field, data)
    }
  }

  const mapDataModal = {
    countryCode: {
      title: 'Country Code',
      mapData: {
        code: 'Country Code',
        name: 'Name',
      },
      data: countryCodeList,
    },
    customInfoIdentifier: {
      title: 'Custom Information Identifier',
      mapData: {
        code: 'Code',
        description: 'Description',
      },
      data: customInfoList,
    },
    informationIdentifier: {
      title: 'Information Identifier',
      mapData: {
        code: 'Code',
        description: 'Description',
      },
      data: informationList,
    },
  }


  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span>  Other Customs Information</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        {fields.map((item, index) => (
          <Row key={item.id} className='gy-2 ps-1 mb-2'>
            <Col lg={12} className="text-end">
              {!isDetail && index >0 ? (
                <ActionButton onClick={() => remove(index)} action='remove' />
              ) : null}
            </Col>
            <Col
              lg={6}
              sm={12}
              className='d-flex flex-column'
              style={{gap: '10px'}}
            >
              <ErrorFieldWrapper
                error={errors.otherCustomInfo?.[index]?.countryCode}
                message={errors.otherCustomInfo?.[index]?.countryCode?.message}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label className='form-label m-0 w-25'>
                    ISO Country Code
                  </Label>
                  <Controller
                    name={`otherCustomInfo.${index}.countryCode`}
                    type='text'
                    control={control}
                    render={({field}) => (
                      <div className='d-flex align-items-stretch gap-1' style={{width: '67.5%'}}>
                        <InputPopupModal
                          fieldName={`otherCustomInfo.${index}.countryCode`}
                          // checkValidData={checkValidData}
                          {...field}
                          value={field.value}
                          maxLength={2}
                          disabled={isDetail}
                        />
                        {
                          !isDetail && <ActionButton onClick={() =>
                            openModal('countryCode', `otherCustomInfo.${index}.countryCode`)
                          } />
                        }
                      </div>
                    )}
                  />
                </div>
              </ErrorFieldWrapper>

              <ErrorFieldWrapper
                error={errors.otherCustomInfo?.[index]?.informationIdentifier}
                message={errors.otherCustomInfo?.[index]?.informationIdentifier?.message}
              >
                <div className='d-flex align-items-center justify-content-between'>

                  <Label className='form-label m-0 w-25'>
                    Information Identifier
                  </Label>
                  <Controller
                    name={`otherCustomInfo.${index}.informationIdentifier`}
                    type='text'
                    control={control}
                    render={({field}) => (
                      <div className='d-flex align-items-stretch gap-1' style={{width: '67.5%'}}>
                        <InputPopupModal
                          fieldName={`otherCustomInfo.${index}.informationIdentifier`}
                          // checkValidData={checkValidData}
                          {...field}
                          value={field.value}
                          maxLength={3}
                          disabled={isDetail}
                        />
                        {
                          !isDetail && <ActionButton onClick={() =>
                            openModal('informationIdentifier', `otherCustomInfo.${index}.informationIdentifier`)
                          } />
                        }
                      </div>
                    )}
                  />
                </div>
              </ErrorFieldWrapper>
            </Col>
            <Col
              lg={6}
              sm={12}
              className='d-flex flex-column'
              style={{gap: '10px'}}
            >
              <ErrorFieldWrapper
                error={errors.otherCustomInfo?.[index]?.customInfoIdentifier}
                message={errors.otherCustomInfo?.[index]?.customInfoIdentifier?.message}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label className='form-label m-0 w-25'>
                    Custom Information Identifier
                  </Label>
                  <Controller
                    name={`otherCustomInfo.${index}.customInfoIdentifier`}
                    type='text'
                    control={control}
                    render={({field}) => (
                      <div className='d-flex align-items-stretch gap-1' style={{width: '67.5%'}}>
                        <InputPopupModal
                          fieldName={`otherCustomInfo.${index}.customInfoIdentifier`}
                          // checkValidData={checkValidData}
                          {...field}
                          value={field.value}
                          maxLength={2}
                          disabled={isDetail}
                        />
                        {
                          !isDetail && <ActionButton onClick={() =>
                            openModal('customInfoIdentifier', `otherCustomInfo.${index}.customInfoIdentifier`)
                          } />
                        }
                      </div>
                    )}
                  />
                </div>
              </ErrorFieldWrapper>

              <ErrorFieldWrapper
                error={errors.otherCustomInfo?.[index]?.supplementaryCustomInformation}
                message={errors.otherCustomInfo?.[index]?.supplementaryCustomInformation?.message}

              >
                <div className='d-flex align-items-center justify-content-between'>

                  <Label htmlFor='supplementaryCustomInformation' className='form-label m-0 w-25'>
                    Supplementary Custom Information
                  </Label>
                  <Controller
                    name={`otherCustomInfo.${index}.supplementaryCustomInformation`}
                    type='text'
                    control={control}
                    render={({field}) => (
                      <InputTextFhlFwb
                        {...field}
                        value={field.value}
                        style={{width: '67.5%'}}
                        disabled={isDetail}
                        maxLength={35}
                      />
                    )}
                  />
                </div>
              </ErrorFieldWrapper>
            </Col>
          </Row>
        ))}
        {
          !isDetail && (
            <Row className='gy-2 ps-1'>
              <Col lg={4} md={6} sm={12}>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='m-0 w-50'></div>
                  <div className='w-50'>
                    <Button
                      type='button'
                      className='btn btn-light'
                      style={{width: 120, color: '#69809A', backgroundColor: '#E7EBEF'}}
                      onClick={() => append({
                        countryCode: '',
                        informationIdentifier: '',
                        customInfoIdentifier: '',
                        supplementaryCustomInformation: '',
                      })}
                    >
                      Add Row
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          )
        }
      </Collapse>

      {
        !isDetail &&
        Object.keys(showPopup).map((key) => {
          return (
            <ListMasterModal
              key={key}
              field={key}
              show={showPopup[key]}
              onCloseClick={() => {
                setShowPopup((v) => ({...v, [key]: !showPopup[key]}))
              }}
              onConfirm={onConfirm}
              dataList={mapDataModal[key].data}
              withFilterAlphabet={false}
              mapData={mapDataModal[key].mapData}
              title={mapDataModal[key].title}
            />
          )
        })
      }
    </div>
  )
}
