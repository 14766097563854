import {Container, Row} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {useState} from 'react'
import DeleteModal from 'Components/Common/DeleteModal'
import {ConsigneeRQ} from 'Hooks/Queries'
import withTable from 'Components/Common/Table/withTable'
import {ConsigneeItem} from 'Components/Master/ConsigneeItem'
import {useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {DATA_LIMIT} from 'configs'

export default function ListConsignee() {
  const [deleteConsigneeId, setDeleteConsigneeId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = ConsigneeRQ.useGetDataList()(false, keyword, offset, limit)

  const useQuery = useQueryClient()
  const navigate = useNavigate()

  const onDeleteSuccess = (key) => {
    setDeleteModal(false)
    setDeleteConsigneeId(null)
    useQuery.invalidateQueries({queryKey: [key]})
    refetch()
  }
  const {mutate: deleteTruck} = ConsigneeRQ.useDeleteData(onDeleteSuccess)()

  const onDeleteClick = (id) => {
    setDeleteModal(true)
    setDeleteConsigneeId(id)
  }

  const onDeleteConfirm = () => {
    deleteTruck(deleteConsigneeId)
  }

  const onAddButtonClicked = () => {
    navigate('/app/consignee/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const ConsigneeTable = withTable(
    ConsigneeItem,
    'consignee',
    ['NO', 'Action', 'Nama', 'PIC Name', 'PIC Email', 'Address', 'STATUS'],
    onDeleteClick,
    onAddButtonClicked,
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Consignees' />

        <Row>
          <ConsigneeTable
            data={data}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            loading={isFetching}
            error={error}
            status={status}
            fetchNextPage={fetchNextPage}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  )
}
