import React from 'react'
import {TabPane} from 'reactstrap'
import {SpecialHandlingCode} from './SpecialHandlingCode'
import {OtherCustomsInformation} from './OtherCustomsInformation'
import {NominatedHandlingParty} from './NominatedHandlingParty'
import {ShipmentReferenceInformation} from './ShipmentReferenceInformation'
import {OtherParticipantInformation} from './OtherParticipantInformation'

export const Step5Fwb = ({
                           errors,
                           control,
                           setValue,
                           clearErrors,
                           handlingCodeList,
                           selected,
                           getValues,
                           countryCodeList,
                           customInfoList,
                           informationList,
                           airportList,
                           fieldsOtherParticipantOffice,
                           appendOtherParticipantOffice,
                           removeOtherParticipantOffice,
                           fieldsOtherCustomInfo,
                           appendOtherCustomInfo,
                           removeOtherCustomInfo,
                           setError,
                           isDetail = false,
                         }) => {

  return (
    <TabPane tabId={5}>
      <SpecialHandlingCode
        errors={errors}
        control={control}
        setValue={setValue}
        clearErrors={clearErrors}
        handlingCodeList={handlingCodeList}
        selected={selected}
        isDetail={isDetail}
        setError={setError}
      />
      <NominatedHandlingParty
        errors={errors}
        control={control}
        selected={selected}
        isDetail={isDetail}
      />
      <ShipmentReferenceInformation
        errors={errors}
        control={control}
        selected={selected}
        isDetail={isDetail}
      />
      <OtherParticipantInformation
        errors={errors}
        control={control}
        selected={selected}
        isDetail={isDetail}
        clearErrors={clearErrors}
        setValue={setValue}
        airportList={airportList}
        fields={fieldsOtherParticipantOffice}
        append={appendOtherParticipantOffice}
        remove={removeOtherParticipantOffice}
      />
      <OtherCustomsInformation
        errors={errors}
        control={control}
        setValue={setValue}
        getValues={getValues}
        clearErrors={clearErrors}
        fields={fieldsOtherCustomInfo}
        append={appendOtherCustomInfo}
        remove={removeOtherCustomInfo}
        countryCodeList={countryCodeList}
        customInfoList={customInfoList}
        informationList={informationList}
        isDetail={isDetail}
      />
    </TabPane>
  )
}
