import React, {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import ErrorFieldWrapper from '../../../Components/Common/ErrorFieldWrapper'
import {airlineRQ} from 'Hooks/Queries'
import {Controller, useForm} from 'react-hook-form'
import Select from 'react-select'

export default function EditCreateAirline(props) {
  const {id} = useParams()
  const navigate = useNavigate()
  const [selectedFhlVersion, setSelectedFhlVersion] = useState('')

  const validation = Yup.object().shape({
    code: Yup.string()
      .required('Code tidak boleh kosong')
      .max(3, 'Code maksimal 3 karakter'),
    sitatexPrefix: Yup.string()
      .required('Sitatex prefix tidak boleh kosong')
      .max(3, 'Sitatex prefix maksimal 3 karakter'),
    name: Yup.string().required('Name tidak boleh kosong'),
    fhlVersion: Yup.string().required('Fhl version tidak boleh kosong'),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      code: '',
      sitatexPrefix: '',
      name: '',
      fhlVersion: '',
    },
  }
  const {
    handleSubmit,
    formState,
    register,
    reset,
    control,
    getValues,
  } = useForm(formOptions)
  const {errors} = formState

  const {mutate: updateAirline, isLoading: isLoadingUpdate} =
    airlineRQ.useUpdateData(navigate)()

  const _updateAirline = (values) => {
    const body = {
      id: id,
      code: values.code,
      sitatex_prefix: values.sitatexPrefix,
      name: values.name,
      fhl_version_enum: values.fhlVersion,
    }
    updateAirline(body)
    console.log(body)
  }

  const {mutate: addAirline, isLoading: isLoadingCreate, } =
    airlineRQ.useAddData(navigate)()

  const _createAirline = async (values) => {
    const body = {
      code: values.code,
      sitatex_prefix: values.sitatexPrefix,
      name: values.name,
      fhl_version_enum: values.fhlVersion,
    }
    console.log(body)
    addAirline(body)
  }

  const {data, error, isFetching, status} = airlineRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      reset({
        code: data.data?.code ?? '',
        sitatexPrefix: data.data?.sitatex_prefix ?? '',
        name: data.data?.name ?? '',
        fhlVersion: data.data?.fhl_version ?? '',
      })

      setSelectedFhlVersion({
        label: data.data?.fhl_version.replace(/\D/g, '') ?? '',
        value: data.data?.fhl_version ?? '',
      })
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
  }, [status, isFetching, data, reset, error])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Airline' pageTitle='Airline' />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(id ? _updateAirline : _createAirline)}>
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Update' : 'Create'} Airlines
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.code}
                        message={errors.code?.message}
                      >
                        <Label htmlFor='code' className='form-label'>
                          CODE
                        </Label>
                        <Controller
                          id='code'
                          name='code'
                          type='text'
                          control={control}
                          render={({field}) => (
                            <Input
                              {...field}
                              value={field.value}
                              maxLength={3}
                              placeholder='Code'
                          
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.sitatexPrefix}
                        message={errors.sitatexPrefix?.message}
                      >
                        <Label htmlFor='sitatexPrefix' className='form-label'>
                          SITATEX PREFIX
                        </Label>
                        <Controller
                          id='sitatexPrefix'
                          name='sitatexPrefix'
                          type='text'
                          control={control}
                          render={({field}) => (
                            <Input
                              {...field}
                              value={field.value}
                              maxLength={3}
                              placeholder='Sitatex Prefix'
                          
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.name}
                        message={errors.name?.message}
                      >
                        <Label htmlFor='name' className='form-label'>
                          NAME
                        </Label>
                        <Controller
                          id='name'
                          name='name'
                          type='number'
                          control={control}
                          render={({field}) => (
                            <Input
                              {...field}
                              value={field.value}
                              placeholder='Name'
                          
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.fhlVersion}
                        message={errors.fhlVersion?.message}
                      >
                        <Label htmlFor='fhlVersion' className='form-label'>
                          FHL VERSION
                        </Label>
                        <Controller
                          id='fhlVersion'
                          name='fhlVersion'
                          type='text'
                          control={control}
                          render={({field: {onChange, ...field}}) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={selectedFhlVersion}
                              options={[
                                {
                                  label: 4,
                                  value: 'FHL_V4',
                                },
                                {
                                  label: 5,
                                  value: 'FHL_V5',
                                },
                              ]}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value)
                                setSelectedFhlVersion(e)
                              }}
                              placeholder='FHL Version'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/airline' className='btn btn-danger'>
                    Cancel
                  </Link>
                  <Button
                    color='success'
                    type='submit'
                    disabled={isLoadingCreate || isLoadingUpdate}
                  >
                    {isLoadingCreate || isLoadingUpdate
                      ? 'Loading...'
                      : id
                      ? 'Update'
                      : 'Create'}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
