import React, {useEffect, useState} from 'react'
import {Input, ModalBody, Table} from 'reactstrap'
import OptionModal from './OptionModal'
import classNames from 'classnames'

const ListMasterModal = ({
  field,
  show,
  onCloseClick,
  onClosed,
  onConfirm,
  title,
  dataList,
  withFilterAlphabet = true,
  withSearch = true,
  mapData = {
    code: 'Code',
    name: 'Name',
  },
}) => {
  const [displayedDataList, setDisplayedDataList] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [activeLink, setActiveLink] = useState('')

  const filterData = (value, isSearch) => {
    if (!isSearch) {
      setActiveLink(value)
    } else {
      setActiveLink('')
    }
    const keys = Object.keys(mapData)
    let filteredData = []
    if (isSearch) {
      filteredData = dataList.filter(
        (item) =>
          item?.[keys[0]]?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item?.[keys[1]]?.toLowerCase()?.includes(value?.toLowerCase()),
      )
    } else {
      setSearchValue('')
      filteredData = dataList.filter(
        (item) =>
          item?.[keys[0]]?.toUpperCase()?.startsWith(value) ||
          item?.[keys[1]]?.toUpperCase()?.startsWith(value),
      )
    }

    setDisplayedDataList(filteredData)
  }

  const getCodeKey = () => {
    const keys = Object.keys(mapData)
    if (keys.includes('sitatex_prefix')) {
      return 'sitatex_prefix'
    }
    return 'code'
  }

  const renderData = () => {
    const handleClick = (value) => {
      onConfirm(field, value)
    }
    const keys = Object.keys(mapData)
    const titles = Object.values(mapData)
    return (
      <div>
        <Table bordered>
          <thead>
            <tr>
              <th>{titles[0]}</th>
              <th>{titles[1]}</th>
            </tr>
          </thead>
          <tbody>
            {displayedDataList?.map((item) => (
              <tr
                key={item.id}
                onClick={() => handleClick(item?.[getCodeKey()])}
                className='cursor-pointer'
              >
                <td>{item?.[keys[0]] ?? '-'}</td>
                <td>{item?.[keys[1]] ?? '-'}</td>
              </tr>
            ))}
            {displayedDataList.length < 1 && (
              <tr>
                <td colSpan={2} className='text-center'>
                  <span className='text-danger'>No Data</span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }

  useEffect(() => {
    setDisplayedDataList(dataList)
  }, [dataList, show])

  const renderHeader = () => {
    return (
      <div>
        <div className='text-center'>
          {withFilterAlphabet && (
            <div className='d-flex flex-column align-items-start px-4'>
              <div className='align-self-start'>Go To:</div>
              <div>
                {[
                  'A',
                  'B',
                  'C',
                  'D',
                  'E',
                  'F',
                  'G',
                  'H',
                  'I',
                  'J',
                  'K',
                  'L',
                  'M',
                  'N',
                  'O',
                  'P',
                  'Q',
                  'R',
                  'S',
                  'T',
                  'U',
                  'V',
                  'W',
                  'X',
                  'Y',
                  'Z',
                ].map((item) => (
                  <span
                    role='button'
                    onClick={() => filterData(item)}
                    key={item}
                    className={classNames(
                      {
                        'fw-bold text-decoration-underline':
                          activeLink === item,
                      },
                      'text-primary me-2 nav-underline',
                    )}
                  >
                    {item}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
        {withSearch && (
          <div className='d-flex flex-column align-items-end mt-3 px-3'>
            <Input
              className='w-50'
              placeholder='Search...'
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  filterData(searchValue, true)
                }
              }}
            />
          </div>
        )}
      </div>
    )
  }
  return (
    <OptionModal
      show={show}
      onCloseClick={onCloseClick}
      onClosed={onClosed}
      title={title}
      header={renderHeader()}
    >
      <ModalBody
        className={'p-0'}
        style={{maxHeight: '50dvh', minHeight: '50dvh', overflowY: 'auto'}}
      >
        {renderData()}
      </ModalBody>
    </OptionModal>
  )
}

export default ListMasterModal
