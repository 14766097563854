import { forwardRef } from "react";
import { Input } from "reactstrap";

const InputTextFhlFwb = forwardRef(({ alpha = false, slant = false, onChange, ...props }, ref) => {
  const handleChange = (e) => {
    let value = e.target.value.toUpperCase(); // Mengubah ke uppercase

    if (alpha) {
      value = value.replace(/[^A-Za-z]/g, "");
    } else if(slant) {
      value = value.replace(/[^a-zA-Z0-9.\-/ ]/g, "");
    } else{
      value = value.replace(/[^a-zA-Z0-9.\- ]/g, "");

    }

    if (onChange) {
      onChange(value);
    }
  };

  return <Input {...props} ref={ref} onChange={handleChange} />;
});

export default InputTextFhlFwb;
