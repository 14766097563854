import React from 'react'
import {Container, Row} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import {useState} from 'react'
import DeleteModal from '../../Components/Common/DeleteModal'
import {UserRQ} from '../../Hooks/Queries'
import {SubscriberItem} from '../../Components/Subscriber/Item'
import withTable from '../../Components/Common/Table/withTable'
import {useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {DATA_LIMIT} from '../../configs'

export default function Subscriber() {
  const [deleteSubscriberId, setDeleteSubscriberId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = UserRQ.useGetDataList()(false, keyword, offset, limit)

  const useQuery = useQueryClient()
  const navigate = useNavigate()

  const onDeleteSuccess = (key) => {
    setDeleteModal(false)
    setDeleteSubscriberId(null)
    useQuery.invalidateQueries({queryKey: [key]})
    refetch()
  }
  const {mutate: deleteSubscriber} = UserRQ.useDeleteData(onDeleteSuccess)()

  const onDeleteClick = (id) => {
    setDeleteModal(true)
    setDeleteSubscriberId(id)
  }

  const onDeleteConfirm = () => {
    deleteSubscriber(deleteSubscriberId)
  }

  const onAddButtonClicked = () => {
    navigate('/app/users/create')
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const SubscriberTable = withTable(
    SubscriberItem,
    'users',
    ['NO', 'Action', 'Username', 'Nama', 'Email', 'Role', 'Status'],
    onDeleteClick,
    onAddButtonClicked,
  )

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='User' pageTitle='User' />
        <Row>
          <SubscriberTable
            data={data}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            loading={isFetching}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            error={error}
            status={status}
            fetchNextPage={fetchNextPage}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  )
}
