import PropTypes from "prop-types";
import React from "react";
import {Button, Modal, ModalBody} from 'reactstrap'
import {formatNumber} from '../../Helpers/number'
import moment from 'moment/moment'

const SuccessPaymentModal = ({
  show,
  onCloseClick,
  data = {},
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <img
            alt=''
            src='/assets/icons/check.svg'
            className='me-1'
          />
          <div className="mt-2 pt-2 fs-15">
            <p className="fw-bold mb-0">
              Terima Kasih <br/>Pembayaran Berhasil
            </p>
            <p className="fs-6 fw-normal mb-0 mt-3">
              Pembayaran menggunakan Saldo berhasil dilakukan
            </p>
            <p className="fs-6 fw-normal mb-0 mt-3">
              Waktu Pembayaran
            </p>
            <p className="fs-6 fw-bold mb-0">
              {moment(data.date).format('DD MMMM YYYY, HH:mm')}
            </p>
            <p className="fs-6 fw-normal mb-0 mt-3">
              Jumlah Pembayaran
            </p>
            <p className="fs-6 fw-bold mb-0">
              Rp. { data?.amount || Number(data?.amount) === 0 ? formatNumber(data?.amount) : '-'}
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <Button
            type="button"
            color='btn btn-primary'
            className="btn w-sm"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            OK
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

SuccessPaymentModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
};

export default SuccessPaymentModal;