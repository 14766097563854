import moment from 'moment'
import React from 'react'
import {Link} from 'react-router-dom'
import {getDocumentLabel, ONLINE_AGENT_TYPE_DOC} from '../../Helpers/options'

export function SentMessageItem({
                                  page,
                                  index,
                                  id,
                                  consignee_name,
                                  shipper_name,
                                  table,
                                  type_doc,
                                  send_date,
                                  created_on,
                                  order_status,
                                  sita_text,
                                  additionalActions,
                                }) {
  const linkedToCreateFhlFwb = () => {
    if (type_doc === ONLINE_AGENT_TYPE_DOC.FHL_V5) {
      return (
        <Link to={`/app/fhl/create/${id}/copy`} className='link-dark'>
          <i className='ri-file-copy-line'></i>
        </Link>
      )
    }
    return (
      <Link to={`/app/fwb/create/${id}/copy`} className='link-dark'>
        <i className='ri-file-copy-line'></i>
      </Link>
    )
  }
  return (
    <tr>
      <td className='fw-medium'>{index + page + 1}</td>
      <td>
        <div className='hstack gap-3 fs-15'>
          <Link
            to='#'
            className='link-dark'
            onClick={() => {
              additionalActions({sita_text, id, order_status})
            }}
          >
            <i className='ri-eye-fill'></i>
          </Link>
          {linkedToCreateFhlFwb()}
        </div>
      </td>
      <td>{getDocumentLabel(type_doc)}</td>
      <td>{consignee_name}</td>
      <td>{shipper_name}</td>
      <td>{created_on ? moment(created_on).format('DD MMMM YYYY') : ''}</td>
      <td>{send_date ? moment(send_date).format('DD MMMM YYYY') : ''}</td>
    </tr>
  )
}
