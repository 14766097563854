import { topupStatuses, TOPUP_STATUS_CONSTANTS,} from "Helpers/options";

const { ON_PROGRESS, PENDING, SUCCESS, FAILED, EXPIRED } = TOPUP_STATUS_CONSTANTS;
const topupStatus = (value) => {
  const selectedTopupStatus = getTopupStatus(value);
  let badgeColor = "";
  switch (selectedTopupStatus?.value) {
    case ON_PROGRESS:
      badgeColor = "badge badge-soft-on-progress";
      break;
    case PENDING:
      badgeColor = "badge badge-soft-draft";
      break;
    case SUCCESS:
      badgeColor = "badge badge-soft-success";
      break;
    case FAILED:
    case EXPIRED:
      badgeColor = "badge badge-soft-unpaid";
      break;
    default:
      badgeColor = "badge badge-soft-danger";
      break;
  }
  return <span className={badgeColor}>{selectedTopupStatus?.label}</span>;
};

const getTopupStatus = (value) => {
  return topupStatuses.find((opt) => opt.value === value);
};

export { topupStatus, getTopupStatus };
