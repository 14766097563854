import React, {useState} from 'react'
import {useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container, FormGroup, Input, Label,
  Row,
} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {TopupRQ} from 'Hooks/Queries'
import {PAYMENT_METHODS} from '../../../Helpers/options'
import {formatNumber} from '../../../Helpers/number'
import {RequestOnlineAgentFormRQ} from '../../../Hooks/Queries/requestOnlineAgentFormRQ'
import {apiBearer} from '../../../Helpers/api'
import {PAYMENT_UPLOAD_REQUEST_ONLINE_AGENT_URL} from '../../../Helpers/url_helper'
import {showError} from '../../../Helpers/show_toast'
import SuccessPaymentModal from '../../../Components/FwbFhl/SuccessPaymentModal'

export default function FwbFhlPayment() {
  const {id} = useParams()
  const navigate = useNavigate()

  const [payload, setPayload] = useState({
    payment_method: null,
  })
  const [disabledBalance, setDisabledBalance] = useState(false)
  const [isLoadingPayment, setIsLoadingPayment] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [dataPayment, setDataPayment] = useState(false)

  const getSaldoRQ = TopupRQ.useGetSaldo()()
  const {data} = RequestOnlineAgentFormRQ.useGetData(id)()


  const onSubmit = async (e) => {
    if (payload.payment_method === PAYMENT_METHODS.BALANCE) {
      onPaymentBalance()
    } else {
      navigate(`/app/fwb-fhl/${id}/payment/bank`)
    }
  }

  const onPaymentBalance = () => {
    setIsLoadingPayment(true)
    apiBearer.post(PAYMENT_UPLOAD_REQUEST_ONLINE_AGENT_URL(id), {
      payment_method: payload.payment_method,
    }).then((result) => {
      setIsLoadingPayment(false)
      if (result?.data?.data) {
        if (payload.payment_method === PAYMENT_METHODS.BALANCE) {
          setDataPayment(result?.data?.data?.payment)
          setShowModal(true)
        } else {
          window.open(result?.data?.data.url, '_blank')
          navigate('/app/fwb-fhl')
        }
      } else {
        showError('Error while saving')
      }
    }).catch((err) => {
      setIsLoadingPayment(false)
    })
  }

  useEffect(() => {
    if (!getSaldoRQ?.data || !data?.data) return

    let myBalance = getSaldoRQ?.data?.data ?? 0
    let totalAmount = data?.data?.total ?? 0
    let insufficientBalance = myBalance < totalAmount
    setDisabledBalance(insufficientBalance)
    setPayload((prev) => ({
      ...prev,
      payment_method: insufficientBalance ? PAYMENT_METHODS.TRANSFER : PAYMENT_METHODS.BALANCE,
    }))
  }, [getSaldoRQ?.data, data?.data])


  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Payment' />
        <Row>
          <Col lg={12}>
            <form onSubmit={() => {
            }}>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={7}>
                      <h3 className='mb-0 flex-grow-1'>Payment Method</h3>
                      <Card className='border border-1 mt-lg-3'>
                        <CardBody className='p-3'>
                          <FormGroup check>
                            <Input
                              name='payment_method'
                              disabled={disabledBalance}
                              checked={payload.payment_method === PAYMENT_METHODS.BALANCE}
                              onChange={() =>
                                setPayload((prev) => ({
                                  ...prev,
                                  payment_method: PAYMENT_METHODS.BALANCE,
                                }))
                              }
                              type='radio'
                              id='1'
                              className='me-3'
                            />
                            <Label check htmlFor='1' className='text-uppercase'>
                                <span className='icon-on'>
                                  <img
                                    alt=''
                                    src='/assets/icons/empty_wallet.svg'
                                    className='me-1'
                                  /> Total Saldo Anda
                                </span>
                            </Label>
                          </FormGroup>
                          <h5 className='fw-bolder fs-14 mb-0 mt-3' style={{marginLeft: '65px'}}>
                            {getSaldoRQ.data &&
                              'Rp.' + formatNumber(getSaldoRQ.data.data)
                            }
                          </h5>
                          <Button
                            className='w-25 btn-outline-primary fw-bold mt-2'
                            style={{marginLeft: '65px'}}
                            color='btn'
                            onClick={() => navigate('/app/topups/create')}
                            type='button'
                          >
                                <span className='icon-on'>
                                  <i className='ri-currency-fill align-bottom me-1'></i> Topup Saldo
                                </span>
                          </Button>

                          {disabledBalance &&
                            <div className='mb-xl-0 fw-bold mt-2' style={{marginLeft: '65px'}}>
                              <span className='text-danger'>Maaf saldo anda tidak mencukupi</span>
                            </div>
                          }
                        </CardBody>
                        <CardFooter>
                          <FormGroup check>
                            <Input
                              name='payment_method'
                              onChange={() =>
                                setPayload((prev) => ({
                                  ...prev,
                                  payment_method: PAYMENT_METHODS.TRANSFER,
                                }))
                              }
                              checked={payload.payment_method === PAYMENT_METHODS.TRANSFER}
                              type='radio'
                              id='2'
                              className='me-3'
                            />
                            <Label check htmlFor='2' className='text-uppercase'>
                                <span className='icon-on'>
                                  <img
                                    alt=''
                                    src='/assets/icons/bank.svg'
                                    className='me-1'
                                  /> Bank Transfer
                                </span>
                            </Label>
                          </FormGroup>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col lg={5}>
                      <Card className='border border-1'>
                        <CardBody className='p-4'>
                          <h2 className='mb-0'>Order History</h2>
                          <div className='d-flex align-items-center mt-5'>
                            <div className='flex-grow-1'>
                              <p className='fw-medium text-muted mb-0'>Transaction fees</p>
                            </div>
                            <div className='flex-shrink-0'>
                              <h5 className='text-muted fs-14 mb-0'>
                                Rp. {formatNumber(data?.data?.transaction_amount) ?? '-'}
                              </h5>
                            </div>
                          </div>
                          <div className='d-flex align-items-center mt-3'>
                            <div className='flex-grow-1'>
                              <p className='fw-medium text-muted mb-0'>PPN</p>
                            </div>
                            <div className='flex-shrink-0'>
                              <h5 className='text-muted fs-14 mb-0'>
                                Rp. {formatNumber(data?.data?.ppn_amount) ?? '-'}
                              </h5>
                            </div>
                          </div>
                          <hr className='mt-4 mb-4' />
                          <div className='d-flex align-items-center mt-3 mb-4'>
                            <div className='flex-grow-1'>
                              <p className='fw-bolder text-muted mb-0'>Total</p>
                            </div>
                            <div className='flex-shrink-0'>
                              <h5 className='fw-bolder fs-14 mb-0'>
                                Rp. {formatNumber(data?.data?.total) ?? '-'}
                              </h5>
                            </div>
                          </div>
                          <Button
                            className='w-100'
                            color='btn btn-primary'
                            onClick={onSubmit}
                            type='button'
                            disabled={isLoadingPayment}
                          >
                            {isLoadingPayment
                              ? 'Loading...'
                              : 'Bayar'}
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Link to='/app/fwb-fhl' className='btn btn-light'>
                    Kembali
                  </Link>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
      <SuccessPaymentModal
        onCloseClick={() => navigate('/app/fwb-fhl')}
        show={showModal}
        data={dataPayment}
      />
    </div>
  )
}
