import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {Controller} from 'react-hook-form'
import {Button, Col, Collapse, Input, Label, Row} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import React, {useState} from 'react'
import ListMasterModal from '../ListMasterModal'
import InputNumeric from '../../../Helpers/InputNumeric'
import InputPopupModal from '../../../Helpers/InputPopupModal'

export const FlightBooking = ({
  errors,
  control,
  setValue,
  clearErrors,
  careerCodeList,
  fields,
  append,
  remove,
  isDetail = false,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [showPopup, setShowPopup] = useState(false)
  const [fieldActive, setFieldActive] = useState('')

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const openModal = (field) => {
    setFieldActive(field)
    setShowPopup(true)
  }

  const onConfirm = (type, value) => {
    clearErrors(fieldActive)
    setValue(fieldActive, value)
    setShowPopup(false)
  }

  const checkValidData = (field, data) => {
    let isExists = careerCodeList.find((a) => a.code === data)
    if (!isExists) {
      setValue(field, '')
      openModal(field)
    } else {
      setValue(field, data)
    }
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Flight Booking</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        {fields.map((item, index) => (
          <Row key={item.id} className='gy-2 ps-1 mb-2'>
            <Col lg={4} md={6} sm={12}>
              <ErrorFieldWrapper
                error={errors.flightBookings?.[index]?.flightBookingCarrierCode}
                message={
                  errors.flightBookings?.[index]?.flightBookingCarrierCode
                    ?.message
                }
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label
                    htmlFor={`flightBookings.${index}.flightBookingCarrierCode`}
                    className='form-label m-0 w-50'
                  >
                    Carrier Code
                  </Label>
                  <Controller
                    name={`flightBookings.${index}.flightBookingCarrierCode`}
                    control={control}
                    render={({field}) => (
                      <div className='d-flex w-50 align-items-stretch gap-1'>
                        <InputPopupModal
                          fieldName={`flightBookings.${index}.flightBookingCarrierCode`}
                          checkValidData={checkValidData}
                          {...field}
                          value={field.value}
                          maxLength={2}
                          disabled={isDetail}
                        />
                        {!isDetail && (
                          <ActionButton
                            onClick={() =>
                              openModal(
                                `flightBookings.${index}.flightBookingCarrierCode`,
                              )
                            }
                          />
                        )}
                      </div>
                    )}
                  />
                </div>
              </ErrorFieldWrapper>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <ErrorFieldWrapper
                error={
                  errors.flightBookings?.[index]?.flightBookingFlightNumber
                }
                message={
                  errors.flightBookings?.[index]?.flightBookingFlightNumber
                    ?.message
                }
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label
                    htmlFor={`flightBookings.${index}.flightBookingFlightNumber`}
                    className='form-label m-0 w-50'
                  >
                    Flight Number
                  </Label>
                  <Controller
                    name={`flightBookings.${index}.flightBookingFlightNumber`}
                    control={control}
                    render={({field}) => (
                      <Input
                        className='w-50'
                        {...field}
                        value={field.value.toUpperCase()}
                        disabled={isDetail}
                        maxLength={5}
                      />
                    )}
                  />
                </div>
              </ErrorFieldWrapper>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <ErrorFieldWrapper
                error={errors.flightBookings?.[index]?.flightBookingFlightDay}
                message={
                  errors.flightBookings?.[index]?.flightBookingFlightDay
                    ?.message
                }
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label
                    htmlFor={`flightBookings.${index}.flightBookingFlightDay`}
                    className='form-label m-0 w-50'
                  >
                    Day
                  </Label>
                  <Controller
                    name={`flightBookings.${index}.flightBookingFlightDay`}
                    control={control}
                    render={({field}) => (
                      <div className='d-flex w-50 align-items-stretch gap-1'>
                        <InputNumeric
                          {...field}
                          value={field.value}
                          disabled={isDetail}
                          maxLength={2}
                        />
                        {!isDetail && index > 0 ? (
                          <ActionButton
                            onClick={() => remove(index)}
                            action='remove'
                          />
                        ) : null}
                      </div>
                    )}
                  />
                </div>
              </ErrorFieldWrapper>
            </Col>
          </Row>
        ))}
        {!isDetail && (
          <Row className='gy-2 ps-1'>
            <Col lg={4} md={6} sm={12}>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='m-0 w-50'></div>
                <div className='w-50'>
                  <Button
                    type='button'
                    className='btn btn-light'
                    style={{
                      width: 120,
                      color: '#69809A',
                      backgroundColor: '#E7EBEF',
                    }}
                    onClick={() =>
                      append({
                        flightBookingCarrierCode: '',
                        flightBookingFlightNumber: '',
                        flightBookingFlightDay: '',
                      })
                    }
                  >
                    Add Row
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </Collapse>
      {!isDetail && (
        <ListMasterModal
          field={'asd'}
          show={showPopup}
          onCloseClick={() => {
            setShowPopup(!showPopup)
          }}
          onConfirm={onConfirm}
          withFilterAlphabet={false}
          dataList={careerCodeList}
          title={`Carrier Code`}
          mapData={{
            description: 'DESCRIPTION',
            code: 'CODE',
          }}
        />
      )}
    </div>
  )
}
