const formatNumber = (number = 0) => {
  return number ? number.toLocaleString() : 0;
};

const parseNumber = (value) => {
  const number = Number(value);
  return isNaN(number) ? 0 : number;
};

export {
  formatNumber,
  parseNumber
};