import BreadCrumb from 'Components/Common/BreadCrumb'
import withTable from 'Components/Common/Table/withTable'
import {APP_NAME, DATA_LIMIT} from 'configs'
import {ADMIN} from 'Helpers/options'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {Container, Row} from 'reactstrap'
import {RequestOnlineAgentFormRQ} from '../../Hooks/Queries/requestOnlineAgentFormRQ'
import SitaApiModal from '../../Components/FwbFhl/SitaApiModal'
import RequestAgentOnlineFormItem from '../../Components/RequestAgentOnlineForm/RequestAgentOnlineFormItem'

const ListRequestAgentOnlineForm = () => {
  const {profile} = useSelector((state) => state.auth)

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const [sitaApiModal, setSitaApiModal] = useState(false)
  const [dataSita, setDataSita] = useState({})
  const navigate = useNavigate()

  const listRQ = RequestOnlineAgentFormRQ.useGetDataList()(
    false,
    keyword,
    offset,
    limit,
    '',
  )

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const onSitaApiClick = (data) => {
    setDataSita(data)
    setSitaApiModal(true)
  }

  const FhlTable = withTable(
    RequestAgentOnlineFormItem,
    'request-agent-online-form',
    [
      'NO',
      'Action',
      'TYPE DOCUMENT',
      'AGENT NAME',
      'UPLOAD DATE',
      'SHIPPER NAME',
      'CONSIGNEE NAME',
      'PAYMENT STATUS',
      'ORDER STATUS',
    ],
    null,
    null,
    onSitaApiClick,
    null,
  )

  useEffect(() => {
    if (!profile.is_approved && profile.role !== ADMIN) {
      navigate('/app/profile')
    }
  }, [navigate, profile.is_approved, profile.role])
  document.title = `${APP_NAME} | Request Agent Online Form`
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Request Agent Online Form'
          pageTitle='Request Agent Online Form'
        />

        <Row>
          <FhlTable
            data={listRQ.data}
            isFetching={listRQ.isFetching}
            loading={listRQ.isFetching}
            error={listRQ.error}
            status={listRQ.status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
            isAdmin={profile.role === ADMIN}
          />
        </Row>
      </Container>
      <SitaApiModal
        onCloseClick={() => setSitaApiModal((v) => !v)}
        show={sitaApiModal}
        data={dataSita}
        isAdmin={true}
      />
    </div>
  )
}

export default ListRequestAgentOnlineForm
