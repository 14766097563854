import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import Select from 'react-select'
import {Col, Collapse, Label, Row} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import ListMasterModal from '../ListMasterModal'
import {chargeCodeOptions} from '../../../Helpers/options'
import InputNumeric from '../../../Helpers/InputNumeric'
import InputPopupModal from '../../../Helpers/InputPopupModal'

export const ChargeDeclaration = ({errors, control, currencyList, clearErrors, setValue, isDetail = false}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [showPopup, setShowPopup] = useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const onConfirm = (field, value) => {
    clearErrors('currencyCode')
    setValue('currencyCode', value)
    setShowPopup(false)
  }
  const checkValidData = (field, data) => {
    let isExists = currencyList.find(a => a.code === data)
    if (!isExists) {
      setValue(field, '')
      setShowPopup(true)
    } else {
      setValue(field, data)
    }
  }
  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Charge Declaration</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.currencyCode}
              message={errors.currencyCode?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='currencyCode' className='form-label m-0 w-50'>
                  ISO Currency Code
                </Label>
                <Controller
                  id='currencyCode'
                  name='currencyCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch w-50 gap-1'>
                      <InputPopupModal
                        fieldName='currencyCode'
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={3}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton
                          onClick={() =>
                            setShowPopup(true)
                          }
                        />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.valuation}
              message={errors.valuation?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='valuation' className='form-label m-0 w-50'>
                  Weight / Valuation
                </Label>
                <Controller
                  id='valuation'
                  name='valuation'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Select
                      className='w-50'
                      {...field}
                      isSearchable={false}
                      cacheOptions
                      isDisabled={isDetail}
                      isClearable
                      defaultOptions
                      value={chargeCodeOptions.find(option => option.value === field.value) || null}
                      onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                      options={chargeCodeOptions}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.otherCharges}
              message={errors.otherCharges?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='otherCharges' className='form-label m-0 w-50'>
                  Other Charges
                </Label>
                <Controller
                  id='otherCharges'
                  name='otherCharges'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Select
                      className='w-50'
                      {...field}
                      isSearchable={false}
                      cacheOptions
                      isClearable
                      defaultOptions
                      isDisabled={isDetail}
                      value={chargeCodeOptions.find(option => option.value === field.value) || null}
                      onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                      options={chargeCodeOptions}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.declaredValueForCarriage}
              message={errors.declaredValueForCarriage?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='declaredValueForCarriage' className='form-label m-0 w-50'>
                  Declared Value for Carriage
                </Label>
                <Controller
                  id='declaredValueForCarriage'
                  name='declaredValueForCarriage'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch w-50 gap-1'>
                      <InputNumeric {...field} value={field.value} disabled={isDetail} placeholder={'NCV'} maxLength={12} />
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.declaredValueForCustoms}
              message={errors.declaredValueForCustoms?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='declaredValueForCustoms' className='form-label m-0 w-50'>
                  Declared Value for Customs
                </Label>
                <Controller
                  id='declaredValueForCustoms'
                  name='declaredValueForCustoms'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch w-50 gap-1'>
                      <InputNumeric {...field} value={field.value} disabled={isDetail} placeholder={'NVD'} maxLength={12} />
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.declaredValueForInsurance}
              message={errors.declaredValueForInsurance?.message}

            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='declaredValueForInsurance' className='form-label m-0 w-50'>
                  Declared Value for Insurance
                </Label>
                <Controller
                  id='declaredValueForInsurance'
                  name='declaredValueForInsurance'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch w-50 gap-1'>
                      <InputNumeric {...field} value={field.value} disabled={isDetail} placeholder={'XXX'} maxLength={11} />
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>

      {
        !isDetail && <ListMasterModal
          show={showPopup}
          onCloseClick={() => {
            setShowPopup((v) => (!showPopup))
          }}
          onConfirm={onConfirm}
          dataList={currencyList}
          withFilterAlphabet={false}
          mapData={
            {
              code: 'Code',
              name: 'Currency',
            }
          }
          title={`Currency`}
        />
      }
    </div>
  )
}
