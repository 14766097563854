import React, {useState} from 'react'
import {useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Label, Row} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {Controller, useForm} from 'react-hook-form'
import ErrorFieldWrapper from '../../../Components/Common/ErrorFieldWrapper'
import {TopupRQ} from 'Hooks/Queries'
import Select from 'react-select'
import {apiBearer} from '../../../Helpers/api'
import {LIST_PAYMENT_BANKS_URL} from '../../../Helpers/url_helper'
import InputNumeric from '../../../Helpers/InputNumeric'

export default function CreateTopup() {
  const navigate = useNavigate()

  const validation = Yup.object().shape({
    amount: Yup.number().required('Jumlah tidak boleh kosong')
      .transform((value, originalValue) => (originalValue === "" ? undefined : value))
      .min(100000, 'Jumlah Amount adalah Rp100.000')
      .max(50000000, 'Jumlah Amount adalah Rp50.000.000')
      .test('is-multiple-of-1000', 'Jumlah harus kelipatan Rp1.000', (value) => {
        return value % 1000 === 0
      }),
    bank_id: Yup.string().required('Bank tidak boleh kosong'),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      amount: '',
      bank_id: '',
    },
  }
  const {handleSubmit, formState, control} = useForm(formOptions)
  const {errors} = formState
  const [bankLists, setBankLists] = useState([])
  const [selectedBank, setSelectedBank] = useState([])

  const {
    mutate: addTopup,
    isLoading,
  } = TopupRQ.useAddData(navigate)()

  // const mutationRQ = TopupRQ.useAddData()()
  // const detailRQ = TopupRQ.useGetData()()

  const _createTopup = async (values) => {
    const data = {
      amount: values.amount,
      bank_id: values.bank_id,
    }

    // mutationRQ.mutate(data)
    addTopup(data)
  }

  const fetchBanks = async () => {
    const result = await apiBearer.get(LIST_PAYMENT_BANKS_URL)
    const {data} = result
    if (data.success) {
      setBankLists(
        data?.data?.map((v) => ({label: v.name, value: v.id})),
      )
    }
  }
  useEffect(() => {
    fetchBanks()
  }, [])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Topup' pageTitle='Topup' />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(_createTopup)}>
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>{'Create'} Topup</h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.bank_id}
                        message={errors.bank_id?.message}
                      >
                        <Label htmlFor='bank_id' className='form-label text-uppercase'>
                          Bank <span className='mandatory'>*</span>
                        </Label>
                        <Controller
                          id='bank_id'
                          name='bank_id'
                          type='bank_id'
                          control={control}
                          render={({field: {onChange, ...field}}) => (
                            <Select
                              {...field}
                              cacheOptions
                              defaultOptions
                              isClearable
                              isSearchable={false}
                              value={selectedBank ?? ''}
                              onChange={(v) => {
                                onChange(v?.value)
                                setSelectedBank(v)
                              }}
                              options={bankLists}
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper error={errors.amount} message={errors.amount?.message}>
                        <Label htmlFor='amount' className='form-label'>Jumlah <span
                          className='mandatory'>*</span></Label>
                        <Controller
                          id='amount'
                          name='amount'
                          type='amount'
                          control={control}
                          render={({field}) => <InputNumeric {...field} value={field.value} />}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/topups' className='btn btn-danger'>Cancel</Link>
                  <Button
                    color='success'
                    type='submit'
                    disabled={isLoading}
                  >{isLoading ? 'Loading...' : 'Create'}</Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
