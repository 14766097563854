import React, {forwardRef} from 'react'
import {Input} from 'reactstrap'

const InputPopupModal = forwardRef(
  ({fieldName, checkValidData, onBlur, ...props}, ref) => {
    const handleBlur = (event) => {
      event.target.value = event.target.value.toUpperCase()
      if (event.target.value && checkValidData) {
        checkValidData(fieldName, event.target.value)
      }
      if (onBlur) {
        onBlur(event)
      }
    }
    return <Input {...props} ref={ref} onBlur={handleBlur} />
  },
)

export default InputPopupModal
