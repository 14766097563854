import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {Button, Col, Collapse, Label, Row} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'
import {accountInformationIdentifierOptions} from '../../../Helpers/options'
import Select from 'react-select'

export const AccountingInformation = ({errors, control, fields, append, remove, isDetail = false}) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className=' bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <Row>
          <Col sm={12}> Accounting Information</Col>
        </Row>
      </div>
      <Collapse isOpen={isOpen}>
        {fields.map((item, index) => (
          <Row key={item.id} className='gy-2 ps-1 mb-2'>
            <Col
              lg={6}
              sm={12}
              className='d-flex flex-column'
              style={{gap: '10px'}}
            >
              <ErrorFieldWrapper
                error={errors.accountingInformation?.[index]?.accountingInformationIdentifier}
                message={errors.accountingInformation?.[index]?.accountingInformationIdentifier?.message}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label htmlFor={`accountingInformation.${index}.accountingInformationIdentifier`} className='form-label m-0 w-25'>
                    Accounting Information Identifier
                  </Label>
                  <Controller
                    name={`accountingInformation.${index}.accountingInformationIdentifier`}
                    type='text'
                    control={control}
                    render={({field}) => (
                      <div style={{width: '67.5%'}}>
                        <Select
                          {...field}
                          isSearchable={false}
                          className='w-100'
                          isDisabled={isDetail}
                          isClearable
                          defaultOptions
                          value={accountInformationIdentifierOptions.find(option => option.value === field.value) || null}
                          onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                          options={accountInformationIdentifierOptions}
                        />
                      </div>
                    )}
                  />
                </div>
              </ErrorFieldWrapper>
            </Col>
            <Col
              lg={6}
              sm={12}
              className='d-flex flex-column'
              style={{gap: '10px'}}
            >
              <ErrorFieldWrapper
                error={errors.accountingInformation?.[index]?.accountingInformation}
                message={errors.accountingInformation?.[index]?.accountingInformation?.message}
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label htmlFor={`accountingInformation.${index}.accountingInformation`} className='form-label m-0 w-25'>
                    Accounting Information
                  </Label>
                  <Controller
                    name={`accountingInformation.${index}.accountingInformation`}
                    type='text'
                    control={control}
                    render={({field}) => (
                      <div className='d-flex align-items-stretch gap-1' style={{width: '67.5%'}}>
                        <InputTextFhlFwb
                          {...field}
                          value={field.value}
                          disabled={isDetail}
                          maxLength={34}
                        />
                        {!isDetail && index >0 ? (
                          <ActionButton onClick={() => remove(index)} action='remove' />
                        ) : null}
                      </div>
                    )}
                  />
                </div>
              </ErrorFieldWrapper>
            </Col>
          </Row>
        ))}
        {
          !isDetail && (
            <Row className='gy-2 ps-1'>
              <Col lg={4} md={6} sm={12}>
                <div className='d-flex align-items-center justify-content-between'>
                  <div className='m-0 w-50'></div>
                  <div className='w-50'>
                    <Button
                      disabled={fields.length === 6}
                      type='button'
                      className='btn btn-light'
                      style={{width: 120, color: '#69809A', backgroundColor: '#E7EBEF'}}
                      onClick={() => append({
                        accountingInformation: '',
                        accountingInformationIdentifier: '',
                      })}
                    >
                      Add Row
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          )
        }
      </Collapse>
    </div>
  )
}
