import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Input, Label, Row} from 'reactstrap'
import InputNumeric from '../../../Helpers/InputNumeric'

export const ChargeSummary = ({errors, control, onCalculateCharge, isDetail = false}) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className=' bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <Row>
          <Col sm={6}> Prepaid Charge Summary</Col>
          <Col sm={6} className='d-flex justify-content-between'>
            <span> Collect Charge Summary</span>
            {isOpen ? (
              <i className='ri-arrow-down-s-line'></i>
            ) : (
              <i className='ri-arrow-up-s-line'></i>
            )}
          </Col>
        </Row>
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col
            lg={6}
            sm={12}
            className='d-flex flex-column'
            style={{gap: '10px'}}
          >
            <ErrorFieldWrapper
              error={errors.prepaidTotalWeightCharge}
              message={errors.prepaidTotalWeightCharge?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='prepaidTotalWeightCharge' className='form-label m-0 w-25'>
                Total Weight Charge
                </Label>
                <Controller
                  id='prepaidTotalWeightCharge'
                  name='prepaidTotalWeightCharge'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled
                      maxLength={12}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            <ErrorFieldWrapper
              error={errors.prepaidValuationCharge}
              message={errors.prepaidValuationCharge?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='prepaidValuationCharge' className='form-label m-0 w-25'>
                Valuation Charge
                </Label>
                <Controller
                  id='prepaidValuationCharge'
                  name='prepaidValuationCharge'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={12}
                      isDecimal={true}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        onCalculateCharge()
                      }}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            <ErrorFieldWrapper
              error={errors.prepaidTaxes}
              message={errors.prepaidTaxes?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='prepaidTaxes' className='form-label m-0 w-25'>
                Taxes
                </Label>
                <Controller
                  id='prepaidTaxes'
                  name='prepaidTaxes'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={12}
                      isDecimal={true}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        onCalculateCharge()
                      }}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            
            <ErrorFieldWrapper
              error={errors.prepaidTotalOrderChargeDueAgent}
              message={errors.prepaidTotalOrderChargeDueAgent?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='prepaidTotalOrderChargeDueAgent' className='form-label m-0 w-25'>
                Total Order Charge Due Agent
                </Label>
                <Controller
                  id='prepaidTotalOrderChargeDueAgent'
                  name='prepaidTotalOrderChargeDueAgent'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            
            <ErrorFieldWrapper
              error={errors.prepaidTotalOrderChargeDueCarrier}
              message={errors.prepaidTotalOrderChargeDueCarrier?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='prepaidTotalOrderChargeDueCarrier' className='form-label m-0 w-25'>
                Total Order Charge Due Carrier
                </Label>
                <Controller
                  id='prepaidTotalOrderChargeDueCarrier'
                  name='prepaidTotalOrderChargeDueCarrier'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            
            <ErrorFieldWrapper
              error={errors.prepaidChargeSummaryTotal}
              message={errors.prepaidChargeSummaryTotal?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='prepaidChargeSummaryTotal' className='form-label m-0 w-25'>
                Charge Summary Total
                </Label>
                <Controller
                  id='prepaidChargeSummaryTotal'
                  name='prepaidChargeSummaryTotal'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

          </Col>

          <Col
            lg={6}
            sm={12}
            className='d-flex flex-column'
            style={{gap: '10px'}}
          >
            
            <ErrorFieldWrapper
              error={errors.collectTotalWeightCharge}
              message={errors.collectTotalWeightCharge?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='collectTotalWeightCharge' className='form-label m-0 w-25'>
                Total Weight Charge
                </Label>
                <Controller
                  id='collectTotalWeightCharge'
                  name='collectTotalWeightCharge'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            
            <ErrorFieldWrapper
              error={errors.collectValuationCharge}
              message={errors.collectValuationCharge?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='collectValuationCharge' className='form-label m-0 w-25'>
                Valuation Charge
                </Label>
                <Controller
                  id='collectValuationCharge'
                  name='collectValuationCharge'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric
                    style={{width: '67.5%'}}
                    {...field}
                    value={field.value}
                    disabled={isDetail}
                    maxLength={12}
                    isDecimal={true}
                    onChange={(e) => {
                      field.onChange(e.target.value)
                      onCalculateCharge()
                    }}
                  />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            
            <ErrorFieldWrapper
              error={errors.collectTaxes}
              message={errors.collectTaxes?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='collectTaxes' className='form-label m-0 w-25'>
                Taxes
                </Label>
                <Controller
                  id='collectTaxes'
                  name='collectTaxes'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={12}
                      isDecimal={true}
                      onChange={(e) => {
                        field.onChange(e.target.value)
                        onCalculateCharge()
                      }}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            
            <ErrorFieldWrapper
              error={errors.collectTotalOrderChargeDueAgent}
              message={errors.collectTotalOrderChargeDueAgent?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='collectTotalOrderChargeDueAgent' className='form-label m-0 w-25'>
                Total Order Charge Due Agent
                </Label>
                <Controller
                  id='collectTotalOrderChargeDueAgent'
                  name='collectTotalOrderChargeDueAgent'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            <Label className='form-label fw-semibold m-0 text-decoration-underline'>
             
            </Label>
            <ErrorFieldWrapper
              error={errors.collectTotalOrderChargeDueCarrier}
              message={errors.collectTotalOrderChargeDueCarrier?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='collectTotalOrderChargeDueCarrier' className='form-label m-0 w-25'>
                Total Order Charge Due Carrier
                </Label>
                <Controller
                  id='collectTotalOrderChargeDueCarrier'
                  name='collectTotalOrderChargeDueCarrier'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>

            
            <ErrorFieldWrapper
              error={errors.collectChargeSummaryTotal}
              message={errors.collectChargeSummaryTotal?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='collectChargeSummaryTotal' className='form-label m-0 w-25'>
                Charge Summary Total
                </Label>
                <Controller
                  id='collectChargeSummaryTotal'
                  name='collectChargeSummaryTotal'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input
                      style={{width: '67.5%'}}
                      {...field}
                      value={field.value}
                      disabled
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>
    </div>
  )
}
