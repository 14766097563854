import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Label, Row} from 'reactstrap'
import React, {useEffect, useState} from 'react'
import {ActionButton} from '../ActionButton'
import MultiListMasterModal from '../MultiListMasterModal'
import InputPopupModal from '../../../Helpers/InputPopupModal'

export const SpecialHandlingCode = ({
                                      errors,
                                      control,
                                      setValue,
                                      clearErrors,
                                      handlingCodeList,
                                      selected,
                                      setError,
                                      isDetail = false,
                                    }) => {
  const [isOpen, setIsOpen] = useState(true)

  const [showPopupSpecial, setShowPopupSpecial] = useState(false)
  const [selectedSpecialCodes, setSelectedSpecialCodes] = useState([])

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const onConfirmSpecialCode = (value) => {
    const newData = value.map(item => item.code)
    clearErrors('hwbSpecialHandlingCode')
    setValue('hwbSpecialHandlingCode', newData)
    setSelectedSpecialCodes(value)
    setShowPopupSpecial(false)
  }

  const checkValidDataHandlingCode = (field, data) => {
    const errorData = []
    const validData = []
    clearErrors(field)
    let codes = data
      .trim()
      .split(',')
      .map((code) => code.trim().toUpperCase())
    codes = [...new Set(codes)]

    codes.forEach((code) => {
      const foundData = handlingCodeList.find((a) => a.code === code.trim())
      foundData ? validData.push(foundData) : errorData.push(code)
    })
    if (validData.length > 0) {
      const newData = validData.map(item => item.code)
      setValue(field, newData)
    } else {
      setValue(field, [])
    }
    setSelectedSpecialCodes(validData)

    if (errorData.length > 0) {
      const errorMessage = `${errorData.join(', ')} tidak valid`
      setError(field, {type: 'manual', message: errorMessage})
    }
  }

  useEffect(() => {
    if (!isDetail) {
      const values = handlingCodeList.filter(item => selected.includes(item.code))
      setSelectedSpecialCodes(values)
    }
  }, [selected, handlingCodeList])

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Special Handling Details</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={12} md={12} sm={12}>
            <ErrorFieldWrapper
              error={errors.hwbSpecialHandlingCode}
              message={errors.hwbSpecialHandlingCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='hwbSpecialHandlingCode' className='form-label m-0 '>
                  Special Handling Code
                </Label>
                <Controller
                  id='hwbSpecialHandlingCode'
                  name='hwbSpecialHandlingCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch gap-1' style={{width: '84%'}}>
                      <InputPopupModal
                        fieldName='hwbSpecialHandlingCode'
                        checkValidData={checkValidDataHandlingCode}
                        {...field}
                        value={field.value}
                        maxLength={35}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton onClick={() =>
                          setShowPopupSpecial(true)
                        } />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>

      {
        !isDetail && (
          <MultiListMasterModal
            key={'hwbSpecialHandlingCode'}
            show={showPopupSpecial}
            onCloseClick={() => {
              setShowPopupSpecial((v) => (!showPopupSpecial))
            }}
            onConfirm={onConfirmSpecialCode}
            dataList={handlingCodeList}
            selectedItems={selectedSpecialCodes}
            mapData={
              {
                description: 'Description',
                code: 'Code',
              }
            }
            title={`Special Handling Code`}
          />
        )
      }


    </div>
  )
}
