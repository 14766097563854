import React from "react";
import { Link } from "react-router-dom";

export function AgentItem({
  page,
  index,
  id,
  name,
  pic_name,
  pic_email,
  address,
  is_approved,
  onDeleteClick,
  table,
}) {
  return (
    <tr>
      <td className="fw-medium">{index + page + 1}</td>
      <td>
        <div className="hstack gap-3 fs-15">
          <Link to={`/app/${table}/detail/${id}`} className="link-success">
            <i className="ri-eye-fill"></i>
          </Link>
          <Link to={`/app/${table}/edit/${id}`} className="link-warning">
            <i className="ri-edit-2-line"></i>
          </Link>
          <Link
            to="#"
            className="link-danger"
            onClick={() => {
              onDeleteClick(id);
            }}
          >
            <i className="ri-delete-bin-5-line"></i>
          </Link>
        </div>
      </td>
      <td>{name}</td>
      <td>{pic_name}</td>
      <td>{pic_email}</td>
      <td>{address}</td>
      <td>
      {is_approved ? (
        <span className="badge badge-soft-success">
          Disetujui
        </span>
          ) : (
          <span className="badge badge-soft-danger">
            Pending
          </span>)}
      </td>
    </tr>
  );
}
