import {Container, Row} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {useState} from 'react'
import DeleteModal from 'Components/Common/DeleteModal'
import {selfPickupRQ} from 'Hooks/Queries'
import withTable from 'Components/Common/Table/withTable'
import {useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {DATA_LIMIT} from 'configs'
import SelfPickupItem from 'Components/SelfPickupItem'

export default function ListselfPickup() {
  const [deleteSelfPickupId, setDeleteSelfPickupId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const {status, data, error, isFetching, refetch} =
    selfPickupRQ.useGetDataList()(false, keyword, offset, limit)

  const useQuery = useQueryClient()
  const navigate = useNavigate()

  const onDeleteSuccess = (key) => {
    setDeleteModal(false)
    setDeleteSelfPickupId(null)
    useQuery.invalidateQueries({queryKey: [key]})
    refetch()
  }
  const {mutate: deleteTruck} = selfPickupRQ.useDeleteData(onDeleteSuccess)()

  const onDeleteClick = (id) => {
    setDeleteModal(true)
    setDeleteSelfPickupId(id)
  }

  const onDeleteConfirm = () => {
    deleteTruck(deleteSelfPickupId)
  }

  const onAddButtonClicked = () => {
    navigate('/app/truck-book/self-pickup/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const SelfPickupTable = withTable(
    SelfPickupItem,
    'truck-book/self-pickup',
    ['NO', 'Action', 'Pengirim', 'penerima', 'no cdo', 'Status'],
    onDeleteClick,
    onAddButtonClicked,
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Self Pickup' />

        <Row>
          <SelfPickupTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  )
}
