import PropTypes from "prop-types";
import React from "react";
import {Button, Modal, ModalBody} from 'reactstrap'

const SuccessModal = ({
  show,
  onCloseClick,
  text = '',
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <img
            alt=''
            src='/assets/icons/ic_check.svg'
            className='me-1'
          />
          <div className="mt-2 pt-2 fs-15">
            <p className="fw-bold mb-0">
              Success!
            </p>
            <p className="fs-6 fw-normal mb-0 mt-3">
              {text}
            </p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <Button
            type="button"
            color='btn btn-primary'
            className="btn w-sm"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            OK
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

SuccessModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
};

export default SuccessModal;