import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Input, Label, Row} from 'reactstrap'
import React, {useState} from 'react'
import {ActionButton} from '../ActionButton'
import ListMasterModal from '../ListMasterModal'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'
import InputPopupModal from '../../../Helpers/InputPopupModal'

export const AlsoNotify = ({
                             errors,
                             control,
                             countryCodeList,
                             clearErrors,
                             setValue,
                             isDetail = false,
                           }) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const [showPopup, setShowPopup] = useState(false)

  const onConfirm = (field, value) => {
    clearErrors(field)
    setValue(field, value)
    setShowPopup(false)
  }

  const checkValidData = (field, data) => {
    let isExists = countryCodeList.find(a => a.code === data)
    if (!isExists) {
      setValue(field, '')
      setShowPopup(true)
    } else {
      setValue(field, data)
    }
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Also Notify</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.alsoNotifyName}
              message={errors.alsoNotifyName?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='alsoNotifyName' className='form-label m-0 w-50'>
                  Name
                </Label>
                <Controller
                  id='alsoNotifyName'
                  name='alsoNotifyName'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={35} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={8} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.alsoNotifyAddress}
              message={errors.alsoNotifyAddress?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='alsoNotifyAddress'
                  className='form-label m-0 '
                >
                  Street Address
                </Label>
                <Controller
                  id='alsoNotifyAddress'
                  name='alsoNotifyAddress'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb maxLength={35} {...field} value={field.value} disabled={isDetail} style={{
                      width: '75.5%',
                    }}/>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.alsoNotifyPlace}
              message={errors.alsoNotifyPlace?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='alsoNotifyPlace'
                  className='form-label m-0 w-50'
                >
                  Place
                </Label>
                <Controller
                  id='alsoNotifyPlace'
                  name='alsoNotifyPlace'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb maxLength={17} className='w-50' {...field} value={field.value} disabled={isDetail} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.alsoNotifyProvince}
              message={errors.alsoNotifyProvince?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='alsoNotifyProvince'
                  className='form-label m-0 w-50'
                >
                  State/Province
                </Label>
                <Controller
                  id='alsoNotifyProvince'
                  name='alsoNotifyProvince'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb maxLength={9} className='w-50' {...field} value={field.value} disabled={isDetail} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.alsoNotifyCountryCode}
              message={errors.alsoNotifyCountryCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='alsoNotifyCountryCode' className='form-label m-0 w-50'>
                  ISO Country Code
                </Label>
                <Controller
                  id='alsoNotifyCountryCode'
                  name='alsoNotifyCountryCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex align-items-stretch gap-1 w-50'>
                      <InputPopupModal
                        fieldName='alsoNotifyCountryCode'
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        maxLength={2}
                        disabled={isDetail}
                      />
                      {
                        !isDetail && <ActionButton onClick={() =>
                          setShowPopup(true)
                        } />
                      }
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.alsoNotifyPostalCode}
              message={errors.alsoNotifyPostalCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='alsoNotifyPostalCode'
                  className='form-label m-0 w-50'
                >
                  Postal Code
                </Label>
                <Controller
                  id='alsoNotifyPostalCode'
                  name='alsoNotifyPostalCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb maxLength={9} className='w-50' {...field} value={field.value} disabled={isDetail} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.alsoNotifyContactNumber}
              message={errors.alsoNotifyContactNumber?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='alsoNotifyContactNumber'
                  className='form-label m-0 w-50'
                >
                  Contact Number
                </Label>
                <Controller
                  id='alsoNotifyContactNumber'
                  name='alsoNotifyContactNumber'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={25} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>

      {
        !isDetail && (
          <ListMasterModal
            field={'alsoNotifyCountryCode'}
            show={showPopup}
            onCloseClick={() => {
              setShowPopup(!showPopup)
            }}
            onConfirm={onConfirm}
            dataList={countryCodeList}
            withFilterAlphabet={false}
            mapData={
              {
                code: 'Country Code',
                name: 'Name',
              }
            }
            title={'Country Code'}
          />
        )
      }
    </div>
  )
}
