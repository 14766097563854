import PropTypes from 'prop-types'
import React from 'react'
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap'
import {useNavigate} from 'react-router-dom'
import {ONLINE_AGENT_TYPE_DOC, ONLINE_AGENT_TYPE_DOCUMENT} from '../../Helpers/options'
import {showSuccess} from 'Helpers/show_toast'

const SitaApiModal = ({
  show,
  onCloseClick = () => {},
  onSendClick = () => {},
  isLoading = false,
  data = {},
  isUpload = false,
  onRevision = () => {},
  isAdmin = false,
}) => {
  const navigate = useNavigate()

  const onUpdate = () => {
    if (data.type === ONLINE_AGENT_TYPE_DOCUMENT.FHL) {
      let fhlPath = 'fhl'
      if (data?.type_doc === ONLINE_AGENT_TYPE_DOC.FHL_V4) fhlPath = 'fhl-v4'
      navigate(`/app/${fhlPath}/edit/${data.id}`)
    } else if (data.type === ONLINE_AGENT_TYPE_DOCUMENT.FWB) {
      navigate(`/app/fwb/edit/${data.id}`)
    }
    onCloseClick()
  }

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text || '')
      .then(() => {
        showSuccess('Copied!')
      })
      .catch((err) => console.error('Failed to copy:', err))
  }
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      backdrop='static'
      scrollable
    >
      <ModalBody className='py-3'>
        <div>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-sm p-0 text-dark ms-1'
              onClick={() => handleCopy(data.sita_text)}
              title='Copy'
              >
              <i className='ri-file-copy-line fs-5'></i>
            </button>
          </div>
          <div className='mt-0 pt-2 fs-15'>
            <p
              className='text-muted text-wrap mb-0'
              style={{whiteSpace: 'pre-line'}}
            >
              {data.sita_text?.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className='d-flex gap-2 mx-auto mt-2 mb-2'>
          <button
            type='button'
            className='btn w-sm btn-light'
            data-bs-dismiss='modal'
            style={{width: 100}}
            onClick={onCloseClick}
          >
            Close
          </button>
          {data.order_status === 'DRAFT' && !isAdmin && (
            <>
              {data.type === ONLINE_AGENT_TYPE_DOCUMENT.FWB && (
                <Button
                  type='button'
                  color='primary'
                  onClick={onSendClick}
                  disabled={isLoading}
                  style={{width: 100}}
                >
                  {isLoading ? 'Loading...' : 'Send'}
                </Button>
              )}
              {isUpload ? (
                <Button
                  onClick={onRevision}
                  color='btn btn-warning'
                  style={{width: 100}}
                >
                  Revision
                </Button>
              ) : (
                <Button
                  onClick={onUpdate}
                  color='secondary'
                  style={{width: 100}}
                >
                  Update
                </Button>
              )}
            </>
          )}
        </div>
      </ModalFooter>
    </Modal>
  )
}

SitaApiModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
}

export default SitaApiModal
