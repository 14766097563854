import { CREATE_AIRLINE_URL, DELETE_AIRLINE_URL, LIST_AIRLINE_URL, UPDATE_AIRLINE_URL, VIEW_AIRLINE_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'airline';
const REDIRECT_URL = '/app/airline';

export const airlineRQ = new RQ(
  QUERY_KEY,
  LIST_AIRLINE_URL,
  VIEW_AIRLINE_URL,
  CREATE_AIRLINE_URL,
  UPDATE_AIRLINE_URL,
  DELETE_AIRLINE_URL,
  REDIRECT_URL
);