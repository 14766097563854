import {yupResolver} from '@hookform/resolvers/yup'
import {OnlineAgentFormRateRQ} from 'Hooks/Queries'
import {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'
import {validNumbers} from 'Helpers/regex_validation'
import {documentTypes} from 'Helpers/options'
import BreadCrumb from 'Components/Common/BreadCrumb'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'

export default function EditCreateOnlineAgentFormRate(props) {
  const {id} = useParams()
  const navigate = useNavigate()

  const [documentType, setDocumentType] = useState(null)

  const validation = Yup.object().shape({
    documentType: Yup.string().required('Jenis Document tidk boleh kosong'),
    rate: Yup.string()
      .required('Rate tidak boleh kosong')
      .matches(validNumbers, {message: 'Rate hanya boleh berisi angka'}),
    description: Yup.string().required('Deskripsi tidak boleh kosong'),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      documentType: '',
      rate: '',
      description: '',
    },
  }
  const {handleSubmit, formState, control, reset} = useForm(formOptions)
  const {errors} = formState

  const {mutate: updateOnlineAgentFormRate, isLoading: isLoadingUpdate} =
    OnlineAgentFormRateRQ.useUpdateData(navigate)()

  const _updateOnlineAgentFormRate = ({documentType, rate, description}) => {
    const body = {
      id,
      online_agent_form_type_enum: documentType,
      rate,
      description,
    }

    updateOnlineAgentFormRate(body)
  }

  const {mutate: addOnlineAgentFormRate, isLoading: isLoadingCreate} =
    OnlineAgentFormRateRQ.useAddData(navigate)()

  const _createOnlineAgentFormRate = async ({
    documentType,
    rate,
    description,
  }) => {
    const body = {
      online_agent_form_type_enum: documentType,
      rate,
      description,
    }

    addOnlineAgentFormRate(body)
  }

  const {
    data: detailData,
  } = OnlineAgentFormRateRQ.useGetData(id)()

  useEffect(() => {
    if (detailData) {
      reset({
        documentType: detailData.data?.online_agent_form_type,
        rate: detailData.data?.rate,
        description: detailData.data?.description,
      })
      setDocumentType({
        label: detailData.data?.online_agent_form_type,
        value: detailData.data?.online_agent_form_type,
      })
    }
  }, [detailData, reset])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Online Agent Form Rate'
          pageTitle='Online Agent Form Rate'
        />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={handleSubmit(
                id ? _updateOnlineAgentFormRate : _createOnlineAgentFormRate,
              )}
            >
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Update' : 'Create'} Online Agent Form Rate
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors?.documentType}
                        message={errors?.documentType?.message}
                      >
                        <Label htmlFor='documentType' className='form-label'>
                          Jenis Document
                        </Label>
                        <Controller
                          id='documentType'
                          name='documentType'
                          type='text'
                          control={control}
                          render={({field: {onChange, ...field}}) => (
                            <Select
                              isDisabled={id}
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={documentType}
                              options={documentTypes}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value)
                                setDocumentType(e)
                              }}
                              placeholder='Pilih Jenis Document'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors?.rate}
                        message={errors?.rate?.message}
                      >
                        <Label htmlFor='rate' className='form-label'>
                          Rate
                        </Label>
                        <Controller
                          id='rate'
                          name='rate'
                          type='number'
                          control={control}
                          render={({field}) => (
                            <Input
                              {...field}
                              value={field.value}
                              placeholder='Rate'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>

                    <Col md={12}>
                      <ErrorFieldWrapper
                        error={errors.description}
                        message={errors.description?.message}
                      >
                        <Label htmlFor='description' className='form-label'>
                          Description
                        </Label>
                        <Controller
                          id='description'
                          name='description'
                          type='text'
                          control={control}
                          render={({field}) => (
                            <Input
                              {...field}
                              value={field.value}
                              placeholder='Description'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link
                    to='/app/struktur-harga/online-agent-form/rate'
                    className='btn btn-danger'
                  >
                    Cancel
                  </Link>
                  <Button
                    color='success'
                    type='submit'
                    disabled={isLoadingCreate || isLoadingUpdate} //|| //!isValueValid}
                  >
                    {isLoadingCreate || isLoadingUpdate
                      ? 'Loading...'
                      : id
                      ? 'Update'
                      : 'Create'}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
