import React, {forwardRef} from 'react'
import {Input} from 'reactstrap'

const InputNumeric = forwardRef(({onChange, isDecimal = false, ...props}, ref) => {
  const handleChange = (e) => {
    let value = e.target.value

    if (isDecimal) {
      value = value.replace(/[^0-9.,]/g, '')
      value = value.replace(',', '.')

      const parts = value.split('.')
      if (parts.length > 2) {
        value = parts[0] + '.' + parts.slice(1).join('')
      }

      if (value.includes('.')) {
        const [integerPart, decimalPart] = value.split('.')
        if (decimalPart.length > 2) {
          value = integerPart + '.' + decimalPart.substring(0, 2)
        }
      }
    } else {
      value = value.replace(/\D/g, '')
    }

    if (onChange) {
      onChange({target: {value}})
    }
  }

  return <Input {...props} ref={ref} onChange={handleChange} />
})


export default InputNumeric
