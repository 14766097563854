import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import React, {useState} from 'react'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Input, Label, Row} from 'reactstrap'
import InputNumeric from '../../../Helpers/InputNumeric'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'

export const SalesIncentiveInformation = ({errors, control, isDetail = false}) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Sales Incentive Information</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.salesIncentiveInformationAmount}
              message={errors.salesIncentiveInformationAmount?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='salesIncentiveInformationAmount'
                  className='form-label m-0 w-50 w-50'
                >
                  Charge Amount
                </Label>
                <Controller
                  id='salesIncentiveInformationAmount'
                  name='salesIncentiveInformationAmount'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={12} isDecimal={true} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.salesIncentiveInformationCassIndicator}
              message={errors.salesIncentiveInformationCassIndicator?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='salesIncentiveInformationCassIndicator'
                  className='form-label m-0 w-50 w-50'
                >
                  CASS Indicator (N)
                </Label>
                <Controller
                  id='salesIncentiveInformationCassIndicator'
                  name='salesIncentiveInformationCassIndicator'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={2} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>
    </div>
  )
}
