export const WAREHOUSE = 'WAREHOUSE'
export const CUSTOMER = 'CUSTOMER'
export const ADMIN = 'ADMIN'
export const TRUCKING = 'TRUCKING'
export const DRIVER = 'DRIVERS'
export const FORWARDERS = 'FORWARDERS'
export const FHL_V4 = 'FHL_V4'
export const FHL_V5 = 'FHL_V5'
export const FWB_V16 = 'FWB_V16'
export const UPLOAD = 'UPLOAD'
export const FHL = 'FHL'
export const FWB = 'FWB'

export const accountTypes = [WAREHOUSE, CUSTOMER, TRUCKING, FORWARDERS]

export const PAYMENT_METHODS = {
  BALANCE: 'BALANCE',
  TRANSFER: 'TRANSFER',
  AGREEMENT: 'AGREEMENT',
}
export const paymentMethods = [
  {label: 'Via Saldo', value: PAYMENT_METHODS.BALANCE},
  {label: 'Direct Payment', value: PAYMENT_METHODS.TRANSFER},
  {label: 'Agreement', value: PAYMENT_METHODS.AGREEMENT},
]

export const UNPAID = 'UNPAID'
export const PAID = 'PAID'
export const FAILED = 'FAILED'
export const SEND = 'SEND'
export const DRAFT = 'DRAFT'
export const NEW_REQUEST = 'NEW_REQUEST'
export const DOC_REVISION = 'DOC_REVISION'
export const REVISION_DRAFT = 'REVISION_DRAFT'
export const PROCESS = 'PROCESS'
export const WAITING_VERIFICATION_DOC = 'WAITING_VERIFICATION_DOC'
export const ONPROGRESS = 'ONPROGRESS'
export const EXPIRED = 'EXPIRED'

export const warehouseShipmentStatuses = (currentStatus) => {
  const statuses = [
    {label: 'Proses', value: 'ON_PROGRESS', disabled: false},
    {label: 'Baru', value: 'PENDING', disabled: false},
    {label: 'Sedang Disiapkan', value: 'PREPARE_GOODS', disabled: false},
    {label: 'Barang Siap Dipickup', value: 'READY_PICKUP', disabled: false},
    {label: 'Pickup Selesai', value: 'PICKUP', disabled: false},
    // { label: "Barang Telah Dimuat", value: "LOADED", disabled: false },
  ]

  if (currentStatus) {
    const selectedIndex = statuses.findIndex((s) => s.value === currentStatus)
    for (let i = 0; i < statuses.length && i <= selectedIndex; i++) {
      statuses[i].disabled = true
    }
  }

  return statuses
}

export const logStatuses = [
  {label: 'Proses', value: 'PENDING'},
  {label: 'Baru', value: 'TRUCKING_APPROVE_BOOKING'},
  {label: 'Ditolak', value: 'TRUCKING_REJECT_BOOKING'},
  {label: 'Vendor assign', value: 'TRUCKING_ASSIGN_DRIVER'},
  {label: 'Driver terima', value: 'DRIVER_APPROVE_BOOKING'},
  {label: 'Driver tolak', value: 'DRIVER_REJECT_BOOKING'},
  {label: 'Barang disiapkan', value: 'PREPARE_GOODS'},
  {label: 'Barang siap', value: 'READY_PICKUP'},
  {label: 'Penjemputan', value: 'PICKING_UP'},
  {label: 'Penaikan Barang', value: 'LOADED'},
  {label: 'Pengiriman', value: 'ON_DELIVERY'},
  {label: 'Penurunan Barang', value: 'UNLOADING'},
  {label: 'Barang Sampai', value: 'DELIVERED'},
  {label: 'Barang Sampai', value: 'RECEIVED_BY_CUSTOMER'},
  {label: 'Security check', value: 'SECURITY_CHECK'},
  {label: 'Selesai', value: 'DONE'},
]

export const TOPUP_STATUS_CONSTANTS = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ON_PROGRESS: 'ON_PROGRESS',
  FAILED: 'FAILED',
  EXPIRED: 'EXPIRED',
}

export const ONLINE_AGENT_TYPE_DOCUMENT = {
  FHL: FHL,
  FWB: FWB,
  UPLOAD: UPLOAD,
}

export const ONLINE_AGENT_ORDER_STATUS = {
  NEW_REQUEST: NEW_REQUEST,
  PROCESS: PROCESS,
  DRAFT: DRAFT,
  DOC_REVISION: DOC_REVISION,
  WAITING_VERIFICATION_DOC: WAITING_VERIFICATION_DOC,
  REVISION_DRAFT: REVISION_DRAFT,
  SEND: SEND,
}

export const ONLINE_AGENT_PAYMENT_STATUS = {
  PAID: PAID,
  UNPAID: UNPAID,
  ONPROGRESS: ONPROGRESS,
  EXPIRED: EXPIRED,
  FAILED: FAILED,
}

export const ONLINE_AGENT_TYPE_DOC = {
  FHL_V4: FHL_V4,
  FHL_V5: FHL_V5,
  FWB_V16: FWB_V16,
}

export const topupStatuses = [
  {label: 'Pending', value: TOPUP_STATUS_CONSTANTS.PENDING},
  {label: 'Success', value: TOPUP_STATUS_CONSTANTS.SUCCESS},
  {label: 'On Progress', value: TOPUP_STATUS_CONSTANTS.ON_PROGRESS},
  {label: 'Failed', value: TOPUP_STATUS_CONSTANTS.FAILED},
  {label: 'Expired', value: TOPUP_STATUS_CONSTANTS.EXPIRED},
]

export const onlineAgentOrderStatus = [
  {label: 'New Request', value: ONLINE_AGENT_ORDER_STATUS.NEW_REQUEST},
  {label: 'Prosess', value: ONLINE_AGENT_ORDER_STATUS.PROCESS},
  {label: 'Draft', value: ONLINE_AGENT_ORDER_STATUS.DRAFT},
  {label: 'Doc Revision', value: ONLINE_AGENT_ORDER_STATUS.DOC_REVISION},
  {
    label: 'Waiting Verification Doc',
    value: ONLINE_AGENT_ORDER_STATUS.WAITING_VERIFICATION_DOC,
  },
  {label: 'Revision Draft', value: ONLINE_AGENT_ORDER_STATUS.REVISION_DRAFT},
  {label: 'Send', value: ONLINE_AGENT_ORDER_STATUS.SEND},
]

export const onlineAgentPaymentStatus = [
  {label: 'Paid', value: ONLINE_AGENT_PAYMENT_STATUS.PAID},
  {label: 'Unpaid', value: ONLINE_AGENT_PAYMENT_STATUS.UNPAID},
  {label: 'On Progress', value: ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS},
  {label: 'Expired', value: ONLINE_AGENT_PAYMENT_STATUS.EXPIRED},
  {label: 'Failed', value: ONLINE_AGENT_PAYMENT_STATUS.FAILED},
]

export const documentTypes = [
  {label: FHL_V4.replace('_', ' '), value: ONLINE_AGENT_TYPE_DOC.FHL_V4},
  {label: FHL_V5.replace('_', ' '), value: ONLINE_AGENT_TYPE_DOC.FHL_V5},
  {label: FWB, value: ONLINE_AGENT_TYPE_DOC.FWB_V16},
]

export const weightCodeOptions = [
  {label: 'KG', value: 'K'},
  {label: 'LBS', value: 'L'},
]
export const rateClassOptions = [
  {label: 'Basic Charge', value: 'B'},
  {label: 'Class Rate Reduction', value: 'R'},
  {label: 'Class Rate Surcharge', value: 'S'},
  {label: 'Minimum Charge', value: 'M'},
  {label: 'Normal Rate', value: 'N'},
  {label: 'Quantity Rate', value: 'Q'},
  {label: 'Rate per Kilogram', value: 'K'},
  {label: 'Specific Commodity Rate', value: 'C'},
  {label: 'Unit Load Device Additional Information', value: 'X'},
  {label: 'Unit Load Device Additional Rate', value: 'E'},
  {label: 'Unit Load Device Basic Charge or Rate', value: 'U'},
  {label: 'Unit Load Device Discount', value: 'Y'},
]

export const accountInformationIdentifierOptions = [
  {label: 'General, Information', value: 'GEN'},
  {label: 'Government Bill of Lading', value: 'GBL'},
  {label: 'Miscellaneous Charge Order', value: 'MCO'},
  {label: 'Mode of Settlement', value: 'STL'},
  {label: 'Return to Origin', value: 'RET'},
  {label: 'Shipper’s Reference Number', value: 'SRN'},
]
export const chargeCodeOptions = [
  {label: 'Prepaid', value: 'P'},
  {label: 'Collect', value: 'C'},
]

export const entitlementCodeOptions = [
  {label: 'Other Charge due Agent', value: 'A'},
  {label: 'Other Charge due Carrier', value: 'C'},
]

export const officeFunctionDesignatorOptions = [
  {label: 'Cargo Handling', value: 'FF'},
  {label: 'Cargo Reservation', value: 'FR'},
  {label: 'Cargo Systems Address', value: 'FM'},
]

export const participantIdentifierOptions = [
  {label: 'Agent', value: 'AGT'},
  {label: 'Freight Forwarder', value: 'FFW'},
  {label: 'Ground Handling Agent', value: 'GHA'},
]

export const transactionType = [
  {label: 'Transaksi', value: 'TRANSACTION'},
  {label: 'CDO', value: 'CDO'},
  {label: 'Refund CDO', value: 'REFUND_CDO'},
  {label: 'Topup', value: 'TOPUP'},
  {label: 'Withdrawal', value: 'WITHDRAWAL'},
  {label: 'Booking Truck', value: 'BOOKING_TRUCK'},
  {label: 'Refund Booking Truck', value: 'REFUND_BOOKING_TRUCK'},
  {label: 'Agreement', value: 'AGREEMENT'},
  {label: 'Request Online Agent Form', value: 'REQUEST_ONLINE_AGENT_FORM'},
]

export const commisionInformationOptions = [
  {value: 'N', label: ' '},
  {value: 'Amount', label: 'Amount'},
  {value: 'Percentage', label: 'Percentage'},
]

//validation status
export const WAITING_APPROVAL = 'WAITING_APPROVAL'
export const APPROVED = 'APPROVED'
export const REJECTED = 'REJECTED'

export const getBadgeClass = (status) => {
  switch (status) {
    case WAITING_APPROVAL:
    case TOPUP_STATUS_CONSTANTS.PENDING:
    case UNPAID:
      return 'badge badge-soft-dark'
    case APPROVED:
    case PAID:
      return 'badge badge-soft-success'
    case REJECTED:
      return 'badge badge-soft-danger'
    case SEND:
      return 'badge badge-soft-info'
    case DRAFT:
      return 'badge badge-soft-secondary'
    default:
      return 'badge badge-soft-danger'
  }
}
export const getBadgeRequestOnlineClass = (status) => {
  switch (status) {
    case UNPAID:
      return 'badge badge-soft-unpaid'
    case PAID:
      return 'badge badge-soft-paid'
    case SEND:
      return 'badge badge-soft-send'
    case DRAFT:
      return 'badge badge-soft-draft'

    case NEW_REQUEST:
      return 'badge badge-soft-new-request'
    case DOC_REVISION:
      return 'badge badge-soft-doc-revision'
    case REVISION_DRAFT:
      return 'badge badge-soft-warning'
    case PROCESS:
      return 'badge badge-soft-primary'
    case ONLINE_AGENT_PAYMENT_STATUS.ONPROGRESS:
      return 'badge badge-soft-on-progress'
    default:
      return 'badge badge-soft-danger'
  }
}

export const faqCategories = [
  {label: CUSTOMER, value: CUSTOMER},
  {label: WAREHOUSE, value: WAREHOUSE},
  {label: TRUCKING, value: TRUCKING},
  {label: 'DRIVERS', value: 'DRIVERS'},
]

export const PERMISSION_CONSTANTS = {
  CREATE: 'ADD',
  READ: 'VIEW',
  UPDATE: 'EDIT',
  DELETE: 'DELETE',
  // APPROVE :'APPROVE',
  // EXPORT :'EXPORT',
}

//ADD, EDIT, DELETE, VIEW

export const getDocumentLabel = (type) => {
  const find = documentTypes.find((a) => a.value === type)
  return find?.label ?? type
}

export const getOnlineAgentOrderStatusLabel = (type) => {
  const find = onlineAgentOrderStatus.find((a) => a.value === type)
  return find?.label ?? type
}

export const getOnlineAgentPaymentStatusLabel = (type) => {
  const find = onlineAgentPaymentStatus.find((a) => a.value === type)
  return find?.label ?? type
}
export const getTransactionTypeLabel = (type) => {
  const find = transactionType.find((a) => a.value === type)
  return find?.label ?? type
}
