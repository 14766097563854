import React from 'react'
import {TabPane} from 'reactstrap'
import {Agent} from './Agent'
import {SpecialServiceRequest} from './SpecialServiceRequest'
import {AlsoNotify} from './AlsoNotify'
import {AccountingInformation} from './AccountingInformation'
import {ChargeDeclaration} from './ChargeDeclaration'
import {CommissionInformation} from './CommissionInformation'

export const Step2Fwb = ({
  errors,
  control,
  setValue,
  getValues,
  clearErrors,
  currencyList,
  countryCodeList,
  chargeCodeList,
  removeSpecialServiceRequest,
  appendSpecialServiceRequest,
  fieldSpecialServiceRequest,
  fieldsAccountingInformation,
  appendAccountingInformation,
  removeAccountingInformation,
  isDetail = false,
}) => {
  return (
    <TabPane tabId={2}>
      <CommissionInformation
        errors={errors}
        control={control}
        isDetail={isDetail}
        getValues={getValues}
      />
      <Agent errors={errors} control={control} isDetail={isDetail} />
      <SpecialServiceRequest
        errors={errors}
        control={control}
        remove={removeSpecialServiceRequest}
        append={appendSpecialServiceRequest}
        fields={fieldSpecialServiceRequest}
        isDetail={isDetail}
      />
      <AlsoNotify
        errors={errors}
        control={control}
        isDetail={isDetail}
        clearErrors={clearErrors}
        setValue={setValue}
        countryCodeList={countryCodeList}
      />
      <AccountingInformation
        errors={errors}
        control={control}
        isDetail={isDetail}
        remove={removeAccountingInformation}
        append={appendAccountingInformation}
        fields={fieldsAccountingInformation}
      />
      <ChargeDeclaration
        errors={errors}
        control={control}
        setValue={setValue}
        clearErrors={clearErrors}
        currencyList={currencyList}
        chargeCodeList={chargeCodeList}
        isDetail={isDetail}
      />
    </TabPane>
  )
}
