import React from 'react'
import {Nav, NavItem, NavLink} from 'reactstrap'
import classnames from 'classnames'

export const HeaderNavFwb = ({toggleTab, activeTab, isDetail = false}) => {

  const renderText = (index) => {
    if (activeTab > index) {
      return <i className='ri-check-double-line fs-16'></i>
    }
    return `${index}`
  }

  const renderClassName = (index) => {
    return classnames(
      {
        active: activeTab === index,
        done: activeTab <= 6 && activeTab > index - 1,
        'bg-soft-dark': activeTab < index,
        'opacity-50': activeTab > index,
      },
      'rounded-pill',
    )
  }

  return (
    <div className='progress-nav mb-4'>
      <Nav className='nav-pills progress-bar-tab custom-nav d-flex w-100' role='tablist'>
        {
          Array.from({length: 5}, (_, i) => i + 1).map((step) => (
            <React.Fragment key={step}>
              <NavItem className='d-flex align-items-center gap-2'>
                <NavLink
                  to='#'
                  type='button'
                  id={`pills-gen-info-tab-${step}`}
                  disabled={activeTab < step && activeTab + 1 !== step && !isDetail}
                  className={renderClassName(step)}
                  onClick={() => toggleTab(step)}
                  tag='button'
                >
                  {renderText(step)}
                </NavLink>
                Step {step}
              </NavItem>

              {step !== 5 && <div className='separator'></div>}
            </React.Fragment>
          ))
        }
      </Nav>
    </div>
  )
}
