import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Input, Label, Row} from 'reactstrap'
import React, {useState} from 'react'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'
import InputNumeric from '../../../Helpers/InputNumeric'

export const Agent = ({
                        errors,
                        control,
                        isDetail = false,
                      }) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Agent</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.agentAccountNumber}
              message={errors.agentAccountNumber?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='agentAccountNumber' className='form-label m-0 w-50'>
                  Account Number
                </Label>
                <Controller
                  id='agentAccountNumber'
                  name='agentAccountNumber'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={14} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.agentIataCargoAgentNumericCode}
              message={errors.agentIataCargoAgentNumericCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='agentIataCargoAgentNumericCode'
                  className='form-label m-0 w-50 w-50'
                >
                  IATA Cargo Agent Numeric Code 
                </Label>
                <Controller
                  id='agentIataCargoAgentNumericCode'
                  name='agentIataCargoAgentNumericCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={7} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.agentIataCargoAgentAddress}
              message={errors.agentIataCargoAgentAddress?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='agentIataCargoAgentAddress'
                  className='form-label m-0 w-50'
                >
                  IATA Cargo Agent CASS Address
                </Label>
                <Controller
                  id='agentIataCargoAgentAddress'
                  name='agentIataCargoAgentAddress'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={4} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.agentParticipantIdentifier}
              message={errors.agentParticipantIdentifier?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='agentParticipantIdentifier'
                  className='form-label m-0 w-50'
                >
                  Participant Identifier
                </Label>
                <Controller
                  id='agentParticipantIdentifier'
                  name='agentParticipantIdentifier'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={3} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.agentName}
              message={errors.agentName?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='agentName'
                  className='form-label m-0 w-50'
                >
                  Name 
                </Label>
                <Controller
                  id='agentName'
                  name='agentName'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={35} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.agentPlaces}
              message={errors.agentPlaces?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='agentPlaces'
                  className='form-label m-0 w-50'
                >
                  Places 
                </Label>
                <Controller
                  id='agentPlaces'
                  name='agentPlaces'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={17} />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>
    </div>
  )
}
