import PropTypes from 'prop-types'
import React from 'react'
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap'
import {useNavigate} from 'react-router-dom'
import {ONLINE_AGENT_TYPE_DOCUMENT} from '../../Helpers/options'
import {showSuccess} from 'Helpers/show_toast'

const SitatextCard = ({
  onCloseClick = () => {},
  onSendClick = () => {},
  isLoading = false,
  data = {},
  isUpload = false,
  onRevision = () => {},
  isAdmin = false,
}) => {
  const navigate = useNavigate()

  const onUpdate = () => {
    navigate(`/app/fwb/edit/${data.id}`)
  }

  const handleCopy = () => {
    let text = data.sita_text

    for (let fhl of data.related_fhl) {
      text += '\n' + fhl.sita_text
    }
    navigator.clipboard
      .writeText(text || '')
      .then(() => {
        showSuccess('Copied!')
      })
      .catch((err) => console.error('Failed to copy:', err))
  }
  return (
    <>
      <div className='d-flex justify-content-end'>
        <button
          className='btn btn-sm p-0 text-dark ms-1'
          onClick={() => handleCopy()}
          title='Copy'
        >
          <i className='ri-file-copy-line fs-5'></i>
        </button>
      </div>
      <div className='overflow-auto' style={{height: 'calc(100vh - 380px)'}}>
        <div className='pt-2 fs-15'>
          <p
            className='text-muted text-wrap mb-0 px-3'
            style={{whiteSpace: 'pre-line'}}
          >
            {data.sita_text?.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p>
        </div>
        {data?.related_fhl?.map((fhl, idx) => (
          <div className='mt-4 pt-2 fs-15' key={idx}>
            <p
              className='text-muted text-wrap mb-0 px-3'
              style={{whiteSpace: 'pre-line'}}
            >
              {fhl.sita_text?.split('\n').map((line, index) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
            </p>
          </div>
        ))}
      </div>
      <div className='d-flex gap-2 justify-content-center mx-auto mt-2'>
        <button
          type='button'
          className='btn w-sm btn-light'
          data-bs-dismiss='modal'
          style={{width: 100}}
          onClick={onCloseClick}
        >
          Close
        </button>
        {(data.order_status === 'DRAFT' && !isAdmin) && (
          <>
            <Button
              type='button'
              color='primary'
              onClick={onSendClick}
              disabled={isLoading}
              style={{width: 100}}
            >
              {isLoading ? 'Loading...' : 'Send'}
            </Button>
            {isUpload ? (
              <Button
                onClick={onRevision}
                color='btn btn-warning'
                style={{width: 100}}
              >
                Revision
              </Button>
            ) : (
              <Button onClick={onUpdate} color='secondary' style={{width: 100}}>
                Update
              </Button>
            )}
          </>
        )}
      </div>
    </>
  )
}

SitatextCard.propTypes = {
  onCloseClick: PropTypes.func,
}

export default SitatextCard
