import { CREATE_INFORMATION_IDENTIFIER_URL, DELETE_INFORMATION_IDENTIFIER_URL, LIST_INFORMATION_IDENTIFIER_URL, UPDATE_INFORMATION_IDENTIFIER_URL, VIEW_INFORMATION_IDENTIFIER_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'information-identifier';
const REDIRECT_URL = '/app/online-agent-form/information-identifier';

export const informationIdentifierRQ = new RQ(
  QUERY_KEY,
  LIST_INFORMATION_IDENTIFIER_URL,
  VIEW_INFORMATION_IDENTIFIER_URL,
  CREATE_INFORMATION_IDENTIFIER_URL,
  UPDATE_INFORMATION_IDENTIFIER_URL,
  DELETE_INFORMATION_IDENTIFIER_URL,
  REDIRECT_URL
);