import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useFieldArray, useForm} from 'react-hook-form'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {MasterAWBConsignmentDetails} from 'Components/FwbFhl/Fhl/MasterAWBConsignmentDetails'
import {HouseWaybillSummaryDetails} from 'Components/FwbFhl/Fhl/HouseWaybillSummaryDetails'
import {FreeTextDescriptionOfGoods} from 'Components/FwbFhl/Fhl/FreeTextDescriptionOfGoods'
import {HarmonisedTariffScheduleInformation} from 'Components/FwbFhl/Fhl/HarmonisedTariffScheduleInformation'
import {OtherCustomsInformation} from 'Components/FwbFhl/Fhl/OtherCustomsInformation'
import {ShipperConsigneeNameAndAddress} from 'Components/FwbFhl/Fhl/ShipperConsigneeNameAndAddress'
import {ChargeDeclaration} from 'Components/FwbFhl/Fhl/ChargeDeclaration'
import {apiBearer} from '../../../Helpers/api'
import {BASE_API_URL} from '../../../configs'
import {
  LIST_ALL_AIRPORT_URL,
  LIST_ALL_COUNTRY_CODE_URL,
  LIST_ALL_CURRENCY_CODE_URL,
  LIST_ALL_CUSTOM_INFO_IDENTIFIER_URL,
  LIST_ALL_HANDLING_CODE_URL,
  LIST_ALL_INFORMATION_IDENTIFIER_URL,
} from '../../../Helpers/url_helper'
import {RequestOnlineAgentFormRQ} from '../../../Hooks/Queries/requestOnlineAgentFormRQ'
import {
  ADMIN,
  ONLINE_AGENT_PAYMENT_STATUS,
  ONLINE_AGENT_TYPE_DOCUMENT,
} from '../../../Helpers/options'
import {useSelector} from 'react-redux'
import {
  customValidation,
  linkedFieldsValidation,
  optionalPhoneValidation,
} from '../../../Helpers/validation'
import {ShipperConsigneeNameAndAddressV4} from 'Components/FwbFhl/Fhl/ShipperConsigneeNameAndAddressV4'

export default function EditCreateFhlV4() {
  const {id, type} = useParams()
  const navigate = useNavigate()
  const {profile} = useSelector((state) => state.auth)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isUpload, setIsUpload] = useState(false)

  useEffect(() => {
    setIsAdmin(profile.role === ADMIN)
  }, [navigate, profile.is_approved, profile.role])

  const fieldsOtherCharges = [
    'currencyCode',
    'valuation',
    'otherCharges',
    'declaredValueForCarriage',
    'declaredValueForCustoms',
    'declaredValueForInsurance',
  ]
  const fieldsShipper = [
    'shipperName',
    'shipperStreetAddress',
    'shipperCity',
    'shipperProvince',
    'shipperCountryCode',
    'shipperPostalCode',
  ]
  const fieldsConsignee = [
    'consigneeName',
    'consigneeStreetAddress',
    'consigneeCity',
    'consigneeProvince',
    'consigneeCountryCode',
    'consigneePostalCode',
  ]
  const fieldShipperConsignee = [...fieldsConsignee, ...fieldsShipper]

  const validation = Yup.object().shape({
    fwbId: Yup.string().required('No Air Waybill tidak valid'),
    airlinePrefix: customValidation('Prefix'),
    awbSerialNumber: customValidation('AWB serial', {length: 8}),
    awbOrigin: customValidation('Origin'),
    awbDestination: customValidation('Destination'),
    awbShipmentDescriptionCode: customValidation('Shipment description'),
    awbNumberOfPieces: customValidation('Shipment description'),
    awbWeightCode: customValidation('Weight code'),
    awbWeight: customValidation('Weight'),
    hwbSerialNumber: customValidation('HWB serial number'),
    hwbOrigin: customValidation('Origin'),
    hwbDestination: customValidation('Destination'),
    hwbNumberOfPieces: customValidation('Number of pieces'),
    hwbWeightCode: customValidation('Weight code'),
    hwbWeight: customValidation('Weight'),
    hwbSlac: customValidation('SLAC', {required: false}),
    hwbManifestDescriptionOfGoods: customValidation('Description of goods'),
    hwbSpecialHandlingCode: Yup.mixed()
      .test('valid-type', 'Format tidak valid', (value) => {
        return Array.isArray(value) || typeof value === 'string'
      })
      .test('max-codes', 'Maksimal 9 special handling code', (value) => {
        if (Array.isArray(value)) {
          return value.length <= 9 // Jika array, pastikan max 9 item
        }
        return true
      })
      .test('manual-validation', 'Kode tidak valid', (value, context) => {
        if (typeof value === 'string') {
          let codes = value
            .trim()
            .split(',')
            .filter((v) => v !== '')
            .map((code) => code.trim().toUpperCase())
          codes = [...new Set(codes)]

          const invalidCodes = codes.filter(
            (code) => !handlingCodeList.some((a) => a.code === code),
          )
          if (invalidCodes.length > 0) {
            return context.createError({
              message: `${invalidCodes.join(', ')} tidak valid`,
            })
          }
        }
        return true
      }),
    freeText: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().optional(),
        }),
      )
      .max(9, 'Maksimal 9 Free text'),
    harmonisedCommodityCode: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string()
            .nullable()
            .notRequired()
            .test(
              'minLength',
              'Harmonised commodity code tidak boleh kurang dari 6 karakter',
              (val) => !val || val.length >= 6,
            ),
        }),
      )
      .max(9, 'Maksimal 9 Harmonised commodity'),
    otherCustomInfo: Yup.array().of(
      Yup.object().shape({
        countryCode: Yup.string().when(
          ['informationIdentifier', 'customInfoIdentifier'],
          {
            is: (customInfoIdentifier, informationIdentifier) =>
              !customInfoIdentifier && !informationIdentifier,
            then: (schema) =>
              schema.required(
                'Country Code harus diisi jika field lain tidak diisi',
              ),
            otherwise: (schema) => schema.notRequired(),
          },
        ),
        customInfoIdentifier: Yup.string().optional(),
        informationIdentifier: Yup.string().optional(),
        suplementaryCustomInformation: Yup.string().when(
          ['countryCode', 'customInfoIdentifier', 'informationIdentifier'],
          {
            is: (countryCode, customInfoIdentifier, informationIdentifier) =>
              !!countryCode ||
              !!customInfoIdentifier ||
              !!informationIdentifier,
            then: (schema) =>
              schema.required(
                'Supplementary Custom Infomation harus diisi jika field lain ada yang diisi',
              ),
            otherwise: (schema) => schema.notRequired(),
          },
        ),
      }),
    ),

    shipperName: linkedFieldsValidation(
      'shipperName',
      'Shipper name',
      fieldShipperConsignee,
    ),
    shipperStreetAddress: linkedFieldsValidation(
      'shipperStreetAddress',
      'Shipper street address',
      fieldShipperConsignee,
    ),
    shipperCity: linkedFieldsValidation(
      'shipperCity',
      'Shipper city',
      fieldShipperConsignee,
    ),
    shipperProvince: Yup.string().optional(),
    shipperCountryCode: linkedFieldsValidation(
      'shipperCountryCode',
      'Shipper country code',
      fieldShipperConsignee,
    ),
    shipperPostalCode: Yup.string().optional(),
    shipperContactDetail: Yup.array()
      .of(
        Yup.object().shape({
          shipperContactIdentifier: customValidation(
            'Shipper contact identifier',
            {
              required: false,
            },
          ),
          shipperContactNumber: optionalPhoneValidation(
            'Shipper contact number',
            {
              required: false,
            },
          ),
        }),
      )
      .optional(),

    consigneeName: linkedFieldsValidation(
      'consigneeName',
      'Consignee name',
      fieldShipperConsignee,
    ),
    consigneeStreetAddress: linkedFieldsValidation(
      'consigneeStreetAddress',
      'Consignee street address',
      fieldShipperConsignee,
    ),
    consigneeCity: linkedFieldsValidation(
      'consigneeCity',
      'Consignee city',
      fieldShipperConsignee,
    ),
    consigneeProvince: Yup.string().optional(),
    consigneeCountryCode: linkedFieldsValidation(
      'consigneeCountryCode',
      'Consignee country code',
      fieldShipperConsignee,
    ),
    consigneePostalCode: Yup.string().optional(),
    consigneeContactDetail: Yup.array()
      .of(
        Yup.object().shape({
          consigneeContactIdentifier: customValidation(
            'Consignee contact identifier',
            {
              required: false,
            },
          ),
          consigneeContactNumber: optionalPhoneValidation(
            'Consignee contact number',
            {
              required: false,
            },
          ),
        }),
      )
      .optional(),

    currencyCode: linkedFieldsValidation(
      'currencyCode',
      'Currency code',
      fieldsOtherCharges,
    ),
    valuation: linkedFieldsValidation(
      'valuation',
      'Valuation',
      fieldsOtherCharges,
    ),
    otherCharges: linkedFieldsValidation(
      'otherCharges',
      'Other charges',
      fieldsOtherCharges,
    ),
    declaredValueForCarriage: Yup.string().optional(),
    declaredValueForCustoms: Yup.string().optional(),
    declaredValueForInsurance: Yup.string().optional(),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      airlinePrefix: '',
      fwbId: '',
      awbSerialNumber: '',
      awbOrigin: '',
      awbDestination: '',
      awbShipmentDescriptionCode: '',
      awbNumberOfPieces: '',
      awbWeightCode: '',
      awbWeight: '',
      hwbSerialNumber: '',
      hwbOrigin: '',
      hwbDestination: '',
      hwbNumberOfPieces: '',
      hwbWeightCode: '',
      hwbWeight: '',
      hwbSlac: '',
      hwbManifestDescriptionOfGoods: '',
      hwbSpecialHandlingCode: [],
      freeText: [{value: ''}],
      harmonisedCommodityCode: [{value: ''}],
      otherCustomInfo: [
        {
          countryCode: '',
          informationIdentifier: '',
          customInfoIdentifier: '',
          suplementaryCustomInformation: '',
        },
      ],
      shipperName: '',
      shipperStreetAddress: '',
      shipperCity: '',
      shipperProvince: '',
      shipperCountryCode: '',
      shipperPostalCode: '',
      shipperContactDetail: [
        {shipperContactIdentifier: '', shipperContactNumber: ''},
      ],
      consigneeName: '',
      consigneeStreetAddress: '',
      consigneeCity: '',
      consigneeProvince: '',
      consigneeCountryCode: '',
      consigneePostalCode: '',
      consigneeContactDetail: [
        {
          consigneeContactIdentifier: '',
          consigneeContactNumber: '',
        },
      ],
      currencyCode: '',
      valuation: '',
      otherCharges: '',
      declaredValueForCarriage: '',
      declaredValueForCustoms: '',
      declaredValueForInsurance: '',
    },
  }
  const {
    handleSubmit,
    formState,
    reset,
    control,
    getValues,
    setValue,
    clearErrors,
    setError,
  } = useForm(formOptions)

  const {
    fields: fieldFreeTexts,
    append: appendFreeText,
    remove: removeFreeText,
  } = useFieldArray({
    control,
    name: 'freeText',
  })

  const {
    fields: fieldHarmonised,
    append: appendHarmonised,
    remove: removeHarmonised,
  } = useFieldArray({
    control,
    name: 'harmonisedCommodityCode',
  })

  const {
    fields: fieldsOtherCustomInfo,
    append: appendOtherCustomInfo,
    remove: removeOtherCustomInfo,
  } = useFieldArray({
    control,
    name: 'otherCustomInfo',
  })

  const {
    fields: fieldsShipperContactDetail,
    append: appendShipperContactDetail,
    remove: removeShipperContactDetail,
  } = useFieldArray({
    control,
    name: 'shipperContactDetail',
  })

  const {
    fields: fieldsConsigneeContactDetail,
    append: appendConsigneeContactDetail,
    remove: removeConsigneeContactDetail,
  } = useFieldArray({
    control,
    name: 'consigneeContactDetail',
  })

  const {errors} = formState
  const [airportList, setAirportList] = useState([])
  const [countryCodeList, setCountryCodeList] = useState([])
  const [customInfoList, setCustomInfoList] = useState([])
  const [informationList, setInformationList] = useState([])
  const [currencyList, setCurrencyList] = useState([])
  const [handlingCodeList, setHandlingCodeList] = useState([])
  const [selectedSpecialCodes, setSelectedSpecialCodes] = useState([])

  const {mutate: updateFhlAgentForm, isLoading: isLoadingUpdate} =
    RequestOnlineAgentFormRQ.useUpdateFhlV4Data(id, navigate)()

  const {mutate: addFhlAgentForm, isLoading: isLoadingCreate} =
    RequestOnlineAgentFormRQ.useAddFhlV4Data(navigate)()

  const _updateFhl = () => {
    console.log(getPayload())
    updateFhlAgentForm({
      data: getPayload(),
      buttonType: isAdmin
        ? 'admin'
        : detailData?.data?.payment_status === ONLINE_AGENT_PAYMENT_STATUS.PAID
        ? 'submit'
        : 'pay',
    })
  }

  const _createFhl = async (action) => {
    console.log(getPayload())
    addFhlAgentForm({
      data: getPayload(),
      buttonType: isAdmin ? 'admin' : action,
    })
  }

  const numberOrNull = (value) => {
    return isNaN(value) || value === '' ? null : Number(value)
  }

  const getPayload = () => {
    const formData = getValues()
    return {
      is_draft: false,
      data: {
        awb: {
          air_way_bill_no: formData.awbSerialNumber,
          air_line: formData.airlinePrefix,
          origin_code: formData.awbOrigin,
          destination_code: formData.awbDestination,
          shipment_description_code: formData.awbShipmentDescriptionCode,
          number_of_pieces: numberOrNull(formData.awbNumberOfPieces),
          weight_code: formData.awbWeightCode,
          weight: numberOrNull(formData.awbWeight),
        },
        hwb: {
          hwb_serial_number: formData.hwbSerialNumber,
          origin_code: formData.hwbOrigin,
          destination_code: formData.hwbDestination,
          number_of_pieces: numberOrNull(formData.hwbNumberOfPieces),
          weight_code: formData.hwbWeightCode,
          weight: numberOrNull(formData.hwbWeight),
          slac: formData.hwbSlac,
          manifest_description_of_goods: formData.hwbManifestDescriptionOfGoods,
          special_handling_code: formData.hwbSpecialHandlingCode
            ? formData.hwbSpecialHandlingCode
            : [],
        },
        description_of_goods: formData.freeText
          .filter((a) => a.value !== '')
          .map((item) => item.value),
        harmonised_tariff_schedule: formData.harmonisedCommodityCode
          .filter((a) => a.value !== '')
          .map((item) => item.value),
        other_information: formData.otherCustomInfo.map((info) => ({
          iso_country_code: info.countryCode,
          custom_info_identifier: info.customInfoIdentifier,
          information_identifier: info.informationIdentifier,
          supplementary_information: info.suplementaryCustomInformation,
        })),
        shipper: {
          name: formData.shipperName,
          street_address: formData.shipperStreetAddress,
          city: formData.shipperCity,
          province: formData.shipperProvince,
          country_code: formData.shipperCountryCode,
          postal_code: formData.shipperPostalCode,
          contact_detail: formData.shipperContactDetail.map((contact) => ({
            contact_identifier: contact.shipperContactIdentifier,
            contact_number: contact.shipperContactNumber,
          })),
        },
        consignee: {
          name: formData.consigneeName,
          street_address: formData.consigneeStreetAddress,
          city: formData.consigneeCity,
          province: formData.consigneeProvince,
          country_code: formData.consigneeCountryCode,
          postal_code: formData.consigneePostalCode,
          contact_detail: formData.consigneeContactDetail.map((contact) => ({
            contact_identifier: contact.consigneeContactIdentifier,
            contact_number: contact.consigneeContactNumber,
          })),
        },
        charge_declarations: {
          iso_currency_code: formData.currencyCode,
          valuation: formData.valuation,
          other_charges: formData.otherCharges,
          declared_value_for_carriage: numberOrNull(
            formData.declaredValueForCarriage,
          ),
          declared_value_for_customs: numberOrNull(
            formData.declaredValueForCustoms,
          ),
          declared_value_for_insurance: numberOrNull(
            formData.declaredValueForInsurance,
          ),
        },
      },
    }
  }

  const onSubmit = (data, event) => {
    const action = event.nativeEvent.submitter.value
    if (id && type !== 'copy') {
      return _updateFhl()
    }
    return _createFhl(action)
  }

  const getAirport = async () => {
    const response = await apiBearer(BASE_API_URL + LIST_ALL_AIRPORT_URL)

    return response?.data?.data ?? []
  }

  const getCountryCode = async () => {
    const response = await apiBearer(BASE_API_URL + LIST_ALL_COUNTRY_CODE_URL)

    return response?.data?.data ?? []
  }

  const getCustomInfo = async () => {
    const response = await apiBearer(
      BASE_API_URL + LIST_ALL_CUSTOM_INFO_IDENTIFIER_URL,
    )

    return response?.data?.data ?? []
  }

  const getInformation = async () => {
    const response = await apiBearer(
      BASE_API_URL + LIST_ALL_INFORMATION_IDENTIFIER_URL,
    )

    return response?.data?.data ?? []
  }

  const getCurrency = async () => {
    const response = await apiBearer(BASE_API_URL + LIST_ALL_CURRENCY_CODE_URL)

    return response?.data?.data ?? []
  }

  const getHandlingCode = async () => {
    const response = await apiBearer(BASE_API_URL + LIST_ALL_HANDLING_CODE_URL)

    return response?.data?.data ?? []
  }

  const setValueEdit = (detailData) => {
    const fhl = detailData?.data?.data
    reset({
      fwbId: fhl?.awb?.air_way_bill_no ?? '',
      awbSerialNumber: fhl?.awb?.air_way_bill_no ?? '',
      airlinePrefix: fhl?.awb?.air_line ?? '',
      awbOrigin: fhl?.awb?.origin_code ?? '',
      awbDestination: fhl?.awb?.destination_code ?? '',
      awbShipmentDescriptionCode: fhl?.awb?.shipment_description_code ?? '',
      awbNumberOfPieces: fhl?.awb?.number_of_pieces ?? '',
      awbWeightCode: fhl?.awb?.weight_code ?? '',
      awbWeight: fhl?.awb?.weight ?? '',

      hwbSerialNumber: fhl?.hwb?.hwb_serial_number ?? '',
      hwbOrigin: fhl?.hwb?.origin_code ?? '',
      hwbDestination: fhl?.hwb?.destination_code ?? '',
      hwbNumberOfPieces: fhl?.hwb?.number_of_pieces ?? '',
      hwbWeightCode: fhl?.hwb?.weight_code ?? '',
      hwbWeight: fhl?.hwb?.weight ?? '',
      hwbSlac: fhl?.hwb?.slac ?? '',
      hwbManifestDescriptionOfGoods:
        fhl?.hwb?.manifest_description_of_goods ?? '',
      hwbSpecialHandlingCode: fhl?.hwb?.special_handling_code ?? '',

      shipperStreetAddress: fhl?.shipper?.street_address ?? '',
      shipperName: fhl?.shipper?.name ?? '',
      shipperCity: fhl?.shipper?.city ?? '',
      shipperProvince: fhl?.shipper?.province ?? '',
      shipperCountryCode: fhl?.shipper?.country_code ?? '',
      shipperPostalCode: fhl?.shipper?.postal_code ?? '',
      shipperContactDetail: fhl?.shipper?.contact_detail?.map((contact) => ({
        shipperContactIdentifier: contact.contact_identifier ?? '',
        shipperContactNumber: contact.contact_number ?? '',
      })) ?? [
        {
          shipperContactIdentifier: '',
          shipperContactNumber: '',
        },
      ],

      consigneeStreetAddress: fhl?.consignee?.street_address ?? '',
      consigneeName: fhl?.consignee?.name ?? '',
      consigneeCity: fhl?.consignee?.city ?? '',
      consigneeProvince: fhl?.consignee?.province ?? '',
      consigneeCountryCode: fhl?.consignee?.country_code ?? '',
      consigneePostalCode: fhl?.consignee?.postal_code ?? '',
      consigneeContactDetail: fhl?.consignee?.contact_detail?.map(
        (contact) => ({
          consigneeContactIdentifier: contact.contact_identifier ?? '',
          consigneeContactNumber: contact.contact_number ?? '',
        }),
      ) ?? [
        {
          consigneeContactIdentifier: '',
          consigneeContactNumber: '',
        },
      ],

      otherCustomInfo: fhl?.other_information?.map((oc) => ({
        countryCode: oc?.iso_country_code ?? '',
        customInfoIdentifier: oc?.custom_info_identifier ?? '',
        informationIdentifier: oc?.information_identifier ?? '',
        suplementaryCustomInformation: oc?.supplementary_information ?? '',
      })) ?? [
        {
          countryCode: '',
          customInfoIdentifier: '',
          informationIdentifier: '',
          suplementaryCustomInformation: '',
        },
      ],

      currencyCode: fhl?.charge_declarations?.iso_currency_code ?? '',
      valuation: fhl?.charge_declarations?.valuation ?? '',
      otherCharges: fhl?.charge_declarations?.other_charges ?? '',
      declaredValueForCarriage:
        fhl?.charge_declarations?.declared_value_for_carriage ?? '',
      declaredValueForCustoms:
        fhl?.charge_declarations?.declared_value_for_customs ?? '',
      declaredValueForInsurance:
        fhl?.charge_declarations?.declared_value_for_insurance ?? '',

      freeText:
        fhl?.description_of_goods?.length > 0
          ? fhl?.description_of_goods?.map((value) => ({value}))
          : [{value: ''}],
      harmonisedCommodityCode:
        fhl?.harmonised_tariff_schedule?.length > 0
          ? fhl?.harmonised_tariff_schedule?.map((value) => ({value}))
          : [{value: ''}],
    })

    setSelectedSpecialCodes(fhl?.hwb?.special_handling_code ?? [''])
  }

  const {
    data: detailData,
    error,
    isFetching,
    status,
  } = RequestOnlineAgentFormRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      if (detailData) {
        setValueEdit(detailData)
        console.log(
          detailData?.data?.type === ONLINE_AGENT_TYPE_DOCUMENT.UPLOAD,
        )

        setIsUpload(
          detailData?.data?.type === ONLINE_AGENT_TYPE_DOCUMENT.UPLOAD,
        )
      }
    }
    if (!isFetching && status === 'error') {
      console.error(error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData, reset])

  useEffect(() => {
    Promise.all([
      getAirport(),
      getCountryCode(),
      getCustomInfo(),
      getInformation(),
      getCurrency(),
      getHandlingCode(),
    ]).then(([airport, country, customInfo, info, currency, handlingCode]) => {
      setAirportList(airport)
      setCountryCodeList(country)
      setCustomInfoList(customInfo)
      setInformationList(info)
      setCurrencyList(currency)
      setHandlingCodeList(handlingCode)
    })
  }, [])

  // TODO: remove this script on production
  useEffect(() => {
    window.fillForm = () => {
      // for testing only
      clearErrors()
      const formData = {
        airlinePrefix: '165',
        awbSerialNumber: '98765432',
        awbOrigin: 'AAA',
        awbDestination: 'CGK',
        awbShipmentDescriptionCode: 'T',
        awbNumberOfPieces: '5',
        awbWeightCode: 'K',
        awbWeight: '100',
        hwbSerialNumber: '54321',
        hwbOrigin: 'AAA',
        hwbDestination: 'CGK',
        hwbNumberOfPieces: '3',
        hwbWeightCode: 'K',
        hwbWeight: '50',
        hwbSlac: '10',
        hwbManifestDescriptionOfGoods: 'Electronic Items',
        hwbSpecialHandlingCode: ['PER', 'AVI'],
        freeText: [{value: 'Handle with care'}],
        harmonisedCommodityCode: [{value: '123456'}],
        otherCustomInfo: [
          {
            countryCode: 'ID',
            informationIdentifier: 'ABI',
            customInfoIdentifier: 'AC',
            suplementaryCustomInformation: 'Additional Info',
          },
        ],
        shipperName: 'John Doe',
        shipperStreetAddress: 'Jl. Sudirman No. 1',
        shipperCity: 'Jakarta',
        shipperProvince: 'DKI Jakarta',
        shipperCountryCode: 'ID',
        shipperPostalCode: '10110',
        shipperContactDetail: [
          {
            shipperContactIdentifier: 'TEL',
            shipperContactNumber: '6281234567890',
          },
        ],
        consigneeName: 'Jane Smith',
        consigneeStreetAddress: 'Orchard Road No. 88',
        consigneeCity: 'Singapore',
        consigneeProvince: 'Central',
        consigneeCountryCode: 'SG',
        consigneePostalCode: '238884',
        consigneeContactDetail: [
          {
            consigneeContactIdentifier: 'TEL',
            consigneeContactNumber: '6598765432',
          },
        ],
        currencyCode: 'USD',
        valuation: 'P',
        otherCharges: 'P',
        declaredValueForCarriage: '4000',
        declaredValueForCustoms: '4500',
        declaredValueForInsurance: '5000',
      }

      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key])
      })

      console.log('✅ Form berhasil diisi otomatis!')
    }
  }, [clearErrors, setValue])

  const navigateToList = () => {
    if (isAdmin) {
      navigate('/app/request-agent-online-form')
    } else {
      navigate('/app/fwb-fhl')
    }
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='FHL V4' pageTitle='FHL V4' />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id && !type ? 'Update' : 'Create'} FHL V4
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <MasterAWBConsignmentDetails
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    clearErrors={clearErrors}
                    setError={setError}
                    isAdmin={isAdmin}
                    isUpload={isUpload}
                  />
                  <HouseWaybillSummaryDetails
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    clearErrors={clearErrors}
                    airportList={airportList}
                    handlingCodeList={handlingCodeList}
                    selected={selectedSpecialCodes}
                    setError={setError}
                  />
                  <FreeTextDescriptionOfGoods
                    errors={errors}
                    control={control}
                    fields={fieldFreeTexts}
                    append={appendFreeText}
                    remove={removeFreeText}
                  />
                  <HarmonisedTariffScheduleInformation
                    errors={errors}
                    control={control}
                    fields={fieldHarmonised}
                    append={appendHarmonised}
                    remove={removeHarmonised}
                  />
                  <OtherCustomsInformation
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    clearErrors={clearErrors}
                    countryCodeList={countryCodeList}
                    customInfoList={customInfoList}
                    informationList={informationList}
                    remove={removeOtherCustomInfo}
                    append={appendOtherCustomInfo}
                    fields={fieldsOtherCustomInfo}
                  />
                  <ShipperConsigneeNameAndAddressV4
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    countryCodeList={countryCodeList}
                    fieldsShipperContactDetail={fieldsShipperContactDetail}
                    appendShipperContactDetail={appendShipperContactDetail}
                    removeShipperContactDetail={removeShipperContactDetail}
                    fieldsConsigneeContactDetail={fieldsConsigneeContactDetail}
                    appendConsigneeContactDetail={appendConsigneeContactDetail}
                    removeConsigneeContactDetail={removeConsigneeContactDetail}
                  />
                  <ChargeDeclaration
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    currencyList={currencyList}
                  />
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-center gap-3'>
                  <Button
                    type='button'
                    className='btn btn-light'
                    onClick={navigateToList}
                    style={{
                      width: 120,
                      color: '#69809A',
                      backgroundColor: '#E7EBEF',
                    }}
                  >
                    Cancel
                  </Button>
                  {(((detailData?.data?.is_draft === true ||
                    detailData?.data?.is_draft === undefined) &&
                    detailData?.data?.type !==
                      ONLINE_AGENT_TYPE_DOCUMENT.UPLOAD) ||
                    type === 'copy' ||
                    detailData?.data?.payment_status ===
                      ONLINE_AGENT_PAYMENT_STATUS.UNPAID) && (
                    <Button
                      type='submit'
                      color='secondary'
                      value='submit'
                      style={{width: 120}}
                      disabled={isLoadingCreate || isLoadingUpdate}
                      // onClick={_onDraft}
                    >
                      {isLoadingCreate || isLoadingUpdate
                        ? 'Loading...'
                        : 'Save'}
                    </Button>
                  )}
                  <Button
                    color='primary'
                    type='submit'
                    value='pay'
                    disabled={isLoadingCreate || isLoadingUpdate}
                    style={{width: 120}}
                  >
                    {isLoadingCreate || isLoadingUpdate
                      ? 'Loading...'
                      : detailData?.data?.payment_status ===
                          ONLINE_AGENT_PAYMENT_STATUS.PAID && type !== 'copy'
                      ? 'Save'
                      : 'Save & Pay'}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
