import React from 'react'
import {TabPane} from 'reactstrap'
import {CustomOrigin} from './CustomOrigin'
import {AgentReferenceData} from './AgentReferenceData'
import {SalesIncentiveInformation} from './SalesIncentiveInformation'
import {CommissionInformation} from './CommissionInformation'
import {CCChargesAndDestinationCurrency} from './CCChargesAndDestinationCurrency'
import {SenderReference} from './SenderReference'

export const Step4Fwb = ({
  errors,
  control,
  setValue,
  clearErrors,
  currencyList,
  airportList,
  isDetail = false,
  trigger,
  fieldsSenderOffice,
  fieldsSenderParticipant,
}) => {
  return (
    <TabPane tabId={4}>
      <CCChargesAndDestinationCurrency
        errors={errors}
        clearErrors={clearErrors}
        setValue={setValue}
        control={control}
        isDetail={isDetail}
        currencyList={currencyList}
      />
      <SenderReference
        errors={errors}
        control={control}
        isDetail={isDetail}
        clearErrors={clearErrors}
        setValue={setValue}
        airportList={airportList}
        trigger={trigger}
        fieldsSenderOffice={fieldsSenderOffice}
        fieldsSenderParticipant={fieldsSenderParticipant}
      />
      <CustomOrigin errors={errors} control={control} isDetail={isDetail} />

      <SalesIncentiveInformation
        errors={errors}
        control={control}
        isDetail={isDetail}
      />
      <AgentReferenceData
        errors={errors}
        control={control}
        isDetail={isDetail}
      />
    </TabPane>
  )
}
