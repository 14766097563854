import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {Controller} from 'react-hook-form'
import {Button, Col, Collapse, Input, Label, Row, Spinner} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import React, {useState} from 'react'
import Select from 'react-select'
import {weightCodeOptions} from '../../../Helpers/options'
import InputNumeric from '../../../Helpers/InputNumeric'
import InputPopupModal from '../../../Helpers/InputPopupModal'
import {apiBearer} from '../../../Helpers/api'
import {GET_MAWB_REQUEST_ONLINE_AGENT_URL} from '../../../Helpers/url_helper'

export const MasterAWBConsignmentDetails = ({
  errors,
  control,
  clearErrors,
  setError,
  getValues,
  setValue,
  isDetail = false,
  isAdmin = false,
  isUpload = false,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)
  const [fwbId, setFwbId] = useState('')

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const getMawb = () => {
    const prefix = getValues('airlinePrefix')
    const awb = getValues('awbSerialNumber')
    return prefix + '-' + awb
  }
  const searchAwb = () => {
    setFwbId('')
    let mawb = getMawb()
    if (getValues('airlinePrefix') && getValues('awbSerialNumber')) {
      setIsLoadingSearch(true)
      apiBearer
        .get(GET_MAWB_REQUEST_ONLINE_AGENT_URL(mawb), {})
        .then((result) => {
          setIsLoadingSearch(false)
          clearErrors('awbSerialNumber')
          setValueMasterAwb(result?.data?.data?.data)
          setValue('fwbId', result?.data?.data?.id, {shouldValidate: true})
          clearErrors()
        })
        .catch(() => {
          setIsLoadingSearch(false)
          setError('awbSerialNumber', {
            type: 'manual',
            message:
              'No Air Waybill tidak ditemukan pastikan sudah melakukan input data FWB',
          })
        })
    }
  }

  const checkValidDataAwb = () => {
    searchAwb()
  }

  const setValueMasterAwb = (data) => {
    console.log(data)
    setValue('awbOrigin', data?.awb?.origin_code)
    setValue('awbDestination', data?.awb?.destination_code)
    setValue('awbShipmentDescriptionCode', data?.awb?.shipment_description_code)
    setValue('awbNumberOfPieces', data?.awb?.number_of_pieces)
    setValue('awbWeightCode', data?.awb?.weight_code)
    setValue('awbWeight', data?.awb?.weight)
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> Master AWB Consignment Details</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12} className='d-flex'>
            <div className='w-100'>
              <ErrorFieldWrapper
                error={
                  errors?.airlinePrefix ??
                  errors?.awbSerialNumber ??
                  errors?.fwbId
                }
                message={
                  errors?.airlinePrefix?.message ??
                  errors?.awbSerialNumber?.message ??
                  errors?.fwbId?.message
                }
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label
                    htmlFor='airlinePrefix'
                    className='form-label m-0 w-50'
                  >
                    Air Waybil No <span className='mandatory'>*</span>
                  </Label>
                  <div className='d-flex w-50 align-items-center gap-1'>
                    <Controller
                      id='airlinePrefix'
                      name='airlinePrefix'
                      type='text'
                      control={control}
                      render={({field}) => (
                        <InputNumeric
                          className='w-25 px-1 text-center'
                          {...field}
                          value={field.value}
                          maxLength={3}
                          onBlur={searchAwb}
                          disabled={isDetail || isAdmin}
                        />
                      )}
                    />
                    <span>-</span>
                    <Controller
                      id='awbSerialNumber'
                      name='awbSerialNumber'
                      type='text'
                      control={control}
                      render={({field}) => (
                        <div className='d-flex w-75 align-items-stretch gap-1'>
                          <InputPopupModal
                            {...field}
                            fieldName='awbSerialNumber'
                            value={field.value}
                            checkValidData={checkValidDataAwb}
                            maxLength={8}
                            disabled={isDetail || isAdmin}
                          />
                          {isLoadingSearch && <Spinner color='success' />}
                          {!isAdmin && !isDetail && !isLoadingSearch && (
                            <ActionButton action='search' onClick={searchAwb} />
                          )}
                        </div>
                      )}
                    />
                    <Controller
                      id='fwbId'
                      name='fwbId'
                      control={control}
                      render={({field}) => <Input type='hidden' {...field} />}
                    />
                  </div>
                </div>
              </ErrorFieldWrapper>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbOrigin}
              message={errors.awbOrigin?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='awbOrigin' className='form-label m-0 w-50'>
                  Origin <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='awbOrigin'
                  name='awbOrigin'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex w-50 align-items-stretch gap-1'>
                      <Input
                        {...field}
                        value={field.value}
                        disabled
                        maxLength={3}
                      />
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbDestination}
              message={errors.awbDestination?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='awbDestination' className='form-label m-0 w-50'>
                  Destination <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='awbDestination'
                  name='awbDestination'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex w-50 align-items-stretch gap-1'>
                      <Input
                        {...field}
                        value={field.value}
                        disabled
                        maxLength={3}
                      />
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbShipmentDescriptionCode}
              message={errors.awbShipmentDescriptionCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='awbShipmentDescriptionCode'
                  className='form-label m-0 w-50 w-50'
                >
                  Shipment Description Code <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='awbShipmentDescriptionCode'
                  name='awbShipmentDescriptionCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbNumberOfPieces}
              message={errors.awbNumberOfPieces?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='awbNumberOfPieces'
                  className='form-label m-0 w-50'
                >
                  Number of Pieces <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='awbNumberOfPieces'
                  name='awbNumberOfPieces'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbWeightCode}
              message={errors.awbWeightCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='awbWeightCode' className='form-label m-0 w-50'>
                  Weight Code <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='awbWeightCode'
                  name='awbWeightCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Select
                      className='w-50'
                      {...field}
                      isSearchable={false}
                      cacheOptions
                      isDisabled
                      isClearable
                      defaultOptions
                      value={
                        weightCodeOptions.find(
                          (option) => option.value === field.value,
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption?.value)
                      }
                      options={weightCodeOptions}
                    />
                    // <Input className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={1}/>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbWeight}
              message={errors.awbWeight?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='awbWeight' className='form-label m-0 w-50'>
                  Weight <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='awbWeight'
                  name='awbWeight'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Input
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>
    </div>
  )
}
