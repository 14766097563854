import {Container, Row} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {useState} from 'react'
import DeleteModal from 'Components/Common/DeleteModal'
import {truckingRateRQ} from 'Hooks/Queries'
import withTable from 'Components/Common/Table/withTable'
import {useQueryClient} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {DATA_LIMIT} from 'configs'
import {TruckingRateITem} from 'Components/TruckingRate/Item'
import {apiBearer} from 'Helpers/api'
import {VIEW_TRUCK_RATE_URL} from 'Helpers/url_helper'
import {showError} from 'Helpers/show_toast'
import {RateListModal} from 'Components/TruckingRate/RateListModal'

export default function ListTruckingRate() {
  const [deleteTruckingId, setDeleteTruckingId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [listModalState, setListModalState] = useState({
    show: false,
    items: [],
  })
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const {status, data, error, isFetching, refetch} =
    truckingRateRQ.useGetDataList()(false, keyword, offset, limit)

  const useQuery = useQueryClient()
  const navigate = useNavigate()

  const onDeleteSuccess = (key) => {
    setDeleteModal(false)
    setDeleteTruckingId(null)
    useQuery.invalidateQueries({queryKey: [key]})
    refetch()
  }
  const {mutate: deleteTruck} = truckingRateRQ.useDeleteData(onDeleteSuccess)()

  const onDeleteClick = (id) => {
    setDeleteModal(true)
    setDeleteTruckingId(id)
  }

  const onDeleteConfirm = () => {
    deleteTruck(deleteTruckingId)
  }

  const onAddButtonClicked = () => {
    navigate('/app/trucking-rate/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const toggleListRateModal = async (id) => {
    try {
      const response = await apiBearer.get(VIEW_TRUCK_RATE_URL(id))
      if (response?.data?.data?.items ?? []) {
        setListModalState((v) => ({...v, items: response.data.data.items}))
        setListModalState((v) => ({...v, show: true}))
      } else {
        showError('Oops, something wrong!')
      }
    } catch (error) {
      showError('Oops, something wrong!')
    }
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
    setOffset(0)
  }

  const TruckingTable = withTable(
    TruckingRateITem,
    'trucking-rate',
    ['NO', 'Action', 'asal', 'tujuan', 'waktu tunggu', 'catatan'],
    onDeleteClick,
    onAddButtonClicked,
    {toggleListRateModal},
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Trucking Rate' />

        <Row>
          <TruckingTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
      <RateListModal
        show={listModalState.show}
        onCloseClick={() =>
          setListModalState((v) => ({...v, show: !listModalState.show}))
        }
        items={listModalState.items}
      />
    </div>
  )
}
