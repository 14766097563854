import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {Controller} from 'react-hook-form'
import {Col, Collapse, Label, Row} from 'reactstrap'
import {ActionButton} from '../ActionButton'
import React, {useState} from 'react'
import ListMasterModal from '../ListMasterModal'
import Select from 'react-select'
import {weightCodeOptions} from '../../../Helpers/options'
import InputTextFhlFwb from '../../../Helpers/InputTextFhlFwb'
import InputNumeric from '../../../Helpers/InputNumeric'
import InputPopupModal from '../../../Helpers/InputPopupModal'
import {apiBearer} from 'Helpers/api'
import {GET_MAWB_REQUEST_ONLINE_AGENT_URL} from 'Helpers/url_helper'

export const AWBConsignmentDetails = ({
  errors,
  control,
  setValue,
  setError,
  getValues,
  clearErrors,
  airportList,
  airlineList,
  isDetail = false,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [fieldActive, setFieldActive] = useState('')
  const [showPopup, setShowPopup] = useState({
    airport: false,
    airlines: false,
  })

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const onConfirm = (type, value) => {
    clearErrors(fieldActive)
    setValue(fieldActive, value)
    setShowPopup({...showPopup, [type]: false})
  }

  const openModal = (type, field) => {
    setFieldActive(field)
    setShowPopup({...showPopup, [type]: true})
  }

  const checkValidData = (field, data) => {
    let isExists = airportList.find((a) => a.code === data)
    if (!isExists) {
      setValue(field, '')
      openModal('airport', field)
    } else {
      setValue(field, data)
    }
  }

  const checkValidDataAirlines = (field, data) => {
    let isExists = airlineList.find((a) => a.sitatex_prefix === data)
    if (!isExists) {
      setValue(field, '')
      openModal('airlines', field)
    } else {
      setValue(field, data)
    }
  }

  const getMawb = () => {
    const prefix = getValues('airlinePrefix')
    const awb = getValues('awbSerialNumber')
    return prefix + '-' + awb
  }
  const searchAwb = () => {
    let mawb = getMawb()
    if (getValues('airlinePrefix') && getValues('awbSerialNumber')) {
      apiBearer
        .get(GET_MAWB_REQUEST_ONLINE_AGENT_URL(mawb), {})
        .then((result) => {
          setError('awbSerialNumber', {
            type: 'manual',
            message:
            'No Air Waybill sudah digunakan',
          })
        })
        .catch(() => {
          clearErrors('awbSerialNumber')
        })
    }
  }

  const mapDataModal = {
    airport: {
      title: 'Airport City Code',
      mapData: {
        name: 'CITY PORT NAME',
        code: 'CITY PORT CODE',
      },
      data: airportList,
    },
    airlines: {
      title: 'Airlines',
      mapData: {
        sitatex_prefix: 'Prefix',
        name: 'Name',
      },
      data: airlineList,
    },
  }

  return (
    <div style={{marginBottom: '22px'}}>
      <div
        className='gy-4 d-flex justify-content-between align-items-center bg-primary text-white mb-3'
        style={{padding: '12px 10px', color: '#677788'}}
        onClick={toggle}
      >
        <span> AWB Consignment Details</span>
        {isOpen ? (
          <i className='ri-arrow-down-s-line'></i>
        ) : (
          <i className='ri-arrow-up-s-line'></i>
        )}
      </div>
      <Collapse isOpen={isOpen}>
        <Row className='gy-2 ps-1'>
          <Col lg={4} md={6} sm={12} className='d-flex'>
            <div className='w-100'>
              <ErrorFieldWrapper
                error={errors?.airlinePrefix ?? errors?.awbSerialNumber}
                message={
                  errors?.airlinePrefix?.message ??
                  errors?.awbSerialNumber?.message
                }
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <Label
                    htmlFor='airlinePrefix'
                    className='form-label m-0 w-50'
                  >
                    Air Waybil No <span className='mandatory'>*</span>
                  </Label>
                  <div className='d-flex w-50 align-items-center gap-1'>
                    <Controller
                      id='airlinePrefix'
                      name='airlinePrefix'
                      type='text'
                      control={control}
                      render={({field}) => (
                        <InputPopupModal
                          fieldName='airlinePrefix'
                          checkValidData={checkValidDataAirlines}
                          className='w-50'
                          {...field}
                          value={field.value}
                          maxLength={3}
                          disabled={isDetail}
                        />
                      )}
                    />
                    <span>-</span>
                    <Controller
                      id='awbSerialNumber'
                      name='awbSerialNumber'
                      type='text'
                      control={control}
                      render={({field}) => (
                        <InputTextFhlFwb
                          {...field}
                          value={field.value}
                          minLength={8}
                          maxLength={8}
                          disabled={isDetail}
                          onBlur={searchAwb}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              searchAwb()
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              </ErrorFieldWrapper>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbOrigin}
              message={errors.awbOrigin?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='awbOrigin' className='form-label m-0 w-50'>
                  Origin <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='awbOrigin'
                  name='awbOrigin'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex w-50 align-items-stretch gap-1'>
                      <InputPopupModal
                        fieldName='awbOrigin'
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        disabled={isDetail}
                        maxLength={3}
                      />
                      {!isDetail && (
                        <ActionButton
                          onClick={() => openModal('airport', 'awbOrigin')}
                        />
                      )}
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbDestination}
              message={errors.awbDestination?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='awbDestination' className='form-label m-0 w-50'>
                  Destination <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='awbDestination'
                  name='awbDestination'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <div className='d-flex w-50 align-items-stretch gap-1'>
                      <InputPopupModal
                        fieldName='awbDestination'
                        checkValidData={checkValidData}
                        {...field}
                        value={field.value}
                        disabled={isDetail}
                        maxLength={3}
                      />
                      {!isDetail && (
                        <ActionButton
                          onClick={() => openModal('airport', 'awbDestination')}
                        />
                      )}
                    </div>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbShipmentDescriptionCode}
              message={errors.awbShipmentDescriptionCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='awbShipmentDescriptionCode'
                  className='form-label m-0 w-50 w-50'
                >
                  Shipment Description Code
                </Label>
                <Controller
                  id='awbShipmentDescriptionCode'
                  name='awbShipmentDescriptionCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      // maxLength={1}
                      // alpha={true}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbNumberOfPieces}
              message={errors.awbNumberOfPieces?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='awbNumberOfPieces'
                  className='form-label m-0 w-50'
                >
                  Number of Pieces <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='awbNumberOfPieces'
                  name='awbNumberOfPieces'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={4}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbWeightCode}
              message={errors.awbWeightCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='awbWeightCode' className='form-label m-0 w-50'>
                  Weight Code <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='awbWeightCode'
                  name='awbWeightCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <Select
                      className='w-50'
                      {...field}
                      isSearchable={false}
                      cacheOptions
                      isDisabled={isDetail}
                      isClearable
                      defaultOptions
                      value={
                        weightCodeOptions.find(
                          (option) => option.value === field.value,
                        ) || null
                      }
                      onChange={(selectedOption) =>
                        field.onChange(selectedOption?.value)
                      }
                      options={weightCodeOptions}
                    />
                    // <Input className='w-50' {...field} value={field.value} disabled={isDetail} maxLength={1}/>
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbWeight}
              message={errors.awbWeight?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='awbWeight' className='form-label m-0 w-50'>
                  Weight <span className='mandatory'>*</span>
                </Label>
                <Controller
                  id='awbWeight'
                  name='awbWeight'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={7}
                      isDecimal={true}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>

          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbVolumeCode}
              message={errors.awbVolumeCode?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label htmlFor='awbVolumeCode' className='form-label m-0 w-50'>
                  Volume Code
                </Label>
                <Controller
                  id='awbVolumeCode'
                  name='awbVolumeCode'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={2}
                      alpha={true}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbVolumeAmount}
              message={errors.awbVolumeAmount?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='awbVolumeAmount'
                  className='form-label m-0 w-50'
                >
                  Volume Amount
                </Label>
                <Controller
                  id='awbVolumeAmount'
                  name='awbVolumeAmount'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={9}
                      isDecimal={true}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbDensityIndicator}
              message={errors.awbDensityIndicator?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='awbDensityIndicator'
                  className='form-label m-0 w-50'
                >
                  Density Indicator
                </Label>
                <Controller
                  id='awbDensityIndicator'
                  name='awbDensityIndicator'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputTextFhlFwb
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={2}
                      alpha={true}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <ErrorFieldWrapper
              error={errors.awbDensityGroup}
              message={errors.awbDensityGroup?.message}
            >
              <div className='d-flex align-items-center justify-content-between'>
                <Label
                  htmlFor='awbDensityGroup'
                  className='form-label m-0 w-50'
                >
                  Density Group
                </Label>
                <Controller
                  id='awbDensityGroup'
                  name='awbDensityGroup'
                  type='text'
                  control={control}
                  render={({field}) => (
                    <InputNumeric
                      className='w-50'
                      {...field}
                      value={field.value}
                      disabled={isDetail}
                      maxLength={2}
                    />
                  )}
                />
              </div>
            </ErrorFieldWrapper>
          </Col>
        </Row>
      </Collapse>
      {!isDetail &&
        Object.keys(mapDataModal).map((key) => {
          return (
            <ListMasterModal
              key={key}
              field={key}
              show={showPopup[key]}
              onCloseClick={() => {
                setShowPopup((v) => ({...v, [key]: !showPopup[key]}))
              }}
              onConfirm={onConfirm}
              dataList={mapDataModal[key].data}
              withFilterAlphabet={key === 'airport'}
              mapData={mapDataModal[key].mapData}
              title={mapDataModal[key].title}
            />
          )
        })}
    </div>
  )
}
