import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  TabContent,
} from 'reactstrap'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import {useFieldArray, useForm} from 'react-hook-form'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {RequestOnlineAgentFormRQ} from '../../../Hooks/Queries/requestOnlineAgentFormRQ'
import {
  ADMIN,
  ONLINE_AGENT_PAYMENT_STATUS,
  ONLINE_AGENT_TYPE_DOCUMENT,
} from '../../../Helpers/options'
import {useSelector} from 'react-redux'
import {HeaderNavFwb} from '../../../Components/FwbFhl/Fwb/HeaderNavFwb'
import {Step1Fwb} from '../../../Components/FwbFhl/Fwb/Step1Fwb'
import {Step2Fwb} from '../../../Components/FwbFhl/Fwb/Step2Fwb'
import {Step3Fwb} from '../../../Components/FwbFhl/Fwb/Step3Fwb'
import {Step4Fwb} from '../../../Components/FwbFhl/Fwb/Step4Fwb'
import {Step5Fwb} from '../../../Components/FwbFhl/Fwb/Step5Fwb'
import {apiBearer} from '../../../Helpers/api'
import {BASE_API_URL} from '../../../configs'
import {
  LIST_AIRLINE_ALL,
  LIST_ALL_AIRPORT_URL,
  LIST_ALL_CARRIER_CODE_URL,
  LIST_ALL_CHARGE_CODE_URL,
  LIST_ALL_COUNTRY_CODE_URL,
  LIST_ALL_CURRENCY_CODE_URL,
  LIST_ALL_CUSTOM_INFO_IDENTIFIER_URL,
  LIST_ALL_HANDLING_CODE_URL,
  LIST_ALL_INFORMATION_IDENTIFIER_URL,
} from '../../../Helpers/url_helper'
import {
  customValidation,
  linkedFieldsValidation,
  linkedFieldValidation,
  optionalPhoneValidation,
} from '../../../Helpers/validation'
import {debounce, set} from 'lodash'
import {parseNumber} from 'Helpers/number'

export default function EditCreateFwb() {
  const {id, type} = useParams()
  const navigate = useNavigate()
  const {profile} = useSelector((state) => state.auth)
  const [isAdmin, setIsAdmin] = useState(false)
  const [activeTab, setActiveTab] = useState(1)
  const [passedSteps, setPassedSteps] = useState([1])
  const [selectedSpecialCodes, setSelectedSpecialCodes] = useState([])
  const [airportList, setAirportList] = useState([])
  const [carrierCodeList, setCarrierCodeList] = useState([])
  const [countryCodeList, setCountryCodeList] = useState([])
  const [currencyList, setCurrencyList] = useState([])
  const [handlingCodeList, setHandlingCodeList] = useState([])
  const [customInfoList, setCustomInfoList] = useState([])
  const [informationList, setInformationList] = useState([])
  const [chargeCodeList, setChargeCodeList] = useState([])
  const [airlineList, setAirlineList] = useState([])

  useEffect(() => {
    setIsAdmin(profile.role === ADMIN)
  }, [navigate, profile.is_approved, profile.role])

  const fieldsFlightBooking = [
    'flightBookingCarrierCode',
    'flightBookingFlightNumber',
    'flightBookingFlightDay',
  ]
  const fieldsAlsoNotify = [
    'alsoNotifyName',
    'alsoNotifyAddress',
    'alsoNotifyPlace',
    'alsoNotifyProvince',
    'alsoNotifyCountryCode',
    'alsoNotifyPostalCode',
    'alsoNotifyContactNumber',
  ]
  const fieldsCCCharges = [
    'destinationCurrencyCode',
    'currencyConversationRate',
    'ccChargeDestinationCurrency',
    'chargeDestinationAmount',
    'totalCollectChargeAmount',
  ]
  const fieldsSenderOffice = [
    'senderOfficeAirportCode',
    'senderOfficeFunctionDesignator',
    'senderCompanyDesignator',
  ]
  const fieldsSenderParticipant = [
    'senderParticipantIdentifier',
    'senderParticipantCode',
    'senderDestinationAirport',
  ]

  const fieldsOtherSenderOffice = [
    'otherParticipantOfficeAirportCode',
    'otherParticipantOfficeFunctionDesignator',
    'otherParticipantCompanyDesignator',
  ]
  const fieldsOtherSenderParticipant = [
    'otherParticipantParticipantIdentifier',
    'otherParticipantParticipantCode',
    'otherParticipantDestinationAirport',
  ]

  const fieldsOtherCharges = [
    'otherChargeChargeCode',
    'entitlementCode',
    'otherChargeAmount',
  ]

  const validation = Yup.object().shape({
    // step 1
    airlinePrefix: customValidation('Prefix'),
    awbSerialNumber: customValidation('AWB serial', {length: 8}),
    awbOrigin: customValidation('Origin'),
    awbDestination: customValidation('Destination'),
    awbShipmentDescriptionCode: customValidation('Shipment description', {
      required: false,
      isFreeText: true,
    }),
    awbWeightCode: customValidation('Weight code'),
    awbNumberOfPieces: customValidation('Number of Pieces'),
    awbWeight: customValidation('Weight'),
    awbVolumeCode: linkedFieldValidation(
      'Volume Code',
      'awbVolumeAmount',
      'Volume Amount',
    ),
    awbVolumeAmount: linkedFieldValidation(
      'Volume Amount',
      'awbVolumeCode',
      'Volume Code',
    ),
    awbDensityIndicator: linkedFieldValidation(
      'Density Indicator',
      'awbDensityGroup',
      'Density Group',
    ),
    awbDensityGroup: linkedFieldValidation(
      'Density Group',
      'awbDensityIndicator',
      'Density Indicator',
    ),
    flightBookings: Yup.array().of(
      Yup.object().shape({
        flightBookingCarrierCode: linkedFieldsValidation(
          'flightBookingCarrierCode',
          'Carrier Code',
          fieldsFlightBooking,
        ),
        flightBookingFlightNumber: linkedFieldsValidation(
          'flightBookingFlightNumber',
          'Flight Number',
          fieldsFlightBooking,
        ),
        flightBookingFlightDay: linkedFieldsValidation(
          'flightBookingFlightDay',
          'Day',
          fieldsFlightBooking,
        ),
      }),
    ),
    destinationAirportCode: Yup.string().optional(),
    destinationCarrierCode: customValidation('Carrier Code'),
    routing: Yup.array().of(
      Yup.object().shape({
        onwardDestinationAirportCode: customValidation('Airport/City Code'),
        onwardDestinationCarrierCode: Yup.string().optional(),
      }),
    ),
    shipperAccountNumber: Yup.string().optional(),
    shipperName: customValidation('Shipper name'),
    shipperStreetAddress: customValidation('Shipper street address'),
    shipperCity: customValidation('Shipper city'),
    shipperProvince: Yup.string().optional(),
    shipperCountryCode: customValidation('Shipper country code'),
    shipperPostalCode: Yup.string().optional(),
    // shipperContactIdentifier: customValidation('Shipper contact identifier'),
    shipperContactNumber: optionalPhoneValidation('Shipper Telephone'),
    consigneeAccountNumber: Yup.string().optional(),
    consigneeName: customValidation('Consignee name'),
    consigneeStreetAddress: customValidation('Consignee street address'),
    consigneeCity: customValidation('Consignee city'),
    consigneeProvince: Yup.string().optional(),
    consigneeCountryCode: customValidation('Consignee country code'),
    consigneePostalCode: Yup.string().optional(),
    // consigneeContactIdentifier: customValidation('Consignee contact identifier'),
    consigneeContactNumber: optionalPhoneValidation('Consignee Telephone'),

    //Step 2
    agentAccountNumber: Yup.string(),
    agentIataCargoAgentNumericCode: customValidation(
      'IATA Cargo Agent Numeric Code',
      {
        required: false,
      },
    ),
    agentIataCargoAgentAddress: Yup.string(),
    agentParticipantIdentifier: Yup.string(),
    agentName: customValidation('Name', {required: false}),
    agentPlaces: customValidation('Place', {required: false}),
    specialServiceRequest: Yup.array().of(
      Yup.object().shape({
        value: Yup.string(),
      }),
    ),
    alsoNotifyName: linkedFieldsValidation(
      'alsoNotifyName',
      'Name',
      fieldsAlsoNotify,
    ),
    alsoNotifyAddress: linkedFieldsValidation(
      'alsoNotifyAddress',
      'Street Address',
      fieldsAlsoNotify,
    ),
    alsoNotifyPlace: linkedFieldsValidation(
      'alsoNotifyPlace',
      'Place',
      fieldsAlsoNotify,
    ),
    alsoNotifyProvince: Yup.string(),
    alsoNotifyCountryCode: linkedFieldsValidation(
      'alsoNotifyCountryCode',
      'ISO Country Code',
      fieldsAlsoNotify,
    ),
    alsoNotifyPostalCode: Yup.string(),
    // alsoNotifyContactIdentifier: customValidation('Contact Identifier'),
    alsoNotifyContactNumber: optionalPhoneValidation('Telephone'),
    accountingInformation: Yup.array().of(
      Yup.object().shape({
        accountingInformationIdentifier: linkedFieldValidation(
          'Accounting Information Identifier',
          'accountingInformation',
          'Accounting Information',
        ),
        accountingInformation: linkedFieldValidation(
          'Accounting Information',
          'accountingInformationIdentifier',
          'Accounting Information Identifier',
        ),
      }),
    ),
    currencyCode: customValidation('ISO Country Code'),
    // chargeDeclarationsChargeCode: customValidation('Charge Code'),
    chargeDeclarationsChargeCode: Yup.string().optional(),
    valuation: linkedFieldValidation(
      'Weight / Valuation',
      'otherCharges',
      'Other Charges',
    ),
    otherCharges: linkedFieldValidation(
      'Other Charges',
      'valuation',
      'Weight / Valuation',
    ),
    declaredValueForCustoms: Yup.string().optional(),
    declaredValueForCarriage: Yup.string().optional(),
    amountOfInsurance: Yup.string().optional(),

    //Step 3
    rates: Yup.array().of(
      Yup.object().shape({
        ratePieces: customValidation('No. of Pieces'),
        rateGrossWeight: customValidation('Gross weght'),
        rateKode: customValidation('Kode'),
        rateClass: customValidation('Rate Class'),
        rateCommodityItemNo: customValidation('Commodity Item No'),
        rateChargeableWeight: customValidation('Chargeable Weight'),
        rateCharge: customValidation('Rate Charge'),
        rateTotal: customValidation('Total'),
        rateNatureQtyGoods: Yup.array().of(
          Yup.object().shape({
            value: customValidation('Nature and Quantity of Goods'),
          }),
        ),
      }),
    ),
    otherCharge: Yup.array().of(
      Yup.object().shape({
        otherChargeIndicator: Yup.string().when('items', (items, schema) => {
          const isAnyItemFilled = items.some((item) =>
            Object.values(item).some((value) => !!value),
          )
          return isAnyItemFilled
            ? schema.required(
                'P/C Indicator harus diisi jika salah satu field lain ada yang diisi',
              )
            : schema
        }),
        items: Yup.array().of(
          Yup.object().shape({
            otherChargeChargeCode: linkedFieldsValidation(
              'otherChargeChargeCode',
              'Charge Code',
              fieldsOtherCharges,
            ),
            entitlementCode: linkedFieldsValidation(
              'entitlementCode',
              'Entitlement Code',
              fieldsOtherCharges,
            ),
            otherChargeAmount: linkedFieldsValidation(
              'otherChargeAmount',
              'Charge Amount',
              fieldsOtherCharges,
            ),
          }),
        ),
      }),
    ),
    prepaidTotalWeightCharge: Yup.string(),
    prepaidValuationCharge: Yup.string(),
    prepaidTaxes: Yup.string(),
    prepaidTotalOrderChargeDueAgent: Yup.string(),
    prepaidTotalOrderChargeDueCarrier: Yup.string(),
    prepaidChargeSummaryTotal: Yup.string(),
    collectTotalWeightCharge: Yup.string(),
    collectValuationCharge: Yup.string(),
    collectTaxes: Yup.string(),
    collectTotalOrderChargeDueAgent: Yup.string(),
    collectTotalOrderChargeDueCarrier: Yup.string(),
    collectChargeSummaryTotal: Yup.string(),
    shipperCertificationSignature: Yup.string(),
    carrierExecutionDate: Yup.string().matches(
      /^(0[1-9]|[12][0-9]|3[01])(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)\d{2}$/,
      'Format Executed on (date) harus DDMMMYY, contoh: 21FEB25',
    ),
    // .required('Executed on (date) tidak boleh kosong'),
    carrierExecutionPlace: customValidation('Place'),
    carrierExecutionSignature: Yup.string(),
    otherService: Yup.array().of(
      Yup.object().shape({
        value: Yup.string(),
      }),
    ),

    // Step 4
    destinationCurrencyCode: linkedFieldsValidation(
      'destinationCurrencyCode',
      'ISO Currency Code',
      fieldsCCCharges,
    ),
    currencyConversationRate: linkedFieldsValidation(
      'currencyConversationRate',
      'Rate of Exchange',
      fieldsCCCharges,
    ),
    ccChargeDestinationCurrency: linkedFieldsValidation(
      'ccChargeDestinationCurrency',
      'Change Amount',
      fieldsCCCharges,
    ),
    chargeDestinationAmount: linkedFieldsValidation(
      'chargeDestinationAmount',
      'Change Amount',
      fieldsCCCharges,
    ),
    totalCollectChargeAmount: Yup.string(),
    senderOfficeAirportCode: linkedFieldsValidation(
      'senderOfficeAirportCode',
      'Airport/City Code',
      fieldsSenderOffice,
    ),

    senderOfficeFunctionDesignator: linkedFieldsValidation(
      'senderOfficeFunctionDesignator',
      'Other Function Designator',
      fieldsSenderOffice,
    ),
    senderCompanyDesignator: linkedFieldsValidation(
      'senderCompanyDesignator',
      'Company Designator',
      fieldsSenderOffice,
    ),
    senderParticipantIdentifier: linkedFieldsValidation(
      'senderParticipantIdentifier',
      'Participant Identifier',
      fieldsSenderParticipant,
    ),
    senderParticipantCode: linkedFieldsValidation(
      'senderParticipantCode',
      'Participant Code',
      fieldsSenderParticipant,
    ),
    senderDestinationAirport: linkedFieldsValidation(
      'senderDestinationAirport',
      'Airport/City Code',
      fieldsSenderParticipant,
    ),
    senderOfficeFileReference: Yup.string(),
    customOriginCode: Yup.string(),
    noCommissionIndicator: Yup.string(),
    commissionAmount: Yup.string(),
    commissionPercentage: Yup.string(),
    salesIncentiveInformationAmount: Yup.string(),
    salesIncentiveInformationCassIndicator: Yup.string(),
    fileReference: Yup.string(),

    // Step 5
    hwbSpecialHandlingCode: Yup.mixed()
      .test('valid-type', 'Format tidak valid', (value) => {
        return Array.isArray(value) || typeof value === 'string'
      })
      .test('max-codes', 'Maksimal 9 special handling code', (value) => {
        if (Array.isArray(value)) {
          return value.length <= 9 // Jika array, pastikan max 9 item
        }
        return true
      })
      .test('manual-validation', 'Kode tidak valid', (value, context) => {
        if (typeof value === 'string') {
          let codes = value
            .trim()
            .split(',')
            .filter((v) => v !== '')
            .map((code) => code.trim().toUpperCase())
          codes = [...new Set(codes)]

          const invalidCodes = codes.filter(
            (code) => !handlingCodeList.some((a) => a.code === code),
          )
          if (invalidCodes.length > 0) {
            return context.createError({
              message: `${invalidCodes.join(', ')} tidak valid`,
            })
          }
        }
        return true
      }),
    nominatedHandlingPartyName: linkedFieldValidation(
      'Name',
      'nominatedHandlingPartyPlace',
      'Place',
    ),
    nominatedHandlingPartyPlace: linkedFieldValidation(
      'Place',
      'nominatedHandlingPartyName',
      'Name',
    ),
    // shipmentReferenceNumber: Yup.string().when(['shipmentReferenceSupplementaryInformation', 'shipmentReferenceSupplementaryInformation2'], {
    //   is: (shipmentReferenceSupplementaryInformation2, shipmentReferenceSupplementaryInformation) => !shipmentReferenceSupplementaryInformation2 && !shipmentReferenceSupplementaryInformation,
    //   then: (schema) => schema.required('Reference Number harus diisi jika field lain tidak ada yang diisi'),
    //   otherwise: (schema) => schema.notRequired(),
    // }),
    shipmentReferenceNumber: Yup.string(),
    shipmentReferenceSupplementaryInformation: Yup.string(),
    shipmentReferenceSupplementaryInformation2: Yup.string(),
    otherParticipantOffice: Yup.array().of(
      Yup.object().shape({
        otherParticipantName: Yup.string().when(
          [
            'otherParticipantOfficeAirportCode',
            'otherParticipantOfficeFunctionDesignator',
            'otherParticipantCompanyDesignator',
            'otherParticipantOfficeFileReference',
            'otherParticipantParticipantIdentifier',
            'otherParticipantParticipantCode',
            'otherParticipantDestinationAirport',
          ],
          {
            is: (
              otherParticipantOfficeAirportCode,
              otherParticipantOfficeFunctionDesignator,
              otherParticipantCompanyDesignator,
              otherParticipantOfficeFileReference,
              otherParticipantParticipantIdentifier,
              otherParticipantParticipantCode,
              otherParticipantDestinationAirport,
            ) =>
              otherParticipantOfficeAirportCode ||
              otherParticipantOfficeFunctionDesignator ||
              otherParticipantCompanyDesignator ||
              otherParticipantOfficeFileReference ||
              otherParticipantParticipantIdentifier ||
              otherParticipantParticipantCode ||
              otherParticipantDestinationAirport,
            then: (schema) =>
              schema.required('Name harus diisi jika field lain diisi'),
            otherwise: (schema) => schema.notRequired(),
          },
        ),
        otherParticipantOfficeFileReference: Yup.string(),

        otherParticipantOfficeAirportCode: linkedFieldsValidation(
          'otherParticipantOfficeAirportCode',
          'Airport/City Code',
          fieldsOtherSenderOffice,
        ),
        otherParticipantOfficeFunctionDesignator: linkedFieldsValidation(
          'otherParticipantOfficeFunctionDesignator',
          'Other Function Designator',
          fieldsOtherSenderOffice,
        ),
        otherParticipantCompanyDesignator: linkedFieldsValidation(
          'otherParticipantCompanyDesignator',
          'Company Designator',
          fieldsOtherSenderOffice,
        ),

        otherParticipantParticipantIdentifier: linkedFieldsValidation(
          'otherParticipantParticipantIdentifier',
          'Participant Identifier',
          fieldsOtherSenderParticipant,
        ),
        otherParticipantParticipantCode: linkedFieldsValidation(
          'otherParticipantParticipantCode',
          'Participant Code',
          fieldsOtherSenderParticipant,
        ),

        otherParticipantDestinationAirport: linkedFieldsValidation(
          'otherParticipantDestinationAirport',
          'Airport/City Code',
          fieldsOtherSenderParticipant,
        ),
      }),
    ),

    otherCustomInfo: Yup.array().of(
      Yup.object().shape({
        informationIdentifier: Yup.string().optional(),
        countryCode: Yup.string().when(
          ['informationIdentifier', 'customInfoIdentifier'],
          {
            is: (customInfoIdentifier, informationIdentifier) =>
              !customInfoIdentifier && !informationIdentifier,
            then: (schema) =>
              schema.required(
                'Country Code harus diisi jika field lain tidak diisi',
              ),
            otherwise: (schema) => schema.notRequired(),
          },
        ),
        customInfoIdentifier: Yup.string().optional(),
        supplementaryCustomInformation: Yup.string().when(
          ['countryCode', 'customInfoIdentifier', 'informationIdentifier'],
          {
            is: (countryCode, customInfoIdentifier, informationIdentifier) =>
              !!countryCode ||
              !!customInfoIdentifier ||
              !!informationIdentifier,
            then: (schema) =>
              schema.required(
                'Supplementary Custom Infomation harus diisi jika field lain ada yang diisi',
              ),
            otherwise: (schema) => schema.notRequired(),
          },
        ),
      }),
    ),
  })

  const stepFields = {
    1: [
      'airlinePrefix',
      'awbSerialNumber',
      'awbOrigin',
      'awbDestination',
      'awbShipmentDescriptionCode',
      'awbWeightCode',
      'awbNumberOfPieces',
      'awbWeight',
      'awbVolumeCode',
      'awbVolumeAmount',
      'awbDensityIndicator',
      'awbDensityGroup',
      'flightBookings',
      'destinationAirportCode',
      'destinationCarrierCode',
      'routing',
      'shipperAccountNumber',
      'shipperName',
      'shipperName2',
      'shipperStreetAddress',
      'shipperStreetAddress2',
      'shipperCity',
      'shipperProvince',
      'shipperCountryCode',
      'shipperPostalCode',
      'shipperContactIdentifier',
      'shipperContactNumber',
      'consigneeAccountNumber',
      'consigneeName',
      'consigneeName2',
      'consigneeStreetAddress',
      'consigneeStreetAddress2',
      'consigneeCity',
      'consigneeProvince',
      'consigneeCountryCode',
      'consigneePostalCode',
      'consigneeContactIdentifier',
      'consigneeContactNumber',
    ],
    2: [
      'noCommissionIndicator',
      'commissionAmount',
      'commissionPercentage',
      'agentAccountNumber',
      'agentIataCargoAgentNumericCode',
      'agentIataCargoAgentAddress',
      'agentParticipantIdentifier',
      'agentName',
      'agentPlaces',
      'specialServiceRequest',
      'alsoNotifyName',
      'alsoNotifyAddress',
      'alsoNotifyPlace',
      'alsoNotifyProvince',
      'alsoNotifyCountryCode',
      'alsoNotifyPostalCode',
      'alsoNotifyContactIdentifier',
      'alsoNotifyContactNumber',
      'accountingInformation',
      'currencyCode',
      'chargeDeclarationsChargeCode',
      'valuation',
      'otherCharges',
      'declaredValueForCustoms',
      'declaredValueForCarriage',
      'amountOfInsurance',
    ],
    3: [
      'rates',
      'otherCharge',
      'prepaidTotalWeightCharge',
      'prepaidValuationCharge',
      'prepaidTaxes',
      'prepaidTotalOrderChargeDueAgent',
      'prepaidTotalOrderChargeDueCarrier',
      'prepaidChargeSummaryTotal',
      'collectTotalWeightCharge',
      'collectValuationCharge',
      'collectTaxes',
      'collectTotalOrderChargeDueAgent',
      'collectTotalOrderChargeDueCarrier',
      'collectChargeSummaryTotal',
      'shipperCertificationSignature',
      'carrierExecutionDate',
      'carrierExecutionPlace',
      'carrierExecutionSignature',
      'otherService',
    ],
    4: [
      'destinationCurrencyCode',
      'currencyConversationRate',
      'ccChargeDestinationCurrency',
      'chargeDestinationAmount',
      'totalCollectChargeAmount',
      'senderOfficeAirportCode',
      'senderOfficeFunctionDesignator',
      'senderCompanyDesignator',
      'senderOfficeFileReference',
      'senderParticipantIdentifier',
      'senderParticipantCode',
      'senderDestinationAirport',
      'customOriginCode',
      'salesIncentiveInformationAmount',
      'salesIncentiveInformationCassIndicator',
      'fileReference',
    ],
    5: [
      'hwbSpecialHandlingCode',
      'nominatedHandlingPartyName',
      'nominatedHandlingPartyPlace',
      'shipmentReferenceNumber',
      'shipmentReferenceSupplementaryInformation',
      'shipmentReferenceSupplementaryInformation2',
      'otherParticipantOffice',
      'otherCustomInfo',
    ],
  }

  const formOptions = {
    mode: 'onChange',
    resolver: yupResolver(validation),
    defaultValues: {
      airlinePrefix: '',
      awbSerialNumber: '',
      awbOrigin: '',
      awbDestination: '',
      awbShipmentDescriptionCode: '',
      awbNumberOfPieces: '',
      awbWeightCode: 'K',
      awbVolumeAmount: '',
      awbDensityIndicator: '',
      awbWeight: '',
      awbVolumeCode: '',
      awbDensityGroup: '',
      specialServiceRequest: [{value: ''}],
      flightBookings: [
        {
          flightBookingCarrierCode: '',
          flightBookingFlightNumber: '',
          flightBookingFlightDay: '',
        },
      ],
      destinationAirportCode: '',
      destinationCarrierCode: '',
      routing: [
        {onwardDestinationAirportCode: '', onwardDestinationCarrierCode: ''},
      ],
      shipperAccountNumber: '',
      shipperName: '',
      shipperName2: '',
      shipperStreetAddress: '',
      shipperStreetAddress2: '',
      shipperCity: '',
      shipperProvince: '',
      shipperCountryCode: '',
      shipperPostalCode: '',
      shipperContactIdentifier: '',
      shipperContactNumber: '',
      consigneeAccountNumber: '',
      consigneeName: '',
      consigneeName2: '',
      consigneeStreetAddress: '',
      consigneeStreetAddress2: '',
      consigneeCity: '',
      consigneeProvince: '',
      consigneeCountryCode: '',
      consigneePostalCode: '',
      consigneeContactIdentifier: '',
      consigneeContactNumber: '',
      agentAccountNumber: '',
      agentIataCargoAgentNumericCode: '',
      agentIataCargoAgentAddress: '',
      agentParticipantIdentifier: '',
      agentName: '',
      agentPlaces: '',
      alsoNotifyName: '',
      alsoNotifyAddress: '',
      alsoNotifyPlace: '',
      alsoNotifyProvince: '',
      alsoNotifyCountryCode: '',
      alsoNotifyPostalCode: '',
      alsoNotifyContactIdentifier: '',
      alsoNotifyContactNumber: '',
      accountingInformation: [
        {accountingInformationIdentifier: '', accountingInformation: ''},
      ],
      currencyCode: '',
      chargeDeclarationsChargeCode: '',
      valuation: '',
      otherCharges: '',
      declaredValueForCustoms: '',
      declaredValueForCarriage: '',
      amountOfInsurance: '',
      otherCharge: [
        {
          otherChargeIndicator: '',
          items: [
            {
              otherChargeChargeCode: '',
              entitlementCode: '',
              otherChargeAmount: '',
            },
          ],
        },
      ],
      rates: [
        {
          ratePieces: '',
          rateGrossWeight: '',
          rateKode: '',
          rateClass: '',
          rateCommodityItemNo: '',
          rateChargeableWeight: '',
          rateCharge: '',
          rateTotal: '',
          rateNatureQtyGoods: [{value: ''}],
        },
      ],
      prepaidTotalWeightCharge: '',
      prepaidValuationCharge: '',
      prepaidTaxes: '',
      prepaidTotalOrderChargeDueAgent: '',
      prepaidTotalOrderChargeDueCarrier: '',
      prepaidChargeSummaryTotal: '',
      collectTotalWeightCharge: '',
      collectValuationCharge: '',
      collectTaxes: '',
      collectTotalOrderChargeDueAgent: '',
      collectTotalOrderChargeDueCarrier: '',
      collectChargeSummaryTotal: '',
      shipperCertificationSignature: '',
      carrierExecutionDate: '',
      carrierExecutionPlace: '',
      carrierExecutionSignature: '',
      otherService: [{value: ''}],
      destinationCurrencyCode: '',
      currencyConversationRate: '',
      ccChargeDestinationCurrency: '',
      chargeDestinationAmount: '',
      totalCollectChargeAmount: '',
      senderDestinationAirport: '',
      senderOfficeFunctionDesignator: '',
      senderCompanyDesignator: '',
      senderOfficeFileReference: '',
      senderParticipantIdentifier: '',
      senderParticipantCode: '',
      senderOfficeAirportCode: '',
      customOriginCode: '',
      noCommissionIndicator: '',
      commissionAmount: '',
      commissionPercentage: '',
      salesIncentiveInformationAmount: '',
      salesIncentiveInformationCassIndicator: '',
      fileReference: '',
      hwbSpecialHandlingCode: [],
      nominatedHandlingPartyName: '',
      nominatedHandlingPartyPlace: '',
      shipmentReferenceNumber: '',
      shipmentReferenceSupplementaryInformation: '',
      shipmentReferenceSupplementaryInformation2: '',
      otherParticipantOffice: [
        {
          otherParticipantName: '',
          otherParticipantOfficeAirportCode: '',
          otherParticipantOfficeFunctionDesignator: '',
          otherParticipantCompanyDesignator: '',
          otherParticipantOfficeFileReference: '',
          otherParticipantParticipantIdentifier: '',
          otherParticipantParticipantCode: '',
          otherParticipantDestinationAirport: '',
        },
      ],
      otherCustomInfo: [
        {
          countryCode: '',
          informationIdentifier: '',
          customInfoIdentifier: '',
          supplementaryCustomInformation: '',
        },
      ],
    },
  }
  const {
    handleSubmit,
    formState: {errors},
    reset,
    control,
    getValues,
    setValue,
    clearErrors,
    setError,
    trigger,
    watch,
  } = useForm(formOptions)

  const {
    append: appendSpecialServiceRequest,
    fields: fieldSpecialServiceRequest,
    remove: removeSpecialServiceRequest,
  } = useFieldArray({
    control,
    name: 'specialServiceRequest',
  })

  const {
    fields: fieldsRouting,
    append: appendRouting,
    remove: removeRouting,
  } = useFieldArray({
    control,
    name: 'routing',
  })

  const {
    fields: fieldsFlightBookings,
    append: appendFlightBookings,
    remove: removeFlightBookings,
  } = useFieldArray({
    control,
    name: 'flightBookings',
  })

  const {
    fields: fieldsAccountingInformation,
    append: appendAccountingInformation,
    remove: removeAccountingInformation,
  } = useFieldArray({
    control,
    name: 'accountingInformation',
  })

  const {
    fields: fieldsOtherService,
    append: appendOtherService,
    remove: removeOtherService,
  } = useFieldArray({
    control,
    name: 'otherService',
  })

  const {
    fields: fieldsOtherCharge,
    append: appendOtherCharge,
    remove: removeOtherCharge,
  } = useFieldArray({
    control,
    name: 'otherCharge',
  })

  const {
    fields: fieldsRates,
    append: appendRates,
    remove: removeRates,
  } = useFieldArray({
    control,
    name: 'rates',
  })

  const {
    fields: fieldsOtherParticipantOffice,
    append: appendOtherParticipantOffice,
    remove: removeOtherParticipantOffice,
  } = useFieldArray({
    control,
    name: 'otherParticipantOffice',
  })

  const {
    fields: fieldsOtherCustomInfo,
    append: appendOtherCustomInfo,
    remove: removeOtherCustomInfo,
  } = useFieldArray({
    control,
    name: 'otherCustomInfo',
  })

  async function toggleTab(tab) {
    if (Object.keys(errors).length > 0) return
    if (activeTab !== tab) {
      const modifiedSteps = [...passedSteps, tab]

      if (tab >= 1 && tab <= 6) {
        if (activeTab < tab) {
          const isValid = await trigger(stepFields[activeTab])
          if (!isValid) {
            return
          }
        }
        setActiveTab(tab)
        setPassedSteps(modifiedSteps)
      }
    }
  }

  const {mutate: updateFwbAgentForm, isLoading: isLoadingUpdate} =
    RequestOnlineAgentFormRQ.useUpdateFwbData(id, navigate)()

  const {mutate: addFwbAgentForm, isLoading: isLoadingCreate} =
    RequestOnlineAgentFormRQ.useAddFwbData(navigate)()

  const _updateFwb = (_) => {
    console.log('update')

    updateFwbAgentForm({
      data: getPayload(false),
      buttonType: isAdmin
        ? 'admin'
        : detailData?.data?.payment_status === ONLINE_AGENT_PAYMENT_STATUS.PAID
        ? 'submit'
        : 'pay',
    })
  }

  const _createFwb = async (_) => {
    addFwbAgentForm({
      data: getPayload(false),
      buttonType: isAdmin ? 'admin' : 'pay',
    })
  }

  const _onDraft = async () => {
    clearErrors()
    if (!id || type === 'copy') {
      addFwbAgentForm({data: getPayload(true), buttonType: 'submit'})
    } else {
      updateFwbAgentForm({data: getPayload(true), buttonType: 'submit'})
    }
  }

  const numberOrNull = (value) => {
    return isNaN(value) || value === '' ? null : Number(value)
  }

  const getPayload = (isDraft) => {
    const formData = getValues()
    return {
      is_draft: false,
      data: {
        awb: {
          air_way_bill_no: formData.awbSerialNumber,
          air_line: formData.airlinePrefix,
          origin_code: formData.awbOrigin,
          destination_code: formData.awbDestination,
          shipment_description_code: formData.awbShipmentDescriptionCode,
          number_of_pieces: numberOrNull(formData.awbNumberOfPieces),
          weight_code: formData.awbWeightCode,
          weight: numberOrNull(formData.awbWeight),
          volume_code: formData.awbVolumeCode,
          volume: numberOrNull(formData.awbVolumeAmount),
          density_indicator: formData.awbDensityIndicator,
          density_group: formData.awbDensityGroup,
        },
        flight_bookings: formData.flightBookings.map((fb) => ({
          career_code: fb.flightBookingCarrierCode,
          flight_number: fb.flightBookingFlightNumber,
          day: numberOrNull(fb.flightBookingFlightDay),
        })),
        routing: {
          destination_airport_code: formData.destinationAirportCode,
          destination_carrier_code: formData.destinationCarrierCode,
          onward_destination: formData.routing.map(
            ({onwardDestinationAirportCode, onwardDestinationCarrierCode}) => ({
              onward_destination_airport_code: onwardDestinationAirportCode,
              onward_destination_carrier_code: onwardDestinationCarrierCode,
            }),
          ),
        },
        shipper: {
          account_number: formData.shipperAccountNumber,
          name: formData.shipperName,
          street_address: formData.shipperStreetAddress,
          street_address2: formData.shipperStreetAddress2,
          place: formData.shipperCity,
          province: formData.shipperProvince,
          country_code: formData.shipperCountryCode,
          postal_code: formData.shipperPostalCode,
          contact_identifier: formData.shipperContactIdentifier,
          contact_number: formData.shipperContactNumber,
        },
        consignee: {
          account_number: formData.consigneeAccountNumber,
          name: formData.consigneeName,
          street_address: formData.consigneeStreetAddress,
          street_address2: formData.consigneeStreetAddress2,
          place: formData.consigneeCity,
          province: formData.consigneeProvince,
          country_code: formData.consigneeCountryCode,
          postal_code: formData.consigneePostalCode,
          contact_identifier: formData.consigneeContactIdentifier,
          contact_number: formData.consigneeContactNumber,
        },
        agent: {
          account_number: formData.agentAccountNumber,
          iata_cargo_agent_numeric_code:
            formData.agentIataCargoAgentNumericCode,
          iata_cargo_agent_address: formData.agentIataCargoAgentAddress,
          participant_identifier: formData.agentParticipantIdentifier,
          name: formData.agentName,
          place: formData.agentPlaces,
        },
        special_service_requests: formData.specialServiceRequest.map(
          (item) => item.value,
        ),
        also_notify: {
          name: formData.alsoNotifyName,
          street_address: formData.alsoNotifyAddress,
          city: formData.alsoNotifyPlace,
          province: formData.alsoNotifyProvince,
          country_code: formData.alsoNotifyCountryCode,
          postal_code: formData.alsoNotifyPostalCode,
          contact_identifier: formData.alsoNotifyContactIdentifier,
          contact_number: formData.alsoNotifyContactNumber,
        },
        accounting_information: formData.accountingInformation.map((acc) => ({
          account_information_identifier: acc.accountingInformationIdentifier,
          accounting_information: acc.accountingInformation,
        })),
        charge_declarations: {
          iso_currency_code: formData.currencyCode,
          charge_code: formData.chargeDeclarationsChargeCode,
          valuation: formData.valuation,
          other_charges: formData.otherCharges,
          declared_value_for_customs: numberOrNull(
            formData.declaredValueForCustoms,
          ),
          declared_value_for_carriage: numberOrNull(
            formData.declaredValueForCarriage,
          ),
          amount_of_insurance: numberOrNull(formData.amountOfInsurance),
        },
        rate_descriptions: formData.rates.map((rate) => ({
          no_of_pieces: numberOrNull(rate.ratePieces),
          gross_weight: numberOrNull(rate.rateGrossWeight),
          code: rate.rateKode,
          rate_class: rate.rateClass,
          commodity_item_no: rate.rateCommodityItemNo,
          chargeable_weight: numberOrNull(rate.rateChargeableWeight),
          rate_charge: numberOrNull(rate.rateCharge),
          total: numberOrNull(rate.rateTotal),
          nature_and_quantity_of_goods: rate.rateNatureQtyGoods?.map(
            (item) => item?.value,
          ),
        })),
        other_charges: formData.otherCharge.map((oc) => ({
          other_charge_indicator: oc.otherChargeIndicator,
          items: oc?.items?.map((item) => ({
            charge_code: item.otherChargeChargeCode,
            entitlement_code: item.entitlementCode,
            charge_amount: numberOrNull(item.otherChargeAmount),
          })),
        })),
        prepaid_charge_summary: {
          total_weight_charge_amount: numberOrNull(
            formData.prepaidTotalWeightCharge,
          ),
          valuation_charge_amount: numberOrNull(
            formData.prepaidValuationCharge,
          ),
          taxes_charge_amount: numberOrNull(formData.prepaidTaxes),
          total_order_charge_due_agent_amount: numberOrNull(
            formData.prepaidTotalOrderChargeDueAgent,
          ),
          total_order_charge_due_carrier_amount: numberOrNull(
            formData.prepaidTotalOrderChargeDueCarrier,
          ),
          charge_summary_total_amount: numberOrNull(
            formData.prepaidChargeSummaryTotal,
          ),
        },
        collect_charge_summary: {
          total_weight_charge_amount: numberOrNull(
            formData.collectTotalWeightCharge,
          ),
          valuation_charge_amount: numberOrNull(
            formData.collectValuationCharge,
          ),
          taxes_charge_amount: numberOrNull(formData.collectTaxes),
          total_order_charge_due_agent_amount: numberOrNull(
            formData.collectTotalOrderChargeDueAgent,
          ),
          total_order_charge_due_carrier_amount: numberOrNull(
            formData.collectTotalOrderChargeDueCarrier,
          ),
          charge_summary_total_amount: numberOrNull(
            formData.collectChargeSummaryTotal,
          ),
        },
        shipper_signature: formData.shipperCertificationSignature,
        shipper_certification: {
          executed_date: formData.carrierExecutionDate,
          place: formData.carrierExecutionPlace,
          signature: formData.carrierExecutionSignature,
        },
        other_service_information: formData.otherService.map(
          (service) => service.value,
        ),
        cc_charge_destination_currency: {
          destination_currency_code: formData.destinationCurrencyCode,
          currency_rate_exchange: numberOrNull(
            formData.currencyConversationRate,
          ),
          cc_charge_amount: numberOrNull(formData.ccChargeDestinationCurrency),
          charge_destination_amount: numberOrNull(
            formData.chargeDestinationAmount,
          ),
          total_charge_amount: numberOrNull(formData.totalCollectChargeAmount),
        },
        sender_reference: {
          sender_office_airport_code: formData.senderOfficeAirportCode,
          sender_office_function_designator:
            formData.senderOfficeFunctionDesignator,
          sender_office_company_designator: formData.senderCompanyDesignator,
          sender_file_reference: formData.senderOfficeFileReference,
          sender_participant_airport_code: formData.senderDestinationAirport,
          sender_participant_identifier: formData.senderParticipantIdentifier,
          sender_participant_code: formData.senderParticipantCode,
        },
        customs_origin: formData.customOriginCode,
        commission_information: {
          no_commission_indication: formData.noCommissionIndicator,
          commission_amount:
            formData.noCommissionIndicator === 'Amount'
              ? numberOrNull(formData.commissionAmount)
              : null,
          commission_percentage:
            formData.noCommissionIndicator === 'Amount'
              ? numberOrNull(formData.commissionPercentage)
              : null,
        },
        sales_incentive_information: {
          charge_amount: numberOrNull(formData.salesIncentiveInformationAmount),
          cass_indication: formData.salesIncentiveInformationCassIndicator,
        },
        agent_reference_file: formData.fileReference,
        special_handling_code: formData.hwbSpecialHandlingCode,
        nominated_handling_party: {
          name: formData.nominatedHandlingPartyName,
          place: formData.nominatedHandlingPartyPlace,
        },
        shipment_reference_information: {
          reference_number: formData.shipmentReferenceNumber,
          supplementary_shipment_information:
            formData.shipmentReferenceSupplementaryInformation,
          supplementary_shipment_information2:
            formData.shipmentReferenceSupplementaryInformation2,
        },
        other_participant_information: formData.otherParticipantOffice.map(
          (item) => ({
            name: item.otherParticipantName,
            other_participant_office_airport_code:
              item.otherParticipantOfficeAirportCode,
            other_participant_office_function_designator:
              item.otherParticipantOfficeFunctionDesignator,
            other_participant_office_company_designator:
              item.otherParticipantCompanyDesignator,
            other_participant_office_file_reference:
              item.otherParticipantOfficeFileReference,
            other_participant_airport_code:
              item.otherParticipantDestinationAirport,
            other_participant_identifier:
              item.otherParticipantParticipantIdentifier,
            other_participant_code: item.otherParticipantParticipantCode,
          }),
        ),

        other_information: formData.otherCustomInfo.map((info) => ({
          iso_country_code: info.countryCode,
          custom_info_identifier: info.customInfoIdentifier,
          information_identifier: info.informationIdentifier,
          supplementary_information: info.supplementaryCustomInformation,
        })),
      },
    }
  }

  const onSubmit = (data) => {
    if (id && type !== 'copy') {
      return _updateFwb(data)
    }
    return _createFwb(data)
  }

  const setValueEdit = (detailData) => {
    const fwb = detailData?.data?.data

    reset({
      awbSerialNumber: fwb?.awb?.air_way_bill_no ?? '',
      airlinePrefix: fwb?.awb?.air_line ?? '',
      awbOrigin: fwb?.awb?.origin_code ?? '',
      awbDestination: fwb?.awb?.destination_code ?? '',
      awbShipmentDescriptionCode: fwb?.awb?.shipment_description_code ?? '',
      awbNumberOfPieces: fwb?.awb?.number_of_pieces ?? '',
      awbWeightCode: fwb?.awb?.weight_code ?? '',
      awbWeight: fwb?.awb?.weight ?? '',
      awbVolumeCode: fwb?.awb?.volume_code ?? '',
      awbVolumeAmount: fwb?.awb?.volume ?? '',
      awbDensityIndicator: fwb?.awb?.density_indicator ?? '',
      awbDensityGroup: fwb?.awb?.density_group ?? '',

      flightBookings: fwb?.flight_bookings?.map((fb) => ({
        flightBookingCarrierCode: fb?.career_code ?? '',
        flightBookingFlightNumber: fb?.flight_number ?? '',
        flightBookingFlightDay: fb?.day ?? '',
      })) ?? [
        {
          flightBookingCarrierCode: '',
          flightBookingFlightNumber: '',
          flightBookingFlightDay: '',
        },
      ],

      destinationAirportCode: fwb?.routing?.destination_airport_code ?? '',
      destinationCarrierCode: fwb?.routing?.destination_carrier_code ?? '',
      routing: fwb?.routing?.onward_destination?.map((route) => ({
        onwardDestinationAirportCode:
          route?.onward_destination_airport_code ?? '',
        onwardDestinationCarrierCode:
          route?.onward_destination_carrier_code ?? '',
      })) ?? [
        {
          onwardDestinationAirportCode: '',
          onwardDestinationCarrierCode: '',
        },
      ],

      shipperAccountNumber: fwb?.shipper?.account_number ?? '',
      shipperName: fwb?.shipper?.name ?? '',
      shipperName2: fwb?.shipper?.name2 ?? '',
      shipperStreetAddress: fwb?.shipper?.street_address ?? '',
      shipperStreetAddress2: fwb?.shipper?.street_address2 ?? '',
      shipperCity: fwb?.shipper?.place ?? '',
      shipperProvince: fwb?.shipper?.province ?? '',
      shipperCountryCode: fwb?.shipper?.country_code ?? '',
      shipperPostalCode: fwb?.shipper?.postal_code ?? '',
      shipperContactIdentifier: fwb?.shipper?.contact_identifier ?? '',
      shipperContactNumber: fwb?.shipper?.contact_number ?? '',

      consigneeAccountNumber: fwb?.consignee?.account_number ?? '',
      consigneeName: fwb?.consignee?.name ?? '',
      consigneeName2: fwb?.consignee?.name2 ?? '',
      consigneeStreetAddress: fwb?.consignee?.street_address ?? '',
      consigneeStreetAddress2: fwb?.consignee?.street_address2 ?? '',
      consigneeCity: fwb?.consignee?.place ?? '',
      consigneeProvince: fwb?.consignee?.province ?? '',
      consigneeCountryCode: fwb?.consignee?.country_code ?? '',
      consigneePostalCode: fwb?.consignee?.postal_code ?? '',
      consigneeContactIdentifier: fwb?.consignee?.contact_identifier ?? '',
      consigneeContactNumber: fwb?.consignee?.contact_number ?? '',

      agentAccountNumber: fwb?.agent?.account_number ?? '',
      agentIataCargoAgentNumericCode:
        fwb?.agent?.iata_cargo_agent_numeric_code ?? '',
      agentIataCargoAgentAddress: fwb?.agent?.iata_cargo_agent_address ?? '',
      agentParticipantIdentifier: fwb?.agent?.participant_identifier ?? '',
      agentName: fwb?.agent?.name ?? '',
      agentPlaces: fwb?.agent?.place ?? '',

      specialServiceRequest: fwb?.special_service_requests?.map((ssr) => ({
        value: ssr,
      })) ?? [{value: ''}],

      alsoNotifyName: fwb?.also_notify?.name ?? '',
      alsoNotifyAddress: fwb?.also_notify?.street_address ?? '',
      alsoNotifyPlace: fwb?.also_notify?.city ?? '',
      alsoNotifyProvince: fwb?.also_notify?.province ?? '',
      alsoNotifyCountryCode: fwb?.also_notify?.country_code ?? '',
      alsoNotifyPostalCode: fwb?.also_notify?.postal_code ?? '',
      alsoNotifyContactIdentifier: fwb?.also_notify?.contact_identifier ?? '',
      alsoNotifyContactNumber: fwb?.also_notify?.contact_number ?? '',

      accountingInformation: fwb?.accounting_information?.map((acc) => ({
        accountingInformationIdentifier:
          acc?.account_information_identifier ?? '',
        accountingInformation: acc?.accounting_information ?? '',
      })) ?? [
        {
          accountingInformationIdentifier: '',
          accountingInformation: '',
        },
      ],

      currencyCode: fwb?.charge_declarations?.iso_currency_code ?? '',
      chargeDeclarationsChargeCode: fwb?.charge_declarations?.charge_code ?? '',
      valuation: fwb?.charge_declarations?.valuation ?? '',
      otherCharges: fwb?.charge_declarations?.other_charges ?? '',
      declaredValueForCustoms:
        fwb?.charge_declarations?.declared_value_for_customs ?? '',
      declaredValueForCarriage:
        fwb?.charge_declarations?.declared_value_for_carriage ?? '',
      amountOfInsurance: fwb?.charge_declarations?.amount_of_insurance ?? '',

      rates: fwb?.rate_descriptions?.map((rate) => ({
        ratePieces: rate?.no_of_pieces ?? '',
        rateGrossWeight: rate?.gross_weight ?? '',
        rateKode: rate?.code ?? '',
        rateClass: rate?.rate_class ?? '',
        rateCommodityItemNo: rate?.commodity_item_no ?? '',
        rateChargeableWeight: rate?.chargeable_weight ?? '',
        rateCharge: rate?.rate_charge ?? '',
        rateTotal: rate?.total ?? '',
        rateNatureQtyGoods: rate?.nature_and_quantity_of_goods?.map((nqg) => ({
          value: nqg,
        })) ?? [{value: ''}],
      })) ?? [
        {
          ratePieces: '',
          rateGrossWeight: '',
          rateKode: '',
          rateClass: '',
          rateCommodityItemNo: '',
          rateChargeableWeight: '',
          rateCharge: '',
          rateTotal: '',
          rateNatureQtyGoods: [{value: ''}],
        },
      ],

      otherCharge: fwb?.other_charges?.map((oc) => ({
        otherChargeIndicator: oc?.other_charge_indicator ?? '',
        items: oc?.items?.map((item) => ({
          otherChargeChargeCode: item?.charge_code ?? '',
          entitlementCode: item?.entitlement_code ?? '',
          otherChargeAmount: item?.charge_amount ?? '',
        })) ?? [
          {
            otherChargeChargeCode: '',
            entitlementCode: '',
            otherChargeAmount: '',
          },
        ],
      })) ?? [
        {
          otherChargeIndicator: '',
          items: [
            {
              otherChargeChargeCode: '',
              entitlementCode: '',
              otherChargeAmount: '',
            },
          ],
        },
      ],

      prepaidTotalWeightCharge:
        fwb?.prepaid_charge_summary?.total_weight_charge_amount ?? '',
      prepaidValuationCharge:
        fwb?.prepaid_charge_summary?.valuation_charge_amount ?? '',
      prepaidTaxes: fwb?.prepaid_charge_summary?.taxes_charge_amount ?? '',
      prepaidTotalOrderChargeDueAgent:
        fwb?.prepaid_charge_summary?.total_order_charge_due_agent_amount ?? '',
      prepaidTotalOrderChargeDueCarrier:
        fwb?.prepaid_charge_summary?.total_order_charge_due_carrier_amount ??
        '',
      prepaidChargeSummaryTotal:
        fwb?.prepaid_charge_summary?.charge_summary_total_amount ?? '',

      collectTotalWeightCharge:
        fwb?.collect_charge_summary?.total_weight_charge_amount ?? '',
      collectValuationCharge:
        fwb?.collect_charge_summary?.valuation_charge_amount ?? '',
      collectTaxes: fwb?.collect_charge_summary?.taxes_charge_amount ?? '',
      collectTotalOrderChargeDueAgent:
        fwb?.collect_charge_summary?.total_order_charge_due_agent_amount ?? '',
      collectTotalOrderChargeDueCarrier:
        fwb?.collect_charge_summary?.total_order_charge_due_carrier_amount ??
        '',
      collectChargeSummaryTotal:
        fwb?.collect_charge_summary?.charge_summary_total_amount ?? '',

      shipperCertificationSignature: fwb?.shipper_signature ?? '',
      carrierExecutionDate: fwb?.shipper_certification?.executed_date ?? '',
      carrierExecutionPlace: fwb?.shipper_certification?.place ?? '',
      carrierExecutionSignature: fwb?.shipper_certification?.signature ?? '',

      otherService: fwb?.other_service_information?.map((service) => ({
        value: service,
      })) ?? [{value: ''}],

      destinationCurrencyCode:
        fwb?.cc_charge_destination_currency?.destination_currency_code ?? '',
      currencyConversationRate:
        fwb?.cc_charge_destination_currency?.currency_rate_exchange ?? '',
      ccChargeDestinationCurrency:
        fwb?.cc_charge_destination_currency?.cc_charge_amount ?? '',
      chargeDestinationAmount:
        fwb?.cc_charge_destination_currency?.charge_destination_amount ?? '',
      totalCollectChargeAmount:
        fwb?.cc_charge_destination_currency?.total_charge_amount ?? '',

      senderOfficeAirportCode:
        fwb?.sender_reference?.sender_office_airport_code ?? '',
      senderOfficeFunctionDesignator:
        fwb?.sender_reference?.sender_office_function_designator ?? '',
      senderCompanyDesignator:
        fwb?.sender_reference?.sender_office_company_designator ?? '',
      senderOfficeFileReference:
        fwb?.sender_reference?.sender_file_reference ?? '',
      senderDestinationAirport:
        fwb?.sender_reference?.sender_participant_airport_code ?? '',
      senderParticipantIdentifier:
        fwb?.sender_reference?.sender_participant_identifier ?? '',
      senderParticipantCode:
        fwb?.sender_reference?.sender_participant_code ?? '',

      noCommissionIndicator:
        fwb?.commission_information?.no_commission_indication ?? '',
      commissionAmount: fwb?.commission_information?.commission_amount ?? '',
      commissionPercentage:
        fwb?.commission_information?.commission_percentage ?? '',

      salesIncentiveInformationAmount:
        fwb?.sales_incentive_information?.charge_amount ?? '',
      salesIncentiveInformationCassIndicator:
        fwb?.sales_incentive_information?.cass_indication ?? '',
      fileReference: fwb?.agent_reference_file ?? '',

      hwbSpecialHandlingCode: fwb?.special_handling_code ?? [],
      nominatedHandlingPartyName: fwb?.nominated_handling_party?.name ?? '',
      nominatedHandlingPartyPlace: fwb?.nominated_handling_party?.place ?? '',

      shipmentReferenceNumber:
        fwb?.shipment_reference_information?.reference_number ?? '',
      shipmentReferenceSupplementaryInformation:
        fwb?.shipment_reference_information
          ?.supplementary_shipment_information ?? '',
      shipmentReferenceSupplementaryInformation2:
        fwb?.shipment_reference_information
          ?.supplementary_shipment_information2 ?? '',

      otherParticipantOffice: fwb?.other_participant_information?.map(
        (opo) => ({
          otherParticipantName: opo.name ?? '',
          otherParticipantOfficeAirportCode:
            opo.other_participant_office_airport_code ?? '',
          otherParticipantOfficeFunctionDesignator:
            opo.other_participant_office_function_designator ?? '',
          otherParticipantCompanyDesignator:
            opo.other_participant_office_company_designator ?? '',
          otherParticipantOfficeFileReference:
            opo.other_participant_office_file_reference ?? '',
          otherParticipantDestinationAirport:
            opo.other_participant_airport_code ?? '',
          otherParticipantParticipantIdentifier:
            opo.other_participant_identifier ?? '',
          otherParticipantParticipantCode: opo.other_participant_code ?? '',
        }),
      ) ?? [
        {
          otherParticipantName: '',
          otherParticipantOfficeAirportCode: '',
          otherParticipantOfficeFunctionDesignator: '',
          otherParticipantCompanyDesignator: '',
          otherParticipantOfficeFileReference: '',
          otherParticipantParticipantIdentifier: '',
          otherParticipantParticipantCode: '',
          otherParticipantDestinationAirport: '',
        },
      ],

      otherCustomInfo: fwb?.other_information?.map((oc) => ({
        countryCode: oc?.iso_country_code ?? '',
        customInfoIdentifier: oc?.custom_info_identifier ?? '',
        informationIdentifier: oc?.information_identifier ?? '',
        supplementaryCustomInformation: oc?.supplementary_information ?? '',
      })) ?? [
        {
          countryCode: '',
          customInfoIdentifier: '',
          informationIdentifier: '',
          supplementaryCustomInformation: '',
        },
      ],

      customOriginCode: fwb?.customs_origin ?? '',
    })

    setSelectedSpecialCodes(fwb?.special_handling_code ?? [])
  }

  const {
    data: detailData,
    error,
    isFetching,
    status,
  } = RequestOnlineAgentFormRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      if (detailData) {
        setValueEdit(detailData)
      }
    }
    if (!isFetching && status === 'error') {
      console.error(error)
    }
  }, [detailData, reset])

  const getAirport = async () => {
    const response = await apiBearer(BASE_API_URL + LIST_ALL_AIRPORT_URL)

    return response?.data?.data ?? []
  }

  const getCarrierCode = async () => {
    const response = await apiBearer(BASE_API_URL + LIST_ALL_CARRIER_CODE_URL)

    return response?.data?.data ?? []
  }

  const getCountryCode = async () => {
    const response = await apiBearer(BASE_API_URL + LIST_ALL_COUNTRY_CODE_URL)

    return response?.data?.data ?? []
  }

  const getCurrency = async () => {
    const response = await apiBearer(BASE_API_URL + LIST_ALL_CURRENCY_CODE_URL)

    return response?.data?.data ?? []
  }

  const getHandlingCode = async () => {
    const response = await apiBearer(BASE_API_URL + LIST_ALL_HANDLING_CODE_URL)

    return response?.data?.data ?? []
  }

  const getCustomInfo = async () => {
    const response = await apiBearer(
      BASE_API_URL + LIST_ALL_CUSTOM_INFO_IDENTIFIER_URL,
    )

    return response?.data?.data ?? []
  }

  const getInformation = async () => {
    const response = await apiBearer(
      BASE_API_URL + LIST_ALL_INFORMATION_IDENTIFIER_URL,
    )

    return response?.data?.data ?? []
  }

  const getChargeCode = async () => {
    const response = await apiBearer(BASE_API_URL + LIST_ALL_CHARGE_CODE_URL)

    return response?.data?.data ?? []
  }

  const getAirlines = async () => {
    const response = await apiBearer(BASE_API_URL + LIST_AIRLINE_ALL)

    return response?.data?.data ?? []
  }

  useEffect(() => {
    Promise.all([
      getAirport(),
      getCarrierCode(),
      getCountryCode(),
      getCurrency(),
      getHandlingCode(),
      getCustomInfo(),
      getInformation(),
      getChargeCode(),
      getAirlines(),
    ]).then(
      ([
        airport,
        carrierCode,
        countryCode,
        currency,
        handlingCode,
        customInfo,
        information,
        chargeCode,
        airlines,
      ]) => {
        setAirportList(airport)
        setCarrierCodeList(carrierCode)
        setCountryCodeList(countryCode)
        setCurrencyList(currency)
        setHandlingCodeList(handlingCode)
        setCustomInfoList(customInfo)
        setInformationList(information)
        setChargeCodeList(chargeCode)
        setAirlineList(airlines)
      },
    )
  }, [])

  const getTotalChargeAmount = useCallback(
    ({otherChargeIndicator, entitlementCode}) => {
      let total = 0
      const watchedOtherCharge = watch('otherCharge')

      watchedOtherCharge
        ?.filter((item) => item?.otherChargeIndicator === otherChargeIndicator)
        .forEach((otherCharge, index) => {
          otherCharge?.items?.forEach((oc, idx) => {
            if (oc.entitlementCode === entitlementCode) {
              total += parseNumber(oc?.otherChargeAmount)
            }
          })
        })

      return total
    },
    [watch],
  )

  const setChargeSummary = useCallback(() => {
    const watchedRates = watch('rates') || []
    const watchedOtherCharge = watch('otherCharge') || []

    const otherChargeIndicator =
      watchedOtherCharge[0]?.otherChargeIndicator || ''

    const rateTotal = watchedRates.reduce(
      (total, _, index) =>
        total + parseNumber(watch(`rates.${index}.rateTotal`)),
      0,
    )

    const prepaidTotalWeightCharge =
      otherChargeIndicator === 'P' ? rateTotal : 0
    const collectTotalWeightCharge =
      otherChargeIndicator === 'C' ? rateTotal : 0

    const rateTotalAgentPrepaid = getTotalChargeAmount({
      otherChargeIndicator: 'P',
      entitlementCode: 'A',
    })
    const rateTotalCarrierPrepaid = getTotalChargeAmount({
      otherChargeIndicator: 'P',
      entitlementCode: 'C',
    })

    const rateTotalAgentCollect = getTotalChargeAmount({
      otherChargeIndicator: 'C',
      entitlementCode: 'A',
    })
    const rateTotalCarrierCollect = getTotalChargeAmount({
      otherChargeIndicator: 'C',
      entitlementCode: 'C',
    })

    const valuationChargePrepaid = parseNumber(watch('prepaidValuationCharge'))
    const taxesPrepaid = parseNumber(watch('prepaidTaxes'))
    const prepaidChargeSummaryTotal =
      prepaidTotalWeightCharge +
      valuationChargePrepaid +
      taxesPrepaid +
      rateTotalAgentPrepaid +
      rateTotalCarrierPrepaid

    const valuationChargeCollect = parseNumber(watch('collectValuationCharge'))
    const taxesCollect = parseNumber(watch('collectTaxes'))
    const collectChargeSummaryTotal =
      collectTotalWeightCharge +
      valuationChargeCollect +
      taxesCollect +
      rateTotalAgentCollect +
      rateTotalCarrierCollect

    setValue('collectTotalWeightCharge', collectTotalWeightCharge || '')
    setValue('prepaidTotalWeightCharge', prepaidTotalWeightCharge || '')
    setValue('prepaidTotalOrderChargeDueAgent', rateTotalAgentPrepaid || '')
    setValue('prepaidTotalOrderChargeDueCarrier', rateTotalCarrierPrepaid || '')
    setValue('prepaidChargeSummaryTotal', prepaidChargeSummaryTotal || '')
    setValue('collectTotalOrderChargeDueAgent', rateTotalAgentCollect || '')
    setValue('collectTotalOrderChargeDueCarrier', rateTotalCarrierCollect || '')
    setValue('collectChargeSummaryTotal', collectChargeSummaryTotal || '')
  }, [watch, getTotalChargeAmount, setValue])

  const onCalculateCharge = debounce(setChargeSummary, 500)

  const navigateToList = () => {
    if (isAdmin) {
      navigate('/app/request-agent-online-form')
    } else {
      navigate('/app/fwb-fhl')
    }
  }

  // TODO: remove this script on production
  useEffect(() => {
    window.fillForm = async () => {
      // for testing only
      clearErrors()
      const formData = {
        airlinePrefix: '165',
        awbSerialNumber: '87654321',
        awbOrigin: 'CGK',
        awbDestination: 'AGD',
        awbShipmentDescriptionCode: 'T',
        awbWeightCode: 'K',
        awbNumberOfPieces: 5,
        awbWeight: 50,
        awbVolumeCode: 'V',
        awbVolumeAmount: 120,
        awbDensityIndicator: 'D',
        awbDensityGroup: 'G',
        flightBookings: [
          {
            flightBookingCarrierCode: 'GA',
            flightBookingFlightNumber: '1234',
            flightBookingFlightDay: '10',
          },
        ],
        destinationAirportCode: 'AAA',
        destinationCarrierCode: 'GA',
        routing: [
          {
            onwardDestinationAirportCode: 'AAC',
            onwardDestinationCarrierCode: 'JT',
          },
        ],
        shipperAccountNumber: 'SH123456',
        shipperName: 'John Doe',
        shipperName2: '',
        shipperStreetAddress: 'Jl. Merdeka No. 1',
        shipperStreetAddress2: '',
        shipperCity: 'Jakarta',
        shipperProvince: 'DKI Jakarta',
        shipperCountryCode: 'ID',
        shipperPostalCode: '10110',
        shipperContactIdentifier: '',
        shipperContactNumber: '08123456789',
        consigneeAccountNumber: 'CS987654',
        consigneeName: 'Jane Smith',
        consigneeName2: '',
        consigneeStreetAddress: 'Jl. Sudirman No. 99',
        consigneeStreetAddress2: '',
        consigneeCity: 'Denpasar',
        consigneeProvince: 'Bali',
        consigneeCountryCode: 'ID',
        consigneePostalCode: '80226',
        consigneeContactIdentifier: '',
        consigneeContactNumber: '08987654321',
        agentAccountNumber: 'AG567890',
        agentIataCargoAgentNumericCode: '3935687',
        agentIataCargoAgentAddress: '0936',
        agentParticipantIdentifier: 'PPP',
        agentName: 'Cargo Express',
        agentPlaces: 'Jakarta',
        specialServiceRequest: [{value: 'Fragile'}],
        alsoNotifyName: 'Michael Scott',
        alsoNotifyAddress: 'Jl. Bekasi Raya No. 22',
        alsoNotifyPlace: 'Surabaya',
        alsoNotifyProvince: 'Jawa Timur',
        alsoNotifyCountryCode: 'ID',
        alsoNotifyPostalCode: '60123',
        alsoNotifyContactIdentifier: '',
        alsoNotifyContactNumber: '08234567890',
        accountingInformation: [
          {
            accountingInformationIdentifier: 'MCO',
            accountingInformation: 'Pembayaran via bank transfer',
          },
        ],
        currencyCode: 'IDR',
        chargeDeclarationsChargeCode: 'ACC',
        valuation: 'C',
        otherCharges: 'P',
        declaredValueForCarriage: '2000000',
        declaredValueForCustoms: '2000000',
        amountOfInsurance: '500000',
        rates: [
          {
            ratePieces: 5,
            rateGrossWeight: 50,
            rateKode: 'K',
            rateClass: 'N',
            rateCommodityItemNo: '1001',
            rateChargeableWeight: 48,
            rateCharge: '200000',
            rateTotal: '1000000',
            rateNatureQtyGoods: [{value: 'Perishable'}],
          },
        ],
        otherCharge: [
          {
            otherChargeIndicator: 'C',
            items: [
              {
                otherChargeChargeCode: 'ASC',
                entitlementCode: 'C',
                otherChargeAmount: '100000',
              },
            ],
          },
        ],
        // prepaidTotalWeightCharge: '150000',
        prepaidValuationCharge: '20000',
        prepaidTaxes: '30000',
        prepaidTotalOrderChargeDueAgent: '50000',
        prepaidTotalOrderChargeDueCarrier: '75000',
        prepaidChargeSummaryTotal: '250000',
        // collectTotalWeightCharge: '120000',
        collectValuationCharge: '15000',
        collectTaxes: '25000',
        collectTotalOrderChargeDueAgent: '40000',
        collectTotalOrderChargeDueCarrier: '60000',
        collectChargeSummaryTotal: '200000',
        shipperCertificationSignature: 'John Doe',
        carrierExecutionDate: '25FEB25',
        carrierExecutionPlace: 'Jakarta',
        carrierExecutionSignature: 'Cargo Express',
        otherService: [{value: 'Cooling Required'}],
        destinationCurrencyCode: 'AAC',
        currencyConversationRate: '15000',
        ccChargeDestinationCurrency: '50',
        chargeDestinationAmount: '750000',
        totalCollectChargeAmount: '2000000',
        senderDestinationAirport: 'DPS',
        senderOfficeFunctionDesignator: 'XX',
        senderCompanyDesignator: 'YY',
        senderOfficeFileReference: 'REF123',
        senderParticipantIdentifier: 'EXD',
        senderParticipantCode: 'PC001',
        senderOfficeAirportCode: 'AAL',
        customOriginCode: 'ORI123',
        noCommissionIndicator: 'N',
        commissionAmount: '',
        commissionPercentage: '',
        salesIncentiveInformationAmount: '20000',
        salesIncentiveInformationCassIndicator: 'Y',
        fileReference: 'FILE001',
        hwbSpecialHandlingCode: ['AVI'],
        nominatedHandlingPartyName: 'Logistics Indonesia',
        nominatedHandlingPartyPlace: 'Jakarta',
        shipmentReferenceNumber: 'SHIP98765',
        shipmentReferenceSupplementaryInformation: 'Handle with care',
        shipmentReferenceSupplementaryInformation2: '',
        otherParticipantOffice: [
          {
            otherParticipantName: 'Sub Agent A',
            otherParticipantOfficeAirportCode: 'AAB',
            otherParticipantOfficeFunctionDesignator: 'XC',
            otherParticipantCompanyDesignator: 'AS',
            otherParticipantOfficeFileReference: 'REF999',
            // otherParticipantDestinationAirport: 'AAB',
            // otherParticipantParticipantIdentifier: 'PART002',
            // otherParticipantParticipantCode: 'PC002',
          },
        ],
        otherCustomInfo: [
          {
            countryCode: 'ID',
            customInfoIdentifier: 'AN',
            informationIdentifier: 'ACK',
            supplementaryCustomInformation: 'No additional tax',
          },
        ],
      }

      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key])
      })

      // await toggleTab(5)
      console.log('✅ Form berhasil diisi otomatis!')
    }
  }, [setValue])

  return (
    <div className='page-content position-relative'>
      <Container fluid>
        <BreadCrumb title='FWB' pageTitle='FWB' />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/*<form>*/}
              <Card>
                <CardHeader
                  className='align-items-center d-flex position-sticky'
                  style={{top: 70, zIndex: 100}}
                >
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id && !type ? 'Update' : 'Create'} FWB
                  </h4>
                  <Button
                    color='light'
                    className='btn-icon me-2'
                    disabled={activeTab === 1}
                    onClick={() => toggleTab(activeTab - 1)}
                  >
                    <i className=' ri-arrow-left-line' />
                  </Button>
                  <Button
                    color={'primary'}
                    outline
                    className='rounded-pill'
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => toggleTab(activeTab + 1)}
                    disabled={activeTab === 5}
                  >
                    <i className='ri-arrow-right-line label-icon align-middle fs-16 me-2'></i>
                    Next Step
                  </Button>
                </CardHeader>
                <CardBody className='card-body'>
                  <HeaderNavFwb activeTab={activeTab} toggleTab={toggleTab} />
                  <TabContent activeTab={activeTab}>
                    <Step1Fwb
                      errors={errors}
                      control={control}
                      setValue={setValue}
                      setError={setError}
                      getValues={getValues}
                      clearErrors={clearErrors}
                      fieldsFlightBookings={fieldsFlightBookings}
                      appendFlightBookings={appendFlightBookings}
                      removeFlightBookings={removeFlightBookings}
                      fieldsRouting={fieldsRouting}
                      removeRouting={removeRouting}
                      appendRouting={appendRouting}
                      airportList={airportList}
                      careerCodeList={carrierCodeList}
                      countryCodeList={countryCodeList}
                      airlineList={airlineList}
                    />
                    <Step2Fwb
                      errors={errors}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      clearErrors={clearErrors}
                      fieldSpecialServiceRequest={fieldSpecialServiceRequest}
                      appendSpecialServiceRequest={appendSpecialServiceRequest}
                      removeSpecialServiceRequest={removeSpecialServiceRequest}
                      fieldsAccountingInformation={fieldsAccountingInformation}
                      appendAccountingInformation={appendAccountingInformation}
                      removeAccountingInformation={removeAccountingInformation}
                      fieldsRates={fieldsRates}
                      appendRates={appendRates}
                      removeRates={removeRates}
                      currencyList={currencyList}
                      countryCodeList={countryCodeList}
                      chargeCodeList={chargeCodeList}
                      onCalculateCharge={onCalculateCharge}
                    />
                    <Step3Fwb
                      errors={errors}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      clearErrors={clearErrors}
                      fieldsRates={fieldsRates}
                      removeRates={removeRates}
                      appendRates={appendRates}
                      fieldsOtherCharge={fieldsOtherCharge}
                      appendOtherCharge={appendOtherCharge}
                      removeOtherCharge={removeOtherCharge}
                      fieldsOtherService={fieldsOtherService}
                      appendOtherService={appendOtherService}
                      removeOtherService={removeOtherService}
                      chargeCodeList={chargeCodeList}
                      onCalculateCharge={onCalculateCharge}
                      trigger={trigger}
                      watch={watch}
                      setError={setError}
                    />
                    <Step4Fwb
                      errors={errors}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      clearErrors={clearErrors}
                      currencyList={currencyList}
                      airportList={airportList}
                      trigger={trigger}
                      fieldsSenderOffice={fieldsSenderOffice}
                      fieldsSenderParticipant={fieldsSenderParticipant}
                    />
                    <Step5Fwb
                      errors={errors}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      clearErrors={clearErrors}
                      selected={selectedSpecialCodes}
                      fieldsOtherParticipantOffice={
                        fieldsOtherParticipantOffice
                      }
                      appendOtherParticipantOffice={
                        appendOtherParticipantOffice
                      }
                      removeOtherParticipantOffice={
                        removeOtherParticipantOffice
                      }
                      removeOtherCustomInfo={removeOtherCustomInfo}
                      appendOtherCustomInfo={appendOtherCustomInfo}
                      fieldsOtherCustomInfo={fieldsOtherCustomInfo}
                      handlingCodeList={handlingCodeList}
                      customInfoList={customInfoList}
                      countryCodeList={countryCodeList}
                      informationList={informationList}
                      airportList={airportList}
                      setError={setError}
                    />
                  </TabContent>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-center gap-3'>
                  <Button
                    type='button'
                    className='btn btn-light'
                    onClick={navigateToList}
                    style={{
                      width: 120,
                      color: '#69809A',
                      backgroundColor: '#E7EBEF',
                    }}
                  >
                    Cancel
                  </Button>
                  {activeTab === 5 &&
                    (((detailData?.data?.is_draft === true ||
                      detailData?.data?.is_draft === undefined) &&
                      detailData?.data?.type !==
                        ONLINE_AGENT_TYPE_DOCUMENT.UPLOAD) ||
                      type === 'copy' ||
                      detailData?.data?.payment_status ===
                        ONLINE_AGENT_PAYMENT_STATUS.UNPAID) && (
                      <Button
                        // type='submit'
                        color='secondary'
                        value='submit'
                        style={{width: 120}}
                        disabled={isLoadingCreate || isLoadingUpdate}
                        onClick={_onDraft}
                      >
                        {isLoadingCreate || isLoadingUpdate
                          ? 'Loading...'
                          : 'Save'}
                      </Button>
                    )}
                  {activeTab === 5 && (
                    <Button
                      color='primary'
                      type='submit'
                      value='pay'
                      disabled={isLoadingCreate || isLoadingUpdate}
                      style={{width: 120}}
                    >
                      {isLoadingCreate || isLoadingUpdate
                        ? 'Loading...'
                        : detailData?.data?.payment_status === 'PAID' &&
                          type !== 'copy'
                        ? 'Save'
                        : 'Save and Pay'}
                    </Button>
                  )}
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
