import { formatNumber } from "../../Helpers/number";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { getInvLink } from "Components/Cdo/helpers";
import { useSelector } from "react-redux";
import {getTransactionTypeLabel} from '../../Helpers/options'

export function HistoryBalanceItem({
  page,
  index,
  id,
  code,
  date,
  amount,
  transaction_type,
  table,
  reff_id,
}) {
  const { profile } = useSelector((state) => state.auth);
  return (
    <tr>
      <td className="fw-medium">{index + page + 1}</td>
      {/* <td>
        <div className="hstack gap-3 fs-15">
        <Link to={`/app/${table}/detail/${id}`} className="link-success">
            <i className="ri-eye-fill"></i>
          </Link>
        </div>
      </td> */}
      <td>
        <Link to={getInvLink(transaction_type, reff_id, profile.role)} target="_blank">
          {code}
        </Link>
      </td>
      <td>{moment(date).format("DD MMMM YYYY")}</td>
      <td>{getTransactionTypeLabel(transaction_type)}</td>
      <td>Rp.{formatNumber(amount)}</td>
    </tr>
  );
}
